"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fontFamilySecondary = exports.fontFamilyPrimary = exports.pxToPercent = exports.pxToRem = void 0;
const createTypography_1 = require("@material-ui/core/styles/createTypography");
const palette_1 = require("./palette");
const fontSize = 14;
const htmlFontSize = 16;
const coef = fontSize / 14;
function pxToRem(value) {
    return `${(value / htmlFontSize) * coef}rem`;
}
exports.pxToRem = pxToRem;
function pxToPercent(value, fontSize) {
    return value / fontSize;
}
exports.pxToPercent = pxToPercent;
const fontWeightBold = 700;
const fontWeightSemiBold = 600;
const fontWeightRegular = 400;
exports.fontFamilyPrimary = ['Open Sans', 'Arial', 'sans-serif'].join(',');
exports.fontFamilySecondary = ['Roboto Slab', 'Helvetica', 'Arial', 'sans-serif'].join(',');
const uppercase = 'uppercase';
const capitalize = 'capitalize';
const typography = createTypography_1.default(palette_1.default, {
    fontSize,
    htmlFontSize,
    h1: {
        fontSize: pxToRem(60),
        letterSpacing: pxToRem(0),
        lineHeight: pxToPercent(68, 60),
        fontWeight: fontWeightBold,
        fontFamily: exports.fontFamilyPrimary,
        textTransform: uppercase,
    },
    h2: {
        fontSize: pxToRem(48),
        letterSpacing: pxToRem(0.48),
        lineHeight: pxToPercent(58, 48),
        fontWeight: fontWeightBold,
        fontFamily: exports.fontFamilyPrimary,
        textTransform: uppercase,
    },
    h3: {
        fontSize: pxToRem(40),
        letterSpacing: pxToRem(1),
        lineHeight: pxToPercent(48, 40),
        fontWeight: fontWeightBold,
        fontFamily: exports.fontFamilyPrimary,
        textTransform: uppercase,
    },
    h4: {
        fontSize: pxToRem(32),
        letterSpacing: pxToRem(1.13),
        lineHeight: pxToPercent(40, 32),
        fontWeight: fontWeightBold,
        fontFamily: exports.fontFamilyPrimary,
        textTransform: uppercase,
    },
    h5: {
        fontSize: pxToRem(24),
        letterSpacing: pxToRem(0.4),
        lineHeight: pxToPercent(33, 24),
        fontWeight: fontWeightBold,
        fontFamily: exports.fontFamilyPrimary,
        textTransform: uppercase,
    },
    h6: {
        fontSize: pxToRem(18),
        letterSpacing: pxToRem(1.5),
        lineHeight: pxToPercent(28, 18),
        fontWeight: fontWeightBold,
        fontFamily: exports.fontFamilyPrimary,
        textTransform: uppercase,
    },
    subtitle1: {
        fontSize: pxToRem(16),
        letterSpacing: pxToRem(0.15),
        lineHeight: pxToPercent(26, 16),
        fontWeight: fontWeightBold,
        fontFamily: exports.fontFamilySecondary,
        textTransform: capitalize,
    },
    subtitle2: {
        fontSize: pxToRem(14),
        letterSpacing: pxToRem(0.9),
        lineHeight: pxToPercent(26, 14),
        fontWeight: fontWeightBold,
        fontFamily: exports.fontFamilyPrimary,
        textTransform: capitalize,
    },
    body1: {
        fontSize: pxToRem(16),
        letterSpacing: pxToRem(0.5),
        lineHeight: pxToPercent(26, 16),
        fontWeight: fontWeightRegular,
        fontFamily: exports.fontFamilySecondary,
        textTransform: 'none',
    },
    body2: {
        fontSize: pxToRem(14),
        letterSpacing: pxToRem(0.25),
        lineHeight: pxToPercent(26, 14),
        fontWeight: fontWeightRegular,
        fontFamily: exports.fontFamilySecondary,
        textTransform: uppercase,
    },
    button: {
        fontSize: pxToRem(16),
        letterSpacing: pxToRem(1.5),
        lineHeight: pxToPercent(26, 16),
        fontWeight: fontWeightSemiBold,
        fontFamily: exports.fontFamilyPrimary,
        textTransform: uppercase,
    },
    caption: {
        fontSize: pxToRem(12),
        letterSpacing: pxToRem(0.4),
        lineHeight: pxToPercent(26, 12),
        fontWeight: fontWeightRegular,
        fontFamily: exports.fontFamilySecondary,
        textTransform: capitalize,
        opacity: '60%',
    },
    overline: {
        fontSize: pxToRem(18),
        letterSpacing: pxToRem(1.35),
        lineHeight: pxToPercent(32, 18),
        fontWeight: fontWeightSemiBold,
        fontFamily: exports.fontFamilySecondary,
        textTransform: uppercase,
    },
});
exports.default = typography;
