"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_infinite_scroll_component_1 = require("react-infinite-scroll-component");
const LoadingProductCard_1 = require("@components/pages/search/LoadingProductCard");
const makeStyles_1 = require("@material-ui/core/styles/makeStyles");
const createStyles_1 = require("@material-ui/core/styles/createStyles");
const LabelContext_1 = require("@components/common/context/LabelContext");
const ProductCard_1 = require("@components/pages/product/ProductCard");
const HBTypography_1 = require("@components/library/HBTypography");
const useStyles = makeStyles_1.default(({ spacing }) => createStyles_1.default({
    root: {
        padding: spacing(0),
        position: 'relative',
        minHeight: '100vh',
    },
    grid: {
        outline: 'none',
        marginTop: spacing(1),
        marginBottom: spacing(1),
        display: 'flex',
        flexWrap: 'wrap',
    },
    gridItem: {
        padding: spacing(1.5),
        width: '25%',
        display: 'flex',
        flex: 'none',
        alignContent: 'stretch',
        boxSizing: 'border-box',
        '@media (max-width: 1024px)': {
            width: '33%',
        },
        '@media (max-width: 768px)': {
            width: '50%',
        },
        '@media (max-width: 480px)': {
            width: '50%',
        },
    },
    footer: {
        padding: '1rem',
        textAlign: 'center',
    },
}));
const VirtualGrid = ({ products, loadMore, isLoading, endReached }) => {
    const classes = useStyles();
    const labels = LabelContext_1.useLabelContext();
    const [isMounted, setIsMounted] = React.useState(false);
    React.useEffect(() => {
        setIsMounted(true);
    }, []);
    return (React.createElement("div", { className: classes.root }, isMounted && (React.createElement(react_infinite_scroll_component_1.default, { dataLength: products.length, next: loadMore, hasMore: !endReached, loader: React.createElement("div", { className: classes.grid }, [...Array(10).keys()].map(index => (React.createElement("div", { key: index, className: classes.gridItem },
            React.createElement(LoadingProductCard_1.default, null))))), endMessage: React.createElement(HBTypography_1.default, { align: "center", variant: "h6", marginTop: 5, marginBottom: 5 }, labels.END_OF_SEARCH_RESULTS), scrollThreshold: "80%", style: { overflow: 'unset' } },
        React.createElement("div", { className: classes.grid }, products.map((product, index) => (React.createElement("div", { key: index, className: classes.gridItem },
            React.createElement(ProductCard_1.default, Object.assign({}, product, { imgSize: 258 }))))))))));
};
exports.default = VirtualGrid;
