"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const Grid_1 = require("@material-ui/core/Grid");
const createStyles_1 = require("@material-ui/core/styles/createStyles");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const styles = () => createStyles_1.default({
    root: {
        overflow: 'hidden',
        width: '100%',
        height: '100%',
        '& img': {
            transition: 'transform 6s ease-out',
        },
        '&:hover': {
            '& img': {
                transform: 'scale(1.2)',
            },
        },
    },
    dense: {
        '& img': {
            transition: 'transform 4s ease-out',
        },
        '&:hover': {
            '& img': {
                transform: 'scale(1.15)',
            },
        },
    },
});
const ZoomOutImage = ({ classes, className, dense, children }) => (React.createElement(Grid_1.default, { item: true, className: classnames_1.default(className, classes.root, { [classes.dense]: dense }) }, children));
exports.default = withStyles_1.default(styles)(ZoomOutImage);
