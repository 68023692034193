"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const HBImageStyles_1 = require("./HBImageStyles");
const LazyImage_1 = require("@components/library/HBImage/LazyImage");
const useMediaQuery_1 = require("@material-ui/core/useMediaQuery");
const HBImage = ({ classes, className, src, alt = '', responsive = true, size, style }) => {
    const mediaQueryOne = useMediaQuery_1.default('(min-width:0px)');
    const mediaQueryTwo = useMediaQuery_1.default('(min-width:600px)');
    const mediaQueryThree = useMediaQuery_1.default('(min-width:900px)');
    const mediaQueryFour = useMediaQuery_1.default('(min-width:1200px)');
    const mediaQueryFive = useMediaQuery_1.default('(min-width:1536px)');
    function mediaQueryCheck() {
        switch (mediaQueryOne || mediaQueryTwo || mediaQueryThree || mediaQueryFour || mediaQueryFive) {
            case mediaQueryFive:
                return '2200';
            case mediaQueryFour:
                return '1536';
            case mediaQueryThree:
                return '1200';
            case mediaQueryTwo:
                return '900';
            default:
                return '600';
        }
    }
    const imgSrc = !!size ? `${src}?w=${size}&h=${size}&crop` : `${src}?w=${mediaQueryCheck()}&crop`;
    return (React.createElement(LazyImage_1.default, { src: imgSrc, alt: alt, className: classnames_1.default(className, classes.image), placeholder: `${src}?w=20&h=20&crop`, style: style }));
};
exports.default = withStyles_1.default(HBImageStyles_1.default)(HBImage);
