"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const createStyles_1 = require("@material-ui/core/styles/createStyles");
const ButtonBase_1 = require("@material-ui/core/ButtonBase");
const HBIcon_1 = require("@components/library/HBIcon");
const styles = ({ palette, transitions }) => createStyles_1.default({
    root: {
        width: 40,
        height: 40,
        zIndex: 1,
        flexShrink: 0,
        opacity: 0.8,
        '&$disabled': {
            opacity: 0,
        },
        backgroundColor: palette.primary.main,
        color: palette.common.white,
        transition: transitions.create(['all']),
    },
    vertical: {
        width: '100%',
        position: 'absolute',
        left: 0,
    },
    upIcon: {
        top: 0,
    },
    downIcon: {
        bottom: 0,
    },
    disabled: {},
});
const verticalOrientationIcons = {
    left: 'arrow_up',
    right: 'arrow_down',
};
const defaultOrientationIcons = {
    left: 'arrow_left',
    right: 'arrow_right',
};
const TabScrollButton = ({ className, classes, direction, orientation, disabled, ...other }) => {
    return (React.createElement(ButtonBase_1.default, Object.assign({ component: "div", className: classnames_1.default(classes.root, {
            [classes.vertical]: orientation === 'vertical',
            [classes.disabled]: disabled,
        }, className, direction === 'left' ? classes.upIcon : classes.downIcon) }, other), orientation !== 'vertical' ? (React.createElement(HBIcon_1.default, { icon: defaultOrientationIcons[direction], fontSize: "2xsmall", color: "default", strokeWidth: 3 })) : (React.createElement(HBIcon_1.default, { icon: verticalOrientationIcons[direction], fontSize: "2xsmall", color: "default", strokeWidth: 3 }))));
};
exports.default = withStyles_1.default(styles)(TabScrollButton);
