"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const withLayout_1 = require("@components/common/layout/withLayout");
const VirtualGridRenderer_1 = require("@components/common/VirtualGridRenderer");
const VirtualGridRendererContext_1 = require("@components/common/context/VirtualGridRendererContext");
const HBExpander_1 = require("@components/library/HBExpander/HBExpander");
const BrandCampaignCard_1 = require("@components/pages/brand/BrandCampaignCard/BrandCampaignCard");
const HBContainer_1 = require("@components/library/HBContainer");
const HBTypography_1 = require("@components/library/HBTypography");
const Grid_1 = require("@material-ui/core/Grid");
const PageProductList = ({ page: { headline, brand, fullSlug, name, products, brandContent = [], visibleHeight = 400 }, }) => {
    return (React.createElement(VirtualGridRendererContext_1.default, { fullSlug: fullSlug, initData: { products } },
        React.createElement(Grid_1.default, { container: true, direction: "column", alignItems: "center" },
            React.createElement(HBTypography_1.default, { variant: "h6", component: "h1", gutterBottom: true }, brand === null || brand === void 0 ? void 0 : brand.name),
            headline && (React.createElement(HBTypography_1.default, { variant: "h2", align: "center" }, headline))),
        React.createElement(HBContainer_1.default, null, !!brandContent.length && (React.createElement(HBExpander_1.default, { collapsedHeight: visibleHeight, border: false }, brandContent.map((campaign, idX) => (React.createElement(BrandCampaignCard_1.default, Object.assign({ key: idX.toString() }, campaign))))))),
        React.createElement(VirtualGridRenderer_1.default, { name: name, dense: true, border: true })));
};
exports.default = withLayout_1.default(PageProductList, { paddingTop: 0, paddingBottom: 0 });
