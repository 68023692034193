"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Svg_1 = require("./Svg");
const G_1 = require("./Svg/G");
const LocationPin = ({ color, strokeWidth, ...rest }) => (React.createElement(Svg_1.default, Object.assign({}, rest),
    React.createElement(G_1.default, { transform: "translate(2 1)", color: color, strokeWidth: strokeWidth, fill: "none", fillRule: "evenodd" },
        React.createElement("path", { d: "M10 0a11.763 11.763 0 016.112 1.693c4.75 2.88 5.1 8.829 1.315 13.178A56.376 56.376 0 0110 22a56.376 56.376 0 01-7.427-7.129C-1.211 10.521-.862 4.573 3.888 1.693A11.763 11.763 0 0110 0z", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("circle", { cx: "10", cy: "9", r: "4" }))));
exports.default = LocationPin;
