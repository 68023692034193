"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Svg_1 = require("./Svg");
const G_1 = require("./Svg/G");
const Shop = ({ color = 'primary', ...rest }) => (React.createElement(Svg_1.default, Object.assign({}, rest, { viewBox: "0 0 52 46" }),
    React.createElement(G_1.default, { transform: "translate(0 1.333)", color: color, strokeWidth: "2", fill: "none", fillRule: "evenodd", strokeLinecap: "round", strokeLinejoin: "round" },
        React.createElement("path", { d: "M46.583 16.982v24.185c0 1.196-.97 2.166-2.166 2.166H7.583a2.167 2.167 0 01-2.166-2.166V16.982" }),
        React.createElement("rect", { height: "13", rx: ".5", width: "17.333", x: "9.75", y: "23.833" }),
        React.createElement("path", { d: "M42.25 43.333H31.417V24.917c0-.599.485-1.084 1.083-1.084h8.667c.598 0 1.083.485 1.083 1.084zM47.181 1.198A2.167 2.167 0 0045.244 0H6.756c-.82 0-1.57.464-1.937 1.198L1.083 8.667h49.834zM50.917 10.833a6.201 6.201 0 01-10.834 4.334A8.77 8.77 0 0126 16.553a8.77 8.77 0 01-14.083-1.386 6.203 6.203 0 01-10.834-4.334V8.667h49.834z" }),
        React.createElement("path", { d: "M11.917 15.167v-6.5L14.083 0M26 16.564V0M40.083 15.167v-6.5L37.917 0" }))));
exports.default = Shop;
