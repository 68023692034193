"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const typography_1 = require("./typography");
const resetMargins = {
    '&:first-child': {
        marginTop: 0,
    },
    '&:last-child': {
        marginBottom: 0,
    },
};
const marginHeadlines = {
    marginTop: '1.5em',
    marginBottom: '0.5em',
    ...resetMargins,
};
const marginCopy = {
    marginTop: '0.67em',
    marginBottom: '0.67em',
    ...resetMargins,
};
const styles = ({ palette, typography }) => ({
    '@global': {
        html: {
            color: palette.text.primary,
        },
        body: {
            ...typography.body2,
        },
        p: {
            ...typography.body1,
        },
        h1: { ...typography.h1, ...marginHeadlines },
        h2: { ...typography.h2, ...marginHeadlines },
        h3: { ...typography.h3, ...marginHeadlines },
        h4: { ...typography.h4 },
        ul: { ...marginCopy },
        a: {
            fontSize: typography_1.pxToRem(18),
            letterSpacing: typography_1.pxToRem(1.35),
            lineHeight: typography_1.pxToPercent(32, 18),
            fontFamily: ['Roboto Slab', 'Helvetica', 'Arial', 'sans-serif'].join(','),
            textTransform: 'uppercase',
            fontWeight: 'normal',
            textDecoration: 'underline',
            color: 'inherit',
        },
        '.progress': {
            position: 'fixed',
            top: 0,
            height: 4,
            zIndex: 9999,
            width: '100%',
        },
    },
});
exports.default = styles;
