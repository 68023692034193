"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const LabelContext_1 = require("@components/common/context/LabelContext");
const SearchInputContext_1 = require("@components/common/context/SearchInputContext");
const ProductBestseller_1 = require("@components/common/layout/Header/Search/ProductBestseller");
const SearchInput_1 = require("@components/common/layout/Header/Search/SearchInput");
const HBFilter_1 = require("@components/library/HBFilter");
const HBIcon_1 = require("@components/library/HBIcon");
const HBIconButton_1 = require("@components/library/HBIconButton/HBIconButton");
const LoadingLogo_1 = require("@components/library/icons/LoadingLogo");
const Backdrop_1 = require("@material-ui/core/Backdrop");
const Grid_1 = require("@material-ui/core/Grid");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const classnames_1 = require("classnames");
const React = require("react");
const react_router_dom_1 = require("react-router-dom");
const SearchContext_1 = require("@components/common/context/SearchContext");
const BrandGallery_1 = require("@components/pages/home/BrandGallery/BrandGallery");
const SearchStyles_1 = require("./SearchStyles");
const Search = ({ classes }) => {
    var _a, _b, _c;
    const history = react_router_dom_1.useHistory();
    const labels = LabelContext_1.useLabelContext();
    const { focused, handleCloseSearch, handleOpenSearch, isSearching, typeAheadResults } = SearchInputContext_1.useSearchInputContext();
    const { handleSelectedFilters } = SearchContext_1.useSearchContext();
    React.useEffect(() => {
        if (focused) {
            handleCloseSearch();
        }
    }, [history.location.search]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Backdrop_1.default, { open: focused, onClick: handleCloseSearch }),
        React.createElement("div", { className: classnames_1.default(classes.searchWrapper, {
                [classes.searchWrapperFocused]: focused,
            }) },
            React.createElement("div", { className: classnames_1.default(classes.content, {
                    [classes.contentFocused]: focused,
                }), onClick: handleOpenSearch },
                focused && (React.createElement(HBIconButton_1.default, { onClick: handleCloseSearch, className: classes.closeIconWrapper, label: "Abbrechen", underline: false },
                    React.createElement(HBIcon_1.default, { icon: "close" }))),
                React.createElement(SearchInput_1.default, { classes: classes }),
                focused && isSearching && (React.createElement("div", { className: classes.loadingLogo },
                    React.createElement(LoadingLogo_1.default, null))),
                !isSearching && !!typeAheadResults && (React.createElement("div", { className: classnames_1.default(classes.searchPopupWrapper, {
                        [classes.searchPopupWrapperFocused]: focused,
                    }) },
                    React.createElement("div", { className: classes.searchSuggestionsWrapper },
                        React.createElement(Grid_1.default, { container: true, spacing: 3, direction: "column" },
                            React.createElement(Grid_1.default, { item: true, xs: 12, md: 12 }, !!((_a = typeAheadResults.trends) === null || _a === void 0 ? void 0 : _a.length) && (React.createElement(React.Fragment, null,
                                React.createElement(Grid_1.default, { container: true, spacing: 1, direction: "row" }, typeAheadResults.trends.map(item => (React.createElement(Grid_1.default, { item: true, key: item.id },
                                    React.createElement(HBFilter_1.default, { label: item.title, endIcon: "plus", onClick: () => handleSelectedFilters(item) })))))))),
                            React.createElement(Grid_1.default, { item: true, xs: 12, md: 12 },
                                React.createElement(Grid_1.default, { container: true, direction: "column" },
                                    !!((_b = typeAheadResults.products) === null || _b === void 0 ? void 0 : _b.length) && (React.createElement(Grid_1.default, { item: true, xs: 12, md: 12 },
                                        React.createElement(ProductBestseller_1.default, { title: labels.BESTSELLERS_TITLE, products: typeAheadResults.products, classes: classes }))),
                                    React.createElement(Grid_1.default, { item: true, xs: 12, md: 12 }, !!((_c = typeAheadResults.brands) === null || _c === void 0 ? void 0 : _c.length) && React.createElement(BrandGallery_1.default, { items: typeAheadResults.brands }))))))))))));
};
exports.default = withStyles_1.default(SearchStyles_1.default)(Search);
