"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const FormFieldContext_1 = require("@components/common/utils/Form/FormFieldContext/FormFieldContext");
const useApiRequest_1 = require("@components/common/utils/hooks/useApiRequest");
const ApiContext_1 = require("@components/common/context/ApiContext");
const Grid_1 = require("@material-ui/core/Grid");
const Collapse_1 = require("@material-ui/core/Collapse");
const FormButton_1 = require("@components/common/utils/Form/FormButton/FormButton");
const makeStyles_1 = require("@material-ui/core/styles/makeStyles");
const FormRadioGroup_1 = require("@components/common/utils/Form/FormElement/FormRadioGroup");
const FormCheckboxField_1 = require("@components/common/utils/Form/FormElement/FormCheckboxField");
const HBFilter_1 = require("@components/library/HBFilter");
const LabelContext_1 = require("@components/common/context/LabelContext");
const useStyles = makeStyles_1.default(({ breakpoints, spacing }) => ({
    root: {
        '& .MuiGrid-item:not(:last-child)': {
            marginBottom: spacing(3),
            [breakpoints.down('sm')]: {
                marginBottom: spacing(2),
            },
        },
        '& .MuiFormControl-root': {
            margin: 0,
        },
    },
    button: {
        maxWidth: 392,
        margin: '16px auto 0',
    },
    cancel: {
        maxWidth: 392,
        margin: '16px auto 0',
    },
}));
const AddressInfoSelectFieldDefaults = {
    addressInvoiceOption: '',
    addressShippingOption: '',
    useInvoiceAddress: false,
};
const AddressInfoStepSelectForm = ({ api, addressShipping, addressInvoice, updateContext, onEdit, onAddNew, }) => {
    var _a, _b;
    const classes = useStyles();
    const defaultValues = {
        ...AddressInfoSelectFieldDefaults,
        addressShippingOption: ((_a = addressShipping === null || addressShipping === void 0 ? void 0 : addressShipping.find(opt => opt.selected)) === null || _a === void 0 ? void 0 : _a.value) || '',
        addressInvoiceOption: ((_b = addressInvoice === null || addressInvoice === void 0 ? void 0 : addressInvoice.find(opt => opt.selected)) === null || _b === void 0 ? void 0 : _b.value) || '',
    };
    const { methods } = FormFieldContext_1.useFormHook({ defaultValues });
    const { onSubmit, isLoading, data, apiError } = useApiRequest_1.default();
    const labels = LabelContext_1.useLabelContext();
    const isUseInvoiceAddress = methods.watch('useInvoiceAddress', false);
    React.useEffect(() => {
        if (!apiError && !!data) {
            updateContext(data);
        }
    }, [data]);
    const handleSubmit = async (payload) => await onSubmit(() => api.addressOption(payload));
    const renderAddressOptions = (name, options) => (React.createElement(React.Fragment, null,
        React.createElement(Grid_1.default, { item: true },
            React.createElement(FormRadioGroup_1.default, { options: options.map(option => ({
                    ...option,
                    ...{
                        helperText1: (React.createElement(HBFilter_1.default, { startIcon: "edit", label: labels.EDIT, onClick: () => onEdit(option.value), disabled: isLoading })),
                    },
                })), name: name, disabled: isLoading })),
        React.createElement(Grid_1.default, { item: true, container: true, justify: "center" },
            React.createElement(HBFilter_1.default, { startIcon: "plus", label: labels.ADD_NEW_ADDRESS, onClick: onAddNew, disabled: isLoading }))));
    return (React.createElement(React.Fragment, null,
        React.createElement(FormFieldContext_1.default, { methods: methods, onSubmit: handleSubmit },
            React.createElement(Grid_1.default, { container: true, direction: "column", className: classes.root },
                renderAddressOptions('addressShippingOption', addressShipping),
                React.createElement(Grid_1.default, { item: true },
                    React.createElement(FormCheckboxField_1.default, { name: "useInvoiceAddress", label: labels.ADD_BILLING_ADDRESS, error: apiError, disabled: isLoading })),
                React.createElement(Collapse_1.default, { in: isUseInvoiceAddress }, renderAddressOptions('addressInvoiceOption', addressInvoice))),
            React.createElement(Grid_1.default, { className: classes.button },
                React.createElement(FormButton_1.default, { type: "submit", label: labels.OK, disabled: isLoading, className: classes.button })))));
};
exports.default = ApiContext_1.withApi(AddressInfoStepSelectForm);
