"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const LoginDetailInfo_1 = require("@components/pages/profile/detail/LoginDetailInfo");
const AddressDetailnfo_1 = require("@components/pages/profile/detail/AddressDetailnfo");
const ProfileDetail = ({ loginDetailInfo, addressDetailInfo }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(LoginDetailInfo_1.default, Object.assign({}, loginDetailInfo)),
        React.createElement(AddressDetailnfo_1.default, Object.assign({}, addressDetailInfo))));
};
exports.default = ProfileDetail;
