"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Grid_1 = require("@material-ui/core/Grid");
const HBTypography_1 = require("@components/library/HBTypography");
const LabelContext_1 = require("@components/common/context/LabelContext");
const ProductSummary = ({ labels, summary = [] }) => (React.createElement(Grid_1.default, { item: true, container: true, spacing: 2 },
    React.createElement(Grid_1.default, { item: true },
        React.createElement(HBTypography_1.default, { variant: "h6" }, labels.DIMENSION_DETAILS)),
    React.createElement(Grid_1.default, { item: true, container: true, spacing: 1, alignItems: "center" }, summary === null || summary === void 0 ? void 0 : summary.map(({ key, value }) => {
        return (value && (React.createElement(Grid_1.default, { key: key, item: true, container: true, spacing: 2, wrap: "nowrap", xs: 12 },
            React.createElement(Grid_1.default, { item: true, xs: 4 },
                React.createElement(HBTypography_1.default, { variant: "subtitle1" }, key)),
            React.createElement(Grid_1.default, { item: true, xs: 8 },
                React.createElement(HBTypography_1.default, { variant: "body1" }, typeof value === 'string' ? value : value.map(item => item.name).join(' / '))))));
    }))));
exports.default = LabelContext_1.withLabel(ProductSummary);
