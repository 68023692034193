"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
const typography_1 = require("@components/library/theme/typography");
const styles = ({ palette, spacing, breakpoints, shadows }) => styles_1.createStyles({
    searchWrapper: {
        width: 'auto',
        backgroundColor: palette.common.white,
        zIndex: 1101,
        height: '100%',
        position: 'relative',
        maxHeight: 48,
    },
    searchWrapperFocused: {
        transition: 'all 400ms ease-out',
        left: 0,
        right: 0,
        top: 0,
        zIndex: 1101,
        position: 'fixed',
        maxHeight: '80vh',
        overflow: 'auto',
        width: '100%',
        padding: spacing(0, 3),
        [breakpoints.down('xs')]: {
            padding: spacing(0, 1),
        },
    },
    searchInner: {
        position: 'absolute',
        left: 0,
        right: 0,
        opacity: 0,
        background: '#e91e63',
        margin: `0 auto`,
        outline: 0,
        height: '80px',
        top: 0,
        transition: 'all 0.7s cubic-bezier(0.4, 0, 0.2, 1)',
    },
    searchInnerFocused: {
        top: '50%',
        transform: 'translateY(-50%)',
        opacity: 1,
        width: '100%',
        height: '100%',
    },
    content: {
        height: 48,
    },
    contentFocused: {
        maxWidth: '1280px',
        margin: '0 auto',
        width: '100%',
        animation: '$opacity 0.5s ease',
        position: 'relative',
    },
    '@keyframes opacity': {
        '0%': {
            opacity: 0,
        },
        '30%': {
            opacity: 0,
        },
        '100%': {
            opacity: 1,
        },
    },
    inputBase: {
        display: 'flex',
        position: 'absolute',
        paddingLeft: spacing(0.75),
        paddingRight: spacing(0.75),
        height: '48px',
        ['@media (max-width:768px)']: {
            marginTop: spacing(-2),
        },
    },
    inputBaseFocused: {
        flexWrap: 'wrap',
        ['@media (max-width:768px)']: {
            flexWrap: 'nowrap',
        },
        position: 'relative',
        paddingLeft: spacing(3.25),
        paddingRight: spacing(3.25),
        paddingBottom: spacing(2),
        [breakpoints.down('sm')]: {
            paddingLeft: spacing(0.75),
            paddingRight: spacing(0.75),
            paddingBottom: spacing(1.25),
        },
        height: 'auto',
    },
    inputBaseFocusedSpacing: {
        paddingRight: 140,
        ['@media (max-width:760px)']: {
            paddingRight: spacing(6),
        },
    },
    input: {
        fontFamily: typography_1.fontFamilyPrimary,
        fontSize: 32,
        fontWeight: 'bold',
        lineHeight: 1.25,
        letterSpacing: '1.13px',
        height: '100%',
        display: 'none',
        textAlign: 'center',
        paddingBottom: spacing(1.25),
        '&::placeholder': {
            opacity: 0.2,
            fontSize: spacing(4),
            fontWieght: 700,
            letterSpacing: '1.13px',
            [breakpoints.down('md')]: {
                fontSize: '2vw',
            },
            [breakpoints.down('sm')]: {
                fontSize: spacing(2),
            },
        },
    },
    inputFocused: {
        paddingLeft: spacing(2.5),
        flexGrow: 1,
        width: 'auto',
        display: 'block',
        textAlign: 'left',
    },
    inputVisible: {
        display: 'block',
    },
    searchIconWrapper: {
        display: 'flex',
        overflow: 'hidden',
    },
    searchIconWrapperFocused: {},
    searchIconWrapperSpacing: {
        marginRight: spacing(-2.25),
    },
    searchIcon: {
        transition: 'all 0.3s ease-out',
        width: spacing(3),
        height: spacing(3),
        display: 'block',
        marginRight: 2,
        [breakpoints.down('md')]: {
            width: spacing(2.5),
            height: spacing(2.5),
        },
        '& svg': {
            opacity: 0.8,
            [breakpoints.down('md')]: {
                width: spacing(2.5),
                height: spacing(2.5),
            },
        },
    },
    searchIconSpacing: {
        marginTop: spacing(0.65),
    },
    closeIconWrapper: {
        display: 'block',
        margin: `${spacing(3)}px auto ${spacing(3.25)}px auto`,
        [breakpoints.up('md')]: {
            margin: `${spacing(5)}px auto ${spacing(3.125)}px auto`,
        },
        '& svg': {
            opacity: 1,
        },
    },
    searchTypeAheadLoadingIcon: {
        fontSize: '6rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '25px auto',
        position: 'absolute',
        left: 0,
        right: 0,
    },
    searchTerms: {
        display: 'flex',
        flexDirection: 'row',
        overflowX: 'scroll',
        '& button': {
            marginRight: spacing(1),
            marginBottom: spacing(1),
        },
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    searchTermsFocused: {
        flexWrap: 'wrap',
        overflowX: 'auto',
    },
    searchTermsFilled: {
        marginLeft: spacing(1.25),
        ['@media (max-width:695px)']: {
            marginLeft: spacing(2),
        },
    },
    searchTermClearButton: {
        transition: 'all 400ms ease-out',
        position: 'absolute',
        right: 0,
        opacity: 0.2,
        fontSize: 24,
        fontWeight: 'bold',
        letterSpacing: '0.4px',
        paddingRight: 0,
        '&:hover': {
            opacity: 1,
        },
        ['@media (max-width:760px)']: {
            padding: 0,
        },
    },
    searchPopupWrapper: {
        opacity: 0,
        display: 'none',
        transition: 'all 0.7s cubic-bezier(0.4, 0, 0.2, 1)',
        padding: spacing(0, 6.25),
        [breakpoints.down('sm')]: {
            padding: spacing(0, 3.8),
        },
        [breakpoints.down('xs')]: {
            padding: spacing(0, 1),
        },
    },
    searchPopupWrapperFocused: {
        opacity: 1,
        display: 'block',
        paddingBottom: spacing(6),
        [breakpoints.down('xs')]: {
            paddingBottom: spacing(4),
        },
    },
    searchSuggestionsWrapper: {
        marginTop: spacing(4),
    },
    searchHelpWrapper: {
        marginTop: spacing(6.25),
        maxWidth: 640,
        width: '100%',
    },
    prodImageWrapper: {
        height: 132,
    },
    productName: {
        height: spacing(6),
    },
    prodNameWrapper: {
        '& p': {
            fontSize: 14,
        },
    },
    loadingLogo: {
        position: 'absolute',
        left: 0,
        right: 0,
        zIndex: 1,
        transform: 'translate(0%, 150%)',
    },
});
exports.default = styles;
