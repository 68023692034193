"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const styles_1 = require("@material-ui/core/styles");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const Grid_1 = require("@material-ui/core/Grid");
const HBTypography_1 = require("@components/library/HBTypography");
const HBButton_1 = require("@components/library/HBButton");
const HBDialog_1 = require("@components/library/HBDialog");
const HBIcon_1 = require("@components/library/HBIcon");
const styles = ({ palette, spacing, breakpoints, transitions, zIndex }) => styles_1.createStyles({
    header: {
        transition: transitions.create(['all']),
        backgroundColor: palette.primary.main,
        color: palette.common.white,
        padding: spacing(2.5, 4.5),
        [breakpoints.down('sm')]: {
            padding: spacing(2),
        },
    },
    button: {
        height: spacing(3.25),
        padding: spacing(0),
        [breakpoints.down('xs')]: {
            padding: spacing(3, 0, 2, 0),
        },
    },
    content: {
        margin: spacing(3, 0),
    },
    backdrop: {
        zIndex: zIndex.drawer + 1,
        color: palette.common.white,
    },
});
const ProfileDetailItem = ({ classes, title, actionLabel, formComponent, children }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const handleOpen = () => {
        setIsOpen(true);
    };
    const handleClose = () => {
        setIsOpen(false);
    };
    const handleLoading = value => {
        setIsLoading(value);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Grid_1.default, { item: true, container: true, direction: "column" },
            React.createElement(Grid_1.default, { item: true, container: true, alignItems: "center", justify: "space-between", className: classes.header },
                React.createElement(HBTypography_1.default, { variant: "h6" }, title),
                !!formComponent && actionLabel && (React.createElement(HBButton_1.default, { onClick: handleOpen, disableHover: true, variant: "contained", label: actionLabel, color: "primary", startIcon: React.createElement(HBIcon_1.default, { icon: "plus", color: "default" }), className: classes.button }))),
            React.createElement(Grid_1.default, { item: true, className: classes.content }, children)),
        formComponent &&
            React.Children.map(formComponent, child => {
                if (React.isValidElement(child)) {
                    return (React.createElement(HBDialog_1.default, { open: isOpen, onClose: handleClose, disabled: isLoading }, React.cloneElement(child, { onChange: handleClose, handleLoading: handleLoading })));
                }
                return child;
            })));
};
exports.default = withStyles_1.default(styles)(ProfileDetailItem);
