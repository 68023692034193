"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const createStyles_1 = require("@material-ui/core/styles/createStyles");
const makeStyles_1 = require("@material-ui/core/styles/makeStyles");
const FullWidthBackground_1 = require("@components/common/utils/FullWidthBackground");
const Grid_1 = require("@material-ui/core/Grid");
const HBTypography_1 = require("@components/library/HBTypography");
const VirtualGridRendererContext_1 = require("@components/common/context/VirtualGridRendererContext");
const classnames_1 = require("classnames");
const VirtualGrid_1 = require("@components/common/VirtualGridRenderer/VirtualGrid");
const useStyles = makeStyles_1.default(({ spacing, breakpoints }) => createStyles_1.default({
    root: {},
    dense: {
        paddingTop: spacing(5),
    },
    title: {
        paddingTop: spacing(5),
        [breakpoints.down('sm')]: {
            paddingTop: spacing(3),
        },
    },
    gridListContainer: {
        paddingTop: spacing(6),
        [breakpoints.down('sm')]: {
            paddingTop: spacing(4),
        },
    },
}));
const VirtualGridRenderer = ({ name, border, dense }) => {
    const classes = useStyles();
    const { loadedResults: { products = [], count } = {}, loadMore, isLoading, endReached, } = VirtualGridRendererContext_1.useVirtualGridRendererContext();
    return (React.createElement(FullWidthBackground_1.default, { border: border, className: classnames_1.default({ [classes.dense]: dense }) }, !!(products === null || products === void 0 ? void 0 : products.length) && (React.createElement(Grid_1.default, { container: true, direction: "column" },
        React.createElement(Grid_1.default, { item: true, className: classes.title },
            React.createElement(Grid_1.default, { container: true, alignItems: "baseline" },
                React.createElement(HBTypography_1.default, { variant: "h4", textTransformNone: true }, `${name}`),
                React.createElement(HBTypography_1.default, { variant: "h6", opacity: 0.4, marginLeft: 2 }, count))),
        React.createElement(Grid_1.default, { item: true, className: classes.gridListContainer },
            React.createElement(VirtualGrid_1.default, { products: products, loadMore: loadMore, isLoading: isLoading, endReached: endReached }))))));
};
exports.default = VirtualGridRenderer;
