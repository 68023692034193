"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_router_dom_1 = require("react-router-dom");
const styles_1 = require("@material-ui/core/styles");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const AppBar_1 = require("@material-ui/core/AppBar");
const Toolbar_1 = require("@material-ui/core/Toolbar");
const HBContainer_1 = require("@components/library/HBContainer");
const Grid_1 = require("@material-ui/core/Grid");
const Logo_1 = require("@components/common/layout/Logo");
const HBIcon_1 = require("@components/library/HBIcon");
const HBTypography_1 = require("@components/library/HBTypography");
const FooterLegalBar_1 = require("@components/common/layout/Footer/FooterLegalBar");
const CheckoutCart_1 = require("@components/pages/checkout/cart/CheckoutCart");
const HBButton_1 = require("@components/library/HBButton");
const drawerWidth = 480;
const drawerWidthSM = 415;
const styles = ({ palette, spacing, breakpoints }) => styles_1.createStyles({
    root: {
        display: 'flex',
        [breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    header: {
        padding: spacing(2.5, 0, 1),
    },
    footer: {
        marginTop: spacing(10),
        padding: spacing(2, 0),
    },
    logo: {
        maxWidth: 243,
        maxHeight: 30,
    },
    appBar: {
        backgroundColor: palette.common.white,
        width: `calc(100% - ${drawerWidth}px)`,
        marginRight: drawerWidth,
        [breakpoints.down('sm')]: {
            width: `100%`,
            marginRight: 0,
        },
    },
    toolbar: {
        minHeight: 110,
    },
    content: {
        flexGrow: 1,
        padding: spacing(3, 0),
        maxWidth: 600,
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
});
const PageWrapperCheckout = ({ classes, header: { logo }, footer: { legalBar }, children, }) => {
    const history = react_router_dom_1.useHistory();
    return (React.createElement("div", { className: classes.root },
        React.createElement(HBContainer_1.default, { maxWidth: "sm", className: classes.wrapper },
            React.createElement(AppBar_1.default, { position: "fixed", color: "default", className: classes.appBar, elevation: 0 },
                React.createElement(Toolbar_1.default, null,
                    React.createElement(HBContainer_1.default, { maxWidth: "sm" },
                        React.createElement(Grid_1.default, { container: true, direction: "column", spacing: 3, className: classes.header },
                            React.createElement(Grid_1.default, { item: true },
                                React.createElement(Logo_1.default, { logo: logo, className: classes.logo })),
                            React.createElement(Grid_1.default, { item: true },
                                React.createElement(HBButton_1.default, { variant: "text", color: "secondary", onClick: () => history.goBack(), hasIcon: true },
                                    React.createElement(HBIcon_1.default, { icon: "arrow_left", color: "secondary", fontSize: "xsmall" }),
                                    React.createElement(HBTypography_1.default, { variant: "body2", component: "span", marginLeft: 1 }, "Zur\u00FCck zum Shop"))))))),
            React.createElement("main", { className: classes.content },
                React.createElement("div", { className: classes.toolbar }),
                children),
            React.createElement("footer", null,
                React.createElement(FooterLegalBar_1.default, Object.assign({ className: classes.footer }, legalBar)))),
        React.createElement(CheckoutCart_1.default, { drawerWidth: drawerWidth, drawerWidthSM: drawerWidthSM })));
};
exports.default = withStyles_1.default(styles)(PageWrapperCheckout);
