"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Step_1 = require("@components/pages/checkout/Step/Step");
const AddressInfoStepSummary_1 = require("./AddressInfoStepSummary");
const AddressInfoStepForm_1 = require("./AddressInfoStepForm");
const AddressInfoStepEdit_1 = require("@components/pages/checkout/Step/AddressInfoStep/AddressInfoStepEdit");
const AppContext_1 = require("@components/common/context/AppContext");
const AddressInfoStep = ({ completed, disabled, isEditing, title, form, formSelect }) => {
    const { state: { cart: { addressShippingOptions = [], addressInvoiceOptions = [] } = {} } = {}, dispatch } = AppContext_1.useAppContext();
    const handleEdit = () => dispatch({ type: AppContext_1.ActionTypes.EDITING_ADDRESS_INFO });
    const handleAddAddressInfo = payload => dispatch({ type: AppContext_1.ActionTypes.ADD_ADDRESS_INFO, payload });
    return (React.createElement(Step_1.default, { title: title, active: !disabled, completed: completed, onChange: handleEdit, isEditing: isEditing, summary: React.createElement(AddressInfoStepSummary_1.default, { addressShipping: addressShippingOptions === null || addressShippingOptions === void 0 ? void 0 : addressShippingOptions.find(item => !!item.selected), addressInvoice: addressInvoiceOptions === null || addressInvoiceOptions === void 0 ? void 0 : addressInvoiceOptions.find(item => !!item.selected) }) }, !!(addressShippingOptions === null || addressShippingOptions === void 0 ? void 0 : addressShippingOptions.length) ? (React.createElement(AddressInfoStepEdit_1.default, { form: form })) : (React.createElement(AddressInfoStepForm_1.default, { form: form, updateContext: handleAddAddressInfo }))));
};
exports.default = AddressInfoStep;
