"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const PersonalInfoStep_1 = require("@components/pages/checkout/Step/PersonalInfoStep");
const AddressInfoStep_1 = require("@components/pages/checkout/Step/AddressInfoStep");
const ShippingInfoStep_1 = require("@components/pages/checkout/Step/ShippingInfoStep");
const VoucherInfoStep_1 = require("@components/pages/checkout/Step/VoucherInfoStep");
const PaymentInfoStep_1 = require("@components/pages/checkout/Step/PaymentInfoStep");
const AppContext_1 = require("@components/common/context/AppContext");
const CheckoutSteps = ({ personalInfo, addressInfo, shippingInfo, voucherInfo, paymentInfo, }) => {
    const { state: { steps } = {} } = AppContext_1.useAppContext();
    return (React.createElement(React.Fragment, null,
        React.createElement(PersonalInfoStep_1.default, Object.assign({}, personalInfo, { disabled: !steps.personalInfo.active, completed: steps.personalInfo.completed, isEditing: steps.personalInfo.isEditing })),
        React.createElement(AddressInfoStep_1.default, Object.assign({}, addressInfo, { disabled: !steps.addressInfo.active, completed: steps.addressInfo.completed, isEditing: steps.addressInfo.isEditing })),
        React.createElement(ShippingInfoStep_1.default, Object.assign({}, shippingInfo, { disabled: !steps.shippingInfo.active, completed: steps.shippingInfo.completed, isEditing: steps.shippingInfo.isEditing })),
        voucherInfo && (React.createElement(VoucherInfoStep_1.default, Object.assign({}, voucherInfo, { disabled: !steps.voucherInfo.active, completed: steps.voucherInfo.completed }))),
        React.createElement(PaymentInfoStep_1.default, Object.assign({}, paymentInfo, { disabled: !steps.paymentInfo.active, completed: steps.paymentInfo.completed }))));
};
exports.default = CheckoutSteps;
