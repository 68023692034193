"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Logo_1 = require("./Logo");
const Bulletpoint_1 = require("./Bulletpoint");
const ArrowDown_1 = require("./ArrowDown");
const ArrowLeft_1 = require("./ArrowLeft");
const ArrowRight_1 = require("./ArrowRight");
const ArrowUp_1 = require("./ArrowUp");
const Basket_1 = require("./Basket");
const Category_1 = require("./Category");
const Check_1 = require("./Check");
const CheckboxFilled_1 = require("./CheckboxFilled");
const CheckboxOutline_1 = require("./CheckboxOutline");
const CheckMark_1 = require("./CheckMark");
const Close_1 = require("./Close");
const Delivery_1 = require("./Delivery");
const Download_1 = require("./Download");
const ErrorOutline_1 = require("./ErrorOutline");
const FaqCircle_1 = require("./FaqCircle");
const Help_1 = require("./Help");
const KeyboardArrowDown_1 = require("./KeyboardArrowDown");
const KeyboardArrowRight_1 = require("./KeyboardArrowRight");
const KeyboardArrowLeft_1 = require("./KeyboardArrowLeft");
const LocationPin_1 = require("./LocationPin");
const EMailCircle_1 = require("./EMailCircle");
const Minus_1 = require("./Minus");
const Person_1 = require("./Person");
const PersonFilled_1 = require("./PersonFilled");
const Phone_1 = require("./Phone");
const PhoneCircle_1 = require("./PhoneCircle");
const Plus_1 = require("./Plus");
const Return_1 = require("./Return");
const Search_1 = require("./Search");
const Wishlist_1 = require("./Wishlist");
const WishlistFilled_1 = require("./WishlistFilled");
const Delete_1 = require("./Delete");
const ShopOpen_1 = require("./ShopOpen");
const Shop_1 = require("./Shop");
const Parking_1 = require("./Parking");
const Telephone_1 = require("./Telephone");
const Degree360_1 = require("./Degree360");
const Edit_1 = require("./Edit");
const SignOut_1 = require("./SignOut");
const Visibility_1 = require("./Visibility");
const VisibilityOff_1 = require("./VisibilityOff");
const Filter_1 = require("./Filter");
const BackArrow_1 = require("./BackArrow");
const ZoomIn_1 = require("./ZoomIn");
const ZoomOut_1 = require("./ZoomOut");
const ZoomOutAll_1 = require("./ZoomOutAll");
const Material_1 = require("@components/library/icons/Material");
const Color_1 = require("@components/library/icons/Color");
const Bath_1 = require("./header/category/Bath");
const Mirror_1 = require("./header/category/Mirror");
const Textile_1 = require("./header/category/Textile");
const Beauty_1 = require("./header/category/Beauty");
const BathAccessories_1 = require("./header/category/BathAccessories");
const HomeAccessories_1 = require("./header/category/HomeAccessories");
const Lighting_1 = require("./header/category/Lighting");
const Misc_1 = require("./header/category/Misc");
const Fashion_1 = require("./header/category/Fashion");
const Calendar_1 = require("./service/Calendar");
const DeliveryTruck_1 = require("./service/DeliveryTruck");
const DHL_1 = require("./shipping/DHL");
const DPD_1 = require("./shipping/DPD");
const ClickAndCollect_1 = require("./shipping/ClickAndCollect");
const Paypal_1 = require("./payment/Paypal");
const Visa_1 = require("./payment/Visa");
const MasterCard_1 = require("./payment/MasterCard");
const Sepa_1 = require("./payment/Sepa");
const AmericanExpress_1 = require("./payment/AmericanExpress");
const Klarna_1 = require("./payment/Klarna");
const Facebook_1 = require("./social/Facebook");
const Instagram_1 = require("./social/Instagram");
const Pinterest_1 = require("./social/Pinterest");
const Icons = Object.freeze({
    material: Material_1.default,
    color: Color_1.default,
    bulletpoint: Bulletpoint_1.default,
    logo: Logo_1.default,
    arrow_down: ArrowDown_1.default,
    arrow_left: ArrowLeft_1.default,
    arrow_right: ArrowRight_1.default,
    arrow_up: ArrowUp_1.default,
    cart: Basket_1.default,
    category: Category_1.default,
    check: Check_1.default,
    checkbox_filled: CheckboxFilled_1.default,
    checkbox_outline: CheckboxOutline_1.default,
    check_mark: CheckMark_1.default,
    close: Close_1.default,
    delivery: Delivery_1.default,
    download: Download_1.default,
    error_outline: ErrorOutline_1.default,
    faq_circle: FaqCircle_1.default,
    contact: Help_1.default,
    keyboard_arrow_down: KeyboardArrowDown_1.default,
    keyboard_arrow_right: KeyboardArrowRight_1.default,
    keyboard_arrow_left: KeyboardArrowLeft_1.default,
    marker: LocationPin_1.default,
    email_circle: EMailCircle_1.default,
    minus: Minus_1.default,
    account: Person_1.default,
    member: PersonFilled_1.default,
    phone: Phone_1.default,
    phone_circle: PhoneCircle_1.default,
    plus: Plus_1.default,
    return_back: Return_1.default,
    search: Search_1.default,
    wishlist: Wishlist_1.default,
    wishlist_filled: WishlistFilled_1.default,
    bath: Bath_1.default,
    mirror: Mirror_1.default,
    textiles: Textile_1.default,
    beauty: Beauty_1.default,
    accessories: BathAccessories_1.default,
    fashion: Fashion_1.default,
    living: HomeAccessories_1.default,
    lighting: Lighting_1.default,
    misc: Misc_1.default,
    remove: Delete_1.default,
    open: ShopOpen_1.default,
    shop: Shop_1.default,
    parking: Parking_1.default,
    telephone: Telephone_1.default,
    degree_360: Degree360_1.default,
    edit: Edit_1.default,
    signOut: SignOut_1.default,
    filter: Filter_1.default,
    back_arrow: BackArrow_1.default,
    calendar: Calendar_1.default,
    delivery_truck: DeliveryTruck_1.default,
    dhl: DHL_1.default,
    dpd: DPD_1.default,
    clickandcollect: ClickAndCollect_1.default,
    paypal: Paypal_1.default,
    visa: Visa_1.default,
    master_card: MasterCard_1.default,
    sepa: Sepa_1.default,
    american_express: AmericanExpress_1.default,
    klarna: Klarna_1.default,
    facebook: Facebook_1.default,
    instagram: Instagram_1.default,
    pinterest: Pinterest_1.default,
    visibility: Visibility_1.default,
    visibility_off: VisibilityOff_1.default,
    zoom_in: ZoomIn_1.default,
    zoom_out: ZoomOut_1.default,
    zoom_out_all: ZoomOutAll_1.default,
});
exports.default = Icons;
