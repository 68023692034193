"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const styles_1 = require("@material-ui/core/styles");
const Colors_1 = require("@components/library/theme/Colors");
const Grid_1 = require("@material-ui/core/Grid");
const FormControlLabel_1 = require("@material-ui/core/FormControlLabel");
const AddressInfoEditForm_1 = require("@components/pages/profile/detail/AddressDetailnfo/AddressInfoEditForm");
const HBTypography_1 = require("@components/library/HBTypography");
const HBCheckbox_1 = require("@components/library/HBCheckbox");
const core_1 = require("@material-ui/core");
const CircularProgress_1 = require("@material-ui/core/CircularProgress");
const ApiContext_1 = require("@components/common/context/ApiContext");
const useApiRequest_1 = require("@components/common/utils/hooks/useApiRequest");
const AppContext_1 = require("@components/common/context/AppContext");
const ProfileDetailItemSummary_1 = require("@components/pages/profile/detail/ProfileDetailItemSummary");
const styles = ({ zIndex, palette }) => styles_1.createStyles({
    labelChecked: {
        color: Colors_1.default.mainGold,
    },
    checkboxAddress: {
        '& .Mui-disabled': {
            cursor: 'not-allowed',
            color: Colors_1.default.mainGold,
            '& svg': {
                opacity: 1,
            },
        },
        '& .Mui-checked + span': {
            color: Colors_1.default.mainGold,
        },
    },
    backdrop: {
        zIndex: zIndex.drawer + 1,
        color: palette.common.white,
    },
});
const AddressDetailItem = ({ api, classes, _id, adressLine, adressLine1, streetAddress, addressLocality, postalCode, shippingDefault, invoiceDefault, editForm, dispatch, handleDelete, }) => {
    const { data, isLoading, onSubmit, apiError } = useApiRequest_1.default();
    const handleShippingDefault = async (event) => {
        const { checked, value } = event.target;
        if (checked) {
            await onSubmit(() => api.updateMemberAddress({ shippingDefault: true }, value));
        }
    };
    const handleInvoiceDefault = async (event) => {
        const { checked, value } = event.target;
        if (checked) {
            await onSubmit(() => api.updateMemberAddress({ invoiceDefault: true }, value));
        }
    };
    React.useEffect(() => {
        if (!apiError && (data === null || data === void 0 ? void 0 : data.member)) {
            dispatch({ type: AppContext_1.ActionTypes.UPDATE_MEMBER_ADDRESS, payload: data.member });
        }
    }, [data]);
    return (React.createElement(React.Fragment, null,
        React.createElement(core_1.Backdrop, { className: classes.backdrop, open: isLoading },
            React.createElement(CircularProgress_1.default, { color: "inherit" })),
        React.createElement(ProfileDetailItemSummary_1.default, { title: adressLine, subheader: React.createElement(Grid_1.default, { container: true, direction: "column" },
                React.createElement(HBTypography_1.default, { variant: "body1" }, streetAddress),
                React.createElement(HBTypography_1.default, { variant: "body1" }, postalCode),
                React.createElement(HBTypography_1.default, { variant: "body1" }, addressLocality)), onDelete: () => handleDelete(_id), canDelete: !shippingDefault && !invoiceDefault, formComponent: React.createElement(AddressInfoEditForm_1.default, { defaults: {
                    adressLine,
                    adressLine1,
                    streetAddress,
                    addressLocality,
                    postalCode,
                    shippingDefault,
                    invoiceDefault,
                }, id: _id, editForm: editForm, dispatch: dispatch }) },
            React.createElement(Grid_1.default, { container: true, direction: "column" },
                React.createElement(Grid_1.default, { item: true },
                    React.createElement(FormControlLabel_1.default, { className: classes.checkboxAddress, disabled: shippingDefault, control: React.createElement(HBCheckbox_1.default, { color: "primary", name: "shippingDefault", value: _id, checked: shippingDefault, onChange: handleShippingDefault }), label: "Dies ist Ihre bevorzugte Lieferadresse" })),
                React.createElement(Grid_1.default, { item: true },
                    React.createElement(FormControlLabel_1.default, { className: classes.checkboxAddress, disabled: invoiceDefault, control: React.createElement(HBCheckbox_1.default, { color: "secondary", name: "invoiceDefault", value: _id, checked: invoiceDefault, onChange: handleInvoiceDefault }), label: "Dies ist Ihre bevorzugte Rechnungsadresse" }))))));
};
exports.default = ApiContext_1.withApi(withStyles_1.default(styles)(AddressDetailItem));
