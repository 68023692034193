"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const createStyles_1 = require("@material-ui/core/styles/createStyles");
const Grid_1 = require("@material-ui/core/Grid");
const Tabs_1 = require("@material-ui/core/Tabs");
const Tab_1 = require("@material-ui/core/Tab");
const HBTypography_1 = require("@components/library/HBTypography");
const HBTabPanel_1 = require("@components/library/HBTabPanel");
const LoginForm_1 = require("@components/pages/myaccount/LoginForm");
const RegisterForm_1 = require("@components/pages/myaccount/RegisterForm");
const useQuery_1 = require("@components/common/utils/hooks/useQuery");
const withLayout_1 = require("@components/common/layout/withLayout");
const FullWidthBackground_1 = require("@components/common/utils/FullWidthBackground");
const styles = ({ palette, spacing, breakpoints }) => createStyles_1.default({
    root: {
        padding: spacing(8, 1.25),
        ['@media (max-width:768px)']: {
            padding: spacing(5, 1.25),
        },
        [breakpoints.down('xs')]: {
            padding: spacing(1.25),
        },
    },
    container: {
        [breakpoints.down('xs')]: {
            padding: spacing(0),
        },
    },
    wrapper: {
        backgroundColor: palette.common.white,
        padding: spacing(5),
        margin: '0 auto',
        maxWidth: 680,
        ['@media (max-width:768px)']: {
            padding: spacing(3),
        },
        [breakpoints.down('xs')]: {
            padding: spacing(2.5),
        },
    },
    tabPanelWrapper: {
        width: '100%',
        maxWidth: 392,
    },
    tabs: {
        marginBottom: spacing(4),
        '& button:first-child': {
            marginRight: spacing(5),
            [breakpoints.down('sm')]: {
                marginRight: spacing(3),
            },
            ['@media (max-width:370px)']: {
                marginRight: spacing(1),
            },
        },
    },
});
const PageMyaccount = ({ classes, page: { title, tabs = [] } }) => {
    const query = useQuery_1.default();
    const [value, setValue] = React.useState(0);
    React.useEffect(() => {
        setValue(query.get('tab') === 'login' ? 0 : 1);
    }, []);
    const isFirstRun = React.useRef(true);
    React.useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }
        const href = `${window.location.pathname}?tab=${value === 0 ? 'login' : 'register'}`;
        window.history.replaceState(null, '', href);
        window.scrollTo(0, 0);
    }, [value]);
    const handleChange = (event, value) => {
        setValue(value);
    };
    const handleTab = value => {
        setValue(value);
    };
    return (React.createElement(FullWidthBackground_1.default, { className: classes.root, classes: { container: classes.container } },
        React.createElement(Grid_1.default, { container: true, item: true },
            React.createElement(Grid_1.default, { item: true, container: true, direction: "column", alignItems: "center", className: classes.wrapper },
                React.createElement(Grid_1.default, { item: true },
                    React.createElement(Tabs_1.default, { value: value, onChange: handleChange, indicatorColor: "secondary", textColor: "primary", "aria-label": `${tabs[0].title} ${tabs[1].title}`, centered: true, selectionFollowsFocus: true, className: classes.tabs },
                        React.createElement(Tab_1.default, { label: React.createElement(HBTypography_1.default, { variant: "h5" }, tabs[0].title), id: "tab-0", "aria-label": tabs[0].title, "aria-controls": "tabpanel-0", value: 0 }),
                        React.createElement(Tab_1.default, { label: React.createElement(HBTypography_1.default, { variant: "h5" }, tabs[1].title), id: "tab-1", "aria-label": tabs[1].title, "aria-controls": "tabpanel-1", value: 1 }))),
                React.createElement(Grid_1.default, { item: true, className: classes.tabPanelWrapper },
                    React.createElement(HBTabPanel_1.default, { value: value, index: 0 },
                        React.createElement(LoginForm_1.default, { content: tabs[0], onChange: () => handleTab(1) })),
                    React.createElement(HBTabPanel_1.default, { value: value, index: 1 },
                        React.createElement(RegisterForm_1.default, { content: tabs[1], onChange: () => handleTab(0) })))))));
};
exports.default = withStyles_1.default(styles)(withLayout_1.default(PageMyaccount, {
    paddingTop: 0,
    paddingBottom: 0,
}));
