"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useProductDetailContext = void 0;
const React = require("react");
const SeoUtils_1 = require("@components/common/layout/SeoUtils");
const AppContext_1 = require("@components/common/context/AppContext");
const getQuantityFromCart = (cartItems, itemId) => {
    const item = cartItems === null || cartItems === void 0 ? void 0 : cartItems.find(prod => prod._id === itemId.toString());
    return (item === null || item === void 0 ? void 0 : item.amount) || 1;
};
const getLightboxImages = (images) => images === null || images === void 0 ? void 0 : images.map(image => ({
    src: image.path,
    thumbnail: `${image.path}?w=200&h=200&crop`,
}));
const ProductDetailContext = React.createContext(null);
const ProductDetailContextProvider = ({ page, settings: { seo }, children, }) => {
    const { product = {} } = page;
    const { images = [] } = product;
    const [variantCurrentSlide, setVariantCurrentSlide] = React.useState(null);
    const { state: { cart: { items = [] } = {} } = {} } = AppContext_1.useAppContext();
    const [lightboxImages, setLightboxImages] = React.useState(() => getLightboxImages(images));
    const [quantity, setQuantity] = React.useState(getQuantityFromCart(items, product._id));
    const handleVariantCurrentSlide = src => setVariantCurrentSlide(src);
    const handleQuantity = qty => {
        setQuantity(qty);
    };
    return (React.createElement(ProductDetailContext.Provider, { value: {
            ...page,
            variantCurrentSlide,
            handleVariantCurrentSlide,
            quantity,
            handleQuantity,
            lightboxImages,
        } },
        SeoUtils_1.renderProductMicroData(product, seo),
        SeoUtils_1.renderProductMetaTags(product, page, seo),
        children));
};
exports.default = ProductDetailContextProvider;
exports.useProductDetailContext = () => React.useContext(ProductDetailContext);
