"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const styles_1 = require("@material-ui/core/styles");
const Colors_1 = require("@components/library/theme/Colors");
const Path = ({ color = 'primary', isStroke = false, ...rest }) => {
    const { palette: { primary, secondary, error, common }, } = styles_1.useTheme();
    const pathColor = color === 'primary'
        ? primary.main
        : color === 'secondary'
            ? secondary.main
            : color === 'error'
                ? error.main
                : color === 'ternary'
                    ? Colors_1.default.successGreen
                    : common.white;
    const props = rest;
    if (isStroke) {
        props.stroke = pathColor;
    }
    else {
        props.fill = pathColor;
    }
    return React.createElement("path", Object.assign({}, props));
};
exports.default = Path;
