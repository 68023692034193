"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const createStyles_1 = require("@material-ui/core/styles/createStyles");
const styles_1 = require("@material-ui/core/styles");
const useTheme_1 = require("@material-ui/core/styles/useTheme");
const useMediaQuery_1 = require("@material-ui/core/useMediaQuery");
const react_zoom_pan_pinch_1 = require("react-zoom-pan-pinch");
const HBIcon_1 = require("@components/library/HBIcon");
const HBImage_1 = require("@components/library/HBImage");
const ProductThumbImageList_1 = require("@components/pages/product/detail/ProductThumbImageList");
const ProductImageLightboxCarousel_1 = require("./ProductImageLightboxCarousel");
const styles = ({ palette, spacing, transitions }) => createStyles_1.default({
    root: {
        height: '100vh',
        width: '100vw',
        display: 'block',
    },
    container: {
        boxSizing: 'border-box',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
    },
    containerLeft: {
        flexDirection: 'row-reverse',
    },
    containerRight: {
        flexDirection: 'row',
    },
    containerTop: {
        flexDirection: 'column-reverse',
    },
    containerBottom: {
        flexDirection: 'column',
    },
    actionWrapper: {
        position: 'absolute',
        right: spacing(2),
    },
    actionButton: {
        transition: transitions.create(['all']),
        backgroundColor: styles_1.fade(palette.primary.main, 0.8),
        margin: '0.25rem',
        borderRadius: 0,
        zIndex: 9998,
        '& svg': {
            opacity: 0.6,
        },
        '&:hover': {
            backgroundColor: styles_1.fade(palette.primary.main, 0.9),
            '& svg': {
                opacity: 0.8,
            },
        },
    },
    largeImage: {
        maxHeight: 'calc(100vh - 164px)',
    },
    imageWrapper: {
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
        cursor: 'zoom-in',
        padding: spacing(2),
        width: '100%',
        '& .react-transform-component': {
            zIndex: 9997,
            overflow: 'inherit',
            margin: 'auto',
        },
    },
    cursorGrab: {
        '& .react-transform-component': {
            cursor: 'grab',
        },
    },
    thumbnailWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    thumbnailWrapperContent: {
        zIndex: 9998,
    },
    thumbnailWrapperLeftRight: {
        height: '100%',
        alignItems: 'center',
        minWidth: '100px',
    },
    thumbnailWrapperBottom: {},
    thumbnailWrapperTop: {},
    thumbnail: {
        height: '80px',
        width: '100px',
    },
});
const ProductImageLightboxContent = ({ classes, openLightbox, name, images, thumbnailDirection = 'left', handleClose, hideThumbnail, handleChange, }) => {
    const theme = useTheme_1.default();
    const isMobile = useMediaQuery_1.default(theme.breakpoints.down('sm'));
    const direction = isMobile ? 'bottom' : thumbnailDirection;
    const [panZoomEnabled, setPanZoomEnabled] = React.useState(false);
    const [currentIndex, setCurrentIndex] = React.useState(() => images.findIndex(image => image.src === openLightbox) || 0);
    const [currentPanZoomSlide, setCurrentPanZoomSlide] = React.useState(openLightbox);
    React.useEffect(() => {
        setCurrentIndex(images.findIndex(image => image.src === openLightbox) || 0);
    }, [openLightbox, images]);
    React.useEffect(() => {
        setCurrentPanZoomSlide(images[currentIndex].src);
        if (handleChange)
            handleChange(images[currentIndex], currentIndex);
    }, [currentIndex]);
    const handleChangeIndex = index => {
        setCurrentIndex(index);
    };
    const handleNext = () => {
        setPanZoomEnabled(false);
        handleChangeIndex(currentIndex === images.length - 1 ? 0 : currentIndex + 1);
    };
    const handlePrev = () => {
        setPanZoomEnabled(false);
        handleChangeIndex(currentIndex <= 0 ? images.length - 1 : currentIndex - 1);
    };
    const handleThumbImage = (src, index) => {
        handleChangeIndex(index);
    };
    return (React.createElement("div", { className: classes.root },
        React.createElement(React.Fragment, null,
            React.createElement("div", { className: classes.actionWrapper },
                panZoomEnabled && (React.createElement(HBIcon_1.default, { icon: "zoom_out_all", isButton: true, color: "default", fontSize: "small", ButtonProps: { className: classes.actionButton, onClick: () => setPanZoomEnabled(false) } })),
                React.createElement(HBIcon_1.default, { icon: "close", isButton: true, color: "default", fontSize: "small", ButtonProps: { className: classes.actionButton, onClick: handleClose } })),
            React.createElement("div", { className: classnames_1.default(classes.container, {
                    [classes.containerLeft]: direction === 'left',
                    [classes.containerRight]: direction === 'right',
                    [classes.containerBottom]: direction === 'bottom',
                    [classes.containerTop]: direction === 'top',
                }) },
                React.createElement("div", { className: classnames_1.default(classes.imageWrapper, {
                        [classes.cursorGrab]: panZoomEnabled,
                    }) },
                    images.length > 1 && !isMobile && (React.createElement(HBIcon_1.default, { icon: "keyboard_arrow_left", isButton: true, color: "default", fontSize: "small", ButtonProps: { className: classes.actionButton, onClick: handlePrev } })),
                    panZoomEnabled ? (React.createElement(react_zoom_pan_pinch_1.TransformWrapper, { defaultScale: 1.5, defaultPositionX: 100, defaultPositionY: 100 },
                        React.createElement(react_zoom_pan_pinch_1.TransformComponent, null,
                            React.createElement(HBImage_1.default, { src: currentPanZoomSlide, alt: name, className: classes.largeImage })))) : (React.createElement(ProductImageLightboxCarousel_1.default, { className: classes.largeImage, images: images, name: name, handleZoom: () => setPanZoomEnabled(true), onChangeIndex: handleChangeIndex, index: currentIndex, hideNav: true })),
                    images.length > 1 && !isMobile && (React.createElement(HBIcon_1.default, { icon: "keyboard_arrow_right", isButton: true, color: "default", fontSize: "small", ButtonProps: { className: classes.actionButton, onClick: handleNext } }))),
                React.createElement("div", null, !hideThumbnail && (React.createElement("div", { className: classnames_1.default(classes.thumbnailWrapper, {
                        [classes.thumbnailWrapperLeftRight]: direction === 'left' || direction === 'right',
                        [classes.thumbnailWrapperBottom]: direction === 'bottom',
                        [classes.thumbnailWrapperTop]: direction === 'top',
                    }) },
                    React.createElement(ProductThumbImageList_1.default, { images: images, handleThumbImage: handleThumbImage, direction: direction, index: currentIndex, classes: { root: classes.thumbnailWrapperContent } }))))))));
};
exports.default = withStyles_1.default(styles)(ProductImageLightboxContent);
