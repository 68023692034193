"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const makeStyles_1 = require("@material-ui/core/styles/makeStyles");
const Box_1 = require("@material-ui/core/Box");
const Grid_1 = require("@material-ui/core/Grid");
const HBTypography_1 = require("@components/library/HBTypography");
const HBIcon_1 = require("@components/library/HBIcon");
const LabelContext_1 = require("@components/common/context/LabelContext");
const useStyles = makeStyles_1.default(({ spacing }) => ({
    root: {
        '& .MuiGrid-item + .MuiGrid-item': {
            marginTop: spacing(2),
        },
    },
}));
const AddressInfoStepSummary = ({ addressShipping, addressInvoice }) => {
    const classes = useStyles();
    const labels = LabelContext_1.useLabelContext();
    const renderAddress = (address, title) => (React.createElement(Grid_1.default, { item: true, container: true, direction: "column" },
        React.createElement(Box_1.default, { display: "inline-flex" },
            React.createElement(HBTypography_1.default, { variant: "body2", opacity: 0.4, textTransformNone: true }, title),
            React.createElement(HBIcon_1.default, { icon: "check", color: "ternary" })),
        React.createElement(HBTypography_1.default, { variant: "body1" }, address.label),
        address.helperText1 && React.createElement(HBTypography_1.default, { variant: "body1" }, address.helperText1),
        React.createElement(HBTypography_1.default, { variant: "body1" }, address.helperText)));
    return (React.createElement(Grid_1.default, { container: true, className: classes.root },
        addressShipping && renderAddress(addressShipping, labels.MAIN_OR_SHIPPING_ADDRESS),
        addressInvoice &&
            addressInvoice.value !== addressShipping.value &&
            renderAddress(addressInvoice, labels.INVOICE_ADDRESS)));
};
exports.default = AddressInfoStepSummary;
