"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@material-ui/core");
const styles_1 = require("@material-ui/core/styles");
const Colors_1 = require("@components/library/theme/Colors");
const styles = ({ breakpoints, palette, spacing, typography }) => styles_1.createStyles({
    root: {
        flexGrow: 1,
    },
    titleWrapper: {
        padding: spacing(3.375, 2.5, 0.5, 2.5),
        [breakpoints.up('sm')]: {
            padding: spacing(2.75, 3, 0.5, 3),
        },
        flexGrow: 0,
        position: 'sticky',
        top: '0',
        backgroundColor: Colors_1.default.backgroundGrey,
        zIndex: 1,
    },
    title: {
        padding: spacing(0, 0, 2.5, 0),
        borderBottom: `1px solid ${core_1.fade(palette.primary.main, 0.3)}`,
        ...typography.h6,
        [breakpoints.up('sm')]: {
            ...typography.h5,
        },
    },
    cartContent: {
        backgroundColor: Colors_1.default.backgroundGrey,
        padding: spacing(0, 2.5, 0, 2.5),
        [breakpoints.up('sm')]: {
            padding: spacing(0, 3, 0, 3),
        },
        flexGrow: 1,
    },
    cartEmpty: {
        paddingTop: spacing(3),
        display: 'flex',
        alignItems: 'center',
        height: '80%',
    },
});
exports.default = styles;
