"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFormHook = void 0;
const React = require("react");
const react_hook_form_1 = require("react-hook-form");
function useFormHook(props) {
    const methods = react_hook_form_1.useForm({
        criteriaMode: 'all',
        reValidateMode: 'onSubmit',
        ...props,
    });
    return {
        methods,
    };
}
exports.useFormHook = useFormHook;
function FormContextProvider(props) {
    const { methods, children, onSubmit, ...other } = props;
    const handleSubmitted = (data) => {
        if (onSubmit) {
            onSubmit(data);
        }
    };
    return (React.createElement(react_hook_form_1.FormProvider, Object.assign({}, methods),
        React.createElement("form", Object.assign({ onSubmit: methods.handleSubmit(handleSubmitted), noValidate: true }, other), children)));
}
exports.default = FormContextProvider;
