"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const FormFieldContext_1 = require("@components/common/utils/Form/FormFieldContext/FormFieldContext");
const ApiContext_1 = require("@components/common/context/ApiContext");
const useApiRequest_1 = require("@components/common/utils/hooks/useApiRequest");
const FormContext_1 = require("@components/common/utils/Form/FormContext");
const AppContext_1 = require("@components/common/context/AppContext");
const PersonalInfoFieldDefaults = { email: '', createAccount: false, acceptNewsletter: false };
const PersonalInfoStepForm = ({ api, form }) => {
    const defaultValues = { ...PersonalInfoFieldDefaults };
    const { methods } = FormFieldContext_1.useFormHook({ defaultValues });
    const { onSubmit, isLoading, data, apiError } = useApiRequest_1.default();
    const { dispatch } = AppContext_1.useAppContext();
    React.useEffect(() => {
        if (!apiError && (data === null || data === void 0 ? void 0 : data.member)) {
            dispatch({ type: AppContext_1.ActionTypes.ADD_PERSONAL_INFO, payload: data });
        }
    }, [data]);
    const handleSubmit = async (payload) => await onSubmit(() => api.guestSignUp(payload));
    return (React.createElement(FormFieldContext_1.default, { methods: methods, onSubmit: handleSubmit },
        React.createElement(FormContext_1.default, Object.assign({}, form, { hideAsterisk: true, error: !!apiError, errors: apiError === null || apiError === void 0 ? void 0 : apiError.inputError, disabled: isLoading }))));
};
exports.default = ApiContext_1.withApi(PersonalInfoStepForm);
