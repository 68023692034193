"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const CartContent_1 = require("@components/pages/cart/CartContent/CartContent");
const Drawer_1 = require("@material-ui/core/Drawer");
const makeStyles_1 = require("@material-ui/core/styles/makeStyles");
const Box_1 = require("@material-ui/core/Box");
const StickyCart_1 = require("@components/pages/checkout/cart/StickyCart/StickyCart");
const LabelContext_1 = require("@components/common/context/LabelContext");
const useStyles = makeStyles_1.default(({ breakpoints }) => ({
    drawer: {
        width: ({ drawerWidth }) => drawerWidth,
        flexShrink: 0,
        [breakpoints.down('sm')]: {
            width: ({ drawerWidthSM }) => drawerWidthSM,
        },
    },
    drawerPaper: {
        border: 'none',
        width: ({ drawerWidth }) => drawerWidth,
        [breakpoints.down('sm')]: {
            width: ({ drawerWidthSM }) => drawerWidthSM,
        },
    },
    sideCart: {
        display: 'block',
        [breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}));
const CheckoutCart = ({ drawerWidth, drawerWidthSM }) => {
    const classes = useStyles({ drawerWidth, drawerWidthSM });
    const labels = LabelContext_1.useLabelContext();
    return (React.createElement(React.Fragment, null,
        React.createElement(Box_1.default, { className: classes.sideCart },
            React.createElement(Drawer_1.default, { className: classes.drawer, variant: "permanent", classes: {
                    paper: classes.drawerPaper,
                }, anchor: "right" },
                React.createElement(CartContent_1.default, { enableAction: false, title: labels.SHOPPING_CART, totalTextlabel: labels.TOTAL, showSubtotal: true }))),
        React.createElement(StickyCart_1.default, null)));
};
exports.default = CheckoutCart;
