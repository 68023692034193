"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Svg_1 = require("./Svg");
const G_1 = require("./Svg/G");
const Color = ({ color = 'primary', ...rest }) => (React.createElement(Svg_1.default, Object.assign({}, rest, { viewBox: "0 0 32 32" }),
    React.createElement(G_1.default, { color: color, fill: "none", enableBackground: "new 0 0 22 32" },
        React.createElement("path", { fill: color, d: "M15.403,21h4.58c1.053,0,1.909-0.856,2.017-1.909V1.909C21.892,0.856,21.036,0,19.983,0H2.017\n\t\tC0.964,0,0.108,0.856,0,1.909v17.182C0.108,20.144,0.964,21,2.017,21h4.962c0.348,0,2.083,0.074,2.083,1.542\n\t\tc0,2.622-0.553,3.842-0.762,4.302l-0.049,0.109l0.403,0.174v0.013l-0.447-0.09c-0.017,0.033-0.03,0.068-0.038,0.104\n\t\tc-0.154,0.411-0.232,0.833-0.232,1.257C7.938,30.558,9.168,32,11,32c1.851,0,3.094-1.442,3.094-3.589\n\t\tc0-0.391-0.066-0.78-0.198-1.161c-0.005-0.114-0.049-0.22-0.119-0.302c-0.026-0.06-0.06-0.125-0.101-0.202\n\t\tC13.457,26.331,13,25.469,13,22.542C13,21.922,13.64,21,15.403,21z M1,12.059h20V16H1V12.059z M2.017,1h17.966\n\t\tC20.484,1,20.892,1.408,21,1.909v9.149H1V1.909C1.108,1.408,1.516,1,2.017,1z M12.792,27.214c0.028,0.053,0.052,0.097,0.093,0.189\n\t\tc0,0.001,0.003,0.007,0.007,0.016c0.004,0.013,0.008,0.025,0.013,0.038c0.125,0.313,0.188,0.635,0.188,0.954\n\t\tC13.094,29.663,12.544,31,11,31c-1.521,0-2.062-1.337-2.062-2.589c0-0.319,0.063-0.641,0.188-0.954\n\t\tc0.004-0.012,0.009-0.023,0.012-0.035c0.014-0.03,0.031-0.068,0.033-0.072l0.042-0.093c0.232-0.514,0.851-1.876,0.851-4.715\n\t\tC10.062,20.535,8.125,20,6.95,20c0,0,0,0-0.001,0H2.017C1.516,20,1.108,19.592,1,19.091V17h20v2.091\n\t\tC20.892,19.592,20.484,20,19.983,20h-4.58C12.906,20,12,21.521,12,22.542C12,25.717,12.535,26.729,12.792,27.214z" }),
        ' ')));
exports.default = Color;
