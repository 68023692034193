"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const styles_1 = require("@material-ui/styles");
const withStyles_1 = require("@material-ui/styles/withStyles");
const HBTypography_1 = require("@components/library/HBTypography");
const HBIcon_1 = require("@components/library/HBIcon");
const HBDialog_1 = require("@components/library/HBDialog");
const Slide_1 = require("@material-ui/core/Slide");
const CartContent_1 = require("@components/pages/cart/CartContent");
const Avatar_1 = require("@material-ui/core/Avatar");
const Box_1 = require("@material-ui/core/Box");
const HBContainer_1 = require("@components/library/HBContainer");
const Grid_1 = require("@material-ui/core/Grid");
const HBImage_1 = require("@components/library/HBImage");
const AppContext_1 = require("@components/common/context/AppContext");
const LabelContext_1 = require("@components/common/context/LabelContext");
const styles = ({ palette, spacing, breakpoints, transitions, shadows }) => styles_1.createStyles({
    root: {
        position: 'sticky',
        bottom: 0,
        padding: spacing(2.5, 2),
        backgroundColor: '#F5F5F5',
        display: 'none',
        [breakpoints.down('sm')]: {
            display: 'block',
            boxShadow: shadows[6],
        },
    },
    content: {
        justifyContent: 'space-between',
        ['@media (max-width:460px)']: {
            justifyContent: 'flex-start',
        },
    },
    badge: {
        width: '100%',
        justifyContent: 'center',
        backgroundColor: palette.primary.main,
    },
    avatar: {
        backgroundColor: palette.common.white,
        color: palette.primary.main,
        width: spacing(7),
        height: spacing(7),
        borderRadius: 999,
        '& img': {
            objectFit: 'contain',
        },
    },
    extraAvatar: {
        backgroundColor: palette.secondary.main,
        width: spacing(7),
        height: spacing(7),
    },
    icon: {
        transform: 'rotate(-180deg)',
    },
    productWrapper: {
        display: 'flex',
        ['@media (max-width:460px)']: {
            display: 'none',
        },
    },
    productWrapperMobile: {
        display: 'none',
        ['@media (max-width:460px)']: {
            display: 'flex',
        },
    },
    totalWrapper: {
        transition: transitions.create(['all'], { easing: 'ease-in-out' }),
        ['@media (max-width:699px)']: {
            marginRight: '12%',
        },
        ['@media (max-width:460px)']: {
            marginRight: '0',
            marginLeft: spacing(2),
        },
    },
    arrowIcon: {
        top: 15,
    },
});
const Transition = React.forwardRef(function Transition(props, ref) {
    return React.createElement(Slide_1.default, Object.assign({ direction: "up", ref: ref }, props));
});
const StickyCart = ({ classes }) => {
    var _a;
    const { state: { cart: { items, summary: { total } = {} } = {} } = {} } = AppContext_1.useAppContext();
    const labels = LabelContext_1.useLabelContext();
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Box_1.default, { className: classes.root },
            React.createElement(HBContainer_1.default, { maxWidth: "sm" },
                React.createElement(Grid_1.default, { container: true, direction: "column", spacing: 2 },
                    React.createElement(Grid_1.default, { item: true, container: true, alignItems: "center", justify: "space-between" },
                        React.createElement(Grid_1.default, { item: true },
                            React.createElement(HBTypography_1.default, { variant: "h6" }, labels.VIEW_SHOPPING_CART)),
                        React.createElement(Grid_1.default, { item: true },
                            React.createElement(HBIcon_1.default, { icon: "keyboard_arrow_down", isButton: true, ButtonProps: { className: classes.icon, onClick: handleClickOpen } }))),
                    React.createElement(Grid_1.default, { item: true, container: true, direction: "row", alignItems: "center", className: classes.content },
                        React.createElement(Grid_1.default, { item: true },
                            React.createElement(Grid_1.default, { container: true, direction: "row", spacing: 1, className: classes.productWrapper }, (_a = items === null || items === void 0 ? void 0 : items.slice(0, 2)) === null || _a === void 0 ? void 0 :
                                _a.map(({ _id, image, name }) => (React.createElement(Grid_1.default, { item: true, key: _id },
                                    React.createElement(Grid_1.default, { className: classes.avatar },
                                        React.createElement(HBImage_1.default, { src: image === null || image === void 0 ? void 0 : image.path, size: 56, alt: name }))))),
                                (items === null || items === void 0 ? void 0 : items.length) > 2 && (React.createElement(Grid_1.default, { item: true },
                                    React.createElement(Avatar_1.default, { className: classes.extraAvatar },
                                        "+",
                                        items.length - 2)))),
                            React.createElement(Grid_1.default, { container: true, className: classes.productWrapperMobile },
                                React.createElement(Avatar_1.default, { className: classes.extraAvatar }, items.length))),
                        React.createElement(Grid_1.default, { item: true, className: classes.totalWrapper },
                            React.createElement(HBTypography_1.default, { variant: "body1" }, labels.CART_TOTAL),
                            React.createElement(HBTypography_1.default, { variant: "h6" }, total)))))),
        React.createElement(HBDialog_1.default, { forceFullScreen: true, open: open, onClose: handleClose, icon: "keyboard_arrow_down", TransitionComponent: Transition, IconWrapperClass: classes.arrowIcon },
            React.createElement(CartContent_1.default, { title: labels.SHOPPING_CART, totalTextlabel: labels.TOTAL, enableAction: false, fullWidth: true, showSubtotal: true }))));
};
exports.default = withStyles_1.default(styles)(StickyCart);
