"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegisterFormFieldsDefaults = void 0;
const React = require("react");
const ApiContext_1 = require("@components/common/context/ApiContext");
const FormFieldContext_1 = require("@components/common/utils/Form/FormFieldContext");
const FormFieldContext_2 = require("@components/common/utils/Form/FormFieldContext/FormFieldContext");
const useApiRequest_1 = require("@components/common/utils/hooks/useApiRequest");
const FormContext_1 = require("@components/common/utils/Form/FormContext");
exports.RegisterFormFieldsDefaults = {
    email: '',
    password: '',
    confirmPassword: '',
    givenName: '',
    familyName: '',
    acceptNewsletter: false,
};
const RegisterForm = ({ api, content, onChange }) => {
    const defaultValues = { ...exports.RegisterFormFieldsDefaults };
    const { methods } = FormFieldContext_2.useFormHook({ defaultValues });
    const { onSubmit, isLoading, apiError } = useApiRequest_1.default();
    const handleSubmit = (data) => onSubmit(() => api.signUp(data));
    return (React.createElement(FormFieldContext_1.default, { methods: methods, onSubmit: handleSubmit },
        React.createElement(FormContext_1.default, Object.assign({}, content.form, { divider: true, onChange: onChange, error: !!apiError, errors: apiError === null || apiError === void 0 ? void 0 : apiError.inputError, disabled: isLoading }))));
};
exports.default = ApiContext_1.withApi(RegisterForm);
