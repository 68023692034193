"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Svg_1 = require("../Svg");
const Pinterest = props => (React.createElement(Svg_1.default, Object.assign({}, props, { viewBox: "0 0 44 44" }),
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { d: "M0 0h44v44H0z" }),
        React.createElement("circle", { cx: "22", cy: "22", r: "21", stroke: "#b6956c", strokeWidth: "2" }),
        React.createElement("path", { d: "M22.477 10.305c-6.66 0-10.018 4.774-10.018 8.757 0 2.41.913 4.555 2.87 5.354.321.132.61.005.702-.35.065-.246.219-.866.287-1.126.093-.351.057-.474-.202-.781-.565-.665-.926-1.527-.926-2.749 0-3.542 2.65-6.713 6.902-6.713 3.764 0 5.832 2.3 5.832 5.371 0 4.042-1.789 7.454-4.444 7.454-1.466 0-2.563-1.213-2.212-2.7.42-1.776 1.237-3.692 1.237-4.975 0-1.147-.616-2.104-1.89-2.104-1.5 0-2.704 1.551-2.704 3.628 0 1.323.447 2.218.447 2.218l-1.803 7.638c-.535 2.268-.08 5.046-.041 5.326.022.167.236.207.333.082.138-.181 1.924-2.385 2.53-4.588.173-.623.986-3.852.986-3.852.487.929 1.911 1.746 3.425 1.746 4.505 0 7.562-4.108 7.562-9.606.001-4.158-3.52-8.03-8.873-8.03z", fill: "#b6956c", fillRule: "nonzero" }))));
exports.default = Pinterest;
