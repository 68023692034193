"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_virtual_1 = require("react-virtual");
const VirtualColorVariantList = ({ columns, ItemComponent, itemComponentProps }) => {
    const parentRef = React.useRef();
    const columnVirtualizer = react_virtual_1.useVirtual({
        horizontal: true,
        size: columns.length,
        parentRef,
    });
    return (React.createElement("div", { ref: parentRef, style: {
            width: `auto`,
            height: `100px`,
            overflow: 'auto',
        } },
        React.createElement("div", { style: {
                width: `${columnVirtualizer.totalSize}px`,
                height: '100%',
                position: 'relative',
            } }, columnVirtualizer.virtualItems.map(virtualColorVariant => (React.createElement("div", { key: virtualColorVariant.index, ref: virtualColorVariant.measureRef, style: {
                position: 'absolute',
                top: 0,
                left: 0,
                height: '80px',
                width: `${columns[virtualColorVariant.index]}px`,
                transform: `translateX(${virtualColorVariant.start}px)`,
            } },
            React.createElement(ItemComponent, Object.assign({}, columns[virtualColorVariant.index], itemComponentProps))))))));
};
exports.default = VirtualColorVariantList;
