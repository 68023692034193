"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const styles_1 = require("@material-ui/core/styles");
const Drawer_1 = require("@material-ui/core/Drawer");
const HBIconButton_1 = require("@components/library/HBIconButton");
const HBIcon_1 = require("@components/library/HBIcon");
const Colors_1 = require("@components/library/theme/Colors");
const useStyles = styles_1.makeStyles(({ breakpoints }) => ({
    contentWrapper: {
        position: 'relative',
        height: '100%',
    },
    closeIcon: {
        top: 21,
        right: 14,
        position: 'fixed',
        zIndex: 2,
        [breakpoints.up('sm')]: {
            top: 18,
            right: 21,
        },
    },
    paper: {
        backgroundColor: Colors_1.default.backgroundGrey,
    },
    rotateIcon: {
        '&:hover': {
            '& svg': {
                transform: 'rotate(90deg)',
            },
        },
    },
}));
const Drawer = ({ className, icon, label, anchor, children }) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const toggleDrawer = open => event => {
        event.preventDefault();
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpen(open);
    };
    const DrawerContent = () => (React.createElement("div", { className: classes.contentWrapper, role: "presentation" },
        React.createElement(HBIcon_1.default, { icon: "close", fontSize: "2xsmall", isButton: true, ButtonProps: {
                className: classnames_1.default(classes.closeIcon, classes.rotateIcon),
                onClick: toggleDrawer(false),
            } }),
        children));
    return (React.createElement(React.Fragment, null,
        React.createElement(HBIconButton_1.default, { label: label, onClick: toggleDrawer(true), className: className }, icon),
        React.createElement(Drawer_1.default, { classes: {
                paper: classes.paper,
            }, anchor: anchor, open: open, onClose: toggleDrawer(false), transitionDuration: 400 }, DrawerContent())));
};
exports.default = Drawer;
