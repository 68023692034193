"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const useMediaQuery_1 = require("@material-ui/core/useMediaQuery");
const Grid_1 = require("@material-ui/core/Grid");
const useApiRequest_1 = require("@components/common/utils/hooks/useApiRequest");
const ApiContext_1 = require("@components/common/context/ApiContext");
const AppContext_1 = require("@components/common/context/AppContext");
const AddressInfoEditForm_1 = require("@components/pages/profile/detail/AddressDetailnfo/AddressInfoEditForm");
const AddressDetailItem_1 = require("@components/pages/profile/detail/AddressDetailnfo/AddressDetailItem");
const ProfileDetailItem_1 = require("@components/pages/profile/detail/ProfileDetailItem");
const AddressDetailnfo = ({ api, title, editForm }) => {
    const { state: { member: { crmContact: { crmAdresses = [] } = {} } = {} } = {}, dispatch } = AppContext_1.useAppContext();
    const isTablet = useMediaQuery_1.default('(max-width:760px)');
    const { isLoading, onSubmit } = useApiRequest_1.default();
    const handleDelete = async (id) => {
        await onSubmit(() => api.deleteMemberAddress(id), () => dispatch({ type: AppContext_1.ActionTypes.DELETE_MEMBER_ADDRESS, payload: id }));
    };
    return (React.createElement(ProfileDetailItem_1.default, { title: title, actionLabel: "Neue Adresse hinzuf\u00FCgen", formComponent: React.createElement(AddressInfoEditForm_1.default, { editForm: editForm, dispatch: dispatch, isNew: true }) },
        React.createElement(Grid_1.default, { container: true, spacing: isTablet ? 2 : 3 }, crmAdresses === null || crmAdresses === void 0 ? void 0 : crmAdresses.map(addrs => (React.createElement(Grid_1.default, { key: addrs._id, item: true, xs: isTablet ? 12 : 6 },
            React.createElement(AddressDetailItem_1.default, Object.assign({}, addrs, { editForm: editForm, dispatch: dispatch, handleDelete: handleDelete }))))))));
};
exports.default = ApiContext_1.withApi(AddressDetailnfo);
