"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Grid_1 = require("@material-ui/core/Grid");
const HBIcon_1 = require("@components/library/HBIcon");
const HBTypography_1 = require("@components/library/HBTypography");
const HBLink_1 = require("@components/library/HBLink/HBLink");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const styles_1 = require("@material-ui/core/styles");
const styles = ({ breakpoints }) => styles_1.createStyles({
    info: {
        [breakpoints.up('sm')]: {
            justifyContent: 'center',
        },
        '& svg': {
            ['@media (min-width:960px) and (max-width:990px)']: {
                fontSize: 30,
            },
        },
    },
});
const ShopInfo = ({ classes, info: infos }) => {
    const Info = ({ to, children }) => !to ? (React.createElement(Grid_1.default, { item: true }, children)) : (React.createElement(Grid_1.default, { item: true, component: HBLink_1.default, to: to }, children));
    return (React.createElement(Grid_1.default, { container: true, spacing: 2, justify: "space-between" }, infos === null || infos === void 0 ? void 0 : infos.map(({ title, subTitle, to, icon }) => (React.createElement(Grid_1.default, { key: title, item: true, xs: 12, sm: 6, md: 3 },
        React.createElement(Info, { to: to },
            React.createElement(Grid_1.default, { container: true, spacing: 2, wrap: "nowrap", className: classes.info },
                icon && (React.createElement(Grid_1.default, { item: true },
                    React.createElement(HBIcon_1.default, { icon: icon, fontSize: "large" }))),
                React.createElement(Grid_1.default, { item: true },
                    title && React.createElement(HBTypography_1.default, { variant: "h6" }, title),
                    subTitle &&
                        (typeof subTitle === 'string' ? (React.createElement(HBTypography_1.default, { variant: "body1" }, subTitle)) : (subTitle === null || subTitle === void 0 ? void 0 : subTitle.map(label => (React.createElement(HBTypography_1.default, { key: label, variant: "body1" }, label)))))))))))));
};
exports.default = withStyles_1.default(styles)(ShopInfo);
