"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.renderSlide = exports.getItemCount = void 0;
const HBIcon_1 = require("@components/library/HBIcon");
const classnames_1 = require("classnames");
const React = require("react");
const core_1 = require("swiper/core");
const react_1 = require("swiper/react");
const SliderStyles_1 = require("./SliderStyles");
core_1.default.use([core_1.Navigation, core_1.Pagination, core_1.Mousewheel, core_1.Keyboard, core_1.Autoplay]);
exports.getItemCount = (items, max) => (items.length > max ? max : items.length);
exports.renderSlide = (slide, idx) => {
    return (React.createElement(react_1.SwiperSlide, { key: `slideContent-${idx}` },
        React.createElement("div", { style: {
                width: '100%',
                height: '100%',
            } }, slide)));
};
const renderIcon = (color, icon) => React.createElement(HBIcon_1.default, { icon: icon, color: color, isButton: true, ButtonProps: { color: color } });
const Slider = ({ className, items = [], color = 'default', dots = true, arrows = true, infinite = true, autoplay = false, useKeyboard = true, useMousewheel = false, showNavOnHover = true, slidesToShow = 1, slidesToScroll = 1, responsive = [], prevIcon = 'arrow_left', nextIcon = 'arrow_right', preventClicks = true, preventClicksPropagation = true, }) => {
    const classes = SliderStyles_1.default({});
    const paginationRef = React.useRef(null);
    const sliderRef = React.useRef(null);
    const isFirstRun = React.useRef(true);
    React.useEffect(() => {
        isFirstRun.current = false;
    }, []);
    const sliderDefaultProps = {};
    if (dots) {
        sliderDefaultProps.pagination = {
            el: paginationRef.current,
            clickable: true,
            bulletClass: classes.paginationClass,
            bulletActiveClass: classes.paginationActiveClass,
            clickableClass: classes.paginationClickableClass,
        };
    }
    const handlePrev = React.useCallback(() => {
        if (!sliderRef.current)
            return;
        sliderRef.current.childNodes[0].swiper.slidePrev();
    }, []);
    const handleNext = React.useCallback(() => {
        if (!sliderRef.current)
            return;
        sliderRef.current.childNodes[0].swiper.slideNext();
    }, []);
    return (React.createElement("div", { className: classnames_1.default(className, classes.root, { [classes.showNavOnHover]: showNavOnHover }), ref: sliderRef },
        React.createElement(react_1.Swiper, Object.assign({}, sliderDefaultProps, { onBeforeInit: (swiper) => {
                if (responsive && !!responsive.length) {
                    swiper.params.breakpoints = {};
                    responsive.forEach(({ breakpoint, settings }) => {
                        swiper.params.breakpoints[breakpoint] = {
                            slidesPerView: settings.slidesToShow,
                            slidesPerGroup: settings.slidesToScroll,
                        };
                    });
                }
            }, preventClicksPropagation: preventClicksPropagation, preventClicks: preventClicks, autoplay: autoplay, mousewheel: useMousewheel, keyboard: useKeyboard, loop: infinite, className: classes.sliderContainer, slidesPerView: slidesToShow, slidesPerGroup: slidesToScroll }), items.map(exports.renderSlide)),
        dots && React.createElement("span", { ref: paginationRef, className: classes.pagination }),
        arrows && (React.createElement(React.Fragment, null,
            React.createElement("span", { onClick: handlePrev, className: classnames_1.default('slider-prev', classes.sliderNav, classes.sliderNavPrev) }, renderIcon(color, prevIcon)),
            React.createElement("span", { onClick: handleNext, className: classnames_1.default('slider-next', classes.sliderNav, classes.sliderNavNext) }, renderIcon(color, nextIcon))))));
};
exports.default = Slider;
