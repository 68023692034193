"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const ButtonBase_1 = require("@material-ui/core/ButtonBase");
const HBTypography_1 = require("../HBTypography");
const HBIcon_1 = require("../HBIcon");
const HBFilterStyles_1 = require("./HBFilterStyles");
const LabelTypography = withStyles_1.default(({ spacing }) => ({
    root: {
        maxWidth: 240,
        width: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& svg': {
            marginRight: spacing(1),
        },
    },
}))(HBTypography_1.default);
const HBFilter = ({ classes, className, label, selected, onClick, filterColor, startIcon, endIcon, endIconProps, disabled, onDelete, textWrap, ...rest }) => (React.createElement(ButtonBase_1.default, Object.assign({ className: classnames_1.default(className, classes.root, {
        [classes.selected]: selected,
        [classes.disabled]: disabled,
    }), onClick: onClick, disabled: disabled }, rest),
    filterColor && React.createElement("div", { className: classes.color }),
    React.createElement(LabelTypography, { align: "left", variant: "body2", truncate: true, clamp: 1, noWrap: true, textTransformNone: true, className: classnames_1.default(classes.label, {
            [classes.labelWithEndIcon]: endIcon,
            [classes.textWrap]: textWrap,
        }) },
        startIcon && React.createElement(HBIcon_1.default, { icon: startIcon, fontSize: "3xsmall" }),
        label),
    endIcon && (React.createElement("span", { className: classes.endIcon, onClick: onDelete },
        React.createElement(HBIcon_1.default, Object.assign({ icon: endIcon, fontSize: "4xsmall" }, endIconProps))))));
exports.default = withStyles_1.default(HBFilterStyles_1.default)(HBFilter);
