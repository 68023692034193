"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
const styles = ({ palette, spacing, typography, breakpoints }) => styles_1.createStyles({
    root: {
        position: 'relative',
        width: '100%',
        height: '100%',
        backgroundColor: 'transparent',
        borderRadius: 0,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'center',
    },
    nameWrapper: {
        padding: spacing(2, 0, 0, 0),
    },
    name: {
        height: 52,
        wordBreak: 'break-word',
        [breakpoints.down('xs')]: {
            height: 50,
            width: '',
        },
    },
    alternateName: {
        height: 52,
        wordBreak: 'break-word',
        [breakpoints.down('xs')]: {
            display: 'inline-block',
            width: 'calc(100%)',
            height: 50,
            whiteSpace: 'nowrap',
        },
    },
    priceWrapper: {
        ...typography.h6,
        textAlign: 'center',
    },
    price: {
        ...typography.h6,
        textAlign: 'center',
    },
    salePrice: {
        width: '102px',
        height: '32px',
    },
    wishlistIconWrapper: {
        position: 'absolute',
        top: spacing(0),
        right: spacing(0),
        [breakpoints.down('xs')]: {
            top: spacing(0),
            right: spacing(0),
        },
        '& button': {
            '&:hover': {
                backgroundColor: palette.common.white,
            },
        },
    },
    wishlistIcon: {
        backgroundColor: palette.common.white,
        '&:hover': {
            opacity: 1,
        },
        '&.Mui-disabled': {
            backgroundColor: palette.common.white,
            '&:hover': {
                backgroundColor: palette.common.white,
            },
        },
    },
    wishlistFilledIcon: {
        backgroundColor: palette.common.white,
        '&:hover': {
            opacity: 1,
        },
        '& svg': {
            color: palette.primary.main,
        },
        '&.Mui-disabled': {
            backgroundColor: palette.common.white,
            '&:hover': {
                backgroundColor: palette.common.white,
            },
        },
    },
    prodImageWrapper: {
        position: 'relative',
        overflow: 'hidden',
        height: 320,
        width: '100%',
        minHeight: '100%',
        backgroundColor: palette.common.white,
        [breakpoints.down('xs')]: {
            height: 200,
        },
        '& img': {
            width: '100%',
            height: '100%',
            maxWidth: '100%',
        },
    },
    ctaWrapper: {
        justifyContent: 'center',
        '& button': {
            whiteSpace: 'nowrap',
        },
    },
    outOfStock: {
        opacity: 0.4,
    },
    outOfStockButton: {
        color: `${palette.error.main} !important`,
    },
    inCartButton: {
        color: '#34c585 !important',
    },
});
exports.default = styles;
