"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Step_1 = require("@components/pages/checkout/Step/Step");
const makeStyles_1 = require("@material-ui/core/styles/makeStyles");
const PaymentInfoStepForm_1 = require("@components/pages/checkout/Step/PaymentInfoStep/PaymentInfoStepForm");
const useStyles = makeStyles_1.default(({ spacing }) => ({
    root: {
        paddingTop: spacing(2),
    },
}));
const PaymentInfoStep = ({ disabled, completed, form }) => {
    const classes = useStyles();
    return (React.createElement(Step_1.default, { noBackground: true, active: !disabled, completed: completed, classes: { root: classes.root } },
        React.createElement(PaymentInfoStepForm_1.default, { form: form })));
};
exports.default = PaymentInfoStep;
