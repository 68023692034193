"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const createStyles_1 = require("@material-ui/core/styles/createStyles");
const Card_1 = require("@material-ui/core/Card");
const CardHeader_1 = require("@material-ui/core/CardHeader");
const CardContent_1 = require("@material-ui/core/CardContent");
const HBTypography_1 = require("@components/library/HBTypography");
const HBIcon_1 = require("@components/library/HBIcon");
const HBDialog_1 = require("@components/library/HBDialog");
const DeleteProfileItem_1 = require("@components/pages/profile/detail/DeleteProfileItem");
const styles = ({ spacing, breakpoints }) => createStyles_1.default({
    root: {
        borderRadius: 0,
        height: '100%',
        width: '100%',
        minWidth: 345,
        padding: spacing(5),
        [breakpoints.down('xs')]: {
            padding: spacing(2),
        },
    },
    header: {
        padding: spacing(0, 0, 4, 0),
    },
    content: {
        padding: 0,
        '&.MuiCardContent-root:last-child': {
            paddingBottom: 0,
        },
    },
    disabled: {
        cursor: 'not-allowed',
        '&:hover': {
            background: 'transparent',
        },
    },
    extraSpacing: {
        paddingBottom: spacing(8.5),
    },
});
const ProfileDetailItemSummary = ({ classes, title, subheader, canEdit = true, canDelete, onDelete, children, formComponent, }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const handleOpen = () => {
        setIsOpen(true);
    };
    const handleClose = () => {
        setIsOpen(false);
    };
    const handleLoading = value => {
        setIsLoading(value);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Card_1.default, { className: classes.root, elevation: 0 },
            React.createElement(CardHeader_1.default, { className: classnames_1.default(classes.header, { [classes.extraSpacing]: !!subheader }), action: React.createElement(React.Fragment, null,
                    canEdit && !!formComponent && (React.createElement(HBIcon_1.default, { icon: "edit", isButton: true, ButtonProps: { 'aria-label': 'edit', onClick: handleOpen } })),
                    onDelete && React.createElement(DeleteProfileItem_1.default, { canDelete: canDelete, onDelete: onDelete, className: classes.disabled })), title: React.createElement(HBTypography_1.default, { variant: "h6" }, title), subheader: subheader && (React.createElement(HBTypography_1.default, { variant: "body1", component: "div" }, subheader)) }),
            React.createElement(CardContent_1.default, { className: classes.content }, children)),
        formComponent &&
            React.Children.map(formComponent, child => {
                if (React.isValidElement(child)) {
                    return (React.createElement(HBDialog_1.default, { open: isOpen, onClose: handleClose, disabled: isLoading }, React.cloneElement(child, { onChange: handleClose, handleLoading: handleLoading })));
                }
                return child;
            })));
};
exports.default = withStyles_1.default(styles)(ProfileDetailItemSummary);
