"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const withLayout_1 = require("@components/common/layout/withLayout");
const ProductDetailContext_1 = require("@components/common/context/ProductDetailContext");
const ProductDetail_1 = require("@components/pages/product/detail/ProductDetail");
const PageProductDetail = (data) => {
    return (React.createElement(ProductDetailContext_1.default, Object.assign({}, data),
        React.createElement(ProductDetail_1.default, null)));
};
exports.default = withLayout_1.default(PageProductDetail, { paddingBottom: 0 });
