"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const NotFound = () => (React.createElement("svg", { viewBox: "0 0 406 406", xmlns: "http://www.w3.org/2000/svg", height: "406", width: "406" },
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { d: "M0 0h406v406H0z", fill: "#fff" }),
        React.createElement("g", { strokeWidth: "3.2" },
            React.createElement("g", { transform: "translate(43 71)" },
                React.createElement("rect", { height: "183.467", rx: "4.8", stroke: "#b6956c", width: "273.067", x: "23.467", y: "57.6" }),
                React.createElement("rect", { height: "230.4", rx: "4.8", stroke: "#000", width: "320", y: "34.133" }),
                React.createElement("path", { d: "M168.533 8.533l38.913 25.6H111.36l40.107-25.6", stroke: "#000" }),
                React.createElement("circle", { cx: "160", cy: "8.533", r: "8.533", stroke: "#b6956c" })),
            React.createElement("path", { d: "M138 211c6.338.848 11.273 1 14.809 1s8.4-.152 15.191-1m-39 40c6.3.832 12.656 3 24.297 3 11.642 0 16.954-2.168 23.703-3", stroke: "#b6956c" }),
            React.createElement("g", { stroke: "#000" },
                React.createElement("path", { d: "M124.554 266.7l22.051-94.383a3 3 0 0 1 2.922-2.317h6.301a3 3 0 0 1 2.924 2.328l21.704 94.38a3 3 0 0 1-1.697 3.41C170.006 274.038 161.253 276 152.5 276c-8.75 0-17.5-1.96-26.251-5.88a3 3 0 0 1-1.695-3.42z", strokeLinejoin: "round" }),
                React.createElement("path", { d: "M179.019 260.192l26.3 9.232a1 1 0 0 1 .003 1.887l-50.719 17.908a5 5 0 0 1-3.333-.001l-50.633-17.92a1 1 0 0 1-.006-1.883l25.543-9.223", strokeLinejoin: "round" }),
                React.createElement("path", { d: "M142 191.19a89.327 89.327 0 0 0 21 0M134 231c6.349.535 14.906 1 19.33 1s11.868-.465 18.67-1" })),
            React.createElement("g", null,
                React.createElement("path", { d: "M257.576 154.778l46.714 78.219a3 3 0 0 1-2.576 4.538h-93.428a3 3 0 0 1-2.576-4.538l46.714-78.22a3 3 0 0 1 5.152 0z", stroke: "#000", strokeLinejoin: "round" }),
                React.createElement("g", { stroke: "#b6956c", transform: "translate(248 178.597)" },
                    React.createElement("ellipse", { cx: "7", cy: "44.031", rx: "6", ry: "6.047" }),
                    React.createElement("path", { d: "M3.17 30.993h7.66C10.83 17.536 17.831 0 7 0S3.17 17.427 3.17 30.993z", strokeLinejoin: "round" })))))));
exports.default = NotFound;
