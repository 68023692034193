"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const react_router_dom_1 = require("react-router-dom");
const makeStyles_1 = require("@material-ui/core/styles/makeStyles");
const Link_1 = require("@material-ui/core/Link");
const Grid_1 = require("@material-ui/core/Grid");
const typography_1 = require("../theme/typography");
const HBIcon_1 = require("../HBIcon");
const useStyles = makeStyles_1.default(({ palette, spacing, transitions }) => ({
    root: {
        fontSize: typography_1.pxToRem(18),
        letterSpacing: typography_1.pxToRem(1.35),
        lineHeight: typography_1.pxToPercent(32, 18),
        fontFamily: ['Roboto Slab', 'Helvetica', 'Arial', 'sans-serif'].join(','),
        textTransform: 'uppercase',
        fontWeight: 'normal',
    },
    smallSize: {
        fontSize: typography_1.pxToRem(14),
        letterSpacing: typography_1.pxToRem(1),
        lineHeight: typography_1.pxToRem(1.86),
    },
    hoverEffect: {
        transition: 'opacity 0.4s',
        '&:hover': {
            opacity: 0.7,
        },
    },
    decoration: {
        '&:hover': {
            textDecoration: 'none',
        },
    },
    nocase: {
        textTransform: 'none',
    },
    noWrap: {
        whiteSpace: 'nowrap',
    },
    underline: {
        display: 'inline-block',
        position: 'relative',
        '& a': {
            position: 'relative',
            '&:before': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                width: '100%',
                left: 0,
                right: 0,
                height: 2,
                transition: 'all .4s cubic-bezier(0.51, 0.18, 0, 0.88) .1s',
                background: ({ color }) => (color ? palette[color].main : palette.primary.main),
            },
        },
        '&:hover': {
            '& a::before': {
                width: '113%',
            },
        },
    },
    hoverline: {
        display: 'inline-block',
        position: 'relative',
        '&:before': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            width: 0,
            right: 0,
            height: 2,
            transition: 'width .4s cubic-bezier(0.51, 0.18, 0, 0.88) .1s',
            background: ({ color }) => (color ? palette[color].main : palette.primary.main),
        },
        '&:hover': {
            '&:before': {
                width: '100%',
                left: 0,
            },
        },
    },
    arrowClass: {
        display: 'inline-flex',
        alignItems: 'center',
        position: 'relative',
        '& .arrow': {
            position: 'relative',
            right: 25,
            opacity: 0,
            transition: transitions.create(['all'], { duration: 400, easing: 'ease-out' }),
            willChange: 'right, opacity',
        },
        '&:hover .arrow': {
            opacity: 1,
            right: 0,
            marginLeft: spacing(1),
        },
    },
}));
const ComposedLink = ({ href, isExternal, ...other }) => isExternal ? React.createElement(Link_1.default, Object.assign({ component: 'a', href: href }, other)) : React.createElement(Link_1.default, Object.assign({ component: react_router_dom_1.Link, to: href }, other));
const HBLink = ({ to, className: classNameProps, activeClassName = 'active', showUnderline, showHoverline, nocase, size = 'default', decoration, hoverOpacity, target, color, hoverArrow, borderWidth, borderStyle, noWrap, ...other }) => {
    var _a;
    const classes = useStyles({ borderWidth, borderStyle, color });
    const history = react_router_dom_1.useHistory();
    let className = classnames_1.default(classNameProps, classes.root, {
        [classes.underline]: showUnderline,
        [classes.hoverline]: showHoverline,
        [classes.noWrap]: noWrap,
        [classes.nocase]: nocase,
        [classes.hoverEffect]: hoverOpacity,
        [classes.smallSize]: size === 'small',
    }, { [classes.decoration]: !decoration });
    let href = '';
    let isExternal = false;
    if (!!to) {
        href = typeof to === 'string' ? to : to.url;
        isExternal = !!((_a = to) === null || _a === void 0 ? void 0 : _a.external);
    }
    else {
        return (React.createElement(Grid_1.default, { className: className, color: color }, other.children));
    }
    className = classnames_1.default(className, { [activeClassName]: history.pathname === href && activeClassName });
    return hoverArrow ? (React.createElement("div", { className: classnames_1.default(className, classes.arrowClass) },
        React.createElement(ComposedLink, Object.assign({ href: href, color: color, target: target, isExternal: isExternal }, other)),
        hoverArrow && (React.createElement(HBIcon_1.default, { icon: "arrow_right", className: "arrow", color: color, fontSize: size === 'small' ? '4xsmall' : '3xsmall', strokeWidth: 3 })))) : (React.createElement(ComposedLink, Object.assign({ href: href, className: className, color: color, target: target, isExternal: isExternal }, other)));
};
exports.default = HBLink;
