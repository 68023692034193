"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Grid_1 = require("@material-ui/core/Grid");
const FormElement_1 = require("@components/common/utils/Form/FormElement");
const FormButton_1 = require("@components/common/utils/Form/FormButton/FormButton");
const FormFieldContext_1 = require("@components/common/utils/Form/FormFieldContext");
const FormFieldContext_2 = require("@components/common/utils/Form/FormFieldContext/FormFieldContext");
const ApiContext_1 = require("@components/common/context/ApiContext");
const useApiRequest_1 = require("@components/common/utils/hooks/useApiRequest");
const AppContext_1 = require("@components/common/context/AppContext");
const VoucherInfoFieldDefaults = {
    voucherId: '',
};
const VoucherInfoStepForm = ({ api, form: { elements, button } }) => {
    const { dispatch } = AppContext_1.useAppContext();
    const defaultValues = { ...VoucherInfoFieldDefaults };
    const { methods } = FormFieldContext_2.useFormHook({ defaultValues });
    const { onSubmit, isLoading, data, apiError } = useApiRequest_1.default();
    React.useEffect(() => {
        if (!apiError && (data === null || data === void 0 ? void 0 : data.cart)) {
            dispatch({ type: AppContext_1.ActionTypes.ADD_VOUCHER_INFO, payload: data });
        }
    }, [data]);
    const handleSubmit = async (payload) => await onSubmit(() => api.addVoucher(payload));
    return (React.createElement(FormFieldContext_1.default, { methods: methods, onSubmit: handleSubmit },
        React.createElement(Grid_1.default, { container: true, direction: "row", spacing: 4, alignItems: "center" }, elements === null || elements === void 0 ? void 0 :
            elements.map((element, index) => (React.createElement(Grid_1.default, { key: element.name + index.toString(), item: true, xs: 8 },
                React.createElement(FormElement_1.default, Object.assign({ hideAsterisk: true }, element, { error: !!apiError, errors: apiError === null || apiError === void 0 ? void 0 : apiError.inputError, disabled: isLoading, autoComplete: "off" }))))),
            React.createElement(Grid_1.default, { item: true, xs: 4 },
                React.createElement(FormButton_1.default, Object.assign({}, button, { disabled: isLoading }))))));
};
exports.default = ApiContext_1.withApi(VoucherInfoStepForm);
