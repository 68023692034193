"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
const styles = () => styles_1.createStyles({
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        objectPosition: 'center',
        display: 'flex',
        flexShrink: 0,
    },
});
exports.default = styles;
