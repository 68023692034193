"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Svg_1 = require("./Svg");
const G_1 = require("./Svg/G");
const Close = ({ color = 'primary', ...rest }) => (React.createElement(Svg_1.default, Object.assign({}, rest),
    React.createElement(G_1.default, { color: color, transform: "translate(1 1)", strokeWidth: "2", fill: "none", fillRule: "evenodd", strokeLinecap: "round", strokeLinejoin: "round" },
        React.createElement("path", { d: "M21.542 0.458L0.458 21.542" }),
        React.createElement("path", { d: "M21.542 21.542L0.458 0.458" }))));
exports.default = Close;
