"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const styles_1 = require("@material-ui/core/styles");
const HBIcon_1 = require("@components/library/HBIcon");
const HBFab_1 = require("@components/library/HBFab");
const AppContext_1 = require("@components/common/context/AppContext");
const useApiRequest_1 = require("@components/common/utils/hooks/useApiRequest");
const ApiContext_1 = require("@components/common/context/ApiContext");
const LabelContext_1 = require("@components/common/context/LabelContext");
const styles = ({ palette }) => styles_1.createStyles({
    filledIcon: {
        backgroundColor: palette.error.main,
        '&:hover': {
            backgroundColor: palette.error.main,
        },
        '&.Mui-disabled': {
            backgroundColor: palette.error.main,
            '&:hover': {
                backgroundColor: palette.error.main,
            },
        },
        '& svg': {
            animation: '$heartbeat 0.5s ease',
            color: palette.common.white,
        },
    },
    icon: {
        '&.Mui-disabled': {
            backgroundColor: palette.primary.main,
            '&:hover': {
                backgroundColor: palette.primary.main,
            },
        },
    },
    '@keyframes heartbeat': {
        '0%': {
            transform: 'scale(1)',
        },
        '50%': {
            transform: 'scale(1.1)',
        },
        '100%': {
            transform: 'scale(.95)',
        },
    },
});
const ProductWishlist = ({ api, classes, iconColor = 'default', item }) => {
    const { state: { wishlist }, dispatch, } = AppContext_1.useAppContext();
    const { onSubmit, isLoading } = useApiRequest_1.default();
    const labels = LabelContext_1.useLabelContext();
    const addToWishlist = async (product) => {
        await onSubmit(() => api.addToWishlist({ oid: product._id }), () => {
            dispatch({ type: AppContext_1.ActionTypes.ADD_TO_WISHLIST, payload: product });
        });
    };
    const removeFromWishlist = async (product) => {
        await onSubmit(() => api.removeFromWishlist(product._id), () => {
            dispatch({ type: AppContext_1.ActionTypes.REMOVE_FROM_WISHLIST, payload: product });
        });
    };
    return (React.createElement(React.Fragment, null, (wishlist === null || wishlist === void 0 ? void 0 : wishlist.some(prod => { var _a; return prod._id === ((_a = item._id) === null || _a === void 0 ? void 0 : _a.toString()); })) ? (React.createElement(HBFab_1.default, { className: classes.filledIcon, color: "inherit", "aria-label": labels.REMOVE_FROM_WISHLIST, onClick: () => removeFromWishlist(item), disabled: isLoading },
        React.createElement(HBIcon_1.default, { icon: "wishlist_filled", color: iconColor }))) : (React.createElement(HBFab_1.default, { className: classes.icon, color: "primary", "aria-label": labels.ADD_TO_WISHLIST, onClick: () => addToWishlist(item), disabled: isLoading },
        React.createElement(HBIcon_1.default, { icon: "wishlist", color: iconColor })))));
};
exports.default = ApiContext_1.withApi(withStyles_1.default(styles)(ProductWishlist));
