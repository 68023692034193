"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const createPalette_1 = require("@material-ui/core/styles/createPalette");
const Colors_1 = require("./Colors");
const palette = createPalette_1.default({
    primary: {
        dark: Colors_1.default.darkGrey,
        main: Colors_1.default.mainGrey,
        light: Colors_1.default.lightGrey,
    },
    secondary: {
        dark: Colors_1.default.darkGold,
        main: Colors_1.default.mainGold,
        light: Colors_1.default.lightGold,
        contrastText: Colors_1.default.white,
    },
    warning: {
        main: Colors_1.default.orange,
    },
    error: {
        main: Colors_1.default.red,
    },
    success: {
        main: Colors_1.default.green,
    },
    divider: Colors_1.default.borderGrey,
    background: {
        default: Colors_1.default.white,
    },
});
exports.default = palette;
