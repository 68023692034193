"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const withLayout_1 = require("@components/common/layout/withLayout");
const SearchPageContext_1 = require("@components/common/context/SearchPageContext");
const SearchFilter_1 = require("@components/pages/search/SearchFilter");
const SearchResults_1 = require("@components/pages/search/SearchResults");
const Grid_1 = require("@material-ui/core/Grid");
const PageSearchContextInner = withLayout_1.default(() => (React.createElement(Grid_1.default, { container: true, direction: "column" },
    React.createElement(SearchFilter_1.default, null),
    React.createElement(SearchResults_1.default, null))), { paddingTop: 0, paddingBottom: 0 });
const PageSearch = (props) => {
    return (React.createElement(SearchPageContext_1.default, Object.assign({}, props.page),
        React.createElement(PageSearchContextInner, Object.assign({}, props))));
};
exports.default = PageSearch;
