"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const react_router_dom_1 = require("react-router-dom");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const Grid_1 = require("@material-ui/core/Grid");
const HBButton_1 = require("@components/library/HBButton");
const CartContentInner_1 = require("@components/pages/cart/CartContentInner");
const AppContext_1 = require("@components/common/context/AppContext");
const CartContentStyles_1 = require("./CartContentStyles");
const CartSummary_1 = require("@components/pages/cart/CartSummary");
const LabelContext_1 = require("@components/common/context/LabelContext");
const CartContent = ({ classes, title, showSubtotal, totalTextlabel, fullWidth, enableAction = true, }) => {
    const history = react_router_dom_1.useHistory();
    const { state: { cart: { items = [], summary } = {} } = {} } = AppContext_1.useAppContext();
    const labels = LabelContext_1.useLabelContext();
    return (React.createElement("div", { className: classnames_1.default(classes.root, { [classes.fullWidth]: fullWidth }) },
        React.createElement(CartContentInner_1.default, { title: title, items: items }),
        !!(items === null || items === void 0 ? void 0 : items.length) && (React.createElement(Grid_1.default, { item: true, container: true, className: classnames_1.default(classes.cartFooter, classes.cartFooterVisible) },
            React.createElement(CartSummary_1.default, Object.assign({ showSubtotal: showSubtotal, totalTextlabel: totalTextlabel }, summary, { dividerClassName: classes.divider })),
            enableAction && (React.createElement(Grid_1.default, { item: true, container: true, justify: "center", className: classes.button },
                React.createElement(HBButton_1.default, { variant: "contained", label: labels.ORDER_NOW, isTernary: true, fullWidth: true, onClick: () => history.push('/checkout') })))))));
};
exports.default = withStyles_1.default(CartContentStyles_1.default)(CartContent);
