"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
const Colors_1 = require("@components/library/theme/Colors");
const styles = ({ palette, spacing, transitions, breakpoints }) => styles_1.createStyles({
    serviceBar: {
        backgroundColor: palette.primary.main,
        color: palette.common.white,
        padding: `${spacing(5.75)}px 0`,
    },
    serviceIcon: {
        fontSize: 60,
        height: 45,
        display: 'block',
        opacity: 0.8,
    },
    serviceIconWithTextWrapper: {
        '&:first-child': {
            marginLeft: spacing(-1.5),
        },
    },
    serviceIconWithTextWrapperInner: {
        margin: `${spacing(1)}px auto auto auto`,
    },
    serviceIconName: {
        marginRight: spacing(2),
        opacity: 0.8,
    },
    serviceIconText: {
        lineHeight: 1.33,
    },
    footerLinksWrapper: {
        backgroundColor: Colors_1.default.backgroundGrey,
        paddingBottom: spacing(3.75),
        paddingTop: spacing(5.375),
    },
    ul: {
        listStyle: 'none',
        padding: 0,
        margin: 0,
    },
    footerLinksMenuLink: {
        color: palette.primary.main,
        '&:hover': {
            color: palette.common.black,
        },
    },
    footerLinksMenulinkText: {
        lineHeight: 2.4,
    },
    socialIcons: {
        opacity: 0.6,
        transition: transitions.create('all'),
        '&:hover': {
            opacity: 1,
        },
        '&:not(:last-child)': {
            marginRight: spacing(4),
        },
    },
    divider: {
        margin: spacing(5, 0, 3.75, 0),
    },
    legalBarLinks: {
        [breakpoints.between('xs', 'md')]: {
            marginRight: '10%',
        },
        ['@media (min-width:960px) and (max-width:1380px)']: {
            marginRight: '5%',
        },
    },
});
exports.default = styles;
