"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const styles_1 = require("@material-ui/core/styles");
const HBTypography_1 = require("@components/library/HBTypography");
const LabelContext_1 = require("@components/common/context/LabelContext");
const styles = ({ palette, spacing, breakpoints, typography }) => styles_1.createStyles({
    root: {
        backgroundColor: palette.error.main,
        color: palette.common.white,
        position: 'absolute',
        top: spacing(0),
        left: spacing(0),
        textAlign: 'center',
        padding: spacing(0, 0.8),
        whiteSpace: 'nowrap',
        zIndex: 500,
    },
    saleTag: {
        ...typography.h6,
        [breakpoints.down('xs')]: {
            ...typography.subtitle2,
            textTransform: 'uppercase',
        },
    },
});
const ProductSaleTag = ({ labels, classes, isSale, isSymbol }) => {
    return isSale ? (React.createElement("div", { className: classes.root },
        React.createElement(HBTypography_1.default, { className: classes.saleTag, component: "span", align: "center" }, isSymbol ? labels.PERCENTAGE_SYMBOL : labels.SALE))) : null;
};
exports.default = withStyles_1.default(styles)(LabelContext_1.withLabel(ProductSaleTag));
