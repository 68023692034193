"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const notistack_1 = require("notistack");
const ApiContext_1 = require("@components/common/context/ApiContext");
const CustomSnackbarContent_1 = require("./CustomSnackbarContent");
const SnackMessages = props => {
    const { closeSnackbar, enqueueSnackbar } = notistack_1.useSnackbar();
    const [errorSubscription, setErrorSubscription] = React.useState();
    React.useEffect(() => {
        const { api: { apiClientRequest } = {} } = props;
        apiClientRequest && setErrorSubscription(apiClientRequest.listenToAlerts(onAlert));
        return () => {
            errorSubscription && errorSubscription.unsubscribe();
        };
    }, []);
    const onAlert = alert => {
        const { message, stack, variant, dontHide = false, autoHideDuration = 2000, preventDuplicate = false } = alert;
        if (message === undefined) {
            return;
        }
        enqueueSnackbar('', {
            content: key => (React.createElement("div", null,
                React.createElement(CustomSnackbarContent_1.default, { message: `${message}${stack ? ': ' + stack : ''}`, variant: variant, onClose: () => closeSnackbar(key), key: key }))),
            variant,
            autoHideDuration,
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            persist: dontHide,
            preventDuplicate,
        });
    };
    return React.createElement("div", null);
};
exports.default = ApiContext_1.withApi(SnackMessages);
