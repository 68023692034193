"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Fade_1 = require("@material-ui/core/Fade");
const HBDialog_1 = require("@components/library/HBDialog");
const ProductImageLightboxContent_1 = require("./ProductImageLightboxContent");
const ProductImageLighbox = ({ openLightbox, name, images, thumbnailDirection = 'bottom', handleClose, handleChange, hideThumbnail, }) => {
    const [isOpen, setIsOpen] = React.useState(!!openLightbox);
    React.useEffect(() => {
        setIsOpen(!!openLightbox);
        return () => setIsOpen(false);
    }, [openLightbox]);
    const handleCloseLightbox = () => {
        setIsOpen(false);
        if (handleClose)
            handleClose();
    };
    return (React.createElement(HBDialog_1.default, { fullScreen: true, open: isOpen, hideIcon: true, onClose: handleCloseLightbox, TransitionComponent: Fade_1.default, transitionDuration: 600 }, isOpen && (React.createElement(ProductImageLightboxContent_1.default, { name: name, images: images, openLightbox: openLightbox, thumbnailDirection: thumbnailDirection, handleClose: handleCloseLightbox, handleChange: handleChange, hideThumbnail: hideThumbnail }))));
};
exports.default = ProductImageLighbox;
