"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Svg_1 = require("../../Svg");
const Bath = ({ color, ...rest }) => (React.createElement(Svg_1.default, Object.assign({ width: "60", height: "60", viewBox: "0 0 60 60" }, rest),
    React.createElement("g", { transform: "translate(1 1)", stroke: "#000", strokeWidth: "2", fill: "none", fillRule: "evenodd", strokeLinecap: "round", strokeLinejoin: "round" },
        React.createElement("path", { d: "M57.75 30.25a2.5 2.5 0 0 1-2.5 2.5H2.75a2.5 2.5 0 1 1 0-5h52.5a2.5 2.5 0 0 1 2.5 2.5zM4 .25h50c.69 0 1.25.56 1.25 1.25v26.25H2.75V1.5C2.75.81 3.31.25 4 .25z" }),
        React.createElement("path", { d: "M30.25 20.25h-20a7.5 7.5 0 0 0 7.5 7.5h5a7.5 7.5 0 0 0 7.5-7.5zm-7.5-7.5a3.75 3.75 0 0 0-7.5 0v7.5m35 35h-15V38.968a23.72 23.72 0 0 1 15 0V55.25zm-20-22.5v5a2.5 2.5 0 0 0 2.5 2.5h2.5m20-7.5v5a2.5 2.5 0 0 1-2.5 2.5h-2.5" }),
        React.createElement("path", { d: "M15.25 57.75L5.25 57.75 4 45.25 16.5 45.25z" }),
        React.createElement("path", { d: "M0.25 45.25L20.25 45.25" }),
        React.createElement("path", { d: "M7.75 45.25v-2.5a2.5 2.5 0 1 1 5 0v2.5" }))));
exports.default = Bath;
