"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Step_1 = require("@components/pages/checkout/Step/Step");
const PersonalInfoStepSummary_1 = require("./PersonalInfoStepSummary");
const PersonalInfoStepForm_1 = require("./PersonalInfoStepForm");
const AppContext_1 = require("@components/common/context/AppContext");
const PersonalInfoStep = ({ completed, disabled, title, form, isEditing }) => {
    const { state: { member } = {}, dispatch } = AppContext_1.useAppContext();
    const handleEdit = () => dispatch({ type: AppContext_1.ActionTypes.EDITING_PERSONAL_INFO });
    return (React.createElement(Step_1.default, { completed: completed, active: !disabled, title: title, onChange: handleEdit, isEditing: isEditing, isEditable: !(member === null || member === void 0 ? void 0 : member.validated), summary: !!member && React.createElement(PersonalInfoStepSummary_1.default, { member: member }) },
        React.createElement(PersonalInfoStepForm_1.default, { form: form })));
};
exports.default = PersonalInfoStep;
