"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Svg_1 = require("../../Svg");
const Textile = ({ color, ...rest }) => (React.createElement(Svg_1.default, Object.assign({ width: "60", height: "58", viewBox: "0 0 60 58" }, rest),
    React.createElement("g", { transform: "translate(1 1)", stroke: "#000", strokeWidth: "2", fill: "none", fillRule: "evenodd", strokeLinecap: "round", strokeLinejoin: "round" },
        React.createElement("path", { d: "M42.75 8L52.75 8" }),
        React.createElement("circle", { cx: "2.75", cy: "8", r: "2.5" }),
        React.createElement("path", { d: "M37.75 6.75A6.25 6.25 0 0 1 44 .5H14a6.25 6.25 0 0 0-6.25 6.25v47.5c0 .69.56 1.25 1.25 1.25h27.5c.69 0 1.25-.56 1.25-1.25V6.75zM44 .5a6.25 6.25 0 0 1 5.02 2.525" }),
        React.createElement("circle", { cx: "55.25", cy: "8", r: "2.5" }),
        React.createElement("path", { d: "M50.25 13v21.25c0 .69-.56 1.25-1.25 1.25h-6.25" }),
        React.createElement("path", { d: "M7.75 40.5H37.75V48H7.75z" }))));
exports.default = Textile;
