"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_router_dom_1 = require("react-router-dom");
const makeStyles_1 = require("@material-ui/core/styles/makeStyles");
const Grid_1 = require("@material-ui/core/Grid");
const Hidden_1 = require("@material-ui/core/Hidden");
const HBImage_1 = require("@components/library/HBImage");
const Carousel_1 = require("@components/common/utils/Carousel");
const ProductMini_1 = require("@components/pages/product/detail/ProductMini");
const ProductSaleTag_1 = require("@components/pages/product/ProductSaleTag");
const react_visibility_sensor_1 = require("react-visibility-sensor");
const HoverZoomImage_1 = require("@components/common/utils/HoverZoomImage");
const ProductThumbImageList_1 = require("@components/pages/product/detail/ProductThumbImageList");
const ProductImageLighbox_1 = require("@components/pages/product/detail/ProductImageLighbox");
const ProductDetailContext_1 = require("@components/common/context/ProductDetailContext");
const useStyles = makeStyles_1.default(({ typography, breakpoints }) => ({
    slickItem: {
        position: 'relative',
        height: 414,
        [breakpoints.up('sm')]: {
            height: 540,
        },
        '& img': {
            position: 'absolute',
            left: 0,
            right: 0,
        },
    },
    saleTag: {
        ...typography.h4,
        [breakpoints.down('xs')]: {
            ...typography.h6,
            textTransform: 'uppercase',
        },
    },
    relative: {
        position: 'relative',
    },
}));
const ProductImage = () => {
    var _a;
    const { product = {}, lightboxImages = [] } = ProductDetailContext_1.useProductDetailContext();
    const { name, isSale } = product;
    const location = react_router_dom_1.useLocation();
    const classes = useStyles();
    const { variantCurrentSlide } = ProductDetailContext_1.useProductDetailContext();
    const [currentSlide, setCurrentSlide] = React.useState((_a = lightboxImages[0]) === null || _a === void 0 ? void 0 : _a.src);
    const [slider1, setSlider1] = React.useState(null);
    const [isVisible, setIsVisible] = React.useState(true);
    const [openLightbox, setOpenLightbox] = React.useState(undefined);
    React.useEffect(() => {
        setIsVisible(true);
    }, [location === null || location === void 0 ? void 0 : location.pathname]);
    React.useEffect(() => {
        var _a;
        setCurrentSlide((_a = lightboxImages[0]) === null || _a === void 0 ? void 0 : _a.src);
    }, [lightboxImages]);
    const handleVisible = isVisible => {
        setIsVisible(isVisible);
    };
    const handleGalleryItem = (src, index) => {
        setCurrentSlide(src);
        if (slider1) {
            slider1.slickGoTo(index);
        }
    };
    const handleSlickClick = src => {
        setOpenLightbox(src);
    };
    const handleChangeLightbox = (item, index) => {
        var _a;
        setCurrentSlide((_a = lightboxImages[index]) === null || _a === void 0 ? void 0 : _a.src);
    };
    const handleCloseLightbox = () => {
        setOpenLightbox(undefined);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ProductMini_1.default, { item: product, visible: !isVisible }),
        React.createElement(react_visibility_sensor_1.default, { onChange: handleVisible, partialVisibility: true, minTopValue: 200 },
            React.createElement(Grid_1.default, { container: true, spacing: 1, direction: "row" },
                React.createElement(ProductImageLighbox_1.default, { name: name, images: lightboxImages, openLightbox: openLightbox, handleClose: handleCloseLightbox, handleChange: handleChangeLightbox, hideThumbnail: lightboxImages.length === 1 }),
                (lightboxImages === null || lightboxImages === void 0 ? void 0 : lightboxImages.length) !== 1 && (React.createElement(Grid_1.default, { item: true, sm: 2 },
                    React.createElement(Hidden_1.default, { xsDown: true, implementation: "css" },
                        React.createElement(ProductThumbImageList_1.default, { images: lightboxImages, handleThumbImage: handleGalleryItem, index: lightboxImages.findIndex(image => image.src === currentSlide) })))),
                React.createElement(Grid_1.default, { item: true, xs: 12, sm: 10 },
                    isSale && (React.createElement("div", { className: classes.relative },
                        React.createElement(ProductSaleTag_1.default, { isSale: isSale, classes: { saleTag: classes.saleTag } }))),
                    React.createElement(Hidden_1.default, { xsDown: true, implementation: "css" },
                        React.createElement(Grid_1.default, { item: true, container: true, justify: "center", onClick: () => handleSlickClick(currentSlide), className: classes.slickItem },
                            React.createElement(HoverZoomImage_1.default, { src: variantCurrentSlide || currentSlide, alt: name, size: 600 }))),
                    React.createElement(Hidden_1.default, { smUp: true, implementation: "css" },
                        React.createElement(Carousel_1.default, { itemRef: slider => setSlider1(slider), items: lightboxImages === null || lightboxImages === void 0 ? void 0 : lightboxImages.map(({ src }) => (React.createElement("div", { key: src, onClick: () => handleSlickClick(src), className: classes.slickItem },
                                React.createElement(HBImage_1.default, { src: src, alt: name, size: 600 })))), color: "primary", slidesToScroll: 1, slidesToShow: 1, arrows: false, swipeToSlide: false, responsive: [
                                {
                                    breakpoint: 600,
                                    settings: {
                                        dots: true,
                                    },
                                },
                            ] })))))));
};
exports.default = ProductImage;
