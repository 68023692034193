"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Box_1 = require("@material-ui/core/Box");
const HBTypography_1 = require("@components/library/HBTypography");
const withLayout_1 = require("@components/common/layout/withLayout");
const PageTemplateError = props => (React.createElement(Box_1.default, { textAlign: "center" },
    React.createElement(HBTypography_1.default, { variant: "h3" }, "Missing Page Template"),
    React.createElement(HBTypography_1.default, { variant: "subtitle1" }, props.component)));
exports.default = withLayout_1.default(PageTemplateError);
