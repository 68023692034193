"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const FormTextField_1 = require("@components/common/utils/Form/FormElement/FormTextField");
const FormCheckboxField_1 = require("@components/common/utils/Form/FormElement/FormCheckboxField");
const FormRadioGroup_1 = require("@components/common/utils/Form/FormElement/FormRadioGroup");
const FormElement = ({ type, hideAsterisk, error, ...other }) => {
    switch (type) {
        case 'checkbox':
            return React.createElement(FormCheckboxField_1.default, Object.assign({ type: type }, other));
        case 'radio':
            return React.createElement(FormRadioGroup_1.default, Object.assign({ type: type }, other));
        default:
            return React.createElement(FormTextField_1.default, Object.assign({ error: error, hideAsterisk: hideAsterisk, type: type }, other));
    }
};
exports.default = FormElement;
