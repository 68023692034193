"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Svg_1 = require("../Svg");
const Facebook = props => (React.createElement(Svg_1.default, Object.assign({}, props, { viewBox: "0 0 44 44" }),
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { d: "M0 0h44v44H0z" }),
        React.createElement("circle", { cx: "22", cy: "22", r: "21", stroke: "#b6956c", strokeWidth: "2" }),
        React.createElement("path", { d: "M23.315 35h-4.878V23.499H16v-3.963h2.437v-2.38c0-3.233 1.376-5.156 5.286-5.156h3.255v3.964h-2.034c-1.523 0-1.623.554-1.623 1.588l-.007 1.984H27l-.431 3.963h-3.255V35z", fill: "#b6956c" }))));
exports.default = Facebook;
