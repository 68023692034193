"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Grid_1 = require("@material-ui/core/Grid");
const OrderHistoryItem_1 = require("@components/pages/profile/orderHistory/OrderHistoryItem");
const createStyles_1 = require("@material-ui/core/styles/createStyles");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const HBTypography_1 = require("@components/library/HBTypography");
const styles = ({ spacing }) => createStyles_1.default({
    orderHistoryItem: {
        marginBottom: spacing(5),
    },
});
const ProfileOrderHistory = ({ classes, items = [] }) => {
    if (!items.length) {
        return (React.createElement(Grid_1.default, { container: true, direction: "column" },
            React.createElement(Grid_1.default, { item: true, key: "nothing" },
                React.createElement(HBTypography_1.default, null, "Du hast noch nichts bestellt"))));
    }
    else {
        return (!!items.length && (React.createElement(Grid_1.default, { container: true, direction: "column" }, items.map(item => (React.createElement(Grid_1.default, { item: true, key: item.orderNo, className: classes.orderHistoryItem },
            React.createElement(OrderHistoryItem_1.default, Object.assign({}, item))))))));
    }
};
exports.default = withStyles_1.default(styles)(ProfileOrderHistory);
