"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const withLayout_1 = require("@components/common/layout/withLayout");
const HBContainer_1 = require("@components/library/HBContainer");
const CustomerCareInfo_1 = require("@components/pages/contact/CustomerCareInfo");
const makeStyles_1 = require("@material-ui/core/styles/makeStyles");
const Grid_1 = require("@material-ui/core/Grid");
const HBTypography_1 = require("@components/library/HBTypography");
const GalleryItem_1 = require("@components/pages/home/GalleryItem/GalleryItem");
const ShopInfo_1 = require("@components/pages/contact/ShopInfo/ShopInfo");
const FullWidthBackground_1 = require("@components/common/utils/FullWidthBackground");
const ZoomOutImage_1 = require("@components/common/utils/ZoomOutImage");
const useStyles = makeStyles_1.default(({ palette, breakpoints, spacing, typography }) => ({
    root: {
        padding: 0,
    },
    bannerContainer: {
        position: 'relative',
        overflow: 'hidden',
        height: 320,
        [breakpoints.up('sm')]: {
            height: 420,
        },
        '& svg': {
            width: 120,
            height: 120,
            [breakpoints.up('sm')]: {
                width: 172,
                height: 172,
            },
        },
    },
    bannerTitle: {
        maxWidth: 640,
        ...typography.h2,
    },
    shopInfoContainer: {
        padding: spacing(5, 0),
        [breakpoints.up('sm')]: {
            padding: spacing(8, 0),
        },
    },
    mapContainer: { border: 0, width: '100%' },
    customerCareInfoContainer: {
        [breakpoints.up('sm')]: {
            paddingTop: spacing(7),
        },
    },
}));
const PageContact = ({ page: { shop_info: { title, banner, info }, customer_care_info, }, }) => {
    const classes = useStyles();
    return (React.createElement(React.Fragment, null,
        React.createElement(HBContainer_1.default, null,
            React.createElement(Grid_1.default, { container: true, direction: "column", justify: "center" },
                React.createElement(Grid_1.default, { item: true },
                    React.createElement(HBTypography_1.default, { variant: "h5", align: "center", marginBottom: 2 }, title)),
                React.createElement(Grid_1.default, { item: true, className: classes.bannerContainer },
                    React.createElement(ZoomOutImage_1.default, null,
                        React.createElement(GalleryItem_1.default, Object.assign({}, banner, { classes: { title: classes.bannerTitle } })))),
                React.createElement(Grid_1.default, { item: true, container: true, alignItems: "center", className: classes.shopInfoContainer },
                    React.createElement(ShopInfo_1.default, { info: info })))),
        React.createElement(Grid_1.default, { item: true, container: true },
            React.createElement("iframe", { src: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1279.3187971304887!2d8.680460315925439!3d50.11178998006885!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xba406066f20f92af!2sDECOR%20WALTHER%20Home%20and%20Bath!5e0!3m2!1sen!2sde!4v1605176162386!5m2!1sen!2sde", height: "300", frameBorder: "0", "aria-hidden": "false", tabIndex: 0, className: classes.mapContainer })),
        React.createElement(FullWidthBackground_1.default, { className: classes.root },
            React.createElement(Grid_1.default, { item: true, container: true },
                React.createElement(CustomerCareInfo_1.default, Object.assign({}, customer_care_info, { titleMargin: 5, classes: { root: classes.customerCareInfoContainer } }))))));
};
exports.default = withLayout_1.default(PageContact, { paddingTop: 40, paddingBottom: 0 });
