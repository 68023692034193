"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Svg_1 = require("./Svg");
const G_1 = require("./Svg/G");
const Basket = ({ color = 'primary', ...rest }) => (React.createElement(Svg_1.default, Object.assign({}, rest),
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement(G_1.default, { color: color, transform: "translate(2 1)", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2" },
            React.createElement("path", { d: "M5.5 9V4.5a4.5 4.5 0 0 1 9 0V9" }),
            React.createElement("path", { d: "M19.469 19.176l-.9-12.235c-.038-.53-.505-.94-1.069-.941H2.503c-.564 0-1.031.412-1.069.941l-.9 12.235c-.023.314-.034.628-.034.942 0 1.04.895 1.882 2 1.882h15c1.105 0 2-.843 2-1.882.002-.314-.008-.628-.031-.942z" })))));
exports.default = Basket;
