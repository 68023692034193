"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLabelContext = exports.withLabel = exports.LabelContextProvider = void 0;
const React = require("react");
const LabelContext = React.createContext(null);
const LabelContextProvider = ({ labels, children }) => {
    return React.createElement(LabelContext.Provider, { value: labels }, children);
};
exports.LabelContextProvider = LabelContextProvider;
const LabelConsumer = LabelContext.Consumer;
const withLabel = Component => props => {
    return React.createElement(LabelConsumer, null, labels => React.createElement(Component, Object.assign({}, props, { labels: labels })));
};
exports.withLabel = withLabel;
const useLabelContext = () => React.useContext(LabelContext);
exports.useLabelContext = useLabelContext;
