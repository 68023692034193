"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
const styles = ({ breakpoints, transitions }) => styles_1.createStyles({
    root: {
        transition: transitions.create(['all']),
        minHeight: '100%',
        width: '322px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        flexShrink: 0,
        ['@media (min-width:1280px)']: {
            marginTop: '-5px',
        },
        [breakpoints.down('md')]: {
            width: '242px',
        },
        [breakpoints.down('sm')]: {
            width: '161px',
        },
        ['@media (max-width:320px)']: {
            width: '148px',
        },
    },
    logo: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexShrink: 0,
        objectFit: 'contain',
        objectPosition: 'center',
    },
});
exports.default = styles;
