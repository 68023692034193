"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginFormFieldsDefaults = void 0;
const React = require("react");
const ApiContext_1 = require("@components/common/context/ApiContext");
const FormFieldContext_1 = require("@components/common/utils/Form/FormFieldContext");
const FormFieldContext_2 = require("@components/common/utils/Form/FormFieldContext/FormFieldContext");
const useApiRequest_1 = require("@components/common/utils/hooks/useApiRequest");
const FormContext_1 = require("@components/common/utils/Form/FormContext");
const react_1 = require("react");
const AppContext_1 = require("@components/common/context/AppContext");
exports.LoginFormFieldsDefaults = { email: '', password: '', rememberMe: false };
const LoginForm = ({ api, content, onChange }) => {
    const { dispatch } = AppContext_1.useAppContext();
    const defaultValues = { ...exports.LoginFormFieldsDefaults };
    const { methods } = FormFieldContext_2.useFormHook({ defaultValues });
    const { onSubmit, data, isLoading, apiError } = useApiRequest_1.default();
    react_1.useEffect(() => {
        if (!apiError && !!(data === null || data === void 0 ? void 0 : data.member)) {
            dispatch({ type: AppContext_1.ActionTypes.UPDATE_MEMBER, payload: data.member });
            dispatch({ type: AppContext_1.ActionTypes.ADD_TO_CART, payload: data });
        }
    }, [data]);
    const handleSubmit = (data) => onSubmit(() => api.signIn(data));
    return (React.createElement(FormFieldContext_1.default, { methods: methods, onSubmit: handleSubmit },
        React.createElement(FormContext_1.default, Object.assign({}, content.form, { hideAsterisk: true, divider: true, error: !!apiError, errors: apiError === null || apiError === void 0 ? void 0 : apiError.inputError, disabled: isLoading, onChange: onChange }))));
};
exports.default = ApiContext_1.withApi(LoginForm);
