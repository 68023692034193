"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Svg_1 = require("./Svg");
const G_1 = require("./Svg/G");
const Delivery = ({ color = 'primary', ...rest }) => (React.createElement(Svg_1.default, Object.assign({}, rest),
    React.createElement(G_1.default, { color: color, transform: "translate(1 4)", strokeWidth: "2", fill: "none", fillRule: "evenodd", strokeLinecap: "round", strokeLinejoin: "round" },
        React.createElement("path", { d: "M14.75 7.006L14.749 7.006" }),
        React.createElement("path", { d: "M15.164 15.3a1 1 0 0 1-.84.025l-6.966-2.973a1 1 0 0 1-.608-.92V4.643a1 1 0 0 1 .577-.906L13.858.689a1 1 0 0 1 .794-.022l6.469 2.588a1 1 0 0 1 .629.928v7.205a1 1 0 0 1-.553.894L15.164 15.3z" }),
        React.createElement("path", { d: "M14.75 7.006L21.562 3.6" }),
        React.createElement("path", { d: "M6.928 4.073L14.749 7.006 14.749 15.403" }),
        React.createElement("path", { d: "M17.981 5.39L10.597 2.211" }),
        React.createElement("path", { d: "M0.25 8.876L0.297 8.897 4.25 10.723" }),
        React.createElement("path", { d: "M1.882 6.13L4.25 7.223" }),
        React.createElement("path", { d: "M3.123 3.203L4.25 3.723" }))));
exports.default = Delivery;
