"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTypeAheadPayload = exports.changeSortOption = exports.refreshSearchTerms = exports.removeSearchTerm = exports.addSearchTerm = exports.updateSearchBrand = exports.refreshSearchFilters = exports.removeSearchFilter = exports.addSearchFilter = exports.resetEntireSearch = exports.refreshSearchTermsAndFilters = exports.refreshFiltersByKey = exports.populateQueryParamsByKey = exports.encodeQueryParamByKey = exports.getFilters = exports.getQueryParams = exports.decodeFilters = exports.encodeFilters = exports.PAGE_NUMBER = exports.AND = exports.EQUAL_TO = exports.SEARCH_QUERY_KEY = exports.QUESTION_MARK = void 0;
const SEARCH_BASE_URL = '/search';
exports.QUESTION_MARK = '?';
exports.SEARCH_QUERY_KEY = 'q';
exports.EQUAL_TO = '=';
exports.AND = '&';
exports.PAGE_NUMBER = 'p';
const SEARCH_FILTER_KEY = 'q';
const SEARCH_TERM_KEY = 't';
const SEARCH_SORT_BY_KEY = 's';
const SEARCH_QUERY_BASE_URL = `${SEARCH_BASE_URL}${exports.QUESTION_MARK}${exports.SEARCH_QUERY_KEY}${exports.EQUAL_TO}`;
exports.encodeFilters = (filters = {}) => window.btoa(unescape(encodeURIComponent(JSON.stringify(filters))));
exports.decodeFilters = (encodedFiltersString = '') => {
    try {
        return JSON.parse(decodeURIComponent(escape(window.atob(encodedFiltersString))));
    }
    catch (e) {
        return {};
    }
};
exports.getQueryParams = history => new URLSearchParams(history.location.search);
exports.getFilters = history => {
    const urlSearchParams = exports.getQueryParams(history);
    const searchQuery = urlSearchParams.get(exports.SEARCH_QUERY_KEY);
    const decodedFilters = exports.decodeFilters(searchQuery);
    decodedFilters[SEARCH_FILTER_KEY] = decodedFilters[SEARCH_FILTER_KEY] || [];
    decodedFilters[SEARCH_TERM_KEY] = decodedFilters[SEARCH_TERM_KEY] || [];
    decodedFilters[SEARCH_SORT_BY_KEY] = decodedFilters[SEARCH_SORT_BY_KEY] || 'relevanz';
    return decodedFilters;
};
exports.encodeQueryParamByKey = (key, value, filters) => {
    switch (key) {
        case SEARCH_FILTER_KEY:
            if (filters[key]) {
                filters[key].push(...value);
                filters[key] = [...new Set(filters[key])];
            }
            else {
                filters[key] = value;
            }
            break;
        case SEARCH_TERM_KEY:
            if (filters[key]) {
                filters[key].push(...value);
                filters[key] = [...new Set(filters[key])];
            }
            else {
                filters[key] = value;
            }
            break;
        case SEARCH_SORT_BY_KEY:
            filters[key] = value;
    }
    return exports.encodeFilters(filters);
};
exports.populateQueryParamsByKey = (key, value = [], history) => {
    const filters = exports.getFilters(history);
    return exports.encodeQueryParamByKey(key, value, filters);
};
exports.refreshFiltersByKey = (id, key, history) => {
    const queryParams = exports.populateQueryParamsByKey(key, [id], history);
    history.push(`${SEARCH_QUERY_BASE_URL}${queryParams}`);
};
exports.refreshSearchTermsAndFilters = (history, selectedTerms, selectedFilters) => {
    const filters = exports.getFilters(history);
    filters[SEARCH_TERM_KEY] = selectedTerms;
    filters[SEARCH_FILTER_KEY] = selectedFilters;
    history.push(`${SEARCH_QUERY_BASE_URL}${exports.encodeFilters(filters)}`);
};
exports.resetEntireSearch = history => {
    const filters = exports.getFilters(history);
    filters[SEARCH_TERM_KEY] = [];
    filters[SEARCH_FILTER_KEY] = [];
    history.push(`${SEARCH_QUERY_BASE_URL}${exports.encodeFilters(filters)}`);
};
exports.addSearchFilter = (id, history) => {
    const filters = exports.getFilters(history);
    const values = filters[SEARCH_FILTER_KEY] || [];
    filters[SEARCH_FILTER_KEY] = [...values, id];
    history.push(`${SEARCH_QUERY_BASE_URL}${exports.encodeFilters(filters)}`);
};
exports.removeSearchFilter = (id, history) => {
    const filters = exports.getFilters(history);
    const values = filters[SEARCH_FILTER_KEY] || [];
    filters[SEARCH_FILTER_KEY] = values.filter(value => value !== id);
    history.push(`${SEARCH_QUERY_BASE_URL}${exports.encodeFilters(filters)}`);
};
exports.refreshSearchFilters = (history, id) => exports.refreshFiltersByKey(id, SEARCH_FILTER_KEY, history);
exports.updateSearchBrand = (newBrandId, allBrandIds, history) => {
    const filters = exports.getFilters(history);
    const values = filters[SEARCH_FILTER_KEY] || [];
    filters[SEARCH_FILTER_KEY] = values.filter((value) => !allBrandIds.includes(value));
    if (!values.includes(newBrandId)) {
        filters[SEARCH_FILTER_KEY].push(newBrandId);
    }
    history.push(`${SEARCH_QUERY_BASE_URL}${exports.encodeFilters(filters)}`);
    return filters;
};
exports.addSearchTerm = (term, history) => {
    const filters = exports.getFilters(history);
    const values = filters[SEARCH_TERM_KEY] || [];
    filters[SEARCH_TERM_KEY] = [...values, term];
    history.push(`${SEARCH_QUERY_BASE_URL}${exports.encodeFilters(filters)}`);
};
exports.removeSearchTerm = (term, history) => {
    const filters = exports.getFilters(history);
    const values = filters[SEARCH_TERM_KEY] || [];
    filters[SEARCH_TERM_KEY] = values.filter((value) => value !== term);
    history.push(`${SEARCH_QUERY_BASE_URL}${exports.encodeFilters(filters)}`);
};
exports.refreshSearchTerms = (history, id) => exports.refreshFiltersByKey(id, SEARCH_FILTER_KEY, history);
exports.changeSortOption = (sortOption, history) => {
    const filters = exports.getFilters(history);
    filters[SEARCH_SORT_BY_KEY] = sortOption;
    history.push(`${SEARCH_QUERY_BASE_URL}${exports.encodeFilters(filters)}`);
};
exports.getTypeAheadPayload = (filters = [], terms = []) => {
    const data = {};
    if (!!filters.length) {
        data[SEARCH_FILTER_KEY] = filters;
    }
    if (!!terms.length) {
        data[SEARCH_TERM_KEY] = terms.filter(t => !!t);
    }
    return data;
};
