"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const SvgIcon_1 = require("@material-ui/core/SvgIcon");
const makeStyles_1 = require("@material-ui/core/styles/makeStyles");
const useStyles = makeStyles_1.default(({ spacing }) => ({
    fontSizeLarge: {
        fontSize: spacing(5.5),
    },
    fontSizeMedium: {
        fontSize: spacing(3.875),
    },
    fontSizeXSmall: {
        fontSize: spacing(2.5),
    },
    fontSize2XSmall: {
        fontSize: spacing(2),
    },
    fontSize3XSmall: {
        fontSize: spacing(1.5),
    },
    fontSize4XSmall: {
        fontSize: spacing(1),
    },
}));
const Svg = ({ fontSize = 'small', className, color, ...rest }) => {
    const classes = useStyles();
    return (React.createElement(SvgIcon_1.default, Object.assign({}, rest, { className: classnames_1.default(className, {
            [classes.fontSizeLarge]: fontSize === 'large',
            [classes.fontSizeMedium]: fontSize === 'medium',
            [classes.fontSizeXSmall]: fontSize === 'xsmall',
            [classes.fontSize2XSmall]: fontSize === '2xsmall',
            [classes.fontSize3XSmall]: fontSize === '3xsmall',
            [classes.fontSize4XSmall]: fontSize === '4xsmall',
        }) })));
};
exports.default = Svg;
