"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const styles_1 = require("@material-ui/core/styles");
const HBContainer_1 = require("@components/library/HBContainer");
const Grid_1 = require("@material-ui/core/Grid");
const BrandItems_1 = require("./BrandItems");
const CategoryItems_1 = require("./CategoryItems");
const styles = ({ spacing, breakpoints }) => styles_1.createStyles({
    root: {
        width: '100%',
        [breakpoints.down('sm')]: {
            height: '100%',
        },
    },
    wrapper: {
        padding: spacing(5, 0, 8, 0),
        [breakpoints.down('sm')]: {
            padding: 0,
            flexDirection: 'column-reverse',
        },
    },
});
const CategoryMenuFlyout = ({ classes, categoryItems, brandItems }) => (React.createElement(HBContainer_1.default, { className: classes.root },
    React.createElement(Grid_1.default, { item: true, container: true, wrap: "nowrap", className: classes.wrapper },
        React.createElement(BrandItems_1.default, { items: brandItems }),
        React.createElement(CategoryItems_1.default, { items: categoryItems }))));
exports.default = withStyles_1.default(styles)(CategoryMenuFlyout);
