"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const styles_1 = require("@material-ui/core/styles");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const Grid_1 = require("@material-ui/core/Grid");
const Hidden_1 = require("@material-ui/core/Hidden");
const Tabs_1 = require("@material-ui/core/Tabs");
const Tab_1 = require("@material-ui/core/Tab");
const withLayout_1 = require("@components/common/layout/withLayout");
const useQuery_1 = require("@components/common/utils/hooks/useQuery");
const HBTypography_1 = require("@components/library/HBTypography");
const HBAccordion_1 = require("@components/library/HBAccordion");
const HBTabPanel_1 = require("@components/library/HBTabPanel");
const FullWidthBackground_1 = require("@components/common/utils/FullWidthBackground");
const ProfileDetail_1 = require("@components/pages/profile/ProfileDetail");
const ProfileOrderHistory_1 = require("@components/pages/profile/ProfileOrderHistory");
const ProfileSignOut_1 = require("@components/pages/profile/detail/ProfileSignOut");
const styles = ({ spacing, breakpoints }) => styles_1.createStyles({
    root: {
        paddingTop: spacing(6),
    },
    content: {
        position: 'relative',
        [breakpoints.down('sm')]: {
            paddingBottom: spacing(8),
        },
    },
    header: {
        padding: spacing(2, 0),
        [breakpoints.down('sm')]: {
            top: 142,
        },
    },
    wrapper: {},
    tabPanelWrapper: {},
    tabs: {
        '& button:first-child': {
            marginRight: spacing(5),
            [breakpoints.down('sm')]: {
                marginRight: spacing(3),
            },
            ['@media (max-width:370px)']: {
                marginRight: spacing(1),
            },
        },
    },
});
const PageProfile = ({ classes, page: { tabs, orderHistory } }) => {
    const query = useQuery_1.default();
    const [value, setValue] = React.useState(0);
    const [valueAccordion, setValueAccordion] = React.useState({ orders: false, profile: false });
    React.useEffect(() => {
        setValue(query.get('tab') === 'order-history' ? 0 : 1);
    }, []);
    const isFirstRun = React.useRef(true);
    React.useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }
        const href = `${window.location.pathname}?tab=${value === 0 ? 'order-history' : 'profile'}`;
        window.history.replaceState(null, '', href);
        window.scrollTo(0, 0);
    }, [value]);
    const handleChange = (event, value) => {
        setValue(value);
    };
    const handleAccordion = (e) => {
        const { id } = e.currentTarget;
        switch (Number(id)) {
            case 0:
                return setValueAccordion(prevState => {
                    return {
                        ...prevState,
                        orders: !prevState.orders,
                    };
                });
            case 1:
                return setValueAccordion(prevState => {
                    return {
                        ...prevState,
                        profile: !prevState.profile,
                    };
                });
        }
    };
    return (React.createElement(FullWidthBackground_1.default, { className: classes.root },
        React.createElement("div", { className: classes.content },
            React.createElement(ProfileSignOut_1.default, null),
            React.createElement(Hidden_1.default, { xsDown: true, implementation: "css" },
                React.createElement(Grid_1.default, { container: true, direction: "column", className: classes.wrapper },
                    React.createElement(Grid_1.default, { item: true, className: classnames_1.default(classes.header) },
                        React.createElement(Tabs_1.default, { value: value, onChange: handleChange, indicatorColor: "secondary", textColor: "primary", "aria-label": `${tabs[0].title} ${tabs[1].title}`, selectionFollowsFocus: true, className: classes.tabs },
                            React.createElement(Tab_1.default, { label: React.createElement(HBTypography_1.default, { variant: "h5", noWrap: true }, tabs[0].title), id: "tab-0", "aria-label": tabs[0].title, "aria-controls": "tabpanel-0", value: 0 }),
                            React.createElement(Tab_1.default, { label: React.createElement(HBTypography_1.default, { variant: "h5", noWrap: true }, tabs[1].title), id: "tab-1", "aria-label": tabs[1].title, "aria-controls": "tabpanel-1", value: 1 }))),
                    React.createElement(Grid_1.default, { item: true, className: classes.tabPanelWrapper },
                        React.createElement(HBTabPanel_1.default, { value: value, index: 0, pt: 4 },
                            React.createElement(ProfileOrderHistory_1.default, { items: orderHistory })),
                        React.createElement(HBTabPanel_1.default, { value: value, index: 1, pt: 4 },
                            React.createElement(Grid_1.default, { container: true, direction: "column" },
                                React.createElement(ProfileDetail_1.default, Object.assign({}, tabs[1]))))))),
            React.createElement(Hidden_1.default, { smUp: true, implementation: "css" },
                React.createElement(HBAccordion_1.default, { index: 0, label: tabs[0].title, selected: valueAccordion.orders === true, onChange: handleAccordion },
                    React.createElement(ProfileOrderHistory_1.default, { items: orderHistory })),
                React.createElement(HBAccordion_1.default, { index: 1, label: tabs[1].title, selected: valueAccordion.profile === true, onChange: handleAccordion },
                    React.createElement(Grid_1.default, { container: true, direction: "column" },
                        React.createElement(ProfileDetail_1.default, Object.assign({}, tabs[1]))))))));
};
exports.default = withLayout_1.default(withStyles_1.default(styles)(PageProfile), { paddingTop: 0, paddingBottom: 0 });
