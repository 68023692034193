"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const createStyles_1 = require("@material-ui/core/styles/createStyles");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const Grid_1 = require("@material-ui/core/Grid");
const Avatar_1 = require("@material-ui/core/Avatar");
const ButtonBase_1 = require("@material-ui/core/ButtonBase");
const styles = ({ palette, typography }) => createStyles_1.default({
    avatar: {},
    avatarInner: {
        ...typography.h4,
        backgroundColor: palette.secondary.main,
        width: '114px',
        height: '112px',
    },
});
const ProductGroup = React.forwardRef(function ProductGroup({ children: childrenProp, classes, handleMax, noMax = false, max = 4, ...other }, ref) {
    const [showAll, setShowAll] = React.useState(false);
    const clampedMax = max < 2 ? 2 : max;
    const children = React.Children.toArray(childrenProp).filter(child => React.isValidElement(child));
    let extraAvatars = children.length > clampedMax ? children.length - clampedMax + 1 : 0;
    if (showAll) {
        extraAvatars = 0;
    }
    const handleShowAll = (event) => {
        setShowAll(true);
        if (handleMax) {
            handleMax(event);
        }
    };
    React.useEffect(() => {
        setShowAll(noMax);
    }, [noMax]);
    return (React.createElement(Grid_1.default, Object.assign({ container: true, direction: "row", ref: ref }, other),
        children.slice(0, children.length - extraAvatars).map(child => {
            return React.cloneElement(child, {
                className: child.props.className,
                xs: 6,
                sm: 4,
                md: 3,
            });
        }),
        extraAvatars ? (React.createElement(Grid_1.default, { item: true, xs: 6, sm: 4, md: 3, container: true, justify: "center", alignItems: "center", className: classes.avatar },
            React.createElement(ButtonBase_1.default, { onClick: handleShowAll },
                React.createElement(Avatar_1.default, { className: classes.avatarInner },
                    "+",
                    extraAvatars)))) : null));
});
exports.default = withStyles_1.default(styles)(ProductGroup);
