"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Zero = () => (React.createElement("svg", { viewBox: "0 0 172 225", xmlns: "http://www.w3.org/2000/svg", width: "172", height: "225" },
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { d: "M85.634 224.93c23.242 0 40.6-9.034 52.075-27.1 11.475-18.066 17.212-45.898 17.212-83.496 0-36.035-5.908-63.355-17.725-81.958C125.38 13.772 108.192 4.471 85.634 4.471c-23.047 0-40.21 8.984-51.49 26.953-11.279 17.969-16.918 45.605-16.918 82.91 0 36.523 5.81 64.087 17.431 82.69 11.621 18.604 28.614 27.906 50.977 27.906zm0-13.184c-17.676 0-30.86-7.934-39.551-23.804-8.691-15.869-13.037-40.405-13.037-73.608 0-31.64 4.15-55.664 12.451-72.07 8.3-16.407 21.68-24.61 40.137-24.61s31.909 8.203 40.356 24.61c8.448 16.406 12.671 40.43 12.671 72.07 0 32.129-4.224 56.396-12.67 72.803-8.448 16.406-21.9 24.61-40.357 24.61z", fill: "#202020", fillRule: "nonzero" }),
        React.createElement("g", { transform: "translate(15)" },
            React.createElement("path", { d: "M85 66.23c.37 33.66.62 50.901.752 51.723.132.82.948 1.213 2.448 1.176v-52.9H85z", fill: "#979797" }),
            React.createElement("path", { d: "M72.97 29.57l52.133 30.638a5 5 0 0 1-2.78 9.305l-102.1-5.063a5 5 0 0 1-1.859-9.529L72.97 29.57c5.241-4.067 5.901-5.73 5.901-10.638 0-10.376-3.783-14.856-12.056-15.29-6.811-.356-12.667 3.388-14.765 9.34", stroke: "#B6956C", strokeWidth: "2", strokeLinecap: "round" }),
            React.createElement("path", { d: "M85.718 65.019c1.455 1.092.885 81.908.885 83.91 0 2.002 7.118 12.729 8.428 12.729 1.31 0 6.493-8.727 6.493-10.729 0-2.002.426-82.818-.661-83.91-1.088-1.092-16.601-3.092-15.145-2z", fill: "#D4D4D4" }),
            React.createElement("path", { d: "M65.766 4.63c-3.64.042-11.001 1.161-15.745 2.719-3.163 1.038-1.88 4.598 3.849 10.68l15.757-2.39C69.48 8.271 68.193 4.601 65.766 4.63z", fill: "#202020", fillRule: "nonzero" })))));
exports.default = Zero;
