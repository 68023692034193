"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Grid_1 = require("@material-ui/core/Grid");
const HBTypography_1 = require("@components/library/HBTypography");
const HBLink_1 = require("@components/library/HBLink");
const HBIcon_1 = require("@components/library/HBIcon");
const LabelContext_1 = require("@components/common/context/LabelContext");
const MediaDownload = ({ icon = 'download', label }) => {
    return (React.createElement(Grid_1.default, { item: true, container: true, spacing: 2, wrap: "nowrap" },
        React.createElement(Grid_1.default, { item: true },
            React.createElement(HBIcon_1.default, { icon: icon })),
        React.createElement(Grid_1.default, { item: true },
            React.createElement(HBTypography_1.default, { variant: "body1" }, label))));
};
const ProductDownloads = ({ labels, downloads = [] }) => (React.createElement(Grid_1.default, { item: true, container: true, spacing: 2 },
    React.createElement(Grid_1.default, { item: true },
        React.createElement(HBTypography_1.default, { variant: "h6" }, labels.DOWNLOADS)),
    React.createElement(Grid_1.default, { item: true, container: true, spacing: 1 }, downloads === null || downloads === void 0 ? void 0 : downloads.map(({ to, ...other }, idX) => !!to ? (React.createElement(HBLink_1.default, { to: to },
        React.createElement(MediaDownload, Object.assign({ key: idX.toString() }, other)))) : (React.createElement(MediaDownload, Object.assign({ key: idX.toString() }, other)))))));
exports.default = LabelContext_1.withLabel(ProductDownloads);
