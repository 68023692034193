"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Svg_1 = require("./Svg");
const G_1 = require("./Svg/G");
const Edit = ({ color = 'primary', strokeWidth = 2, ...rest }) => (React.createElement(Svg_1.default, Object.assign({}, rest),
    React.createElement(G_1.default, { color: color, strokeWidth: strokeWidth, fill: "none", fillRule: "evenodd", strokeLinecap: "round", strokeLinejoin: "round" },
        React.createElement("path", { d: "m17.2 2.912 3.888 3.89-13.796 13.794-3.889-3.889z" }),
        React.createElement("path", { d: "m3.403 16.708-1.945 5.834 5.834-1.945zm17.685-9.908-3.889-3.888.648-.648a2.75 2.75 0 0 1 3.889 3.889l-.648.648z" }))));
exports.default = Edit;
