"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Svg_1 = require("./Svg");
const G_1 = require("./Svg/G");
const ShopOpen = ({ color = 'primary', ...rest }) => (React.createElement(Svg_1.default, Object.assign({}, rest, { viewBox: "0 0 50 46" }),
    React.createElement(G_1.default, { transform: "translate(1.167 1.333)", color: color, strokeWidth: "2", fill: "none", fillRule: "evenodd", strokeLinecap: "round", strokeLinejoin: "round" },
        React.createElement("path", { d: "M10.833 31.417a3.25 3.25 0 01-6.5 0v-6.5a3.25 3.25 0 116.5 0zM36.833 34.667v-13l6.5 13v-13M32.5 34.667h-4.333A2.167 2.167 0 0126 32.5v-8.667c0-1.196.97-2.166 2.167-2.166H32.5M26 28.167h4.333M21.667 24.917a3.25 3.25 0 01-3.25 3.25h-3.25v-6.5h3.25a3.25 3.25 0 013.25 3.25zM15.167 34.667v-6.5" }),
        React.createElement("rect", { height: "30.333", rx: "2", width: "47.667", y: "13" }),
        React.createElement("path", { d: "M33.583 13l-6.5-11.128a3.772 3.772 0 00-6.517 0L14.083 13" }))));
exports.default = ShopOpen;
