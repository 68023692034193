"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const Grid_1 = require("@material-ui/core/Grid");
const HBTypography_1 = require("@components/library/HBTypography");
const HBLink_1 = require("@components/library/HBLink");
const HBIcon_1 = require("@components/library/HBIcon");
const CustomerCareInfoStyles_1 = require("./CustomerCareInfoStyles");
const CustomerCareInfo = ({ classes, title, titleMargin = 3, subTitle, info, maxWidth }) => {
    return (React.createElement(Grid_1.default, { item: true, container: true, justify: "center", direction: "column", className: classes.root },
        title && (React.createElement(Grid_1.default, { item: true },
            React.createElement(HBTypography_1.default, { variant: "h4", align: "center", marginBottom: titleMargin }, title))),
        React.createElement(Grid_1.default, { item: true, className: classnames_1.default({ [classes.maxWidth]: maxWidth }) },
            subTitle && (React.createElement(React.Fragment, null,
                React.createElement(HBTypography_1.default, { variant: "body1", align: "center", marginBottom: 6 }, subTitle))),
            !!info.length && (React.createElement(Grid_1.default, { item: true, container: true, justify: "space-around", spacing: 2, className: classes.contactWrapper }, info === null || info === void 0 ? void 0 : info.map(({ icon, title, subTitle, to }) => (React.createElement(Grid_1.default, { item: true, key: title },
                React.createElement(HBLink_1.default, { to: to },
                    icon && (React.createElement("div", { className: classes.iconWrapper },
                        React.createElement(HBIcon_1.default, { icon: icon }))),
                    title && (React.createElement(HBTypography_1.default, { variant: "h6", align: "center" }, title)),
                    subTitle &&
                        (typeof subTitle === 'string' ? (React.createElement(HBTypography_1.default, { variant: "body1" }, subTitle)) : (subTitle === null || subTitle === void 0 ? void 0 : subTitle.map(label => (React.createElement(HBTypography_1.default, { key: label, variant: "body1" }, label))))))))))))));
};
exports.default = withStyles_1.default(CustomerCareInfoStyles_1.default)(CustomerCareInfo);
