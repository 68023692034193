"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const Grid_1 = require("@material-ui/core/Grid");
const Collapse_1 = require("@material-ui/core/Collapse");
const HBTypography_1 = require("@components/library/HBTypography");
const HBImage_1 = require("@components/library/HBImage");
const HBButton_1 = require("@components/library/HBButton");
const CartSummary_1 = require("@components/pages/cart/CartSummary");
const ProductGroup_1 = require("@components/pages/profile/orderHistory/ProductGroup");
const LabelContext_1 = require("@components/common/context/LabelContext");
const OrderDetailItemStyles_1 = require("./OrderDetailItemStyles");
const OrderHistoryItem = ({ classes, orderNo, orderDate, paymentMethod, isConfirmed, cart: { items, delivery, invoiceAddress, shippingAddress, summary } = {}, }) => {
    const labels = LabelContext_1.useLabelContext();
    const [open, setOpen] = React.useState(false);
    const toggleOrderDetails = () => {
        setOpen(!open);
    };
    const getInfoText = label => label.split(',').map(item => (React.createElement(HBTypography_1.default, { key: item, variant: "body1" }, item)));
    const getInfo = (address, label) => (React.createElement(Grid_1.default, { container: true, direction: "column" },
        React.createElement(HBTypography_1.default, { variant: "subtitle1", marginBottom: 2 }, label),
        getInfoText(address.label),
        getInfoText(address.helperText)));
    return (React.createElement(Grid_1.default, { container: true, direction: "column" },
        React.createElement(Grid_1.default, { item: true, container: true, className: classes.header },
            React.createElement(Grid_1.default, { item: true, xs: 12, md: 6, className: classes.grow },
                React.createElement(HBTypography_1.default, { variant: "h6" }, isConfirmed ? labels.ORDER_STATUS_SETTLED : labels.ORDER_STATUS_FAILED)),
            React.createElement(Grid_1.default, { item: true, xs: 12, md: 6, className: classes.grow },
                React.createElement(Grid_1.default, { container: true, justify: "space-between", className: classes.orderTitleWrapper },
                    React.createElement(Grid_1.default, { item: true, container: true, wrap: "nowrap", className: classes.orderTitle },
                        React.createElement(HBTypography_1.default, { variant: "body1", marginRight: 1 }, labels.ORDER_NO),
                        React.createElement(HBTypography_1.default, { variant: "body1" }, orderNo)),
                    React.createElement(Grid_1.default, { item: true, container: true, wrap: "nowrap", className: classes.orderTitle },
                        React.createElement(HBTypography_1.default, { variant: "body1", marginRight: 1 }, labels.ORDERED_DATE),
                        React.createElement(HBTypography_1.default, { variant: "body1" }, orderDate))))),
        React.createElement(Grid_1.default, { item: true, container: true, direction: "column", className: classes.content },
            React.createElement(Grid_1.default, { item: true },
                React.createElement(ProductGroup_1.default, { classes: { avatar: classes.product }, className: classes.productList, noMax: open, handleMax: toggleOrderDetails }, items.map(prod => {
                    var _a;
                    return (React.createElement(Grid_1.default, { item: true, key: prod._id, className: classes.product },
                        React.createElement(Grid_1.default, { container: true, direction: "column", alignItems: "center" },
                            React.createElement(Grid_1.default, { item: true },
                                React.createElement("div", { className: classes.productImageWrapper },
                                    React.createElement(HBImage_1.default, { src: (_a = prod.image) === null || _a === void 0 ? void 0 : _a.path, alt: prod.name, size: 200 }))),
                            React.createElement(Grid_1.default, { item: true },
                                React.createElement(HBTypography_1.default, { truncate: true, clamp: 1, capitalize: true, variant: "body2", align: "center" }, prod.name)),
                            React.createElement(Grid_1.default, { item: true },
                                React.createElement(HBTypography_1.default, { variant: "subtitle2", align: "center" }, prod.price)))));
                }))),
            React.createElement(Grid_1.default, { item: true },
                React.createElement(Collapse_1.default, { in: open },
                    React.createElement(Grid_1.default, { container: true, direction: "column", wrap: "nowrap" },
                        React.createElement(Grid_1.default, { item: true },
                            React.createElement(Grid_1.default, { container: true, className: classes.orderSummary },
                                React.createElement(Grid_1.default, { item: true, className: classes.orderSummaryInner, sm: 8, md: 6 },
                                    React.createElement(CartSummary_1.default, Object.assign({ spacing: true, largeText: true, showSubtotal: true, totalTextlabel: labels.CART_TOTAL }, summary))))),
                        React.createElement(Grid_1.default, { item: true },
                            React.createElement(Grid_1.default, { container: true, direction: "column" },
                                React.createElement(Grid_1.default, { item: true, className: classes.addressSummaryTitle },
                                    React.createElement(HBTypography_1.default, { variant: "h6", marginBottom: 1 }, labels.ORDER_DETAILS)),
                                React.createElement(Grid_1.default, { item: true, className: classes.addressSummaryContent },
                                    React.createElement(Grid_1.default, { container: true },
                                        React.createElement(Grid_1.default, { item: true, container: true, xs: 12, md: 6 },
                                            React.createElement(Grid_1.default, { item: true, xs: 6 }, getInfo(shippingAddress, labels.SHIPPING_ADDRESS)),
                                            React.createElement(Grid_1.default, { item: true, xs: 6 }, getInfo(invoiceAddress, labels.INVOICE_ADDRESS))),
                                        React.createElement(Grid_1.default, { item: true, container: true, xs: 12, md: 6, className: classes.addressSummaryContentInner },
                                            React.createElement(Grid_1.default, { item: true, xs: 6 }, getInfo(delivery, labels.SHIPPING_METHOD)),
                                            React.createElement(Grid_1.default, { item: true, xs: 6 },
                                                React.createElement(Grid_1.default, { container: true, direction: "column" },
                                                    React.createElement(HBTypography_1.default, { variant: "subtitle1", marginBottom: 2 }, labels.PAYMENT_METHOD),
                                                    React.createElement(HBTypography_1.default, { variant: "body1" }, paymentMethod))))))))))),
            React.createElement(Grid_1.default, { item: true },
                React.createElement(Grid_1.default, { container: true, className: classnames_1.default(classes.buttonsWrapper, { [classes.spacing]: open }) },
                    React.createElement(HBButton_1.default, { variant: "outlined", color: "primary", label: open ? labels.CLOSE : labels.VIEW_ORDER, onClick: toggleOrderDetails }))))));
};
exports.default = withStyles_1.default(OrderDetailItemStyles_1.default)(OrderHistoryItem);
