"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_hook_form_1 = require("react-hook-form");
const Grid_1 = require("@material-ui/core/Grid");
const FormControl_1 = require("@material-ui/core/FormControl");
const FormControlLabel_1 = require("@material-ui/core/FormControlLabel");
const FormHelperText_1 = require("@material-ui/core/FormHelperText");
const RadioGroup_1 = require("@material-ui/core/RadioGroup");
const HBRadio_1 = require("@components/library/HBRadio");
const HBTypography_1 = require("@components/library/HBTypography");
const makeStyles_1 = require("@material-ui/core/styles/makeStyles");
const useStyles = makeStyles_1.default(() => ({
    radio: {
        '& .MuiFormControlLabel-root': {
            display: 'flex',
            '& .MuiFormControlLabel-label': {
                flexGrow: 1,
            },
        },
    },
    noMarginBottom: {
        marginBottom: 0,
    },
}));
const FormRadioGroup = (props) => {
    var _a, _b;
    const classes = useStyles();
    const { name = '', options = [], required, disabled } = props;
    const { control, errors } = react_hook_form_1.useFormContext();
    const errorMessage = (_a = errors[name]) === null || _a === void 0 ? void 0 : _a.message;
    const { shippingform } = props;
    const shippingformBool = shippingform === "true";
    const checkFormType = shippingformBool ? { marginBottom: 0 } : { none: '' };
    if (!options.length) {
        return null;
    }
    return (React.createElement(FormControl_1.default, { component: "fieldset", fullWidth: true, error: Boolean(errorMessage), margin: "normal", required: required, disabled: disabled },
        React.createElement(react_hook_form_1.Controller, { as: React.createElement(RadioGroup_1.default, { "aria-label": name },
                React.createElement(Grid_1.default, { container: true, direction: "column" }, options === null || options === void 0 ? void 0 : options.map(({ value, label, helperText, helperText1 }) => (React.createElement(Grid_1.default, { item: true, container: true, direction: "column", key: value + label, className: classes.radio },
                    React.createElement(Grid_1.default, { item: true },
                        React.createElement(FormControlLabel_1.default, { value: value, control: React.createElement(HBRadio_1.default, null), label: React.createElement(Grid_1.default, { container: true },
                                React.createElement(HBTypography_1.default, { variant: "body1" }, label),
                                (helperText || helperText1) && (React.createElement(Grid_1.default, { item: true, container: true, wrap: "nowrap", alignItems: "center", justify: "space-between", spacing: 3 },
                                    helperText && (React.createElement(Grid_1.default, { item: true, className: classes.noMarginBottom, style: checkFormType },
                                        React.createElement(HBTypography_1.default, { variant: "body1" }, helperText))),
                                    helperText1 && (React.createElement(Grid_1.default, { item: true },
                                        React.createElement(HBTypography_1.default, { variant: "subtitle1", noWrap: true }, helperText1)))))) }))))))), defaultValue: (_b = options === null || options === void 0 ? void 0 : options.find(option => option.selected)) === null || _b === void 0 ? void 0 : _b.value, name: name, control: control }),
        errorMessage && React.createElement(FormHelperText_1.default, null, errorMessage)));
};
exports.default = FormRadioGroup;
