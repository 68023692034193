"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const AppContext_1 = require("@components/common/context/AppContext");
const ApiContext_1 = require("@components/common/context/ApiContext");
const useApiRequest_1 = require("@components/common/utils/hooks/useApiRequest");
const FormFieldContext_1 = require("@components/common/utils/Form/FormFieldContext/FormFieldContext");
const ProfileEditForm_1 = require("@components/pages/profile/detail/ProfileEditForm");
const DefaultValues = {
    adressLine: '',
    adressLine1: '',
    streetAddress: '',
    postalCode: '',
    addressLocality: '',
};
const AddressInfoEditForm = ({ api, id, defaults, editForm, dispatch, handleLoading, onChange, isNew, }) => {
    const defaultValues = { ...DefaultValues, ...defaults };
    const { methods } = FormFieldContext_1.useFormHook({ defaultValues });
    const { onSubmit, isLoading, data, apiError } = useApiRequest_1.default();
    React.useEffect(() => {
        if (!apiError && (data === null || data === void 0 ? void 0 : data.member)) {
            dispatch({ type: AppContext_1.ActionTypes.UPDATE_MEMBER_ADDRESS, payload: data.member });
            onChange();
        }
    }, [data]);
    React.useEffect(() => {
        handleLoading(isLoading);
    }, [isLoading]);
    const handleSubmit = async (payload) => await onSubmit(() => (isNew ? api.addMemberAddress(payload) : api.updateMemberAddress(payload, id)));
    return (React.createElement(FormFieldContext_1.default, { methods: methods, onSubmit: handleSubmit },
        React.createElement(ProfileEditForm_1.default, Object.assign({}, editForm, { error: !!apiError, errors: apiError === null || apiError === void 0 ? void 0 : apiError.inputError, disabled: isLoading, onCancel: onChange }))));
};
exports.default = ApiContext_1.withApi(AddressInfoEditForm);
