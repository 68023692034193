"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Svg_1 = require("./Svg");
const G_1 = require("./Svg/G");
const Download = ({ color = 'primary', ...rest }) => (React.createElement(Svg_1.default, Object.assign({}, rest),
    React.createElement(G_1.default, { color: color, strokeWidth: "2", fill: "none", fillRule: "evenodd", strokeLinejoin: "round" },
        React.createElement("g", { strokeLinecap: "round" },
            React.createElement("path", { d: "M2.833 10.167l8.541 9.717a.833.833 0 001.25 0l8.543-9.717M12 20.167V1.834" })),
        React.createElement("path", { strokeLinecap: "square", d: "M23 23H1" }))));
exports.default = Download;
