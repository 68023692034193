"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Checkbox_1 = require("@material-ui/core/Checkbox");
const styles_1 = require("@material-ui/core/styles");
const HBIcon_1 = require("../HBIcon");
const HBCheckbox = styles_1.withStyles(() => ({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '&.Mui-disabled': {
            '& svg': {
                opacity: '0.3',
            },
        },
    },
}))((props) => (React.createElement(Checkbox_1.default, Object.assign({ icon: React.createElement(HBIcon_1.default, { icon: "checkbox_outline" }), checkedIcon: React.createElement(HBIcon_1.default, { icon: "checkbox_filled" }), color: "primary" }, props))));
exports.default = HBCheckbox;
