"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.withApi = exports.ApiConsumer = exports.ApiProvider = void 0;
const React = require("react");
const react_router_dom_1 = require("react-router-dom");
const react_1 = require("react");
const ApiContext = React.createContext(null);
const ApiProvider = react_router_dom_1.withRouter(props => {
    const { api, children } = props;
    const navigateTo = href => {
        if (!href)
            return;
        const isSilent = href.startsWith('$currentSilently');
        const isReload = href.startsWith('$current');
        const isBack = href === '$back';
        const isParent = href === '$parent';
        const isPopup = href.startsWith('#');
        const { history, location: { state: { code: currentCode } = {} }, } = props;
        let code = currentCode;
        let notClosable = href.endsWith('!');
        if (notClosable)
            href = href.slice(0, -1);
        if (isBack) {
            return window.history.back();
        }
        if (isParent) {
            const newPathName = window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/'));
            return (window.location = window.location.origin + newPathName);
        }
        if (/^http/.test(href)) {
            return (window.location.href = href);
        }
        if (isSilent || isReload) {
            code = parseInt((Math.random() * 99999).toFixed(0), 10).toString(36);
            let [, search, hash] = /(\?.+?)?(#.*?)?$$/.exec(href) || [];
            href = window.location.pathname + (search || window.location.search) + (hash || window.location.hash);
        }
        else if (isPopup) {
            href = window.location.pathname + window.location.search + href;
        }
        history.push(href, { code, isSilent, notClosable });
    };
    react_1.useEffect(() => {
        props.api.apiClientRequest.onNavigateTo = navigateTo;
        window['navigateTo'] = navigateTo;
    }, []);
    return React.createElement(ApiContext.Provider, { value: api }, children);
});
exports.ApiProvider = ApiProvider;
const ApiConsumer = ApiContext.Consumer;
exports.ApiConsumer = ApiConsumer;
const withApi = Component => props => {
    return React.createElement(ApiConsumer, null, api => React.createElement(Component, Object.assign({}, props, { api: api })));
};
exports.withApi = withApi;
