"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Grid_1 = require("@material-ui/core/Grid");
const HBTypography_1 = require("@components/library/HBTypography");
const HBIcon_1 = require("@components/library/HBIcon");
const HBLink_1 = require("@components/library/HBLink");
const LabelContext_1 = require("@components/common/context/LabelContext");
const ProductShippingInfo = ({ labels, shippingInfo }) => (React.createElement(Grid_1.default, { item: true, container: true, spacing: 2 },
    React.createElement(Grid_1.default, { item: true },
        React.createElement(HBTypography_1.default, { variant: "h6", component: "p" }, labels.SHIPPING_AND_RETURNS)),
    React.createElement(Grid_1.default, { item: true, container: true, spacing: 1, alignItems: "center" }, shippingInfo.map(({ icon, label, to }) => (React.createElement(React.Fragment, { key: label }, !!to ? (React.createElement(Grid_1.default, { item: true, container: true, spacing: 2, wrap: "nowrap" },
        React.createElement(Grid_1.default, { item: true },
            React.createElement(HBLink_1.default, { to: to, noWrap: true, color: "secondary", showUnderline: true, hoverArrow: true },
                React.createElement(HBTypography_1.default, { variant: "subtitle1", align: "center", component: "p", fontNormal: true, color: "secondary", uppercase: true, letterSpacing: 1 }, label))))) : (React.createElement(Grid_1.default, { item: true, container: true, spacing: 2, wrap: "nowrap" },
        React.createElement(Grid_1.default, { item: true },
            React.createElement(HBIcon_1.default, { fontSize: "medium", icon: icon })),
        React.createElement(Grid_1.default, { item: true }, label != 'Downloads' && (React.createElement(HBTypography_1.default, { variant: "body2", textTransformNone: true }, label)))))))))));
exports.default = LabelContext_1.withLabel(ProductShippingInfo);
