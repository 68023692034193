"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const AddressInfoStepSelectForm_1 = require("@components/pages/checkout/Step/AddressInfoStep/AddressInfoStepSelectForm");
const AddressInfoStepForm_1 = require("@components/pages/checkout/Step/AddressInfoStep/AddressInfoStepForm");
const Slide_1 = require("@material-ui/core/Slide");
const Grid_1 = require("@material-ui/core/Grid");
const FormButton_1 = require("@components/common/utils/Form/FormButton/FormButton");
const makeStyles_1 = require("@material-ui/core/styles/makeStyles");
const AppContext_1 = require("@components/common/context/AppContext");
const LabelContext_1 = require("@components/common/context/LabelContext");
const useStyles = makeStyles_1.default(({ breakpoints, spacing }) => ({
    cancelButton: {
        maxWidth: 392,
        margin: '16px auto 0',
        opacity: 0.4,
        '&:hover': {
            opacity: 0.5,
        },
    },
    root: {
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'row',
    },
    paper: {
        flex: 1,
        position: 'relative',
    },
}));
const AddressInfoStepEdit = ({ form }) => {
    const classes = useStyles();
    const labels = LabelContext_1.useLabelContext();
    const { state: { member, cart: { addressShippingOptions, addressInvoiceOptions } = {} } = {}, dispatch } = AppContext_1.useAppContext();
    const [isEdit, setIsEdit] = React.useState(false);
    const [editAddress, setEditAddress] = React.useState(undefined);
    const [isNew, setIsNew] = React.useState(false);
    const handleEditAddress = value => {
        var _a;
        const addresses = (_a = member === null || member === void 0 ? void 0 : member.crmContact) === null || _a === void 0 ? void 0 : _a.crmAdresses;
        if (!!value && !!(addresses === null || addresses === void 0 ? void 0 : addresses.length)) {
            setEditAddress(addresses.find(address => address._id === value));
            setIsEdit(true);
            setIsNew(false);
        }
        else {
            setEditAddress(undefined);
            setIsEdit(false);
            setIsNew(false);
        }
    };
    const handleAddNewAddress = () => {
        setEditAddress(undefined);
        setIsEdit(true);
        setIsNew(true);
    };
    const handleEditOrAddNewAddress = data => {
        dispatch({ type: AppContext_1.ActionTypes.EDIT_OR_ADD_NEW_ADDRESS_INFO, payload: data });
        setIsEdit(false);
        setIsNew(false);
    };
    const handleEditAddressInfo = data => dispatch({ type: AppContext_1.ActionTypes.EDIT_ADDRESS_INFO, payload: data });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classes.root },
            !isEdit ? (React.createElement(Slide_1.default, { direction: "right", in: !isEdit, mountOnEnter: true, unmountOnExit: true },
                React.createElement("div", { className: classes.paper },
                    React.createElement(AddressInfoStepSelectForm_1.default, { addressShipping: addressShippingOptions, addressInvoice: addressInvoiceOptions, updateContext: handleEditAddressInfo, onEdit: handleEditAddress, onAddNew: handleAddNewAddress })))) : null,
            isEdit ? (React.createElement(Slide_1.default, { direction: "left", in: isEdit, mountOnEnter: true, unmountOnExit: true },
                React.createElement("div", { className: classes.paper },
                    React.createElement(AddressInfoStepForm_1.default, { form: form, updateContext: handleEditOrAddNewAddress, defaultAddress: editAddress, isEdit: !isNew }),
                    React.createElement(Grid_1.default, { container: true },
                        React.createElement(FormButton_1.default, { type: "button", label: labels.CANCEL, onClick: () => handleEditAddress(null), className: classes.cancelButton }))))) : null)));
};
exports.default = AddressInfoStepEdit;
