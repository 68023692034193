"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const styles_1 = require("@material-ui/core/styles");
const Grid_1 = require("@material-ui/core/Grid");
const HBTypography_1 = require("@components/library/HBTypography");
const HBImage_1 = require("@components/library/HBImage");
const HBLink_1 = require("@components/library/HBLink");
const LabelContext_1 = require("@components/common/context/LabelContext");
const VirtualColorVariantList_1 = require("./VirtualColorVariantList");
const ProductDetailContext_1 = require("@components/common/context/ProductDetailContext");
const styles = ({ spacing, palette, transitions, breakpoints }) => styles_1.createStyles({
    colorLabel: {
        '& > *:not(:first-child)': {
            marginLeft: spacing(2.375),
        },
        marginBottom: spacing(2.25),
    },
    variantWrapper: {
        flexWrap: 'nowrap',
        marginBottom: spacing(2.25),
    },
    variant: {
        [breakpoints.down('sm')]: {
            width: 100,
            height: 100,
        },
        width: 80,
        height: 80,
        display: 'inline-flex',
        flexShrink: 0,
        transition: transitions.create(['all']),
        position: 'relative',
        '&:after': {
            margin: 'auto 4px',
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: '50%',
            right: '50%',
            height: 1,
            background: palette.primary.main,
            transition: 'all .3s ease-out',
        },
        '&:hover': {
            '&:after': {
                left: 0,
                right: 0,
                height: 4,
            },
        },
    },
    activeVariant: {
        '&:after': {
            background: palette.primary.main,
            left: 0,
            right: 0,
            height: 4,
        },
    },
    image: {
        position: 'absolute',
    },
});
const ColorVariant = ({ _id, to, image, name, onVariantHover, activeVariantId, classes }) => {
    return (React.createElement(Grid_1.default, { item: true, className: classnames_1.default(classes.variant, { [classes.activeVariant]: activeVariantId === _id }), onMouseOver: () => onVariantHover(image.path), onMouseOut: () => onVariantHover(null), component: HBLink_1.default, to: to },
        React.createElement(HBImage_1.default, { src: image.path, alt: name, size: 150, className: classes.image })));
};
const ProductColorVariants = ({ classes, id, colors = [], variants = [] }) => {
    const labels = LabelContext_1.useLabelContext();
    const { variantCurrentSlide, handleVariantCurrentSlide } = ProductDetailContext_1.useProductDetailContext();
    const [activeColors, setActiveColors] = React.useState(colors);
    React.useEffect(() => {
        const activeProd = variants === null || variants === void 0 ? void 0 : variants.find(variant => { var _a; return ((_a = variant.image) === null || _a === void 0 ? void 0 : _a.path) === variantCurrentSlide; });
        setActiveColors(activeProd ? activeProd.colors : colors);
    }, [variantCurrentSlide]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Grid_1.default, { container: true, className: classes.colorLabel },
            React.createElement(HBTypography_1.default, { variant: "subtitle1" }, labels.COLOR),
            React.createElement(HBTypography_1.default, { variant: "body1" }, activeColors.map(item => item.name).join(' / '))),
        React.createElement(Grid_1.default, { container: true, spacing: 1, className: classes.variantWrapper },
            React.createElement(VirtualColorVariantList_1.default, { columns: variants, ItemComponent: ColorVariant, itemComponentProps: { activeVariantId: id, classes, onVariantHover: handleVariantCurrentSlide } }))));
};
exports.default = withStyles_1.default(styles)(ProductColorVariants);
