"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const makeStyles_1 = require("@material-ui/core/styles/makeStyles");
const styles_1 = require("@material-ui/core/styles");
const Grid_1 = require("@material-ui/core/Grid");
const ProductPrice_1 = require("@components/pages/product/ProductPrice");
const HBTypography_1 = require("@components/library/HBTypography");
const HBQuantityPicker_1 = require("@components/library/HBQuantityPicker");
const ProductAddtoCartButton_1 = require("@components/pages/product/detail/ProductAddtoCartButton");
const ProductWishlist_1 = require("@components/pages/product/detail/ProductWishlist");
const LabelContext_1 = require("@components/common/context/LabelContext");
const ProductDetailContext_1 = require("@components/common/context/ProductDetailContext");
const useMediaQuery_1 = require("@material-ui/core/useMediaQuery");
const useStyles = makeStyles_1.default(({ spacing, typography }) => styles_1.createStyles({
    flexGrow: {
        flexGrow: 1,
    },
    priceWrapper: {
        marginTop: spacing(2.75),
        marginBottom: spacing(3),
    },
    price: {
        ...typography.h4,
    },
    salePrice: {
        ...typography.h4,
    },
    normalPrice: {
        ...typography.h6,
    },
}));
const ProductPriceCartWrapper = () => {
    const classes = useStyles();
    const labels = LabelContext_1.useLabelContext();
    const { product, quantity, handleQuantity } = ProductDetailContext_1.useProductDetailContext();
    const outOfStock = product.availability === 'OutOfStock';
    const isTablet = useMediaQuery_1.default('(max-width:760px)');
    return (React.createElement("div", null,
        React.createElement(Grid_1.default, { container: true, alignItems: "flex-start", className: classes.priceWrapper, spacing: 1 },
            React.createElement(Grid_1.default, { item: true, className: classes.flexGrow },
                React.createElement(ProductPrice_1.default, { item: product, classes: {
                        price: classes.price,
                        salePrice: classes.salePrice,
                        normalPrice: classes.normalPrice,
                    } }),
                React.createElement(HBTypography_1.default, { variant: "caption" }, labels.VAT_INCLUDED)),
            React.createElement(Grid_1.default, { item: true },
                React.createElement(HBQuantityPicker_1.default, { quantity: quantity, onChange: handleQuantity, incrementLabel: labels.INCREMENT_QUANTITY, decrementLabel: labels.DECREMENT_QUANTITY }))),
        React.createElement(Grid_1.default, { container: true, alignItems: "center", spacing: isTablet ? 1 : 2 },
            React.createElement(Grid_1.default, { item: true, className: classes.flexGrow },
                React.createElement(ProductAddtoCartButton_1.default, { outOfStock: outOfStock })),
            React.createElement(Grid_1.default, { item: true },
                React.createElement(ProductWishlist_1.default, { item: product })))));
};
exports.default = ProductPriceCartWrapper;
