"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const createStyles_1 = require("@material-ui/core/styles/createStyles");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const Grid_1 = require("@material-ui/core/Grid");
const Skeleton_1 = require("@components/common/utils/Skeleton");
const styles = ({ breakpoints }) => createStyles_1.default({
    prodImageWrapper: {
        position: 'relative',
        overflow: 'hidden',
        height: 320,
        width: '100%',
        minHeight: '100%',
        [breakpoints.down('xs')]: {
            height: 200,
        },
        '& img': {
            width: '100%',
            height: '100%',
            maxWidth: '100%',
            transition: 'transform 0.5s ease',
        },
        '&:hover': {
            '& img': {
                transform: 'scale(1.15)',
            },
        },
    },
});
const LoadingProductCard = ({ classes }) => {
    return (React.createElement(Grid_1.default, { container: true, direction: "column" },
        React.createElement(Grid_1.default, { item: true },
            React.createElement(Skeleton_1.default, { className: classes.prodImageWrapper })),
        React.createElement(Grid_1.default, { item: true },
            React.createElement(Skeleton_1.default, { width: 70, mt: 1 })),
        React.createElement(Grid_1.default, { item: true },
            React.createElement(Skeleton_1.default, { width: 40, mt: 1 }))));
};
exports.default = withStyles_1.default(styles)(LoadingProductCard);
