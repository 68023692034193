"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const Grid_1 = require("@material-ui/core/Grid");
const styles_1 = require("@material-ui/core/styles");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const typography_1 = require("@components/library/theme/typography");
const HBTypography_1 = require("@components/library/HBTypography");
const HBLink_1 = require("@components/library/HBLink");
const HBButton_1 = require("@components/library/HBButton");
const FormElement_1 = require("@components/common/utils/Form/FormElement");
const FormButton_1 = require("@components/common/utils/Form/FormButton/FormButton");
const styles = ({ palette, spacing, breakpoints }) => styles_1.createStyles({
    root: {
        maxWidth: 392,
        '& .MuiGrid-item:not(:last-child)': {
            marginBottom: spacing(3),
            [breakpoints.down('sm')]: {
                marginBottom: spacing(2),
            },
        },
        '& .MuiFormControl-root': {
            margin: 0,
        },
    },
    divider: {
        paddingTop: spacing(6),
        '&:before': {
            content: '""',
            display: 'block',
            height: 1,
            background: styles_1.fade(palette.primary.main, 0.5),
            width: 'calc(100% - 20%)',
            maxWidth: 600,
            margin: '0 auto',
            position: 'absolute',
            left: 0,
            right: 0,
        },
    },
    button: {
        maxWidth: 392,
        margin: '48px auto 0',
    },
    helperLink: {
        paddingTop: spacing(1.8),
    },
    linkOption: {
        paddingTop: '36px',
        textAlign: 'center',
    },
    linkOptionButton: {
        height: '100%',
        letterSpacing: typography_1.pxToRem(1),
    },
});
const FormContext = ({ className, classes, error = false, errors, hideAsterisk = false, isTernaryButton = false, disabled = false, elements, button, helperLink, linkOption, onChange, helperText, divider = false, shippingForm = false, }) => {
    var _a;
    return (React.createElement(React.Fragment, null,
        React.createElement(Grid_1.default, { container: true, direction: "column", className: classnames_1.default(className, classes.root) }, elements === null || elements === void 0 ? void 0 :
            elements.map((element, index) => (React.createElement(Grid_1.default, { key: element.name + index.toString(), item: true },
                React.createElement(FormElement_1.default, Object.assign({}, element, { disabled: disabled, error: error, errors: errors, hideAsterisk: hideAsterisk, shippingform: shippingForm.toString() }))))),
            helperText && (React.createElement(Grid_1.default, { item: true },
                React.createElement(HBTypography_1.default, { variant: "caption", textTransformNone: true }, helperText)))),
        React.createElement(Grid_1.default, { className: classes.button },
            React.createElement(FormButton_1.default, Object.assign({}, button, { disabled: disabled, isTernary: isTernaryButton }))),
        helperLink && (helperLink === null || helperLink === void 0 ? void 0 : helperLink.to) && (React.createElement(Grid_1.default, { item: true, className: classes.helperLink },
            React.createElement(HBLink_1.default, { to: helperLink.to },
                React.createElement(HBTypography_1.default, { variant: "body2", align: "center", textTransformNone: true, opacity: 0.6 }, helperLink.title)))),
        divider && React.createElement(Grid_1.default, { item: true, className: classes.divider }),
        linkOption && (((_a = linkOption.link) === null || _a === void 0 ? void 0 : _a.to) || onChange) && (React.createElement(Grid_1.default, { item: true, className: classes.linkOption },
            React.createElement(HBTypography_1.default, { variant: "overline", align: "center", gutterBottom: true, component: "p" }, linkOption.title),
            onChange ? (React.createElement(HBButton_1.default, { classes: { root: classes.linkOptionButton }, onClick: onChange, color: "secondary", size: "small", variant: "text", disableHover: true, showUnderline: true, hoverArrow: true }, linkOption.link.title)) : (React.createElement(HBLink_1.default, { to: linkOption.link.to, showUnderline: true, hoverArrow: true, color: "secondary", size: "small" }, linkOption.link.title))))));
};
exports.default = withStyles_1.default(styles)(FormContext);
