"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_html_parser_1 = require("react-html-parser");
const createStyles_1 = require("@material-ui/core/styles/createStyles");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const Grid_1 = require("@material-ui/core/Grid");
const withLayout_1 = require("@components/common/layout/withLayout");
const FullWidthBackground_1 = require("@components/common/utils/FullWidthBackground");
const HBTypography_1 = require("@components/library/HBTypography");
const styles = ({ spacing, breakpoints }) => createStyles_1.default({
    root: {
        paddingBottom: spacing(10),
    },
    container: {
        maxWidth: 808,
        margin: '0 auto',
        [breakpoints.down('sm')]: {
            padding: spacing(2),
        },
    },
    title: {
        marginBottom: spacing(5),
    },
    contentInner: {
        wordBreak: 'normal',
    },
});
const PageStaticContent = ({ classes, page: { title, content } }) => (React.createElement(FullWidthBackground_1.default, { classes: { root: classes.root, container: classes.container } },
    React.createElement(Grid_1.default, { container: true, direction: "column", justify: "center" },
        React.createElement(Grid_1.default, { item: true, className: classes.title }, title && (React.createElement(HBTypography_1.default, { variant: "h4", align: "center" }, title))),
        React.createElement(Grid_1.default, { item: true, className: classes.contentInner }, react_html_parser_1.default(content)))));
exports.default = withLayout_1.default(withStyles_1.default(styles)(PageStaticContent), {
    paddingTop: 0,
    paddingBottom: 0,
});
