"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Box_1 = require("@material-ui/core/Box");
const Fade_1 = require("@material-ui/core/Fade");
const HBTabPanel = ({ children, value, index, pt, pb, ...other }) => {
    return (React.createElement("div", Object.assign({ role: "tabpanel", hidden: value !== index, id: `tabpanel-${index}`, "aria-labelledby": `tab-${index}` }, other), value === index && (React.createElement(Fade_1.default, { in: value === index },
        React.createElement(Box_1.default, { pt: pt === undefined ? 0 : pt, pb: pb === undefined ? 0 : pb },
            React.createElement(React.Fragment, null, children))))));
};
exports.default = HBTabPanel;
