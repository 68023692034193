"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const withTheme_1 = require("@material-ui/core/styles/withTheme");
const HBTypography_1 = require("@components/library/HBTypography");
const ProductCard_1 = require("@components/pages/product/ProductCard/ProductCard");
const Slider_1 = require("@components/common/utils/Slider/Slider");
const ProductBestseller = ({ title, products, classes, theme: { breakpoints } = {}, }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(HBTypography_1.default, { variant: "h6", marginBottom: 2, noWrap: true }, title),
        React.createElement(Slider_1.default, { items: products === null || products === void 0 ? void 0 : products.map((item, index) => (React.createElement(ProductCard_1.default, Object.assign({ imgSize: 200, key: index.toString() }, item, { classes: {
                    prodImageWrapper: classes.prodImageWrapper,
                    nameWrapper: classes.prodNameWrapper,
                    name: classes.productName,
                } })))), dots: false, prevIcon: "keyboard_arrow_left", nextIcon: "keyboard_arrow_right", color: "primary", slidesToScroll: Slider_1.getItemCount(products, 6), slidesToShow: Slider_1.getItemCount(products, 6), responsive: [
                {
                    breakpoint: breakpoints.values.xs,
                    settings: {
                        arrows: false,
                        slidesToScroll: Slider_1.getItemCount(products, 2),
                        slidesToShow: Slider_1.getItemCount(products, 2),
                    },
                },
                {
                    breakpoint: breakpoints.values.sm,
                    settings: {
                        arrows: false,
                        slidesToScroll: Slider_1.getItemCount(products, 6),
                        slidesToShow: Slider_1.getItemCount(products, 6),
                    },
                },
            ] })));
};
exports.default = withTheme_1.default(ProductBestseller);
