"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const makeStyles_1 = require("@material-ui/core/styles/makeStyles");
const HBContainer_1 = require("@components/library/HBContainer");
const Grid_1 = require("@material-ui/core/Grid");
const Hidden_1 = require("@material-ui/core/Hidden");
const Collapse_1 = require("@material-ui/core/Collapse");
const HBTypography_1 = require("@components/library/HBTypography");
const HBButton_1 = require("@components/library/HBButton");
const HBIcon_1 = require("@components/library/HBIcon");
const Colors_1 = require("@components/library/theme/Colors");
const FilterOption_1 = require("@components/pages/search/SearchFilter/FilterOption");
const SearchResetButton_1 = require("@components/pages/search/SearchFilter/SearchResetButton");
const SearchFilterLargeScreen_1 = require("@components/pages/search/SearchFilter/SearchFilterLargeScreen");
const SearchPageContext_1 = require("@components/common/context/SearchPageContext");
const SearchInputContext_1 = require("@components/common/context/SearchInputContext");
const useStyles = makeStyles_1.default(({ palette, spacing, transitions, breakpoints }) => ({
    root: {
        maxWidth: '100%',
        backgroundColor: Colors_1.default.backgroundGrey,
        transition: transitions.create(['padding'], { duration: 200, easing: 'ease-out' }),
        padding: spacing(6, 0, 4),
        [breakpoints.down('sm')]: {
            padding: spacing(4, 0),
        },
        ['@media (max-width:768px)']: {
            padding: spacing(3, 0),
        },
    },
    stickyRoot: {
        padding: spacing(4, 0),
        [breakpoints.down('sm')]: {
            padding: spacing(2, 0),
        },
    },
    filterWrapper: {
        width: '100%',
        position: 'relative',
    },
    filterOptionListInner: {
        overflowX: 'scroll',
        paddingBottom: spacing(2),
    },
    button: {
        '& .MuiButton-iconSizeMedium > *:first-child': {
            fontSize: 16,
        },
        '&:hover': {
            backgroundColor: 'transparent',
        },
        [breakpoints.down('sm')]: {
            fontSize: 14,
            '& .MuiButton-iconSizeMedium > *:first-child': {
                fontSize: 14,
            },
        },
    },
    filterButton: {
        padding: 0,
    },
    mobileRoot: {
        overflow: 'scroll',
    },
    filterContainer: {
        marginTop: spacing(-10),
        '& *::-webkit-scrollbar': {
            display: 'none',
        },
    },
    filter: {
        minWidth: '225px',
    },
    label: {
        marginRight: spacing(1),
    },
    rotateFilterIcon: {
        transform: 'rotate(90deg)',
    },
    selected: {
        opacity: 0.4,
        color: palette.primary.main,
        backgroundColor: '#d8d8d8',
    },
    filterOptionList: {
        opacity: 0,
        visibility: 'hidden',
        transform: 'translateY(-25px)',
        transition: transitions.create(['all'], { duration: 400, easing: 'ease-out' }),
    },
    filterOptionListOpen: {
        opacity: 1,
        visibility: 'visible',
        transform: 'translateY(0px)',
    },
    resetFilterButton: {
        borderTop: `1px solid ${palette.secondary.main}`,
        borderBottom: `1px solid ${palette.secondary.main}`,
    },
}));
const SearchFilter = () => {
    const { handleClearSearchTerms } = SearchInputContext_1.useSearchInputContext();
    const { loadedResults: { count, filters, sortOptions }, } = SearchPageContext_1.useSearchPageContext();
    const classes = useStyles();
    const [filterOpen, setFilterOpen] = React.useState(true);
    const [filterOptionOpen, setFilterOptionOpen] = React.useState(false);
    const [expanded, setExpanded] = React.useState('');
    const toggleFilter = () => setFilterOpen(!filterOpen);
    const handleActiveFilter = filter => () => {
        setExpanded((filter === null || filter === void 0 ? void 0 : filter.title) === expanded ? '' : (filter === null || filter === void 0 ? void 0 : filter.title) || '');
        setFilterOptionOpen((filter === null || filter === void 0 ? void 0 : filter.title) !== expanded);
    };
    return (React.createElement("div", { className: classes.root },
        React.createElement(HBContainer_1.default, null,
            React.createElement(Grid_1.default, { container: true, spacing: 2 },
                React.createElement(Grid_1.default, { item: true, className: classes.filterWrapper },
                    React.createElement(Grid_1.default, { container: true, justify: "space-between", alignItems: "center", wrap: "nowrap" },
                        React.createElement(Grid_1.default, { item: true },
                            React.createElement(Grid_1.default, { container: true, alignItems: "baseline" },
                                React.createElement(HBTypography_1.default, { variant: "h4", textTransformNone: true }, "Suche"),
                                React.createElement(HBTypography_1.default, { variant: "h6", opacity: 0.4, marginLeft: 2 }, count))),
                        React.createElement(Grid_1.default, { item: true },
                            React.createElement(Hidden_1.default, { mdUp: true, implementation: "css" }, filterOpen ? (React.createElement(HBIcon_1.default, { icon: "close", fontSize: "2xsmall", isButton: true, ButtonProps: { onClick: toggleFilter } })) : (React.createElement(Grid_1.default, { container: true, alignItems: "center" },
                                React.createElement(HBButton_1.default, { variant: "text", className: classnames_1.default(classes.button, classes.filterButton), startIcon: React.createElement(HBIcon_1.default, { icon: "filter", color: "secondary", fontSize: "2xsmall" }), onClick: toggleFilter }, "Filter"))))))),
                React.createElement(Grid_1.default, { item: true, className: classes.filterWrapper },
                    React.createElement(Hidden_1.default, { mdUp: true, implementation: "css" },
                        React.createElement(Collapse_1.default, { in: filterOpen },
                            React.createElement(FilterOption_1.default, Object.assign({}, sortOptions, { selected: sortOptions.title === expanded, onChange: handleActiveFilter(sortOptions), isSelect: true })),
                            filters.map((filter, idX) => (React.createElement(FilterOption_1.default, Object.assign({}, filter, { key: filter.title + idX.toString(), selected: filter.title === expanded, onChange: handleActiveFilter(filter) })))),
                            React.createElement(SearchResetButton_1.default, { border: true, onClick: handleClearSearchTerms })))),
                React.createElement(SearchFilterLargeScreen_1.default, { expanded: expanded, filterOptionOpen: filterOptionOpen, handleActiveFilter: handleActiveFilter })))));
};
exports.default = SearchFilter;
