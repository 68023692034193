"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const styles_1 = require("@material-ui/core/styles");
const HBTypography_1 = require("@components/library/HBTypography");
const styles = ({ palette, spacing, typography }) => styles_1.createStyles({
    root: {
        ...typography.subtitle2,
    },
    price: {
        ...typography.subtitle2,
    },
    salePrice: {
        maxWidth: '170px',
        maxHeight: '42px',
        backgroundColor: palette.error.main,
        color: palette.common.white,
        padding: spacing(0.3, 1),
        whiteSpace: 'nowrap',
    },
    normalPrice: {
        textDecoration: 'line-through',
        whiteSpace: 'nowrap',
    },
});
const ProductPrice = ({ classes, item: { isSale, price, listPrice } }) => {
    return !!isSale ? (React.createElement(HBTypography_1.default, { className: classes.root },
        React.createElement("span", { className: classes.salePrice }, price),
        " ",
        React.createElement("span", { className: classes.normalPrice }, listPrice))) : (React.createElement(HBTypography_1.default, { className: classes.price }, price));
};
exports.default = withStyles_1.default(styles)(ProductPrice);
