"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Svg_1 = require("./Svg");
const G_1 = require("./Svg/G");
const Return = ({ color = 'primary', ...rest }) => (React.createElement(Svg_1.default, Object.assign({}, rest),
    React.createElement(G_1.default, { transform: "translate(1 2)", color: color, strokeWidth: "2", fill: "none", fillRule: "evenodd", strokeLinecap: "round", strokeLinejoin: "round" },
        React.createElement("path", { d: "M0 7.003L3 11.503 6.5 7.503" }),
        React.createElement("path", { d: "M3.006 11.5A9.7 9.7 0 0 1 12.15.51a9.5 9.5 0 0 1 .7 18.987" }))));
exports.default = Return;
