"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FooterLegalBar = void 0;
const React = require("react");
const Grid_1 = require("@material-ui/core/Grid");
const HBTypography_1 = require("@components/library/HBTypography");
const HBLink_1 = require("@components/library/HBLink");
exports.FooterLegalBar = ({ classNameLinks, className, copyRight, legalLinks }) => (React.createElement(Grid_1.default, { className: className, container: true, direction: "row", justify: "space-between", alignItems: "center", spacing: 2 },
    React.createElement(Grid_1.default, { item: true },
        React.createElement(HBTypography_1.default, { variant: "body1", opacity: 0.6 }, copyRight)),
    React.createElement(Grid_1.default, { item: true, className: classNameLinks },
        React.createElement(Grid_1.default, { container: true, direction: "row", spacing: 2, wrap: "nowrap" }, legalLinks === null || legalLinks === void 0 ? void 0 : legalLinks.map(({ to, name }, index) => (React.createElement(Grid_1.default, { item: true, key: index.toString() },
            React.createElement(HBLink_1.default, { to: to, showHoverline: true },
                React.createElement(HBTypography_1.default, { variant: "body1" }, name)))))))));
exports.default = exports.FooterLegalBar;
