"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const icons_1 = require("../icons");
const IconButton_1 = require("@material-ui/core/IconButton");
const HBIcon = ({ icon, className, isButton, ButtonProps = {}, ...other }) => {
    const Component = icons_1.default[icon];
    if (!Component) {
        return null;
    }
    const renderComponent = () => isButton ? (React.createElement(IconButton_1.default, Object.assign({}, ButtonProps),
        React.createElement(Component, Object.assign({}, other, { className: className })))) : (React.createElement(Component, Object.assign({}, other, { className: className })));
    return renderComponent();
};
exports.default = HBIcon;
