"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAppContext = exports.ActionTypes = void 0;
const React = require("react");
const initSteps = {
    personalInfo: { active: true, completed: false, isEditing: false },
    addressInfo: { active: false, completed: false, isEditing: false },
    shippingInfo: { active: false, completed: false, isEditing: false },
    voucherInfo: { active: false, completed: false, isEditing: false },
    paymentInfo: { active: true, completed: false, isEditing: false },
};
const getCartTotalQuantity = (items = []) => items === null || items === void 0 ? void 0 : items.reduce((accumulator, current) => accumulator + current.amount, 0);
const getInitialState = (cart, wishlist = [], member) => {
    var _a, _b;
    const shipping = (_b = (_a = cart === null || cart === void 0 ? void 0 : cart.deliveryOptions) === null || _a === void 0 ? void 0 : _a.find(option => option.selected)) === null || _b === void 0 ? void 0 : _b.value;
    const voucher = cart === null || cart === void 0 ? void 0 : cart.voucher;
    const getSteps = () => {
        var _a;
        let state = initSteps;
        const contactInfo = member === null || member === void 0 ? void 0 : member.crmContact;
        if (contactInfo) {
            if (contactInfo.email) {
                state = {
                    ...state,
                    personalInfo: { active: false, completed: true, isEditing: false },
                    addressInfo: { active: true, completed: false, isEditing: true },
                };
            }
            if (!!((_a = cart === null || cart === void 0 ? void 0 : cart.addressShippingOptions) === null || _a === void 0 ? void 0 : _a.length)) {
                state = {
                    ...state,
                    addressInfo: { active: false, completed: true, isEditing: false },
                    shippingInfo: { active: true, completed: false, isEditing: true },
                };
            }
        }
        if (shipping) {
            state = {
                ...state,
                shippingInfo: { active: false, completed: true, isEditing: false },
                voucherInfo: { active: true, completed: false, isEditing: true },
            };
        }
        if (voucher === null || voucher === void 0 ? void 0 : voucher.id) {
            state = {
                ...state,
                voucherInfo: { active: false, completed: true, isEditing: false },
            };
        }
        return state;
    };
    const getCart = () => {
        return {
            ...cart,
            quantity: getCartTotalQuantity(cart === null || cart === void 0 ? void 0 : cart.items),
        };
    };
    return {
        steps: getSteps(),
        cart: getCart(),
        wishlist: wishlist,
        member,
    };
};
var ActionTypes;
(function (ActionTypes) {
    ActionTypes["ADD_TO_CART"] = "ADD_TO_CART";
    ActionTypes["REMOVE_FROM_CART"] = "REMOVE_FROM_CART";
    ActionTypes["ADD_TO_WISHLIST"] = "ADD_TO_WISHLIST";
    ActionTypes["REMOVE_FROM_WISHLIST"] = "REMOVE_FROM_WISHLIST";
    ActionTypes["ADD_PERSONAL_INFO"] = "ADD_PERSONAL_INFO";
    ActionTypes["EDITING_PERSONAL_INFO"] = "EDITING_PERSONAL_INFO";
    ActionTypes["ADD_ADDRESS_INFO"] = "ADD_ADDRESS_INFO";
    ActionTypes["EDIT_ADDRESS_INFO"] = "EDIT_ADDRESS_INFO";
    ActionTypes["EDIT_OR_ADD_NEW_ADDRESS_INFO"] = "EDIT_OR_ADD_NEW_ADDRESS_INFO";
    ActionTypes["EDITING_ADDRESS_INFO"] = "EDITING_ADDRESS_INFO";
    ActionTypes["ADD_SHIPPING_INFO"] = "ADD_SHIPPING_INFO";
    ActionTypes["EDITING_SHIPPING_INFO"] = "EDITING_SHIPPING_INFO";
    ActionTypes["ADD_VOUCHER_INFO"] = "ADD_VOUCHER_INFO";
    ActionTypes["REMOVE_VOUCHER_INFO"] = "REMOVE_VOUCHER_INFO";
    ActionTypes["ADD_PAYMENT_INFO"] = "ADD_PAYMENT_INFO";
    ActionTypes["UPDATE_MEMBER"] = "UPDATE_MEMBER";
    ActionTypes["UPDATE_MEMBER_ADDRESS"] = "UPDATE_MEMBER_ADDRESS";
    ActionTypes["DELETE_MEMBER_ADDRESS"] = "DELETE_MEMBER_ADDRESS";
})(ActionTypes = exports.ActionTypes || (exports.ActionTypes = {}));
function appReducer(state, { type, payload }) {
    const addToWishlist = (state, product) => ({
        ...state,
        wishlist: [...state === null || state === void 0 ? void 0 : state.wishlist, product],
    });
    const removeFromWishlist = (state, product) => {
        var _a;
        return ({
            ...state,
            wishlist: (_a = state === null || state === void 0 ? void 0 : state.wishlist) === null || _a === void 0 ? void 0 : _a.filter(item => item._id !== product._id),
        });
    };
    const updateCart = (state, data) => {
        var _a;
        return { ...state, cart: { ...data.cart, quantity: getCartTotalQuantity((_a = data === null || data === void 0 ? void 0 : data.cart) === null || _a === void 0 ? void 0 : _a.items) } };
    };
    const addPersonalInfo = (state, data) => {
        const member = data === null || data === void 0 ? void 0 : data.member;
        const contactInfo = member === null || member === void 0 ? void 0 : member.crmContact;
        let steps = state.steps;
        if (contactInfo === null || contactInfo === void 0 ? void 0 : contactInfo.email) {
            steps = {
                ...steps,
                personalInfo: { active: false, completed: true, isEditing: false },
                addressInfo: { active: true, completed: false, isEditing: true },
            };
        }
        if (!!(contactInfo === null || contactInfo === void 0 ? void 0 : contactInfo.crmAdresses.length)) {
            steps = {
                ...steps,
                personalInfo: { active: false, completed: true, isEditing: false },
                addressInfo: { active: true, completed: false, isEditing: true },
                shippingInfo: steps.shippingInfo.completed
                    ? { ...steps.shippingInfo }
                    : {
                        active: true,
                        completed: false,
                        isEditing: true,
                    },
            };
        }
        return { ...state, member, steps };
    };
    const addAddress = (state, data) => {
        var _a;
        const member = data === null || data === void 0 ? void 0 : data.member;
        const cart = data === null || data === void 0 ? void 0 : data.cart;
        const contactInfo = member === null || member === void 0 ? void 0 : member.crmContact;
        let steps = state.steps;
        if (contactInfo === null || contactInfo === void 0 ? void 0 : contactInfo.email) {
            steps = {
                ...steps,
                addressInfo: { active: true, completed: false, isEditing: false },
                shippingInfo: { active: true, completed: false, isEditing: true },
            };
        }
        if (!!((_a = contactInfo === null || contactInfo === void 0 ? void 0 : contactInfo.crmAdresses) === null || _a === void 0 ? void 0 : _a.length)) {
            steps = {
                ...steps,
                addressInfo: { active: false, completed: true, isEditing: false },
                shippingInfo: steps.shippingInfo.completed
                    ? { ...steps.shippingInfo }
                    : {
                        active: true,
                        completed: false,
                        isEditing: true,
                    },
            };
        }
        return { ...state, cart, member, steps };
    };
    const addShippingInfo = (state, data) => {
        const cart = data === null || data === void 0 ? void 0 : data.cart;
        let steps = state.steps;
        steps = {
            ...steps,
            shippingInfo: { active: false, completed: true, isEditing: false },
            voucherInfo: { active: true, completed: false, isEditing: true },
        };
        return { ...state, cart, steps };
    };
    const addVoucher = (state, data) => {
        const cart = data === null || data === void 0 ? void 0 : data.cart;
        const steps = { ...state.steps, voucherInfo: { active: true, completed: true, isEditing: false } };
        return { ...state, cart, steps };
    };
    const removeVoucher = (state, data) => {
        const steps = { ...state.steps, voucherInfo: { active: true, completed: false, isEditing: false } };
        return { ...state, cart: data === null || data === void 0 ? void 0 : data.cart, steps };
    };
    const addPayment = data => {
        var _a;
        (_a = window === null || window === void 0 ? void 0 : window.Datatrans) === null || _a === void 0 ? void 0 : _a.startPayment({ transactionId: data === null || data === void 0 ? void 0 : data.transactionId });
        return state;
    };
    const editAddressInfo = (state, data) => {
        const cart = data === null || data === void 0 ? void 0 : data.cart;
        const member = data === null || data === void 0 ? void 0 : data.member;
        const steps = { ...state.steps, addressInfo: { active: false, completed: true, isEditing: false } };
        return { ...state, cart, member, steps };
    };
    const isEditOrAddNewAddressInfo = (state, data) => {
        const cart = data === null || data === void 0 ? void 0 : data.cart;
        const member = data === null || data === void 0 ? void 0 : data.member;
        return { ...state, cart, member };
    };
    const editingAddressInfo = state => {
        const steps = { ...state.steps, addressInfo: { active: true, completed: true, isEditing: true } };
        return { ...state, steps };
    };
    const editingShippingInfo = state => {
        const steps = { ...state.steps, shippingInfo: { active: true, completed: true, isEditing: true } };
        return { ...state, steps };
    };
    const editingPersonalInfo = state => {
        const steps = { ...state.steps, personalInfo: { active: true, completed: true, isEditing: true } };
        return { ...state, steps };
    };
    const updateMember = (state, member) => {
        var _a, _b;
        if ((_b = (_a = member.crmContact) === null || _a === void 0 ? void 0 : _a.crmAdresses) === null || _b === void 0 ? void 0 : _b.length) {
            const steps = {
                ...state.steps,
                personalInfo: { active: false, completed: true, isEditing: false },
                addressInfo: { active: false, completed: true, isEditing: false },
                shippingInfo: { active: true, completed: false, isEditing: false },
            };
            return { ...state, member, steps };
        }
        else {
            const steps = {
                ...state.steps,
                personalInfo: { active: false, completed: true, isEditing: false },
                addressInfo: { active: true, completed: false, isEditing: false },
            };
            return { ...state, member, steps };
        }
    };
    const deleteMember = (state, id) => {
        var _a, _b;
        let addresses = ((_b = (_a = state === null || state === void 0 ? void 0 : state.member) === null || _a === void 0 ? void 0 : _a.crmContact) === null || _b === void 0 ? void 0 : _b.crmAdresses) || [];
        addresses = addresses.filter(address => address._id !== id);
        state.member.crmContact.crmAdresses = addresses;
        return state;
    };
    switch (type) {
        case ActionTypes.ADD_TO_CART:
            return updateCart(state, payload);
        case ActionTypes.REMOVE_FROM_CART:
            return updateCart(state, payload);
        case ActionTypes.ADD_TO_WISHLIST:
            return addToWishlist(state, payload);
        case ActionTypes.REMOVE_FROM_WISHLIST:
            return removeFromWishlist(state, payload);
        case ActionTypes.ADD_PERSONAL_INFO:
            return addPersonalInfo(state, payload);
        case ActionTypes.ADD_ADDRESS_INFO:
            return addAddress(state, payload);
        case ActionTypes.ADD_SHIPPING_INFO:
            return addShippingInfo(state, payload);
        case ActionTypes.ADD_VOUCHER_INFO:
            return addVoucher(state, payload);
        case ActionTypes.REMOVE_VOUCHER_INFO:
            return removeVoucher(state, payload);
        case ActionTypes.ADD_PAYMENT_INFO:
            return addPayment(payload);
        case ActionTypes.EDIT_ADDRESS_INFO:
            return editAddressInfo(state, payload);
        case ActionTypes.EDIT_OR_ADD_NEW_ADDRESS_INFO:
            return isEditOrAddNewAddressInfo(state, payload);
        case ActionTypes.EDITING_ADDRESS_INFO:
            return editingAddressInfo(state);
        case ActionTypes.EDITING_SHIPPING_INFO:
            return editingShippingInfo(state);
        case ActionTypes.EDITING_PERSONAL_INFO:
            return editingPersonalInfo(state);
        case ActionTypes.UPDATE_MEMBER:
            return updateMember(state, payload);
        case ActionTypes.UPDATE_MEMBER_ADDRESS:
            return updateMember(state, payload);
        case ActionTypes.DELETE_MEMBER_ADDRESS:
            return deleteMember(state, payload);
        default:
            return state;
    }
}
const AppContext = React.createContext({
    state: {},
    dispatch: () => null,
});
const ContextProvider = ({ children, cart, member, wishlist }) => {
    const [state, dispatch] = React.useReducer(appReducer, {}, () => getInitialState(cart, wishlist, member));
    return (React.createElement(AppContext.Provider, { value: {
            state,
            dispatch,
        } }, children));
};
exports.default = ContextProvider;
exports.useAppContext = () => React.useContext(AppContext);
