"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const useScrollTrigger_1 = require("@material-ui/core/useScrollTrigger");
const ElevationScroll = ({ className, stickyClassName, children }) => {
    const trigger = useScrollTrigger_1.default({
        disableHysteresis: true,
        threshold: 0,
    });
    const stickyProps = {};
    if (className) {
        stickyProps.className = className;
    }
    if (className && stickyClassName) {
        stickyProps.className = trigger ? classnames_1.default(className, stickyClassName) : className;
    }
    return React.cloneElement(children, stickyProps);
};
exports.default = ElevationScroll;
