"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const HBImage_1 = require("@components/library/HBImage");
const HBLink_1 = require("@components/library/HBLink");
const Grid_1 = require("@material-ui/core/Grid");
const ZoomOutImage_1 = require("@components/common/utils/ZoomOutImage");
const BrandItem = ({ className, itemClassName, to, logo, name, size }) => (React.createElement(Grid_1.default, { item: true, className: className },
    React.createElement(HBLink_1.default, { to: to, style: { width: '100%', height: '100%' } },
        React.createElement(ZoomOutImage_1.default, { dense: true, className: itemClassName },
            React.createElement(HBImage_1.default, { src: logo === null || logo === void 0 ? void 0 : logo.path, alt: name, size: size })))));
exports.default = BrandItem;
