"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Svg_1 = require("./Svg");
const G_1 = require("./Svg/G");
const Category = ({ color = 'primary', ...rest }) => (React.createElement(Svg_1.default, Object.assign({}, rest),
    React.createElement(G_1.default, { transform: "translate(1 1)", color: color, strokeWidth: "2", fill: "none", fillRule: "evenodd", strokeLinecap: "round", strokeLinejoin: "round" },
        React.createElement("rect", { width: "9", height: "9", rx: "1" }),
        React.createElement("path", { d: "M5.24 21.571c-.216.27-.56.43-.927.43s-.711-.16-.927-.43L.22 17.613a.964.964 0 0 1 0-1.226l3.166-3.958c.216-.27.56-.429.927-.429s.711.16.927.43l3.166 3.957a.964.964 0 0 1 0 1.226L5.24 21.57z" }),
        React.createElement("circle", { cx: "17.5", cy: "4.5", r: "4.5" }),
        React.createElement("path", { d: "M17.322 12.49a.95.95 0 0 0-.824-.49.95.95 0 0 0-.824.49l-4.54 8.006c-.176.308-.179.69-.008 1a.95.95 0 0 0 .83.504h9.088a.95.95 0 0 0 .83-.503c.17-.31.168-.693-.007-1l-4.545-8.007z" }))));
exports.default = Category;
