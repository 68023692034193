"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_html_parser_1 = require("react-html-parser");
const Grid_1 = require("@material-ui/core/Grid");
const HBTypography_1 = require("@components/library/HBTypography");
const LabelContext_1 = require("@components/common/context/LabelContext");
const HBIcon_1 = require("@components/library/HBIcon");
const createStyles_1 = require("@material-ui/core/styles/createStyles");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const styles = ({ spacing }) => createStyles_1.default({
    features: {
        marginBottom: spacing(3),
    },
    feature: {
        '& svg': {
            marginLeft: '-5px',
            marginRight: spacing(1),
        },
    },
});
const getFeatures = (features, className) => { var _a; return (_a = features === null || features === void 0 ? void 0 : features.split('*')) === null || _a === void 0 ? void 0 : _a.map(feature => (React.createElement(Grid_1.default, { container: true, wrap: "nowrap", key: feature, className: className },
    React.createElement(HBIcon_1.default, { icon: "bulletpoint", color: "ternary" }),
    React.createElement(HBTypography_1.default, null, feature)))); };
const ProductDescription = ({ classes, labels, description, features }) => (React.createElement(Grid_1.default, { item: true, container: true, direction: "column", spacing: 2 },
    React.createElement(Grid_1.default, { item: true },
        React.createElement(HBTypography_1.default, { variant: "h6" }, labels.PRODUCT_DETAILS)),
    React.createElement(Grid_1.default, { item: true, container: true },
        features && React.createElement(Grid_1.default, { className: classes.features }, getFeatures(features, classes.feature)),
        description && (React.createElement(HBTypography_1.default, { variant: "body1", textTransformNone: true }, react_html_parser_1.default(description))))));
exports.default = LabelContext_1.withLabel(withStyles_1.default(styles)(ProductDescription));
