"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const IconButton_1 = require("@material-ui/core/IconButton");
const HBTypography_1 = require("../HBTypography");
const HBIconButtonStyles_1 = require("./HBIconButtonStyles");
const HBIconButton = ({ classes, className, label, children, style, buttonRef, underline = true, ...rest }) => (React.createElement(IconButton_1.default, Object.assign({ ref: buttonRef, style: style, className: classnames_1.default(className, classes.icon, { [classes.hoverline]: underline }) }, rest),
    children,
    React.createElement(HBTypography_1.default, { component: "span", variant: "caption", noWrap: true, className: classes.caption }, label)));
exports.default = withStyles_1.default(HBIconButtonStyles_1.default)(HBIconButton);
