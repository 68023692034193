"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const styles_1 = require("@material-ui/core/styles");
const HBTypography_1 = require("@components/library/HBTypography");
const HBLink_1 = require("@components/library/HBLink");
const styles = ({ spacing }) => styles_1.createStyles({
    root: {
        marginBottom: spacing(1.5),
    },
});
const ProductBrand = ({ className, classes, name, to }) => (React.createElement(HBLink_1.default, { to: to, className: classnames_1.default(className, classes.root), showUnderline: true, hoverArrow: true },
    React.createElement(HBTypography_1.default, { variant: "overline", align: "center", component: "p", noWrap: true, fontNormal: true }, name)));
exports.default = withStyles_1.default(styles)(ProductBrand);
