"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const Grid_1 = require("@material-ui/core/Grid");
const Collapse_1 = require("@material-ui/core/Collapse");
const ButtonBase_1 = require("@material-ui/core/ButtonBase");
const HBIcon_1 = require("../HBIcon");
const HBTypography_1 = require("../HBTypography");
const HBAccordionStyles_1 = require("./HBAccordionStyles");
const HBAccordion = ({ classes, label, index, selected, onChange, hideBorder, denseHeader, children, icon, useButtonToSelect, }) => (React.createElement(Grid_1.default, { item: true, className: classnames_1.default(classes.accordionContainer, {
        [classes.accordionContainerSelected]: !hideBorder && selected,
        [classes.accordionContainerBorder]: !hideBorder,
    }) },
    React.createElement(ButtonBase_1.default, { id: index === undefined ? label : index.toString(), value: label, onClick: onChange, className: classnames_1.default(classes.accordionHeader, { [classes.denseHeader]: denseHeader }) },
        typeof label === 'string' ? (React.createElement(HBTypography_1.default, { variant: "subtitle1", align: "left", className: classes.accordionLabel, textTransformNone: true }, label)) : (label),
        React.createElement(HBIcon_1.default, { icon: icon || 'keyboard_arrow_down', color: selected ? 'secondary' : 'primary', fontSize: "2xsmall", isButton: true, ButtonProps: {
                component: 'div',
                className: classnames_1.default(classes.icon, { [classes.selectedIcon]: selected }),
                onClick: useButtonToSelect,
            } })),
    React.createElement(Collapse_1.default, Object.assign({ in: selected }, (selected ? { timeout: 300 } : {})),
        React.createElement(Grid_1.default, { className: classes.accordionContent },
            React.createElement(Grid_1.default, { container: true, spacing: 1, wrap: "nowrap", className: classes.accordionContentInner }, children)))));
exports.default = withStyles_1.default(HBAccordionStyles_1.default)(HBAccordion);
