"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const react_html_parser_1 = require("react-html-parser");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const Grid_1 = require("@material-ui/core/Grid");
const HBImage_1 = require("@components/library/HBImage");
const BrandCampaignCardStyles_1 = require("./BrandCampaignCardStyles");
const ZoomOutImage_1 = require("@components/common/utils/ZoomOutImage");
const BrandCampaignCard = ({ classes, title, content, media, layout }) => {
    const isVertical = layout === 'vertical-top' || layout === 'vertical-bottom';
    return (React.createElement(Grid_1.default, { container: true, justify: "space-between", className: classnames_1.default(classes.root, {
            [classes.vertical]: isVertical,
            [classes.horizontalLeft]: layout === 'horizontal-left',
            [classes.horizontalRight]: layout === 'horizontal-right',
            [classes.verticalTop]: layout === 'vertical-top',
            [classes.verticalBottom]: layout === 'vertical-bottom',
        }) },
        (media === null || media === void 0 ? void 0 : media.path) && (React.createElement(Grid_1.default, { item: true, xs: 12, md: isVertical ? 12 : 6 },
            React.createElement(ZoomOutImage_1.default, null,
                React.createElement(HBImage_1.default, { src: media.path, alt: title, className: classes.img })))),
        React.createElement(Grid_1.default, { item: true, xs: 12, md: isVertical ? 12 : 6 },
            React.createElement(Grid_1.default, { className: classnames_1.default(classes.content, { [classes.contentVertical]: isVertical }) }, content && react_html_parser_1.default(content)))));
};
exports.default = withStyles_1.default(BrandCampaignCardStyles_1.default)(BrandCampaignCard);
