"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSearchPageContext = void 0;
const React = require("react");
const makeStyles_1 = require("@material-ui/core/styles/makeStyles");
const Backdrop_1 = require("@material-ui/core/Backdrop");
const ApiContext_1 = require("@components/common/context/ApiContext");
const SearchUtils_1 = require("@components/common/utils/SearchUtils");
const LoadingLogo_1 = require("@components/library/icons/LoadingLogo");
const useLoadMoreResults_1 = require("../VirtualGridRenderer/useLoadMoreResults");
const react_router_dom_1 = require("react-router-dom");
const SearchPageContext = React.createContext({});
const useStyles = makeStyles_1.default(theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        backgroundColor: 'rgb(255, 255, 255, 0.6)',
    },
}));
const SearchPageContextProvider = ({ api, children, products, sortBy, count, filters, sortOptions }) => {
    const classes = useStyles();
    const { loadedResults, setLoadedResults, loadMoreProducts, endReached, isLoading } = useLoadMoreResults_1.useLoadMoreResults(api, {
        products,
        count,
        filters,
        sortOptions,
    });
    const [currentPageNumber, setCurrentPageNumber] = React.useState(0);
    const [showBackdrop, setShowBackdrop] = React.useState(false);
    const [sortOptionId, setSortOptionId] = React.useState(sortBy);
    const history = react_router_dom_1.useHistory();
    React.useEffect(() => {
        setLoadedResults(() => ({ products, count, filters, sortOptions }));
        endReached.current = false;
        setCurrentPageNumber(0);
    }, [products]);
    React.useEffect(() => {
        setShowBackdrop(false);
        if (currentPageNumber === 0)
            return;
        loadMore();
    }, [currentPageNumber]);
    const handleSortOption = id => {
        setSortOptionId(id);
        SearchUtils_1.changeSortOption(id, history);
    };
    const loadMore = () => {
        const url = SearchUtils_1.getQueryParams(history).get('q');
        loadMoreProducts({ query: url, page: currentPageNumber });
    };
    return (React.createElement(SearchPageContext.Provider, { value: {
            loadedResults,
            handleSortOption,
            isLoading,
            loadMore,
            endReached: endReached === null || endReached === void 0 ? void 0 : endReached.current,
            setCurrentPageNumber,
            currentPageNumber,
            sortOptionId,
        } },
        React.createElement(Backdrop_1.default, { open: isLoading && showBackdrop, className: classes.backdrop },
            React.createElement(LoadingLogo_1.default, null)),
        children));
};
exports.default = ApiContext_1.withApi(SearchPageContextProvider);
exports.useSearchPageContext = () => React.useContext(SearchPageContext);
