"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LazyImage = void 0;
const React = require("react");
const classnames_1 = require("classnames");
const makeStyles_1 = require("@material-ui/core/styles/makeStyles");
const useStyles = makeStyles_1.default(({ palette }) => ({
    root: {},
}));
exports.LazyImage = ({ className, src, alt, placeholder, style }) => {
    const classes = useStyles();
    const [imageSrc, setImageSrc] = React.useState(placeholder);
    const [imageRef, setImageRef] = React.useState();
    const onLoad = event => {
        event.target.classList.add('loaded');
    };
    const onError = event => {
        event.target.classList.add('has-error');
    };
    React.useEffect(() => {
        let observer;
        let didCancel = false;
        if (imageRef && imageSrc !== src) {
            if (IntersectionObserver) {
                observer = new IntersectionObserver(entries => {
                    entries.forEach(entry => {
                        if (!didCancel && (entry.intersectionRatio > 0 || entry.isIntersecting)) {
                            setImageSrc(src);
                            observer.unobserve(imageRef);
                        }
                    });
                }, {
                    threshold: 0.01,
                    rootMargin: '75%',
                });
                observer.observe(imageRef);
            }
            else {
                setImageSrc(src);
            }
        }
        return () => {
            didCancel = true;
            if (observer && observer.unobserve) {
                observer.unobserve(imageRef);
            }
        };
    }, [src, imageSrc, imageRef]);
    return (React.createElement("picture", null,
        React.createElement("img", { className: classnames_1.default(classes.root, className), ref: setImageRef, src: imageSrc, alt: alt, onLoad: onLoad, onError: onError, style: style })));
};
exports.default = exports.LazyImage;
