"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@material-ui/core");
const createOverrides = ({ palette, transitions }) => {
    return {
        MuiLink: {
            underlineHover: {
                '&:hover': {
                    textDecoration: 'none',
                },
            },
        },
        MuiButton: {
            text: {
                padding: 0,
            },
        },
        MuiIconButton: {
            root: {
                '&:hover': {
                    backgroundColor: 'transparent',
                    '& svg': {
                        opacity: 1,
                    },
                },
                '& svg': {
                    transition: transitions.create(['all']),
                    opacity: 0.8,
                },
            },
        },
        MuiFormControlLabel: {
            root: {
                alignItems: 'baseline',
                '& .MuiButtonBase-root': {
                    paddingTop: 0,
                },
                '& span + span': {
                    alignSelf: 'flex-start',
                    marginTop: -1,
                },
            },
        },
        MuiFormHelperText: {
            root: {
                textTransform: 'none',
            },
        },
        MuiInputBase: {
            input: {
                padding: '6px 0 8px',
            },
        },
        MuiInput: {
            underline: {
                '&:hover:not(.Mui-disabled):before': {
                    borderBottom: `1px solid ${core_1.fade(palette.primary.main, 0.42)}`,
                },
                '&:after': {},
            },
        },
        MuiTab: {
            root: {
                padding: 0,
                minWidth: 0,
                ['@media (min-width: 600px)']: {
                    minWidth: 0,
                },
            },
        },
        MuiTabs: {
            indicator: {
                height: 3,
            },
        },
        MuiSnackbar: {
            root: {
                '& > div': {
                    borderRadius: 0,
                },
            },
        },
    };
};
exports.default = createOverrides;
