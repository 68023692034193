"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_html_parser_1 = require("react-html-parser");
const styles_1 = require("@material-ui/core/styles");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const withLayout_1 = require("@components/common/layout/withLayout");
const HBContainer_1 = require("@components/library/HBContainer");
const HBTypography_1 = require("@components/library/HBTypography");
const Grid_1 = require("@material-ui/core/Grid");
const HBButton_1 = require("@components/library/HBButton");
const TeaserItem_1 = require("@components/pages/home/TeaserItem");
const FullWidthBackground_1 = require("@components/common/utils/FullWidthBackground");
const styles = ({ spacing, breakpoints, typography }) => styles_1.createStyles({
    containerSpacing: {
        paddingTop: spacing(6),
        paddingBottom: spacing(6),
        [breakpoints.up('sm')]: {
            paddingTop: spacing(8),
            paddingBottom: spacing(8),
        },
    },
    container: {
        maxWidth: 808,
        margin: '0 auto',
        textAlign: 'center',
    },
    button: {
        marginTop: spacing(2),
    },
    tile: {
        height: 305,
        [breakpoints.between('xs', 'sm')]: {
            height: 250,
        },
    },
    tileTitle: {
        ...typography.h4,
    },
    tileSubTitle: {
        ...typography.body1,
    },
});
const PageMessage = ({ classes, page: { title, content, cta, teaser } }) => {
    var _a;
    return (React.createElement(React.Fragment, null,
        React.createElement(FullWidthBackground_1.default, { classes: { root: classes.containerSpacing, container: classes.container } },
            React.createElement(Grid_1.default, { container: true, spacing: 3, justify: "center" },
                title && (React.createElement(Grid_1.default, { item: true },
                    React.createElement(HBTypography_1.default, { variant: "h3" }, title))),
                content && (React.createElement(Grid_1.default, { item: true },
                    React.createElement(HBTypography_1.default, { variant: "body1" }, react_html_parser_1.default(content)))),
                cta && (React.createElement(Grid_1.default, { item: true },
                    React.createElement(HBButton_1.default, { label: cta.label, fullWidth: true, variant: "contained", color: "primary", className: classes.button }))))),
        teaser && (React.createElement(HBContainer_1.default, { className: classes.containerSpacing },
            React.createElement(Grid_1.default, { container: true, spacing: 4, direction: "column" },
                React.createElement(Grid_1.default, { item: true },
                    React.createElement(HBTypography_1.default, { variant: "h4", align: "center" }, teaser === null || teaser === void 0 ? void 0 : teaser.title)),
                React.createElement(Grid_1.default, { item: true },
                    React.createElement(Grid_1.default, { container: true, spacing: 3 }, (_a = teaser === null || teaser === void 0 ? void 0 : teaser.tiles) === null || _a === void 0 ? void 0 : _a.map((tile, idX) => (React.createElement(Grid_1.default, { key: (tile === null || tile === void 0 ? void 0 : tile.title) || idX.toString(), item: true, xs: 12, sm: 6, md: 4 },
                        React.createElement(TeaserItem_1.default, Object.assign({}, tile, { classes: {
                                root: classes.tile,
                                title: classes.tileTitle,
                                subTitle: classes.tileSubTitle,
                            } }))))))))))));
};
exports.default = withLayout_1.default(withStyles_1.default(styles)(PageMessage), { paddingTop: 0, paddingBottom: 0 });
