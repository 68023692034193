"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Grid_1 = require("@material-ui/core/Grid");
const HBTypography_1 = require("@components/library/HBTypography");
const Divider_1 = require("@material-ui/core/Divider");
const LabelContext_1 = require("@components/common/context/LabelContext");
const CartSummary = ({ showSubtotal, totalTextlabel, subtotal, total, addOns, dividerClassName, largeText, spacing, }) => {
    const labels = LabelContext_1.useLabelContext();
    const summaryLabelText = largeText ? 'body1' : 'body2';
    const summaryValueText = largeText ? 'body1' : 'h6';
    const totalValueText = largeText ? 'h5' : 'h6';
    return (React.createElement(Grid_1.default, { item: true, container: true, direction: "column", spacing: spacing ? 1 : 0 },
        showSubtotal && (React.createElement(Grid_1.default, { item: true, container: true, justify: "space-between" },
            React.createElement(Grid_1.default, { item: true },
                React.createElement(HBTypography_1.default, { variant: summaryLabelText, textTransformNone: true }, labels.SUB_TOTAL)),
            React.createElement(Grid_1.default, { item: true },
                React.createElement(HBTypography_1.default, { variant: summaryValueText }, subtotal)))), addOns === null || addOns === void 0 ? void 0 :
        addOns.map(addon => (React.createElement(Grid_1.default, { key: addon.title, item: true, container: true, justify: "space-between" },
            React.createElement(Grid_1.default, { item: true },
                React.createElement(HBTypography_1.default, { variant: summaryLabelText, textTransformNone: true }, addon.title)),
            React.createElement(Grid_1.default, { item: true },
                React.createElement(HBTypography_1.default, { variant: summaryValueText }, addon.value))))),
        showSubtotal && (React.createElement(Grid_1.default, { item: true },
            React.createElement(Divider_1.default, { className: dividerClassName }))),
        React.createElement(Grid_1.default, { item: true, container: true, justify: "space-between" },
            React.createElement(Grid_1.default, { item: true },
                React.createElement(HBTypography_1.default, { variant: "h6" }, totalTextlabel)),
            React.createElement(Grid_1.default, { item: true },
                React.createElement(HBTypography_1.default, { variant: totalValueText }, total)))));
};
exports.default = CartSummary;
