"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const Fade_1 = require("@material-ui/core/Fade");
const ApiConnect_1 = require("../client/api/ApiConnect");
const PageTemplates_1 = require("../server/PageTemplates");
const PageHome_1 = require("@components/templates/PageHome");
const PageBrandList_1 = require("@components/templates/PageBrandList");
const PageBrandDetail_1 = require("@components/templates/PageBrandDetail");
const PageTemplateError_1 = require("@components/templates/PageTemplateError");
const PageFaq_1 = require("@components/templates/PageFaq");
const PageMyaccount_1 = require("@components/templates/PageMyaccount");
const PageProductDetail_1 = require("@components/templates/PageProductDetail");
const PageWishlist_1 = require("@components/templates/PageWishlist");
const PageProductList_1 = require("@components/templates/PageProductList");
const PageStaticContent_1 = require("@components/templates/PageStaticContent");
const PageMessage_1 = require("@components/templates/PageMessage");
const PageCheckout_1 = require("@components/templates/PageCheckout");
const PageContact_1 = require("@components/templates/PageContact");
const PageProfile_1 = require("@components/templates/PageProfile");
const PageSearch_1 = require("@components/templates/PageSearch");
const PageNotFound_1 = require("@components/templates/PageNotFound");
const PageRequestPassword_1 = require("@components/templates/PageRequestPassword");
const PageSetPassword_1 = require("@components/templates/PageSetPassword");
const LinearProgress_1 = require("@material-ui/core/LinearProgress");
const globalStyles_1 = require("@components/library/theme/globalStyles");
const SeoUtils_1 = require("@components/common/layout/SeoUtils");
const CookieBotConsent_1 = require("@components/common/CookieBotConsent");
function App(props) {
    var _a;
    const pageFactory = (state) => {
        const { component } = state;
        switch (component) {
            case PageTemplates_1.PageTemplates.PT_HOME:
                return React.createElement(PageHome_1.default, Object.assign({}, state));
            case PageTemplates_1.PageTemplates.PT_BRAND_LIST:
                return React.createElement(PageBrandList_1.default, Object.assign({}, state));
            case PageTemplates_1.PageTemplates.PT_BRAND_DETAIL:
                return React.createElement(PageBrandDetail_1.default, Object.assign({}, state));
            case PageTemplates_1.PageTemplates.PT_FAQ:
                return React.createElement(PageFaq_1.default, Object.assign({}, state));
            case PageTemplates_1.PageTemplates.PT_PROFILE_SIGNUP:
                return React.createElement(PageMyaccount_1.default, Object.assign({}, state));
            case PageTemplates_1.PageTemplates.PT_PRODUCT_DETAIL:
                return React.createElement(PageProductDetail_1.default, Object.assign({}, state));
            case PageTemplates_1.PageTemplates.PT_WISHLIST:
                return React.createElement(PageWishlist_1.default, Object.assign({}, state));
            case PageTemplates_1.PageTemplates.PT_PRODUCT_LIST:
                return React.createElement(PageProductList_1.default, Object.assign({}, state));
            case PageTemplates_1.PageTemplates.PT_PLAIN_CONTENT:
                return React.createElement(PageStaticContent_1.default, Object.assign({}, state));
            case PageTemplates_1.PageTemplates.PT_MESSAGE:
                return React.createElement(PageMessage_1.default, Object.assign({}, state));
            case PageTemplates_1.PageTemplates.PT_CHECKOUT:
                return React.createElement(PageCheckout_1.default, Object.assign({}, state));
            case PageTemplates_1.PageTemplates.PT_CONTACT:
                return React.createElement(PageContact_1.default, Object.assign({}, state));
            case PageTemplates_1.PageTemplates.PT_PROFILE_ACCOUNT:
                return React.createElement(PageProfile_1.default, Object.assign({}, state));
            case PageTemplates_1.PageTemplates.PT_SEARCH_RESULT:
                return React.createElement(PageSearch_1.default, Object.assign({}, state));
            case PageTemplates_1.PageTemplates.PT_NOT_FOUD:
                return React.createElement(PageNotFound_1.default, Object.assign({}, state));
            case PageTemplates_1.PageTemplates.PT_REQUEST_PASSWORD:
                return React.createElement(PageRequestPassword_1.default, Object.assign({}, state));
            case PageTemplates_1.PageTemplates.PT_SET_PASSWORD:
                return React.createElement(PageSetPassword_1.default, Object.assign({}, state));
            default:
                return React.createElement(PageTemplateError_1.default, Object.assign({}, state));
        }
    };
    const [state, setState] = React.useState(props);
    const [routeKey, setRouteKey] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const isFirstRun = React.useRef(true);
    React.useEffect(() => {
        const getFreshNHotData = async () => {
            const data = await ApiConnect_1.default.getRoute(props.route.location);
            setState({ ...state, ...data });
            if (isFirstRun.current) {
                isFirstRun.current = false;
            }
            else {
                setRouteKey(routeKey + 1);
            }
            window.scrollTo(0, 0);
        };
        setLoading(true);
        console.time('load');
        getFreshNHotData()
            .then(e => {
            setLoading(false);
            console.timeEnd('load');
        })
            .catch(console.error);
    }, [props.route.location]);
    return (React.createElement(React.Fragment, null,
        SeoUtils_1.renderOrganisationMicroData((_a = state === null || state === void 0 ? void 0 : state.settings) === null || _a === void 0 ? void 0 : _a.seo),
        SeoUtils_1.renderMetaTags(state),
        loading && React.createElement(LinearProgress_1.default, { className: "progress", color: "secondary" }),
        React.createElement(Fade_1.default, { in: true }, pageFactory({ ...state, routeKey })),
        React.createElement(CookieBotConsent_1.default, { domainGroupId: "34b43965-1732-4ef8-b2ce-1c993c74b1f8" })));
}
exports.default = withStyles_1.default(globalStyles_1.default)(App);
