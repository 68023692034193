"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const makeStyles_1 = require("@material-ui/core/styles/makeStyles");
const useMediaQuery_1 = require("@material-ui/core/useMediaQuery");
const useTheme_1 = require("@material-ui/core/styles/useTheme");
const Dialog_1 = require("@material-ui/core/Dialog");
const Grow_1 = require("@material-ui/core/Grow");
const HBIcon_1 = require("../HBIcon");
const useStyles = makeStyles_1.default(() => ({
    root: {
        borderRadius: 0,
        justifyContent: 'center',
        maxWidth: '100%',
    },
    close: {
        position: 'absolute',
        right: 20,
        top: 20,
        zIndex: 9,
    },
    rotateIcon: {
        '&:hover': {
            '& svg': {
                transform: 'rotate(90deg)',
            },
        },
    },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
    return React.createElement(Grow_1.default, Object.assign({ ref: ref }, props));
});
const HBDialog = ({ open, onClose, icon, IconClass, IconWrapperClass, forceFullScreen, TransitionComponent, children, disabled, disableBackdropClick, disableEscapeKeyDown, transitionDuration, hideIcon, ...rest }) => {
    const classes = useStyles();
    const theme = useTheme_1.default();
    const defaultFullScreen = useMediaQuery_1.default(theme.breakpoints.down('xs'));
    return (React.createElement(Dialog_1.default, Object.assign({ classes: { paper: classes.root }, fullScreen: forceFullScreen || defaultFullScreen, open: open, onClose: onClose, TransitionComponent: TransitionComponent || Transition, disableBackdropClick: disabled || disableBackdropClick, disableEscapeKeyDown: disabled || disableEscapeKeyDown, transitionDuration: transitionDuration || 400 }, rest),
        onClose && !hideIcon && (React.createElement(HBIcon_1.default, { icon: icon || 'close', color: "primary", fontSize: "xsmall", className: IconClass, isButton: true, ButtonProps: {
                onClick: onClose,
                'aria-label': 'close',
                className: classnames_1.default(IconWrapperClass, classes.close, { [classes.rotateIcon]: !icon }),
                disabled: disabled,
            } })),
        children));
};
exports.default = HBDialog;
