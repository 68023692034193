"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
const styles = ({ palette, spacing, breakpoints, typography }) => styles_1.createStyles({
    root: {
        display: 'block',
        height: 460,
        [breakpoints.down('sm')]: {
            height: 320,
        },
    },
    wrapper: {
        position: 'relative',
        width: '100%',
        height: '100%',
    },
    content: {
        position: 'absolute',
        backgroundImage: `linear-gradient(to bottom, rgba(26, 26, 26, 0), ${palette.primary.main})`,
        zIndex: 1,
        color: palette.common.white,
        bottom: 0,
        width: '100%',
    },
    contentInner: {
        paddingLeft: spacing(3.75),
        paddingRight: spacing(3.75),
        paddingBottom: spacing(3),
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        ...typography.h2,
        ['@media (min-width:600px) and (max-width:900px)']: {
            ...typography.h5,
        },
    },
    subTitle: {},
    img: {
        objectFit: 'cover',
        position: 'relative',
    },
});
exports.default = styles;
