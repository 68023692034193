"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const withLayout_1 = require("@components/common/layout/withLayout");
const HBContainer_1 = require("@components/library/HBContainer");
const Grid_1 = require("@material-ui/core/Grid");
const NotFound_1 = require("@components/library/icons/NotFound");
const styles_1 = require("@material-ui/core/styles");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const HBTypography_1 = require("@components/library/HBTypography");
const HBIcon_1 = require("@components/library/HBIcon");
const Colors_1 = require("@components/library/theme/Colors");
const HBLink_1 = require("@components/library/HBLink");
const LabelContext_1 = require("@components/common/context/LabelContext");
const styles = ({ palette, spacing, breakpoints, typography }) => styles_1.createStyles({
    iconWrapper: {
        '& svg': {
            maxWidth: '100%',
            height: '100%',
        },
    },
    content: {
        padding: '0 0 104px 0',
        '& h3': {
            textAlign: 'center',
            ...typography.h6,
        },
        '& p': {
            textAlign: 'center',
        },
    },
    containerSpacing: {
        backgroundColor: Colors_1.default.backgroundGrey,
        paddingTop: spacing(6),
        paddingBottom: spacing(6),
        [breakpoints.up('sm')]: {
            paddingTop: spacing(8),
            paddingBottom: spacing(8),
        },
    },
    item: {
        backgroundColor: palette.common.white,
        padding: spacing(3.85),
    },
    icon: {
        display: 'flex',
        flexShrink: 0,
        width: '40px',
        height: '40px',
        marginBottom: spacing(2),
        [breakpoints.down('sm')]: {
            width: spacing(5),
            height: spacing(5),
        },
    },
});
const PageNotFound = ({ classes, header: { menu = [] } = {} }) => {
    const labels = LabelContext_1.useLabelContext();
    const [{ flyout: { categoryItems } = {} } = {}] = React.useState(() => menu.find(m => m.type === 'category'));
    return (React.createElement(Grid_1.default, null,
        React.createElement(Grid_1.default, { item: true, className: classes.content },
            React.createElement(HBContainer_1.default, { maxWidth: "xs" },
                React.createElement(Grid_1.default, { direction: "column", item: true, container: true, justify: "center", alignItems: "center" },
                    React.createElement(Grid_1.default, { item: true, className: classes.iconWrapper },
                        React.createElement(NotFound_1.default, null)),
                    React.createElement(Grid_1.default, { item: true },
                        React.createElement(HBTypography_1.default, { variant: "h6", align: "center" }, labels.PAGE_NOT_FOUND_TITLE)),
                    React.createElement(Grid_1.default, { item: true },
                        React.createElement(HBTypography_1.default, { variant: "body2", align: "center", textTransformNone: true }, labels.PAGE_NOT_FOUND_SUBTITLE))))),
        React.createElement("div", { className: classes.containerSpacing },
            React.createElement(HBContainer_1.default, null,
                React.createElement(Grid_1.default, { container: true, spacing: 5, direction: "column", justify: "center" },
                    React.createElement(Grid_1.default, { item: true },
                        React.createElement(HBTypography_1.default, { variant: "h4" }, labels.PAGE_NOT_FOUND_CATEGORY_SECTION_TITLE)),
                    React.createElement(Grid_1.default, { item: true },
                        React.createElement(Grid_1.default, { container: true, spacing: 3 }, categoryItems === null || categoryItems === void 0 ? void 0 : categoryItems.map(({ name, icon, to }) => (React.createElement(Grid_1.default, { key: name, item: true, xs: 12, sm: 4, md: 3 },
                            React.createElement(Grid_1.default, { container: true, className: classes.item, direction: "column", justify: "center", alignItems: "center", component: HBLink_1.default, to: to, title: name },
                                React.createElement(Grid_1.default, { item: true, alignContent: 'center' },
                                    React.createElement(HBIcon_1.default, { icon: icon, className: classes.icon })),
                                React.createElement(Grid_1.default, { item: true },
                                    React.createElement(HBTypography_1.default, { fontNormal: true, align: "center", noWrap: true }, name)))))))))))));
};
exports.default = withLayout_1.default(withStyles_1.default(styles)(PageNotFound), {
    paddingTop: 0,
    paddingBottom: 0,
});
