"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var _interopRequireDefault = require('@babel/runtime/helpers/interopRequireDefault');
var _react = _interopRequireDefault(require('react'));
const CookieBotConsent = ({ domainGroupId, language }) => {
    if (typeof window === 'undefined')
        return null;
    if (!domainGroupId || !document)
        return null;
    if (domainGroupId.length !== 36)
        console.warn('The cookie bot domain group id is '.concat(domainGroupId.length.toString(), ' characters, instead it should be 36 characters!'));
    if (!isCookieBotInseredInHead()) {
        var script = document.createElement('script');
        script.setAttribute('id', 'CookieBot');
        script.setAttribute('src', 'https://consent.cookiebot.com/uc.js');
        script.setAttribute('data-cbid', domainGroupId);
        script.setAttribute('data-blockingmode', 'auto');
        script.setAttribute('type', 'text/javascript');
        if (language)
            script.setAttribute('data-culture', language);
        var head = document.querySelector('html > head');
        head.insertBefore(script, head.firstChild);
    }
    return _react.default.createElement('script', {
        id: 'CookieDeclaration',
        src: 'https://consent.cookiebot.com/'.concat(domainGroupId, '/cd.js'),
        type: 'text/javascript',
        'data-culture': language,
        async: true,
    });
};
function isCookieBotInseredInHead() {
    var scripts = document.getElementsByTagName('script');
    for (var i = scripts.length; i--;)
        if (scripts[i].id == 'CookieBot')
            return true;
    return false;
}
exports.default = CookieBotConsent;
