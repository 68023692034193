"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const Grid_1 = require("@material-ui/core/Grid");
const Tooltip_1 = require("@material-ui/core/Tooltip");
const Zoom_1 = require("@material-ui/core/Zoom");
const HBTypography_1 = require("@components/library/HBTypography");
const HBIcon_1 = require("@components/library/HBIcon");
const LabelContext_1 = require("@components/common/context/LabelContext");
const LightTooltip = withStyles_1.default(({ palette, shadows, spacing }) => ({
    tooltip: {
        backgroundColor: palette.common.white,
        color: palette.primary.main,
        boxShadow: shadows[6],
        fontSize: 11,
        padding: spacing(2),
        borderRadius: 0,
    },
    arrow: {
        color: palette.common.white,
    },
}))(Tooltip_1.default);
const DeleteProfileItem = ({ className, canDelete, onDelete }) => {
    const labels = LabelContext_1.useLabelContext();
    return canDelete ? (React.createElement(HBIcon_1.default, { icon: "remove", isButton: true, ButtonProps: { 'aria-label': labels.ARIA_DELETE, onClick: onDelete } })) : (React.createElement(LightTooltip, { placement: "top", "aria-label": labels.ARIA_UNABLE_TO_DELETE_ADDRESS, title: React.createElement(Grid_1.default, { container: true, direction: "column" },
            React.createElement(HBTypography_1.default, { variant: "subtitle1", marginBottom: 1 }, labels.DELETE_ADDRESS),
            React.createElement(HBTypography_1.default, { variant: "body2", textTransformNone: true }, labels.DELETE_ADDRESS_TOOLTIP)), TransitionComponent: Zoom_1.default, interactive: true, arrow: true },
        React.createElement(HBIcon_1.default, { icon: "remove", opacity: 0.4, isButton: true, ButtonProps: { 'aria-label': labels.ARIA_DELETE, className: className } })));
};
exports.default = DeleteProfileItem;
