"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
const styles = ({ palette, spacing }) => styles_1.createStyles({
    accordionContainer: {
        position: 'relative',
        width: '100%',
        padding: 0,
        '& *::-webkit-scrollbar': {
            display: 'none',
        },
    },
    accordionContainerBorder: {
        '&:before': {
            backgroundColor: palette.primary.main,
            content: "''",
            position: 'absolute',
            left: 0,
            right: 0,
            height: 1,
            opacity: 1,
            transition: 'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        },
        '&:last-child': {
            borderBottom: `1px solid ${palette.primary.main}`,
        },
    },
    accordionContainerSelected: {
        '&:before': {
            backgroundColor: palette.secondary.main,
        },
        '& + div:before': {
            backgroundColor: palette.secondary.main,
        },
    },
    accordionHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        borderRadius: 0,
        backgroundColor: 'transparent',
        padding: spacing(1.5, 0),
        minHeight: spacing(6),
        textAlign: 'left',
    },
    denseHeader: {
        padding: spacing(1, 0),
    },
    accordionLabel: {
        color: palette.primary.main,
        display: 'flex',
        flexGrow: 1,
    },
    accordionContent: {
        width: '100%',
        paddingBottom: spacing(1),
        position: 'relative',
        left: 0,
        top: 'auto',
    },
    accordionContentInner: {
        width: '100%',
        overflowY: 'hidden',
        overflowX: 'scroll',
        padding: '10px 0',
        maxWidth: '1280px',
        margin: '0 auto',
    },
    icon: {
        transform: 'rotate(0deg)',
        transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    selectedIcon: {
        transform: 'rotate(180deg)',
    },
});
exports.default = styles;
