"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
const colorManipulator_1 = require("@material-ui/core/styles/colorManipulator");
const styles = ({ palette, spacing, transitions }) => styles_1.createStyles({
    root: {
        width: '100%',
        position: 'relative',
        paddingBottom: spacing(6),
    },
    content: {
        padding: 0,
    },
    moreButton: {
        paddingTop: spacing(8.75),
        paddingBottom: 0,
        position: 'absolute',
        bottom: 0,
        textAlign: 'center',
        width: '100%',
        cursor: 'pointer',
        '& button': {
            '&:hover': {
                background: 'transparent',
            },
            '&:focus': {
                background: 'transparent',
            },
        },
    },
    borderBottom: {
        borderBottom: `1.5px solid ${colorManipulator_1.fade(palette.primary.main, 0.6)}`,
    },
    moreButtoncollapse: {
        paddingTop: 0,
    },
    collapseBgImg: {
        backgroundImage: `linear-gradient(to bottom, ${colorManipulator_1.fade(palette.common.white, 0)}, ${palette.primary.contrastText} 60%, ${palette.primary.contrastText} 50%)`,
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    icon: {
        width: '1rem',
        height: '1rem',
        transition: transitions.create(['all']),
    },
});
exports.default = styles;
