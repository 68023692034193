"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const FormFieldContext_1 = require("@components/common/utils/Form/FormFieldContext");
const FormFieldContext_2 = require("@components/common/utils/Form/FormFieldContext/FormFieldContext");
const useApiRequest_1 = require("@components/common/utils/hooks/useApiRequest");
const ApiContext_1 = require("@components/common/context/ApiContext");
const FormContext_1 = require("@components/common/utils/Form/FormContext");
const AppContext_1 = require("@components/common/context/AppContext");
const ShippingInfoStepForm = ({ api, form, defaultSelected }) => {
    const defaultValues = { deliveryOption: defaultSelected || form.elements[0].options[0].value };
    const { methods } = FormFieldContext_2.useFormHook({ defaultValues });
    const { onSubmit, isLoading, data, apiError } = useApiRequest_1.default();
    const { dispatch } = AppContext_1.useAppContext();
    React.useEffect(() => {
        if (!apiError && (data === null || data === void 0 ? void 0 : data.cart)) {
            dispatch({
                type: AppContext_1.ActionTypes.ADD_SHIPPING_INFO,
                payload: data,
            });
        }
    }, [data]);
    React.useEffect(() => {
        methods.reset({ ...defaultValues });
    }, [form]);
    const handleSubmit = async (payload) => await onSubmit(() => api.deliveryOption(payload));
    return (React.createElement(FormFieldContext_1.default, { methods: methods, onSubmit: handleSubmit },
        React.createElement(FormContext_1.default, Object.assign({}, form, { disabled: isLoading, shippingForm: true }))));
};
exports.default = ApiContext_1.withApi(ShippingInfoStepForm);
