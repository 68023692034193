"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const useMediaQuery_1 = require("@material-ui/core/useMediaQuery");
const Grid_1 = require("@material-ui/core/Grid");
const LoginInfoSummary_1 = require("@components/pages/profile/detail/LoginDetailInfo/LoginInfoSummary");
const PersonalInfoSummary_1 = require("@components/pages/profile/detail/LoginDetailInfo/PersonalInfoSummary");
const ProfileDetailItem_1 = require("@components/pages/profile/detail/ProfileDetailItem");
const LoginDetailInfo = ({ title, loginInfo, personalInfo }) => {
    const isTablet = useMediaQuery_1.default('(max-width:760px)');
    return (React.createElement(ProfileDetailItem_1.default, { title: title },
        React.createElement(Grid_1.default, { container: true, spacing: isTablet ? 2 : 3 },
            React.createElement(Grid_1.default, { item: true, xs: isTablet ? 12 : 6 },
                React.createElement(PersonalInfoSummary_1.default, Object.assign({}, personalInfo))),
            React.createElement(Grid_1.default, { item: true, xs: isTablet ? 12 : 6 },
                React.createElement(LoginInfoSummary_1.default, Object.assign({}, loginInfo))))));
};
exports.default = LoginDetailInfo;
