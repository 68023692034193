"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const createStyles_1 = require("@material-ui/core/styles/createStyles");
const makeStyles_1 = require("@material-ui/core/styles/makeStyles");
const useStyles = makeStyles_1.default(({ palette, transitions }) => createStyles_1.default({
    root: {
        position: 'relative',
    },
    sliderContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        position: 'relative',
        overflow: 'hidden',
        listStyle: 'none',
        padding: '0',
        zIndex: 1,
        width: '100%',
        height: '100%',
        '& .swiper-container-pointer-events': { touchAction: 'pan-y' },
        '& .swiper-wrapper': {
            position: 'relative',
            width: '100%',
            height: '100%',
            zIndex: 1,
            display: 'flex',
            transitionProperty: 'transform',
            boxSizing: 'content-box',
            transform: 'translate3d(0px,0,0)',
        },
        '& .swiper-slide': {
            flexShrink: 0,
            width: '100%',
            height: '100%',
            minHeight: '100%',
            position: 'relative',
            transitionProperty: 'transform',
            textAlign: 'center',
            fontSize: '18px',
            background: '#fff',
            display: 'inline-flex',
            WebkitBoxPack: 'center',
            msFlexPack: 'center',
            WebkitJustifyContent: 'center',
            justifyContent: 'center',
            WebkitBoxAlign: 'center',
            msFlexAlign: 'center',
            WebkitAlignItems: 'center',
            alignItems: 'center',
        },
        '& .swiper-slide img': {
            height: '100%',
            width: '100%',
            display: 'flex',
            flexShrink: 0,
        },
        '& .swiper-slide-invisible-blank': { visibility: 'hidden' },
    },
    sliderNav: {
        position: 'absolute',
        top: '50%',
        transform: 'translate(0, -50%)',
        zIndex: 10,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: transitions.create(['all'], { duration: 400 }),
    },
    sliderNavPrev: {
        left: '10px',
        right: 'auto',
    },
    sliderNavNext: {
        right: '10px',
        left: 'auto',
    },
    sliderNavdisabled: {
        opacity: '0.4 !important',
        '&:hover': {
            opacity: '0.4 !important',
        },
        cursor: 'not-allowed',
    },
    showNavOnHover: {
        '& .slider-prev': {
            left: -50,
            opacity: 0,
        },
        '& .slider-next': {
            right: -50,
            opacity: 0,
        },
        '&:hover': {
            '& .slider-prev': {
                left: 0,
                opacity: 1,
            },
            '& .slider-next': {
                right: 0,
                opacity: 1,
            },
        },
    },
    pagination: {
        position: 'absolute',
        textAlign: 'center',
        transition: '.3s opacity',
        transform: 'translate3d(0,0,0)',
        zIndex: 10,
        bottom: '10px',
        left: '0',
        width: '100%',
    },
    paginationClass: {
        backgroundColor: ({ color }) => (palette[color] ? palette[color].main : palette.common.white),
        width: '12px',
        height: '12px',
        display: 'inline-block',
        borderRadius: '50%',
        opacity: 0.2,
        margin: '0 4px',
        transition: transitions.create(['all'], { duration: 400 }),
    },
    paginationActiveClass: {
        opacity: 1,
    },
    paginationClickableClass: {
        cursor: 'pointer',
    },
}));
exports.default = useStyles;
