"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Step_1 = require("@components/pages/checkout/Step/Step");
const ShippingInfoStepSummary_1 = require("@components/pages/checkout/Step/ShippingInfoStep/ShippingInfoStepSummary");
const ShippingInfoStepForm_1 = require("@components/pages/checkout/Step/ShippingInfoStep/ShippingInfoStepForm");
const AppContext_1 = require("@components/common/context/AppContext");
const ShippingInfoStep = ({ disabled, completed, isEditing, title, form }) => {
    var _a, _b;
    let shippingForm = form;
    const { state: { cart: { deliveryOptions = [] } = {} } = {}, dispatch } = AppContext_1.useAppContext();
    const shipping = (_a = deliveryOptions === null || deliveryOptions === void 0 ? void 0 : deliveryOptions.find(option => option.selected)) === null || _a === void 0 ? void 0 : _a.value;
    if (shipping) {
        shippingForm.elements[0].options = deliveryOptions;
    }
    const selectedShipping = ((_b = shippingForm.elements[0].options) === null || _b === void 0 ? void 0 : _b.find(option => option.value === shipping)) || shippingForm.elements[0].options[0];
    const handleEdit = () => dispatch({ type: AppContext_1.ActionTypes.EDITING_SHIPPING_INFO });
    return (React.createElement(Step_1.default, { title: title, active: !disabled, completed: completed, isEditing: isEditing, onChange: handleEdit, summary: shipping && React.createElement(ShippingInfoStepSummary_1.default, Object.assign({}, selectedShipping)) }, !!shipping ? (React.createElement(ShippingInfoStepForm_1.default, { form: shippingForm, defaultSelected: shipping })) : (React.createElement(ShippingInfoStepForm_1.default, { form: shippingForm }))));
};
exports.default = ShippingInfoStep;
