"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const useApiRequest = () => {
    const [data, setData] = React.useState(undefined);
    const [apiError, setApiError] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const onSubmit = async (apiCallMethod, cb) => {
        setIsLoading(true);
        setApiError(undefined);
        try {
            const { request } = apiCallMethod();
            const result = await request;
            setData(result);
            if (cb) {
                cb(result);
            }
        }
        catch (error) {
            if (error.response && error.response.data) {
                setApiError(error.response.data.error);
            }
            else {
                setApiError(error);
            }
        }
        finally {
            setIsLoading(false);
        }
    };
    return {
        data,
        onSubmit,
        apiError,
        isLoading,
        setIsLoading,
    };
};
exports.default = useApiRequest;
