"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const HBLink_1 = require("@components/library/HBLink");
const LogoStyles_1 = require("./LogoStyles");
const Logo = ({ logo, className, classes }) => (React.createElement(HBLink_1.default, { to: "/", className: classnames_1.default(className, classes.root) },
    React.createElement("img", { alt: "", src: logo === null || logo === void 0 ? void 0 : logo.path, className: classes.logo })));
exports.default = withStyles_1.default(LogoStyles_1.default)(Logo);
