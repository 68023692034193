"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const Slider_1 = require("@components/common/utils/Slider");
const GalleryItem_1 = require("@components/pages/home/GalleryItem");
const StageGalleryStyles_1 = require("./StageGalleryStyles");
const ZoomOutImage_1 = require("@components/common/utils/ZoomOutImage");
const StageGallery = ({ classes, items = [] }) => (React.createElement(ZoomOutImage_1.default, null,
    React.createElement(Slider_1.default, { items: items === null || items === void 0 ? void 0 : items.map((item, index) => (React.createElement(GalleryItem_1.default, Object.assign({ className: classes.gallery, key: index.toString() }, item)))), autoplay: true, className: classes.root })));
exports.default = withStyles_1.default(StageGalleryStyles_1.default)(StageGallery);
