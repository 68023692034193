"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
const styles = ({ breakpoints, spacing }) => styles_1.createStyles({
    root: {
        marginTop: '32px',
        flexDirection: 'row',
    },
    img: {
        objectFit: 'cover',
    },
    vertical: {
        flexDirection: 'column',
    },
    horizontalRight: {
        flexDirection: 'row-reverse',
    },
    horizontalLeft: {
        flexDirection: 'row',
    },
    verticalTop: {
        flexDirection: 'column',
    },
    verticalBottom: {
        flexDirection: 'column-reverse',
    },
    content: {
        padding: spacing(3, 0),
        [breakpoints.up('md')]: {
            padding: spacing(10),
        },
    },
    contentVertical: {
        textAlign: 'center',
        [breakpoints.up('md')]: {
            padding: '48px 100px',
        },
        '& p': {
            [breakpoints.up('md')]: {
                padding: spacing(0, 10),
            },
            [breakpoints.down('sm')]: {
                textAlign: 'initial',
            },
        },
    },
});
exports.default = styles;
