"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const createStyles_1 = require("@material-ui/core/styles/createStyles");
const Hidden_1 = require("@material-ui/core/Hidden");
const Grid_1 = require("@material-ui/core/Grid");
const HBSelect_1 = require("@components/library/HBSelect");
const MenuItem_1 = require("@material-ui/core/MenuItem");
const HBFilter_1 = require("@components/library/HBFilter/HBFilter");
const SearchResetButton_1 = require("@components/pages/search/SearchFilter/SearchResetButton");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const SearchPageContext_1 = require("@components/common/context/SearchPageContext");
const SearchContext_1 = require("@components/common/context/SearchContext");
const SearchInputContext_1 = require("@components/common/context/SearchInputContext");
const styles = ({ palette, spacing, transitions }) => createStyles_1.default({
    filterWrapper: {
        width: '100%',
        position: 'relative',
    },
    filterOptionListInner: {
        overflowX: 'scroll',
        paddingBottom: spacing(2),
    },
    filterContainer: {
        marginTop: spacing(-10),
        '& *::-webkit-scrollbar': {
            display: 'none',
        },
    },
    filter: {
        minWidth: '225px',
    },
    label: {
        marginRight: spacing(1),
    },
    rotateFilterIcon: {
        transform: 'rotate(90deg)',
    },
    selected: {
        opacity: 0.4,
        color: palette.primary.main,
        backgroundColor: '#d8d8d8',
    },
    filterOptionList: {
        opacity: 0,
        visibility: 'hidden',
        transform: 'translateY(-25px)',
        transition: transitions.create(['all'], { duration: 400, easing: 'ease-out' }),
    },
    filterOptionListOpen: {
        opacity: 1,
        visibility: 'visible',
        transform: 'translateY(0px)',
    },
});
const SearchFilterLargeScreen = ({ classes, expanded, filterOptionOpen, handleActiveFilter, }) => {
    var _a, _b;
    const { handleSortOption, sortOptionId } = SearchPageContext_1.useSearchPageContext();
    const { selectedFilterIds, handleSelectedFilters } = SearchContext_1.useSearchContext();
    const { handleClearSearchTerms } = SearchInputContext_1.useSearchInputContext();
    const { loadedResults: { filters = [], sortOptions: { title: sortOptionsTitle = '', items: sortOptionsItems = [] } = {} }, } = SearchPageContext_1.useSearchPageContext();
    const handleChange = (event) => handleSortOption(event.target.value);
    return (React.createElement(Grid_1.default, { item: true, className: classes.filterWrapper },
        React.createElement(Hidden_1.default, { smDown: true, implementation: "css" },
            React.createElement(Grid_1.default, { container: true, spacing: 1, alignItems: "center" },
                React.createElement(Grid_1.default, { container: true, alignItems: "center", justify: "flex-end", className: classes.filterContainer },
                    React.createElement(HBSelect_1.default, { id: "sortfilter", value: sortOptionId, onChange: handleChange, className: classes.filter, displayEmpty: true },
                        React.createElement(MenuItem_1.default, { value: "" }, sortOptionsTitle), sortOptionsItems === null || sortOptionsItems === void 0 ? void 0 :
                        sortOptionsItems.map(filter => (React.createElement(MenuItem_1.default, { key: filter.value, value: filter.value }, filter.title))))), filters === null || filters === void 0 ? void 0 :
                filters.map((filter, idX) => (React.createElement(Grid_1.default, { item: true, key: idX.toString() },
                    React.createElement(HBFilter_1.default, { key: filter.title + idX.toString(), value: filter.title, label: filter.title, onClick: handleActiveFilter(filter), selected: filter.title === expanded, endIcon: "keyboard_arrow_right", endIconProps: {
                            color: filter.title === expanded ? 'default' : 'primary',
                            className: classnames_1.default({ [classes.rotateFilterIcon]: filter.title === expanded }),
                        }, textWrap: true })))),
                React.createElement(Grid_1.default, { item: true },
                    React.createElement(SearchResetButton_1.default, { onClick: handleClearSearchTerms }))),
            React.createElement(Grid_1.default, { className: classes.filterWrapper },
                React.createElement("div", { className: classnames_1.default(classes.filterOptionList, { [classes.filterOptionListOpen]: filterOptionOpen }) },
                    React.createElement(Grid_1.default, { container: true, spacing: 1, wrap: "nowrap", className: classes.filterOptionListInner }, (_b = (_a = filters === null || filters === void 0 ? void 0 : filters.find(filter => filter.title === expanded)) === null || _a === void 0 ? void 0 : _a.items) === null || _b === void 0 ? void 0 : _b.map((item, idX) => {
                        const selected = !!selectedFilterIds.some(sFilter => sFilter === (item === null || item === void 0 ? void 0 : item.id));
                        return (React.createElement(Grid_1.default, { item: true, key: idX.toString() },
                            React.createElement(HBFilter_1.default, { value: item.title, label: item.title, onClick: () => handleSelectedFilters(item), selected: selected, endIcon: selected ? 'check_mark' : 'plus', classes: { selected: classes.selected }, textWrap: true })));
                    })))))));
};
exports.default = withStyles_1.default(styles)(SearchFilterLargeScreen);
