"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Svg_1 = require("./Svg");
const G_1 = require("./Svg/G");
const Filter = ({ color = 'primary', ...rest }) => (React.createElement(Svg_1.default, Object.assign({}, rest),
    React.createElement(G_1.default, { transform: "translate(1 2)", color: color, strokeWidth: "2", fill: "none", fillRule: "evenodd", strokeLinecap: "round", strokeLinejoin: "round" },
        React.createElement("circle", { cx: "14.044", cy: "4", r: "4" }),
        React.createElement("circle", { cx: "8.044", cy: "16", r: "4" }),
        React.createElement("path", { d: "M10.044 4H0M22 4h-3.956M12.044 16H22M4 16H0" }))));
exports.default = Filter;
