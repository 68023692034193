"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const Slide_1 = require("@material-ui/core/Slide");
const makeStyles_1 = require("@material-ui/core/styles/makeStyles");
const createStyles_1 = require("@material-ui/core/styles/createStyles");
const useScrollTrigger_1 = require("@material-ui/core/useScrollTrigger");
const HBIcon_1 = require("@components/library/HBIcon");
const HBTypography_1 = require("@components/library/HBTypography");
const typography_1 = require("@components/library/theme/typography");
const LabelContext_1 = require("@components/common/context/LabelContext");
const useStyles = makeStyles_1.default(({ palette, spacing }) => createStyles_1.default({
    root: {
        position: 'fixed',
        bottom: spacing(2),
        marginBottom: spacing(10),
        width: 180,
        height: 56,
        padding: '11px 17px 13px 20px',
        borderRadius: 100,
        boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.4)',
        backgroundColor: palette.primary.main,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        right: -130,
        zIndex: 1,
        transition: 'right 0.3s ease 0s !important',
        ['@media (hover: hover) and (pointer: fine)']: {
            '&:hover': {
                right: -25,
            },
        },
    },
    rightSpacing: {
        right: -200,
    },
    text: {
        width: 79,
        height: 32,
        margin: '0 0 0 20px',
        fontFamily: typography_1.fontFamilyPrimary,
        lineHeight: 1.33,
        letterSpacing: 0.4,
        color: palette.common.white,
        opacity: 1,
        display: 'inline-flex',
        alignItems: 'center',
    },
}));
const BackToTop = () => {
    const labels = LabelContext_1.useLabelContext();
    const classes = useStyles();
    const trigger = useScrollTrigger_1.default({
        disableHysteresis: true,
        threshold: 100,
    });
    const handleClick = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');
        if (anchor) {
            anchor.scrollIntoView({ behavior: 'auto', block: 'center' });
        }
    };
    return (React.createElement(Slide_1.default, { in: trigger, direction: "left", timeout: 6000 },
        React.createElement("div", { role: "presentation", onClick: handleClick, className: classnames_1.default(classes.root, { [classes.rightSpacing]: !trigger }), "aria-label": labels.BACK_TO_TOP_LABEL },
            React.createElement(HBIcon_1.default, { icon: "arrow_up", fontSize: "2xsmall", color: "default" }),
            React.createElement(HBTypography_1.default, { className: classes.text, variant: "caption", fontMedium: true }, labels.BACK_TO_TOP_LABEL))));
};
exports.default = BackToTop;
