"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_swipeable_views_1 = require("react-swipeable-views");
const react_swipeable_views_utils_1 = require("react-swipeable-views-utils");
const react_swipeable_views_core_1 = require("react-swipeable-views-core");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const createStyles_1 = require("@material-ui/core/styles/createStyles");
const HBIcon_1 = require("@components/library/HBIcon");
const HBImage_1 = require("@components/library/HBImage");
const styles = () => createStyles_1.default({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
});
const VirtualizeSwipeableViews = react_swipeable_views_utils_1.bindKeyboard(react_swipeable_views_utils_1.autoPlay(react_swipeable_views_utils_1.virtualize(react_swipeable_views_1.default)));
const ProductImageLightboxCarousel = ({ classes, images, className, name, handleZoom, index: indexProp, onChangeIndex, autoPlay = false, hideNav = false, }) => {
    const [index, setIndex] = React.useState(indexProp || 0);
    const currentIndex = indexProp === undefined ? index : indexProp;
    const updateIndex = i => {
        setIndex(i);
        if (onChangeIndex)
            onChangeIndex(react_swipeable_views_core_1.mod(i, images.length));
    };
    const handleNext = () => {
        updateIndex(currentIndex + 1);
    };
    const handleBack = () => {
        updateIndex(currentIndex - 1);
    };
    const handleChangeIndex = step => {
        updateIndex(step);
    };
    const slideRenderer = ({ index: slideIndex, key }) => {
        const clampedIndex = react_swipeable_views_core_1.mod(slideIndex, images.length);
        return (React.createElement("div", { key: key, onClick: handleZoom },
            React.createElement(HBImage_1.default, { className: className, src: images[clampedIndex].src, alt: name })));
    };
    return (React.createElement("div", { className: classes.root },
        React.createElement(VirtualizeSwipeableViews, { autoplay: autoPlay, index: currentIndex, onChangeIndex: handleChangeIndex, slideRenderer: slideRenderer, enableMouseEvents: true, animateTransitions: false }),
        React.createElement(React.Fragment, null, !hideNav && (React.createElement(React.Fragment, null,
            React.createElement(HBIcon_1.default, { icon: "arrow_left", fontSize: "small", color: "default", onClick: handleBack }),
            React.createElement(HBIcon_1.default, { icon: "arrow_right", fontSize: "small", color: "default", onClick: handleNext }))))));
};
exports.default = withStyles_1.default(styles)(ProductImageLightboxCarousel);
