"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const createStyles_1 = require("@material-ui/core/styles/createStyles");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const Grid_1 = require("@material-ui/core/Grid");
const HBContainer_1 = require("@components/library/HBContainer");
const HBImage_1 = require("@components/library/HBImage");
const ProductBrand_1 = require("@components/pages/product/detail/ProductBrand");
const HBTypography_1 = require("@components/library/HBTypography");
const ProductPrice_1 = require("@components/pages/product/ProductPrice");
const ProductSaleTag_1 = require("@components/pages/product/ProductSaleTag");
const ProductWishlist_1 = require("@components/pages/product/detail/ProductWishlist");
const ProductAddtoCartButton_1 = require("@components/pages/product/detail/ProductAddtoCartButton");
const styles = ({ palette, spacing, transitions, typography, breakpoints }) => createStyles_1.default({
    root: {
        opacity: 0,
        visibility: 'hidden',
        transform: 'translateY(-25px)',
        top: '90px',
        position: 'fixed',
        width: '100%',
        left: 0,
        right: 0,
        paddingTop: spacing(1.25),
        paddingBottom: spacing(2.5),
        background: '#FFF',
        zIndex: 899,
        transition: transitions.create(['all'], { duration: 300, easing: 'ease-out' }),
        borderBottom: `1px solid ${palette.divider}`,
        ['@media (max-width:768px)']: {
            top: '120px',
        },
        ['@media (max-width:740px)']: {
            display: 'none',
        },
    },
    visible: {
        opacity: 1,
        visibility: 'visible',
        transform: 'translateY(0px)',
        zIndex: 900,
    },
    productName: {
        [breakpoints.up('lg')]: {
            maxWidth: 600,
        },
        [breakpoints.between('sm', 'md')]: {
            maxWidth: 520,
        },
        [breakpoints.down('sm')]: {
            maxWidth: 320,
        },
    },
    image: {
        maxWidth: 104,
        maxHeight: 104,
    },
    salePrice: {
        ...typography.h4,
    },
    normalPrice: {
        ...typography.h6,
    },
    price: {
        ...typography.h4,
    },
    relative: {
        position: 'relative',
    },
});
const ProductMini = ({ classes, visible, item }) => {
    const { name, image, brand, isSale } = item;
    const outOfStock = item.availability === 'OutOfStock';
    return (React.createElement("div", { className: classnames_1.default(classes.root, { [classes.visible]: visible }) },
        React.createElement(HBContainer_1.default, null,
            React.createElement(Grid_1.default, { container: true, spacing: 2, alignItems: "center", justify: "space-between", wrap: "nowrap" },
                React.createElement(Grid_1.default, { item: true },
                    React.createElement(Grid_1.default, { container: true, spacing: 2, alignItems: "center", wrap: "nowrap" },
                        React.createElement(Grid_1.default, { item: true, className: classes.relative },
                            React.createElement(ProductSaleTag_1.default, { isSale: isSale, isSymbol: true }),
                            React.createElement(HBImage_1.default, { className: classes.image, src: image === null || image === void 0 ? void 0 : image.path, alt: name, size: 200 })),
                        React.createElement(Grid_1.default, { item: true },
                            React.createElement(Grid_1.default, { container: true, direction: "column" },
                                React.createElement(Grid_1.default, { item: true },
                                    React.createElement(ProductBrand_1.default, Object.assign({}, brand))),
                                React.createElement(Grid_1.default, { item: true },
                                    React.createElement(HBTypography_1.default, { variant: "h4", truncate: true, clamp: 1, noWrap: true, className: classes.productName }, name)))))),
                React.createElement(Grid_1.default, { item: true },
                    React.createElement(Grid_1.default, { container: true, spacing: 5, alignItems: "center", wrap: "nowrap" },
                        React.createElement(Grid_1.default, { item: true },
                            React.createElement(ProductPrice_1.default, { item: item, classes: {
                                    price: classes.price,
                                    salePrice: classes.salePrice,
                                    normalPrice: classes.normalPrice,
                                } })),
                        React.createElement(Grid_1.default, { item: true },
                            React.createElement(Grid_1.default, { container: true, spacing: 2, wrap: "nowrap" },
                                React.createElement(Grid_1.default, { item: true },
                                    React.createElement(ProductAddtoCartButton_1.default, { onlyIcon: true, outOfStock: outOfStock })),
                                React.createElement(Grid_1.default, { item: true },
                                    React.createElement(ProductWishlist_1.default, { item: item }))))))))));
};
exports.default = withStyles_1.default(styles)(ProductMini);
