"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Select_1 = require("@material-ui/core/Select");
const styles_1 = require("@material-ui/core/styles");
const InputBase_1 = require("@material-ui/core/InputBase");
const KeyboardArrowDown_1 = require("../icons/KeyboardArrowDown");
const BootstrapInput = styles_1.withStyles(({ palette, spacing, breakpoints, transitions }) => ({
    root: {
        color: ({ value }) => (value ? 'currentColor' : styles_1.fade('#000', 0.4)),
        height: spacing(6),
        minWidth: '15rem',
        [breakpoints.up('sm')]: {
            minWidth: '24rem',
        },
        'label + &': {},
        '& svg': {
            transition: transitions.create(['all']),
            width: spacing(2),
            height: spacing(2),
            right: spacing(3),
            top: spacing(2),
        },
    },
    input: {
        padding: '13.5px 56px 13.5px 24px !important',
        borderRadius: 999,
        position: 'relative',
        border: `1px solid ${palette.primary.main}`,
        '&:focus': {
            borderRadius: 999,
        },
    },
}))(InputBase_1.default);
const HBSelect = React.forwardRef((props, ref) => {
    const { onChange, MenuProps, value, ...rest } = props;
    const [currentValue, setCurrentValue] = React.useState(value);
    React.useEffect(() => setCurrentValue(value), [value]);
    const onSelectChange = (event, child) => {
        setCurrentValue(event.target.value);
        if (onChange) {
            onChange(event, child);
        }
    };
    return (React.createElement(Select_1.default, Object.assign({ MenuProps: { disablePortal: true, keepMounted: true, ...MenuProps }, value: value, onChange: onSelectChange, input: React.createElement(BootstrapInput, { value: currentValue }), IconComponent: KeyboardArrowDown_1.default }, rest, { ref: ref })));
});
exports.default = HBSelect;
