"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const react_html_parser_1 = require("react-html-parser");
const core_1 = require("@material-ui/core");
const transform = (node, i) => {
    const { name, type, children: nodeChildren, variant, attribs } = node;
    let level = 1;
    let parent = node;
    while ((parent = parent.parent)) {
        level *= 10;
    }
    let children;
    if (type === 'tag') {
        switch (name) {
            case 'a':
                children = react_html_parser_1.processNodes(nodeChildren, transform);
                if (attribs.class) {
                    attribs.className = attribs.class;
                    delete attribs.class;
                }
                return (React.createElement(core_1.Typography, Object.assign({ component: name, key: level + i }, attribs), children));
            case 'h1':
            case 'h2':
            case 'h3':
            case 'h4':
            case 'h5':
            case 'h6':
                children = react_html_parser_1.processNodes(nodeChildren, transform);
                return (React.createElement(core_1.Typography, Object.assign({ key: level + i, variant: name }, attribs), children));
            case 'p':
            case 'ol':
            case 'ul':
                children = react_html_parser_1.processNodes(nodeChildren, transform);
                return (React.createElement(core_1.Typography, Object.assign({ key: level + i, variant: variant || 'body1', component: name }, attribs), children));
            case 'small':
                children = react_html_parser_1.processNodes(nodeChildren, transform);
                return (React.createElement(core_1.Typography, Object.assign({ key: level + i, variant: "caption", component: name }, attribs, { style: { display: 'inherit' } }), children));
        }
    }
    return undefined;
};
const sanitizeParagraphs = (html) => {
    if (!html)
        return html;
    return html
        .replace(/\n|\r/g, '')
        .split('<p><br></p>')
        .map(block => {
        if (!block)
            return block;
        return block
            .split('</p><p>')
            .join('<br>');
    })
        .join('');
};
const RichText = react_1.memo((props) => {
    const { content, variant } = props;
    const sanitizedContent = react_1.useMemo(() => sanitizeParagraphs(content), [content]);
    if (!sanitizedContent)
        return null;
    return react_html_parser_1.default(sanitizedContent, {
        transform: (node, i) => {
            if (variant)
                node.variant = variant;
            return transform(node, i);
        },
    });
});
exports.default = RichText;
