"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const styles_1 = require("@material-ui/core/styles");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const Grid_1 = require("@material-ui/core/Grid");
const HBTypography_1 = require("@components/library/HBTypography");
const HBLink_1 = require("@components/library/HBLink");
const Colors_1 = require("@components//library/theme/Colors");
const BrandItem_1 = require("@components/pages/home/BrandItem");
const LabelContext_1 = require("@components/common/context/LabelContext");
const styles = ({ spacing, breakpoints, transitions }) => styles_1.createStyles({
    root: {
        transition: transitions.create(['all']),
        flexDirection: 'column',
        [breakpoints.down('sm')]: {
            margin: spacing(6, 0),
            flexDirection: 'column',
        },
        '& > div': {
            marginBottom: spacing(3),
        },
        [breakpoints.up('md')]: {
            maxWidth: 175,
            marginRight: spacing(10),
            paddingTop: spacing(1.75),
        },
        ['@media (min-width:960px) and (max-width:1160px)']: {
            marginRight: spacing(3),
        },
        ['@media (min-width:960px) and (max-width:999px)']: {
            maxWidth: 150,
        },
    },
    listWrapper: {
        '& > div': {
            marginBottom: spacing(1),
        },
        [breakpoints.down('sm')]: {
            '& > div': {
                marginBottom: spacing(3),
                marginRight: spacing(1),
            },
            flexWrap: 'nowrap',
            overflowX: 'scroll',
        },
        [breakpoints.up('sm')]: {
            '&::-webkit-scrollbar': {
                display: 'none',
            },
            justifyContent: 'center',
        },
    },
    item: {
        backgroundColor: Colors_1.default.white,
        width: 184,
        height: 110,
        [breakpoints.down('sm')]: {
            width: 176,
            height: 105,
        },
    },
    link: {
        margin: '0 auto',
    },
});
const BrandItems = ({ labels, classes, items }) => (React.createElement(Grid_1.default, { container: true, item: true, wrap: "nowrap", className: classes.root },
    React.createElement(Grid_1.default, { item: true, container: true, justify: "center" },
        React.createElement(HBTypography_1.default, { variant: "h6", noWrap: true }, labels.OUR_BRANDS)),
    React.createElement(Grid_1.default, { item: true },
        React.createElement(Grid_1.default, { item: true, container: true, className: classes.listWrapper }, items === null || items === void 0 ? void 0 : items.map(({ name, to, logo }) => (React.createElement(BrandItem_1.default, { key: name, name: name, to: to, logo: logo, size: 200, className: classes.item }))))),
    React.createElement(Grid_1.default, { item: true, container: true, justify: "center" },
        React.createElement(HBLink_1.default, { to: '/brands', className: classes.link, color: "primary", noWrap: true, showUnderline: true, hoverArrow: true }, labels.ALL_BRANDS))));
exports.default = withStyles_1.default(styles)(LabelContext_1.withLabel(BrandItems));
