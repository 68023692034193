"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    API: {
        MYACCOUNT: {
            SIGN_IN: '/@/signin',
            SIGN_UP: '/@/signup',
            SIGN_OUT: '/@/signout',
            GUEST_SIGN_UP: '/@/guest-signup',
            REQUEST_PASSWORD: '/@/forgot-password',
            RESET_PASSWORD: '/@/login-reset',
        },
        MEMBER: {
            UPDATE_MEMBER: '/@/member',
            DELETE_MEMBER: '/@/member',
            ADD_MEMBER_ADDRESS: '/@/member/address',
            UPDATE_MEMBER_ADDRESS: '/@/member/address/:oid',
            DELETE_MEMBER_ADDRESS: '/@/member/address/:oid',
        },
        WISHLIST: {
            ADD: '/@/wishlist',
            REMOVE: '/@/wishlist/:oid',
        },
        CART: {
            ADD: '/@/cart',
            REMOVE: '/@/cart/:oid',
        },
        VOUCHER: {
            ADD: '/@/voucher',
            REMOVE: '/@/voucher/reset',
        },
        CHECKOUT: {
            ORDER: '/@/checkout',
        },
        DELIVERY_OPTION: '/@/cart/deliveryOption',
        ADDRESS_OPTION: '/@/cart/addressOption',
        SEARCH: {
            LOAD_MORE: '/@/getMoreProducts',
            SEARCH_RESULTS: '/search',
            SEARCH_TYPEAHEAD: '/@/search/typeAhead',
        },
    },
};
