"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Svg_1 = require("./Svg");
const Path_1 = require("./Svg/Path");
const Text_1 = require("./Svg/Text");
const Degree360 = ({ color = 'default', ...rest }) => (React.createElement(Svg_1.default, { viewBox: "0 0 174 174" },
    React.createElement("g", { transform: "translate(1 1)", fill: "none", fillRule: "evenodd" },
        React.createElement(Text_1.default, { fontFamily: "OpenSans-Bold, Open Sans", fontSize: "32", fontWeight: "bold", letterSpacing: "1.129", color: color },
            React.createElement("tspan", { x: "52", y: "101" }, "360\u00B0")),
        React.createElement(Path_1.default, { d: "M121.314 7.596C110.412 2.597 98.777 0 86 0 38.504 0 0 38.504 0 86s38.504 86 86 86 86-38.504 86-86c0-12.428-2.636-24.24-7.38-34.907m-2.864-5.835a86.037 86.037 0 0 0-5.797-9.287m-3.951-4.947c-1.135-1.382-7.339-7.98-9.664-9.998m-5.327-4.266a86.067 86.067 0 0 0-10.12-6.433", color: color, strokeWidth: "2", strokeLinecap: "round", isStroke: true }),
        React.createElement(Path_1.default, { d: "M122.874 143.916c1.13-2.156 2.284-4.466 3.284-6.81m2.16-5.534c.961-2.585 1.79-5.308 2.589-8.072m1.45-5.21c.618-2.627 1.164-5.319 1.632-8.067m.83-5.557A146.427 146.427 0 0 0 136 86c0-47.496-22.386-86-50-86-16.307 0-30.79 13.427-39.918 34.204m-5.824 17.015C37.52 61.854 36 73.621 36 86c0 47.496 22.386 86 50 86 13.145 0 25.105-8.724 34.03-22.99", color: color, strokeWidth: "2", strokeLinecap: "round", isStroke: true }),
        React.createElement(Path_1.default, { d: "M139.937 125.506C159.49 116.209 172 101.966 172 86c0-28.01-38.504-50.718-86-50.718-5.049 0-9.424.11-14.234.601m-6.374.865c-3.852.558-7.602 1.27-11.23 2.123m-6.776 1.799a110.064 110.064 0 0 0-10.327 3.62m-5.44 2.417a84.244 84.244 0 0 0-9.254 5.176m-4.822 3.459C6.12 63.95 0 74.222 0 86c0 28.01 38.504 50.718 86 50.718 11.171 0 21.845-1.256 31.637-3.542", color: color, strokeWidth: "2", strokeLinecap: "round", isStroke: true }))));
exports.default = Degree360;
