"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@material-ui/core");
const styles_1 = require("@material-ui/core/styles");
const styles = ({ palette, spacing, transitions }) => styles_1.createStyles({
    root: {
        alignSelf: 'center',
        position: 'relative',
        margin: 0,
        padding: spacing(0),
        height: spacing(4),
        border: 'none',
        borderRadius: spacing(2),
        color: palette.primary.main,
        backgroundColor: '#E8E8E8',
        transition: transitions.create(['all']),
        '&:hover': {
            backgroundColor: core_1.fade(palette.secondary.main, 0.4),
        },
        '&:focus': {
            outline: 'none',
        },
        '& svg': {
            transition: transitions.create(['all']),
        },
    },
    hover: {
        backgroundColor: core_1.fade(palette.secondary.main, 0.4),
    },
    selected: {
        color: palette.common.white,
        backgroundColor: palette.secondary.main,
    },
    label: {
        textAlign: 'left',
        textTransform: 'none',
        paddingLeft: spacing(1.5),
        paddingRight: spacing(1.5),
        '-webkitLineClamp': 1,
    },
    labelWithEndIcon: {
        paddingRight: 0,
    },
    disabled: {
        opacity: 0.6,
    },
    endIcon: {
        position: 'relative',
        padding: spacing(0, 1.5, 0, 1),
        '& path': {
            strokeWidth: 5,
        },
    },
    color: ({ filterColor }) => ({
        width: spacing(2.25),
        height: spacing(2.25),
        borderRadius: '50%',
        backgroundColor: filterColor,
        border: `1px solid ${core_1.fade(palette.primary.main, 0.25)}`,
        margin: `0px ${spacing(0.875)}px 0px -${spacing(0.625)}px`,
    }),
    textWrap: {
        whiteSpace: 'nowrap !important',
    },
});
exports.default = styles;
