"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const Grid_1 = require("@material-ui/core/Grid");
const HBLink_1 = require("@components/library/HBLink");
const HBTypography_1 = require("@components/library/HBTypography");
const HBImage_1 = require("@components/library/HBImage");
const HBIcon_1 = require("@components/library/HBIcon");
const GalleryItemStyles_1 = require("./GalleryItemStyles");
const GalleryItem = ({ classes, className, media, subTitle, title, to, icon, noShadow }) => (React.createElement(Grid_1.default, { item: true, container: true, component: HBLink_1.default, to: to, className: className },
    React.createElement(HBImage_1.default, { src: media === null || media === void 0 ? void 0 : media.path, alt: title || subTitle, className: classes.img }),
    (title || subTitle) && (React.createElement("div", { className: classnames_1.default(classes.content, { [classes.shadow]: !noShadow }) },
        icon && (React.createElement("div", null,
            React.createElement(HBIcon_1.default, { icon: icon, fontSize: "large", color: "default" }))),
        React.createElement("div", null,
            subTitle && (React.createElement(HBTypography_1.default, { variant: "overline", component: "div", align: "center", className: classes.subtitle }, subTitle)),
            title && (React.createElement(HBTypography_1.default, { variant: "h1", align: "center", className: classes.title }, title)))))));
exports.default = withStyles_1.default(GalleryItemStyles_1.default)(GalleryItem);
