"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Svg_1 = require("../Svg");
const Visa = props => (React.createElement(Svg_1.default, Object.assign({}, props, { viewBox: "0 0 64 48" }),
    React.createElement("mask", { id: "a", fill: "#fff" },
        React.createElement("path", { d: "M22.766 9.871c-.025-1.977 1.762-3.08 3.108-3.737 1.384-.673 1.848-1.105 1.843-1.706-.01-.922-1.104-1.328-2.127-1.344-1.784-.028-2.822.482-3.647.867L21.301.943C22.128.56 23.66.229 25.25.214c3.73 0 6.17 1.842 6.184 4.697.014 3.623-5.012 3.824-4.978 5.443.012.491.48 1.015 1.508 1.149.508.067 1.911.118 3.502-.614l.624 2.91a9.52 9.52 0 0 1-3.324.61c-3.51 0-5.98-1.866-6-4.538m15.323 4.287a1.619 1.619 0 0 1-1.511-1.007L31.249.428h3.728l.741 2.05h4.556l.43-2.05h3.285l-2.867 13.73zm.522-3.709l1.075-5.156H36.74zm-20.365 3.71L15.308.428h3.552l2.937 13.73zm-5.255 0L9.294 4.812 7.8 12.759c-.176.887-.869 1.4-1.638 1.4H.117l-.085-.4c1.24-.269 2.65-.703 3.505-1.167.522-.284.671-.532.843-1.207L7.213.428h3.754l5.755 13.73z", fillRule: "evenodd" })),
    React.createElement("g", { fill: "none", fillRule: "evenodd", mask: "url(#a)", transform: "matrix(1 0 0 -1 10.056 31.468)" },
        React.createElement("path", { d: "M-4.571 12.714l43.31 15.95L48.594 1.91 5.283-14.04", fill: "#fff", fillRule: "nonzero" }))));
exports.default = Visa;
