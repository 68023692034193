"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
const Colors_1 = require("@components/library/theme/Colors");
const styles = ({ spacing, breakpoints, transitions }) => styles_1.createStyles({
    root: {
        display: 'flex',
    },
    dialog: {
        visibility: 'hidden',
        maxHeight: '80vh',
        overflow: 'hidden',
        overflowY: 'auto',
        width: '100%',
        '-ms-overflow-style': 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            '-webkit-appearance': 'none',
            display: 'none',
        },
        [breakpoints.down('sm')]: {
            height: 'auto',
        },
        transition: transitions.create(['all'], { duration: 400, easing: 'ease-out' }),
        willChange: 'top',
        position: 'absolute !important',
        zIndex: 800,
        top: '70px !important',
        right: '0px !important',
        bottom: 'auto !important',
        left: '0px !important',
        '& .MuiBackdrop-root': {
            top: 130,
        },
        ['@media (max-width:768px)']: {
            inset: '100px 0 auto 0 !important',
            '& .MuiBackdrop-root': {
                top: 180,
            },
        },
        '& .MuiCollapse-container': {
            outline: 'none',
        },
        outline: 'none',
        backgroundColor: 'transparent',
    },
    dialogOpnen: {
        visibility: 'visible',
    },
    dialogPaper: {
        backgroundColor: 'transparent',
    },
    dialogContent: {
        padding: 0,
        '&:first-child': {
            padding: 0,
        },
        outline: 'none',
        backgroundColor: 'transparent',
    },
    paper: {
        backgroundColor: Colors_1.default.backgroundGrey,
        marginTop: spacing(2.5),
        borderRadius: 0,
        width: '100%',
        left: '0 !important',
        outline: 'none',
    },
    menuWrapper: {
        padding: 0,
        outline: 'none',
    },
    arrow: {
        position: 'absolute',
        fontSize: spacing(1.75),
        width: spacing(6),
        height: spacing(6),
        marginTop: `-${spacing(1.75)}px`,
        '&:before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid',
            borderColor: `transparent transparent ${Colors_1.default.backgroundGrey} transparent`,
            borderWidth: `0 ${spacing(2)}px ${spacing(2)}px ${spacing(2)}px`,
        },
    },
});
exports.default = styles;
