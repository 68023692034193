"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const core_1 = require("@material-ui/core");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const Grid_1 = require("@material-ui/core/Grid");
const HBTypography_1 = require("@components/library/HBTypography");
const FormElement_1 = require("@components/common/utils/Form/FormElement");
const FormButton_1 = require("@components/common/utils/Form/FormButton/FormButton");
const LabelContext_1 = require("@components/common/context/LabelContext");
const styles = ({ spacing, breakpoints }) => core_1.createStyles({
    container: {
        padding: spacing(5),
        minHeight: 640,
        display: 'flex',
        flexDirection: 'column',
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 600,
        flexGrow: 1,
    },
    title: {
        paddingBottom: spacing(3),
    },
    content: {
        flexGrow: 1,
    },
    actions: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: spacing(3),
        [breakpoints.down('xs')]: {
            flexDirection: 'column',
            '& button': {
                marginBottom: spacing(2),
            },
        },
    },
});
const ProfileEditForm = ({ labels, classes, title, description, form: { elements, button }, disabled, error, errors, onCancel, }) => (React.createElement("div", { className: classes.container },
    React.createElement("div", { className: classes.wrapper },
        React.createElement("div", { className: classes.title },
            React.createElement(HBTypography_1.default, { variant: "h5", align: "center", capitalize: true, marginBottom: 4 }, title),
            React.createElement(HBTypography_1.default, { variant: "body1" }, description)),
        React.createElement(Grid_1.default, { container: true, direction: "column", spacing: 3, className: classes.content }, elements === null || elements === void 0 ? void 0 : elements.map((element, index) => (React.createElement(Grid_1.default, { key: element.name + index.toString(), item: true },
            React.createElement(FormElement_1.default, Object.assign({ disabled: disabled }, element, { error: error, errors: errors })))))),
        React.createElement("div", { className: classes.actions },
            React.createElement(FormButton_1.default, { label: labels.CANCEL, variant: "text", type: "button", onClick: onCancel, disabled: disabled }),
            React.createElement(FormButton_1.default, Object.assign({}, button, { disabled: disabled }))))));
exports.default = withStyles_1.default(styles)(LabelContext_1.withLabel(ProfileEditForm));
