"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const createStyles_1 = require("@material-ui/core/styles/createStyles");
const Grid_1 = require("@material-ui/core/Grid");
const Tab_1 = require("@material-ui/core/Tab");
const Tabs_1 = require("@material-ui/core/Tabs");
const TabScrollButton_1 = require("./TabScrollButton");
const HBImage_1 = require("@components/library/HBImage/HBImage");
const react_swipeable_views_core_1 = require("react-swipeable-views-core");
const styles = ({ palette, transitions, spacing }) => createStyles_1.default({
    root: {
        maxHeight: 520,
    },
    centeredThumbs: {
        maxWidth: 600,
    },
    tabs: {
        position: 'relative',
        alignItems: 'center',
    },
    thumbImage: {
        margin: 'auto',
        height: 104,
        maxWidth: 100,
        transition: transitions.create(['all']),
        opacity: 0.4,
        outline: 'none',
        '&:not(:last-child)': {
            marginBottom: spacing(2),
        },
        '&:hover': {
            opacity: 1,
            cursor: 'pointer',
        },
    },
    activeSlide: {
        opacity: 1,
    },
    indicator: {
        width: '100%',
        borderBottom: `4px solid ${palette.primary.main}`,
        backgroundColor: 'transparent',
    },
});
const ProductThumbImage = React.forwardRef(({ src, name, ...rest }, ref) => (React.createElement(Grid_1.default, Object.assign({ innerRef: ref, item: true }, rest),
    React.createElement(HBImage_1.default, { src: src, alt: name }))));
const ProductThumbImageList = ({ classes, images = [], handleThumbImage, direction = 'left', index = 0, }) => {
    const [activeThumbIndex, setActviveThumbIndex] = React.useState(index);
    React.useEffect(() => {
        setActviveThumbIndex(index);
    }, [index]);
    const handleActiveThumb = (event, newValue) => {
        setActviveThumbIndex(newValue);
        handleThumbImage(images[newValue].src, newValue);
    };
    return (React.createElement(Grid_1.default, { container: true, direction: "column", className: classnames_1.default(classes.root, {
            [classes.centeredThumbs]: direction === 'top' || direction === 'bottom',
        }) },
        React.createElement(Tabs_1.default, { orientation: direction === 'left' || direction === 'right' ? 'vertical' : 'horizontal', variant: "scrollable", value: react_swipeable_views_core_1.mod(activeThumbIndex, images.length), onChange: handleActiveThumb, className: classes.tabs, classes: { indicator: classes.indicator }, ScrollButtonComponent: TabScrollButton_1.default }, images === null || images === void 0 ? void 0 : images.map((item, index) => (React.createElement(Tab_1.default, Object.assign({ component: ProductThumbImage, key: item.src + index }, { src: item.thumbnail }, { className: classnames_1.default(classes.thumbImage, {
                [classes.activeSlide]: react_swipeable_views_core_1.mod(activeThumbIndex, images.length) === index,
            }) })))))));
};
exports.default = withStyles_1.default(styles)(ProductThumbImageList);
