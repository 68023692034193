"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const styles_1 = require("@material-ui/core/styles");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const Colors_1 = require("@components/library/theme/Colors");
const Card_1 = require("@material-ui/core/Card");
const CardHeader_1 = require("@material-ui/core/CardHeader");
const CardContent_1 = require("@material-ui/core/CardContent");
const Collapse_1 = require("@material-ui/core/Collapse");
const HBTypography_1 = require("@components/library/HBTypography");
const HBIcon_1 = require("@components/library/HBIcon");
const styles = ({ spacing, breakpoints }) => styles_1.createStyles({
    root: {
        padding: spacing(2),
        marginBottom: spacing(2),
        position: 'relative',
        borderRadius: 0,
        [breakpoints.up('sm')]: {
            padding: spacing(5),
        },
    },
    background: {
        backgroundColor: Colors_1.default.backgroundGrey,
    },
    disabled: {
        opacity: 0.4,
    },
    header: {
        padding: spacing(0, 0, 2, 0),
        [breakpoints.up('sm')]: {
            padding: spacing(0, 0, 4, 0),
        },
    },
    disabledHeader: {
        paddingBottom: 0,
    },
    content: {
        padding: spacing(0),
        '&.MuiCardContent-root:last-child': {
            paddingBottom: spacing(0),
        },
    },
    icon: {
        position: 'absolute',
        right: 0,
        top: 0,
    },
});
const Step = ({ classes, noBackground, title, children, active, completed = false, onChange, isEditing, isEditable = true, summary, }) => {
    return (React.createElement(Card_1.default, { className: classnames_1.default(classes.root, {
            [classes.background]: !noBackground,
            [classes.disabled]: !active && !completed,
        }), elevation: 0 },
        title && (React.createElement(CardHeader_1.default, { className: classnames_1.default(classes.header, { [classes.disabledHeader]: !active && !completed }), title: React.createElement(HBTypography_1.default, { variant: "h6" }, title), action: completed &&
                !isEditing &&
                isEditable &&
                onChange && React.createElement(HBIcon_1.default, { icon: "edit", isButton: true, ButtonProps: { onClick: onChange } }) })),
        completed && !isEditing && summary,
        React.createElement(Collapse_1.default, { in: active || isEditing },
            React.createElement(CardContent_1.default, { className: classes.content }, children))));
};
exports.default = withStyles_1.default(styles)(Step);
