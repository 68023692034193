"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const createStyles_1 = require("@material-ui/core/styles/createStyles");
const styles_1 = require("@material-ui/core/styles");
const styles = ({ palette, spacing, breakpoints, transitions }) => createStyles_1.default({
    header: {
        transition: transitions.create(['all']),
        backgroundColor: palette.primary.main,
        color: palette.common.white,
        padding: spacing(2.5, 4.5),
        [breakpoints.down('sm')]: {
            padding: spacing(2),
        },
    },
    content: {
        backgroundColor: palette.common.white,
        padding: spacing(0, 5, 5),
        [breakpoints.down('sm')]: {
            padding: spacing(0, 2.5, 2.5),
        },
    },
    grow: {
        flexGrow: 1,
    },
    productList: {
        padding: spacing(2.5, 0),
        [breakpoints.up('md')]: {
            padding: spacing(5, 0),
        },
    },
    product: {
        padding: spacing(1),
    },
    orderTitleWrapper: {
        [breakpoints.up('sm')]: {
            flexWrap: 'nowrap',
        },
    },
    orderTitle: {
        [breakpoints.down('xs')]: {
            justifyContent: 'space-between',
        },
    },
    orderSummary: {
        justifyContent: 'initial',
        [breakpoints.up('sm')]: {
            justifyContent: 'flex-end',
        },
        borderTop: `1px solid ${styles_1.fade(palette.primary.main, 0.5)}`,
        padding: spacing(2.5, 0),
    },
    orderSummaryInner: {
        [breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    addressSummaryTitle: {
        borderBottom: `1px solid ${styles_1.fade(palette.primary.main, 0.5)}`,
    },
    addressSummaryContent: {
        paddingTop: spacing(2),
    },
    addressSummaryContentInner: {
        [breakpoints.down('sm')]: {
            marginTop: spacing(2.5),
        },
    },
    buttonsWrapper: {
        justifyContent: 'flex-end',
        flexWrap: 'nowrap',
        [breakpoints.down('xs')]: {
            flexWrap: 'wrap',
        },
        '& button': {
            [breakpoints.up('md')]: {
                padding: spacing(1.3, 7),
            },
            [breakpoints.down('xs')]: {
                width: '100%',
            },
        },
        '& button + button': {
            marginLeft: spacing(3),
            [breakpoints.down('sm')]: {
                marginLeft: spacing(1.5),
            },
            [breakpoints.down('xs')]: {
                marginTop: spacing(2),
                marginLeft: 0,
            },
        },
    },
    spacing: {
        marginTop: spacing(5),
    },
    productImageWrapper: {
        height: 155,
        width: 155,
    },
});
exports.default = styles;
