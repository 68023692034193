"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Radio_1 = require("@material-ui/core/Radio");
const CheckCircleFilled_1 = require("../icons/CheckCircleFilled");
const CheckCircleOutline_1 = require("../icons/CheckCircleOutline");
const styles_1 = require("@material-ui/core/styles");
const HBRadio = styles_1.withStyles(() => ({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '&.Mui-disabled': {
            '& svg': {
                opacity: '0.3',
            },
        },
    },
}))((props) => (React.createElement(Radio_1.default, Object.assign({ icon: React.createElement(CheckCircleOutline_1.default, null), checkedIcon: React.createElement(CheckCircleFilled_1.default, { color: "ternary" }), color: "default" }, props))));
exports.default = HBRadio;
