"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getItemCount = void 0;
const React = require("react");
const classnames_1 = require("classnames");
const react_slick_1 = require("react-slick");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const HBIcon_1 = require("@components/library/HBIcon");
const CarouselStyles_1 = require("./CarouselStyles");
exports.getItemCount = (items, max) => (items.length > max ? max : items.length);
const SlickButtonFix = ({ currentSlide, slideCount, className, arrows, children, ...props }) => (React.createElement("span", Object.assign({ className: classnames_1.default(arrows, className) }, props), children));
const defaultArrow = (color, icon) => React.createElement(HBIcon_1.default, { icon: icon, color: color, isButton: true, ButtonProps: { color: color } });
const Carousel = ({ classes, prevArrow, nextArrow, prevArrowIcon, nextArrowIcon, itemRef, className, asNavFor, items = [], color = 'default', responsive = [], showArrowsOnHover = true, ...sliderSettings }) => (React.createElement("div", { className: classnames_1.default(classes.mainContainer, classes[color]) },
    React.createElement("div", { className: classnames_1.default(className, classes.sliderContainer, { [classes.showArrowsOnHover]: showArrowsOnHover }) },
        React.createElement(react_slick_1.default, Object.assign({ asNavFor: asNavFor, ref: itemRef }, sliderSettings, { responsive: [
                ...responsive,
                {
                    breakpoint: 769,
                    settings: {
                        arrows: false,
                    },
                },
            ], prevArrow: React.createElement(SlickButtonFix, { arrows: classnames_1.default({ [classes.prev]: !prevArrow }) }, defaultArrow(color, prevArrowIcon || 'arrow_left')), nextArrow: React.createElement(SlickButtonFix, { arrows: classnames_1.default({ [classes.next]: !nextArrow }) }, defaultArrow(color, nextArrowIcon || 'arrow_right')) }), items))));
exports.default = withStyles_1.default(CarouselStyles_1.default)(Carousel);
