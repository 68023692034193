"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLoadMoreResults = void 0;
const React = require("react");
exports.useLoadMoreResults = (api, initialData = {}) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const endReached = React.useRef(false);
    const [loadedResults, setLoadedResults] = React.useState(() => initialData);
    const loadMoreProducts = async (data) => {
        setIsLoading(true);
        try {
            if (!endReached.current) {
                const { request } = api.loadMoreResults(data);
                const result = await request;
                const { page: { products = [] } = {} } = ({} = result);
                setLoadedResults(previousData => ({
                    ...previousData,
                    products: [...previousData === null || previousData === void 0 ? void 0 : previousData.products, ...products],
                }));
                if (!(products === null || products === void 0 ? void 0 : products.length)) {
                    endReached.current = true;
                }
            }
            else {
                setIsLoading(false);
            }
        }
        catch (error) {
            console.error(error);
        }
        finally {
            setIsLoading(false);
        }
    };
    return {
        loadedResults,
        setLoadedResults,
        loadMoreProducts,
        endReached,
        isLoading,
    };
};
