"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const TextField_1 = require("@material-ui/core/TextField");
const InputAdornment_1 = require("@material-ui/core/InputAdornment");
const HBIcon_1 = require("@components/library/HBIcon");
const HBFilter_1 = require("@components/library/HBFilter");
const LabelContext_1 = require("@components/common/context/LabelContext");
const HBButton_1 = require("@components/library/HBButton");
const useMediaQuery_1 = require("@material-ui/core/useMediaQuery");
const SearchInputContext_1 = require("@components/common/context/SearchInputContext");
const SearchContext_1 = require("@components/common/context/SearchContext");
const SearchInput = React.memo(({ classes }) => {
    const { focused, handleCloseSearch, searchSelectedTerms, setSearchTerms, searchTerm, handleSearchTerm, handleDeleteSearchFilter, handleDeleteSearchTerm, handleClearSearchTerms, } = SearchInputContext_1.useSearchInputContext();
    const { selectedFilterIds, selectedFilters = [] } = SearchContext_1.useSearchContext();
    const isTablet = useMediaQuery_1.default('(max-width:760px)');
    const labels = LabelContext_1.useLabelContext();
    const hasSearchTerms = !!(selectedFilters === null || selectedFilters === void 0 ? void 0 : selectedFilters.length) || !!(searchSelectedTerms === null || searchSelectedTerms === void 0 ? void 0 : searchSelectedTerms.length);
    const placeholder = !hasSearchTerms
        ? !focused
            ? labels.SEARCH_INPUT_PLACEHOLDER
            : labels.SEARCH_TYPEAHEAD_INPUT_PLACEHOLDER
        : '';
    const handleKeyDown = (event) => {
        if (event.keyCode === 13 || event.keyCode === 9) {
            handleSearchTerm('');
            if (searchTerm)
                setSearchTerms(prevTerms => [...prevTerms, searchTerm]);
        }
        if (event.keyCode === 27) {
            handleCloseSearch();
        }
    };
    React.useEffect(() => { }, [selectedFilterIds]);
    const handleChange = (event) => handleSearchTerm(event.target.value);
    const getIcon = (filterType) => {
        if (filterType === null || filterType === void 0 ? void 0 : filterType.includes('filterColor')) {
            return 'color';
        }
        if (filterType === null || filterType === void 0 ? void 0 : filterType.includes('color')) {
            return 'color';
        }
        if (filterType === null || filterType === void 0 ? void 0 : filterType.includes('material')) {
            return 'material';
        }
        if (filterType === null || filterType === void 0 ? void 0 : filterType.includes('category')) {
            return 'category';
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(TextField_1.default, { fullWidth: true, onKeyDown: handleKeyDown, placeholder: placeholder, onChange: handleChange, value: searchTerm, InputProps: {
                disabled: !focused,
                inputRef: input => input && input.focus(),
                startAdornment: (React.createElement("div", { className: classnames_1.default(classes.searchIconWrapper, {
                        [classes.searchIconWrapperFocused]: focused,
                        [classes.searchIconWrapperSpacing]: focused && !!hasSearchTerms,
                    }) },
                    React.createElement(InputAdornment_1.default, { position: "start", className: classnames_1.default(classes.searchIcon, { [classes.searchIconSpacing]: !!hasSearchTerms }) },
                        React.createElement(HBIcon_1.default, { icon: "search", color: "primary" })),
                    React.createElement("div", { className: classnames_1.default(classes.searchTerms, {
                            [classes.searchTermsFocused]: focused,
                            [classes.searchTermsFilled]: !!hasSearchTerms,
                        }) },
                        selectedFilters.map(filter => filter && (React.createElement(HBFilter_1.default, { key: filter.id, label: filter.title, startIcon: getIcon(filter.type), endIcon: 'close', onDelete: handleDeleteSearchFilter(filter), textWrap: true }))),
                        searchSelectedTerms.map(item => (React.createElement(HBFilter_1.default, { key: item, startIcon: 'edit', endIcon: 'close', label: item, onDelete: handleDeleteSearchTerm(item), textWrap: true })))))),
                classes: {
                    root: classnames_1.default(classes.inputBase, {
                        [classes.inputBaseFocused]: focused,
                        [classes.inputBaseFocusedSpacing]: focused && !!hasSearchTerms,
                    }),
                    input: classnames_1.default(classes.input, {
                        [classes.inputFocused]: focused,
                        [classes.inputVisible]: !hasSearchTerms,
                    }),
                },
            } }),
        focused &&
            !!hasSearchTerms &&
            (isTablet ? (React.createElement(HBIcon_1.default, { icon: "remove", isButton: true, ButtonProps: {
                    onClick: handleClearSearchTerms,
                    className: classes.searchTermClearButton,
                } })) : (React.createElement(HBButton_1.default, { className: classes.searchTermClearButton, color: "primary", variant: "text", label: "L\u00F6schen", onClick: handleClearSearchTerms })))));
});
exports.default = SearchInput;
