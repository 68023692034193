"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const HBContainer_1 = require("@components/library/HBContainer");
const FullWidthBackground_1 = require("@components/common/utils/FullWidthBackground/FullWidthBackground");
const HBTypography_1 = require("@components/library/HBTypography");
const ProductList_1 = require("@components/pages/product/ProductList");
const LabelContext_1 = require("@components/common/context/LabelContext");
const ProductDetailContext_1 = require("@components/common/context/ProductDetailContext");
const Grid_1 = require("@material-ui/core/Grid");
const ProductImage_1 = require("@components/pages/product/detail/ProductImage");
const ProductBrand_1 = require("@components/pages/product/detail/ProductBrand/ProductBrand");
const ProductTags_1 = require("@components/pages/product/detail/ProductTags");
const ProductColorVariants_1 = require("@components/pages/product/detail/ProductColorVariants/ProductColorVariants");
const Divider_1 = require("@material-ui/core/Divider");
const ProductPriceCartWrapper_1 = require("@components/pages/product/detail/ProductPriceCartWrapper");
const HBExpander_1 = require("@components/library/HBExpander/HBExpander");
const ProductDescription_1 = require("@components/pages/product/detail/ProductDescription/ProductDescription");
const ProductShippingInfo_1 = require("@components/pages/product/detail/ProductShippingInfo/ProductShippingInfo");
const ProductSummary_1 = require("@components/pages/product/detail/ProductSummary/ProductSummary");
const ProductDownloads_1 = require("@components/pages/product/detail/ProductDownloads/ProductDownloads");
const makeStyles_1 = require("@material-ui/core/styles/makeStyles");
const ProductDropdownVariants_1 = require("./ProductDropdownVariants");
const useStyles = makeStyles_1.default(({ spacing }) => ({
    flexGrow: {
        flexGrow: 1,
    },
    title: {
        marginBottom: spacing(3),
    },
    tagsWrapper: {
        marginBottom: spacing(4),
    },
    productImgWrapper: {
        marginBottom: spacing(7),
    },
}));
const ProductDetail = () => {
    const classes = useStyles();
    const labels = LabelContext_1.useLabelContext();
    const { product: { _id, name, alternateName, description, features, colors, brand, tags, colorVariants, dropdownVariants }, relatedProducts = [], downloads, shippingInfo, summary, } = ProductDetailContext_1.useProductDetailContext();
    return (React.createElement(React.Fragment, null,
        React.createElement(HBContainer_1.default, null,
            React.createElement(Grid_1.default, { container: true },
                React.createElement(Grid_1.default, { item: true, container: true, spacing: 2, className: classes.productImgWrapper },
                    React.createElement(Grid_1.default, { item: true, xs: 12, sm: 12, md: 7 },
                        React.createElement(ProductImage_1.default, null)),
                    React.createElement(Grid_1.default, { item: true, xs: 12, md: 5 },
                        brand && React.createElement(ProductBrand_1.default, Object.assign({}, brand)),
                        React.createElement(HBTypography_1.default, { variant: "h4", component: "h6", className: classes.title }, name),
                        React.createElement(HBTypography_1.default, { variant: "h6", className: classes.title }, alternateName),
                        React.createElement(ProductTags_1.default, { tags: tags, className: classnames_1.default(classes.tagsWrapper, classes.flexGrow) }),
                        !!(colorVariants === null || colorVariants === void 0 ? void 0 : colorVariants.length) && (React.createElement(ProductColorVariants_1.default, { id: _id.toString(), variants: colorVariants, colors: colors })),
                        !!(dropdownVariants === null || dropdownVariants === void 0 ? void 0 : dropdownVariants.length) &&
                            dropdownVariants.map(currentVariant => {
                                return (React.createElement(ProductDropdownVariants_1.default, { id: _id.toString(), variants: currentVariant.products, type: currentVariant.type, dropdownLabel: currentVariant === null || currentVariant === void 0 ? void 0 : currentVariant.label }));
                            }),
                        React.createElement(Divider_1.default, null),
                        React.createElement(ProductPriceCartWrapper_1.default, null))),
                React.createElement(Grid_1.default, { item: true },
                    React.createElement(HBExpander_1.default, { border: false },
                        React.createElement(Grid_1.default, { container: true, justify: "space-between", spacing: 3 },
                            React.createElement(Grid_1.default, { item: true, container: true, spacing: 2, xs: 12, sm: 6, md: 5 },
                                React.createElement(ProductDescription_1.default, { description: description, features: features })),
                            React.createElement(Grid_1.default, { item: true, xs: 12, sm: 6, md: 5 },
                                React.createElement(Grid_1.default, { container: true, spacing: 5 },
                                    !!shippingInfo.length && React.createElement(ProductShippingInfo_1.default, { shippingInfo: shippingInfo }),
                                    !!summary.length && React.createElement(ProductSummary_1.default, { summary: summary }),
                                    !!downloads.length && React.createElement(ProductDownloads_1.default, { downloads: downloads })))))))),
        !!relatedProducts.length && (React.createElement(FullWidthBackground_1.default, { border: true },
            React.createElement(HBTypography_1.default, { variant: "h4", align: "center", marginBottom: 4 }, labels.SIMILAR_PRODUCTS),
            React.createElement(ProductList_1.default, { products: relatedProducts })))));
};
exports.default = ProductDetail;
