"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_router_dom_1 = require("react-router-dom");
const classnames_1 = require("classnames");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const useMediaQuery_1 = require("@material-ui/core/useMediaQuery");
const Paper_1 = require("@material-ui/core/Paper");
const DialogContent_1 = require("@material-ui/core/DialogContent");
const Collapse_1 = require("@material-ui/core/Collapse");
const ClickAwayListener_1 = require("@material-ui/core/ClickAwayListener");
const MenuList_1 = require("@material-ui/core/MenuList");
const HBIconButton_1 = require("@components/library/HBIconButton");
const HBDialog_1 = require("@components/library/HBDialog");
const CartDrawer_1 = require("@components/pages/cart/CartDrawer");
const MenuItemStyles_1 = require("./MenuItemStyles");
const MenuItem = ({ classes, label, MenuIcon, enableArrow = true, active = false, className, children, to, responsive = false, type, }) => {
    var _a, _b;
    const isTablet = useMediaQuery_1.default('(max-width:768px)');
    const [open, setOpen] = React.useState(false);
    const [arrowRef, setArrowRef] = React.useState(null);
    const [activeItem, setActiveItem] = React.useState(active);
    const anchorRef = React.useRef(null);
    const history = react_router_dom_1.useHistory();
    const handleToggle = e => {
        e.preventDefault();
        setOpen(prevOpen => !prevOpen);
    };
    const handleClick = e => {
        e.preventDefault();
        history.push(to);
    };
    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    const handleListKeyDown = event => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    };
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
        setActiveItem(open);
    }, [open]);
    React.useEffect(() => {
        setOpen(false);
    }, [(_a = history === null || history === void 0 ? void 0 : history.location) === null || _a === void 0 ? void 0 : _a.pathname]);
    return type === 'cart' ? (React.createElement(CartDrawer_1.default, { icon: MenuIcon, className: className, label: label, anchor: "right" })) : (React.createElement(React.Fragment, null,
        React.createElement(HBIconButton_1.default, { buttonRef: anchorRef, "aria-controls": open ? 'menu-list-grow' : undefined, "aria-haspopup": "true", onClick: !!to ? handleClick : handleToggle, className: classnames_1.default(className, { active: activeItem || ((_b = history === null || history === void 0 ? void 0 : history.location) === null || _b === void 0 ? void 0 : _b.pathname) === to }), label: label }, MenuIcon),
        children &&
            (isTablet && responsive ? (React.createElement(HBDialog_1.default, { open: open, onClose: handleClose, fullScreen: true }, children)) : (React.createElement(HBDialog_1.default, { fullScreen: true, TransitionComponent: Collapse_1.default, transitionDuration: 600, keepMounted: true, disablePortal: true, open: open, onClose: handleClose, className: classnames_1.default(classes.dialog, { [classes.dialogOpnen]: open }), PaperProps: { elevation: 0 }, classes: { paper: classes.dialogPaper }, hideIcon: true },
                React.createElement(DialogContent_1.default, { className: classes.dialogContent },
                    React.createElement(Paper_1.default, { className: classes.paper },
                        React.createElement(ClickAwayListener_1.default, { onClickAway: handleClose },
                            React.createElement(MenuList_1.default, { autoFocusItem: open, id: "menu-list-grow", onKeyDown: handleListKeyDown, className: classes.menuWrapper }, children)))))))));
};
exports.default = withStyles_1.default(MenuItemStyles_1.default)(MenuItem);
