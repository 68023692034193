"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const makeStyles_1 = require("@material-ui/core/styles/makeStyles");
const Header_1 = require("@components/common/layout/Header");
const Footer_1 = require("@components/common/layout/Footer");
const react_transition_group_1 = require("react-transition-group");
const BackToTop_1 = require("@components/common/BackToTop");
const useStyles = makeStyles_1.default(({ spacing, breakpoints }) => ({
    root: {},
    main: {
        flexGrow: 1,
        paddingTop: ({ paddingTop }) => (paddingTop !== undefined ? spacing(paddingTop) : spacing(4)),
        paddingBottom: ({ paddingBottom }) => (paddingBottom !== undefined ? spacing(paddingBottom) : spacing(4)),
        [breakpoints.up('md')]: {
            paddingTop: ({ paddingTop }) => (paddingTop !== undefined ? spacing(paddingTop) : spacing(6)),
            paddingBottom: ({ paddingBottom }) => (paddingBottom !== undefined ? spacing(paddingBottom) : spacing(6)),
        },
    },
    fadeEnter: {
        opacity: 0,
        transform: `translate3d(0, ${-spacing(2)}px, 0)`,
    },
    fadeEnterActive: {
        opacity: 1,
        transform: 'translate3d(0,0,0)',
        transition: `opacity 350ms 100ms,
       transform 350ms ease-out 100ms
      `,
    },
}));
const PageWrapper = ({ className, paddingBottom, paddingTop, children, header, footer, routeKey, style, page, }) => {
    const classes = useStyles({ paddingBottom, paddingTop });
    return (React.createElement(React.Fragment, null,
        React.createElement(Header_1.default, Object.assign({}, header, page)),
        React.createElement("main", { className: classnames_1.default(classes.main, className), style: style },
            React.createElement(react_transition_group_1.TransitionGroup, null,
                React.createElement(react_transition_group_1.CSSTransition, { key: routeKey, timeout: { enter: 500, exit: 0 }, classNames: {
                        enter: classes.fadeEnter,
                        enterActive: classes.fadeEnterActive,
                    }, style: { height: '100%' } }, children))),
        React.createElement(Footer_1.default, Object.assign({}, footer)),
        React.createElement(BackToTop_1.default, null)));
};
exports.default = PageWrapper;
