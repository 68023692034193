"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_html_parser_1 = require("react-html-parser");
const styles_1 = require("@material-ui/core/styles");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const Grid_1 = require("@material-ui/core/Grid");
const HBContainer_1 = require("@components/library/HBContainer");
const withLayout_1 = require("@components/common/layout/withLayout");
const HBTypography_1 = require("@components/library/HBTypography");
const ProductList_1 = require("@components/pages/product/ProductList");
const Zero_1 = require("@components/library/icons/Zero");
const AppContext_1 = require("@components/common/context/AppContext");
const LabelContext_1 = require("@components/common/context/LabelContext");
const FullWidthBackground_1 = require("@components/common/utils/FullWidthBackground");
const styles = ({ spacing, breakpoints, typography }) => styles_1.createStyles({
    wishlistContainer: {
        padding: spacing(4, 0, 6, 0),
        [breakpoints.up('md')]: {
            padding: spacing(6, 0, 9, 0),
        },
    },
    recentlyVisitedContainer: {
        padding: spacing(4, 0, 6, 0),
        [breakpoints.up('md')]: {
            padding: spacing(6, 0, 9, 0),
        },
    },
    emptyWishlist: {
        padding: '84px 0 104px 0',
        '& h3': {
            textAlign: 'center',
            ...typography.h6,
        },
        '& p': {
            textAlign: 'center',
        },
    },
});
const PageWishlist = ({ classes, page: { content, recentlyVisited = [] } }) => {
    const { state: { wishlist = [] } = {} } = AppContext_1.useAppContext();
    const labels = LabelContext_1.useLabelContext();
    return (React.createElement(React.Fragment, null,
        React.createElement(FullWidthBackground_1.default, { className: classes.wishlistContainer },
            React.createElement(Grid_1.default, { item: true, container: true, direction: "column", justify: "center" },
                React.createElement(HBTypography_1.default, { variant: "h3", align: "center", marginBottom: 5 }, labels.WISHLIST),
                !!(wishlist === null || wishlist === void 0 ? void 0 : wishlist.length) ? (React.createElement(ProductList_1.default, { products: wishlist, cartButton: true })) : (React.createElement(Grid_1.default, { item: true, className: classes.emptyWishlist },
                    React.createElement(HBContainer_1.default, { maxWidth: "xs" },
                        React.createElement(Grid_1.default, { direction: "column", item: true, container: true, justify: "center", alignItems: "center" },
                            React.createElement(Zero_1.default, null),
                            react_html_parser_1.default(content))))))),
        !!(recentlyVisited === null || recentlyVisited === void 0 ? void 0 : recentlyVisited.length) && (React.createElement(Grid_1.default, { className: classes.recentlyVisitedContainer },
            React.createElement(HBContainer_1.default, null,
                React.createElement(Grid_1.default, { item: true, container: true, direction: "column", justify: "center" },
                    React.createElement(Grid_1.default, { item: true },
                        React.createElement(HBTypography_1.default, { variant: "h4", align: "center", marginBottom: 4 }, labels.LAST_SEEN)),
                    React.createElement(Grid_1.default, { item: true },
                        React.createElement(ProductList_1.default, { products: recentlyVisited, cartButton: true }))))))));
};
exports.default = withLayout_1.default(withStyles_1.default(styles)(PageWishlist), {
    paddingTop: 0,
    paddingBottom: 0,
});
