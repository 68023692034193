"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
const typography_1 = require("@components/library/theme/typography");
const styles = ({ palette, spacing, breakpoints, transitions }) => styles_1.createStyles({
    icon: {
        transition: transitions.create(['all']),
        color: palette.primary.main,
        borderBottom: '2px solid transparent',
        padding: 0,
        borderRadius: 0,
        opacity: 0.8,
        '& .MuiIconButton-label': {
            width: '100%',
            display: 'inline-flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        '& svg': {
            opacity: 0.8,
            [breakpoints.down('lg')]: {
                width: spacing(2.5),
                height: spacing(2.5),
            },
        },
        '&:hover, &.active': {
            background: 'transparent',
            opacity: 1,
            textDecoration: 'none',
            '& svg': {
                opacity: 1,
            },
            '& span': {
                opacity: 1,
            },
        },
        [breakpoints.down('sm')]: {
            '& svg': {
                opacity: 1,
            },
        },
    },
    iconUnderline: {
        '&:hover, &.active': {
            ['@media (min-width:768px)']: {
                borderBottomColor: palette.secondary.main,
            },
        },
    },
    hoverline: {
        display: 'inline-block',
        position: 'relative',
        '&:before': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            width: 0,
            right: 0,
            height: 1.5,
            transition: 'width .4s cubic-bezier(0.51, 0.18, 0, 0.88) .1s',
            background: palette.secondary.main,
        },
        '&:hover, &.active': {
            ['@media (min-width:768px)']: {
                '&:before': {
                    width: '100%',
                    left: 0,
                },
            },
        },
    },
    caption: {
        fontFamily: typography_1.fontFamilyPrimary,
        ['@media (max-width:768px)']: {
            display: 'none',
        },
    },
});
exports.default = styles;
