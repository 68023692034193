"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
const styles = ({ palette, transitions, breakpoints }) => styles_1.createStyles({
    mainContainer: {
        position: 'relative',
        width: '100%',
    },
    sliderContainer: {
        width: '100%',
        overflow: 'hidden',
        '& .slick-track': {
            display: 'flex',
        },
        '& .slick-vertical .slick-track': {
            flexDirection: 'column',
        },
        '& .slick-slide': {
            display: 'inline-flex',
            '& > div': {
                width: '100%',
                height: '100%',
            },
        },
        '& .slick-arrow': {
            transition: transitions.create(['all'], { duration: 400 }),
        },
        '& .slick-vertical .slick-arrow': {
            padding: '10px',
            display: 'flex !important',
            justifyContent: 'center',
            position: 'absolute',
            width: '100%',
            backgroundColor: 'rgba(26, 26, 26, 0.9)',
            textIndent: '-9999px',
            border: 'none',
            outline: 'none',
            zIndex: '9999',
        },
        '& .slick-vertical .slick-arrow svg': {
            width: '1rem',
            height: '1rem',
        },
        '& .slick-vertical .slick-prev': {
            top: 0,
        },
        '& .slick-vertical .slick-next': {
            bottom: 0,
        },
        '& .slick-vertical .slick-disabled': {
            visibility: 'hidden',
            opacity: 0,
        },
        '& .slick-dots': {
            transition: transitions.create(['all']),
            position: 'absolute',
            width: '100%',
            display: 'flex !important',
            justifyContent: 'center',
            margin: 0,
            bottom: 0,
            padding: '1rem 0',
            listStyle: 'none',
        },
        '& .slick-dots li': {
            margin: '0 0.25rem',
        },
        '& .slick-dots button': {
            outline: 'none',
            display: 'block',
            width: '10px',
            height: '10px',
            [breakpoints.up('md')]: {
                width: '12px',
                height: '12px',
            },
            padding: 0,
            border: 'none',
            borderRadius: '100%',
            backgroundColor: palette.primary.main,
            textIndent: '-9999px',
            opacity: 0.3,
        },
        '& .slick-dots li.slick-active button': {
            opacity: 0.8,
        },
    },
    showArrowsOnHover: {
        '& .slick-prev': {
            left: -30,
            opacity: 0,
        },
        '& .slick-next': {
            right: -30,
            opacity: 0,
        },
        '&:hover': {
            '& .slick-prev': {
                left: 0,
                opacity: 1,
            },
            '& .slick-next': {
                right: 0,
                opacity: 1,
            },
        },
    },
    default: {
        '& .slick-dots button': {
            backgroundColor: palette.common.white,
        },
    },
    primary: {
        '& .slick-dots button': {
            backgroundColor: palette.primary.main,
        },
    },
    secondary: {
        '& .slick-dots button': {
            backgroundColor: palette.secondary.main,
        },
    },
    prev: {
        position: 'absolute',
        transform: 'translateY(-50%)',
        left: 0,
        zIndex: 1,
        top: '50%',
        cursor: 'pointer',
    },
    next: {
        position: 'absolute',
        transform: 'translateY(-50%)',
        right: 0,
        zIndex: 1,
        top: '50%',
        cursor: 'pointer',
    },
});
exports.default = styles;
