"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const styles_1 = require("@material-ui/core/styles");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const react_hook_form_1 = require("react-hook-form");
const TextField_1 = require("@material-ui/core/TextField");
const HBIcon_1 = require("@components/library/HBIcon");
const InputAdornment_1 = require("@material-ui/core/InputAdornment");
const styles = ({ palette }) => styles_1.createStyles({
    asterisk: {
        opacity: 0,
        visibility: 'hidden',
    },
    passwordIcon: {
        '&:hover': {
            color: palette.primary.main,
        },
    },
});
function FormTextField(props) {
    var _a, _b;
    const { classes, name, errors: formErrors = [], error, helperText, fullWidth = true, hideAsterisk, validationRules = {}, ...otherProps } = props;
    const { register, errors, getValues } = react_hook_form_1.useFormContext();
    let rules = validationRules;
    if (rules.pattern) {
        const value = rules.pattern.value;
        rules = { ...rules, pattern: { ...rules.pattern, value: new RegExp(value) } };
    }
    if (props.name === 'passwordRetype' && rules.validate) {
        const passwordMessage = rules.validate.message;
        rules = { ...rules, validate: value => (value === getValues('password') && value !== '') || passwordMessage };
    }
    if (props.name === 'newPasswordRetype' && rules.validate) {
        const passwordMessage = rules.validate.message;
        rules = { ...rules, validate: value => (value === getValues('newPassword') && value !== '') || passwordMessage };
    }
    const errorMessage = (_a = errors[name]) === null || _a === void 0 ? void 0 : _a.message;
    let fieldError = formErrors && ((_b = formErrors[0]) === null || _b === void 0 ? void 0 : _b.some(inputError => inputError === name));
    const [showPassword, setShowPassword] = React.useState(false);
    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const passwordFieldProps = {};
    if (props.type === 'password') {
        passwordFieldProps.InputProps = {
            type: showPassword ? 'text' : 'password',
            endAdornment: (React.createElement(InputAdornment_1.default, { position: "end" },
                React.createElement(HBIcon_1.default, { icon: showPassword ? 'visibility' : 'visibility_off', color: "primary", isButton: true, ButtonProps: { onClick: handleShowPassword, className: classes.passwordIcon } }))),
        };
    }
    return (React.createElement(TextField_1.default, Object.assign({ fullWidth: fullWidth, name: name, inputRef: register(rules), error: !!fieldError || error || Boolean(errorMessage), helperText: errorMessage || helperText, InputLabelProps: {
            classes: {
                asterisk: hideAsterisk ? classes.asterisk : '',
            },
        } }, otherProps, passwordFieldProps)));
}
exports.default = withStyles_1.default(styles)(FormTextField);
