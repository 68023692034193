"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const Grid_1 = require("@material-ui/core/Grid");
const Colors_1 = require("@components/library/theme/Colors");
const createStyles_1 = require("@material-ui/core/styles/createStyles");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const HBContainer_1 = require("@components/library/HBContainer");
const styles = ({ palette, spacing, breakpoints }) => createStyles_1.default({
    root: {
        backgroundColor: Colors_1.default.backgroundGrey,
        paddingTop: spacing(8),
        paddingBottom: spacing(6),
        [breakpoints.down('sm')]: {
            paddingTop: spacing(6),
            paddingBottom: spacing(4),
        },
    },
    container: {},
    border: {
        borderTop: `1px solid ${palette.divider}`,
    },
});
const FullWidthBackground = ({ classes, className, border, children }) => (React.createElement(Grid_1.default, { item: true, className: classnames_1.default(className, classes.root, { [classes.border]: border }) },
    React.createElement(HBContainer_1.default, { className: classes.container }, children)));
exports.default = withStyles_1.default(styles)(FullWidthBackground);
