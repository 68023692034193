"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const lottie_react_web_1 = require("lottie-react-web");
const AnimationData = {
    v: '5.6.10',
    fr: 29.9700012207031,
    ip: 0,
    op: 146.000005946702,
    w: 600,
    h: 600,
    nm: 'Komp 1',
    ddd: 0,
    assets: [
        {
            id: 'comp_0',
            layers: [
                {
                    ddd: 0,
                    ind: 2,
                    ty: 4,
                    nm: 'Formebene 10',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [300, 300, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [52, -19],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-34.651, 12.661],
                                            ],
                                            v: [
                                                [259, -78],
                                                [169, -120],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Pfad 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 90, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Kontur 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transformieren',
                                },
                            ],
                            nm: 'Form 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'tm',
                            s: { a: 0, k: 0, ix: 1 },
                            e: {
                                a: 1,
                                k: [
                                    { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 65, s: [0] },
                                    { t: 67.0000027289659, s: [100] },
                                ],
                                ix: 2,
                            },
                            o: { a: 0, k: 0, ix: 3 },
                            m: 1,
                            ix: 2,
                            nm: 'Pfade trimmen 1',
                            mn: 'ADBE Vector Filter - Trim',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 3,
                    ty: 4,
                    nm: 'Formebene 9',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [300, 300, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8, 23],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-9.578, -27.537],
                                            ],
                                            v: [
                                                [201, -32],
                                                [243, -101],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Pfad 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tm',
                                    s: { a: 0, k: 0, ix: 1 },
                                    e: {
                                        a: 1,
                                        k: [
                                            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 63, s: [0] },
                                            { t: 65.0000026475043, s: [100] },
                                        ],
                                        ix: 2,
                                    },
                                    o: { a: 0, k: 0, ix: 3 },
                                    m: 1,
                                    ix: 2,
                                    nm: 'Pfade trimmen 1',
                                    mn: 'ADBE Vector Filter - Trim',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 60, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Kontur 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transformieren',
                                },
                            ],
                            nm: 'Form 1',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 4,
                    ty: 4,
                    nm: 'Formebene 8',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [300, 300, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-22.245, 13.347],
                                            ],
                                            o: [
                                                [0, 0],
                                                [30, -18],
                                            ],
                                            v: [
                                                [53, 4],
                                                [192, -18],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Pfad 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tm',
                                    s: { a: 0, k: 0, ix: 1 },
                                    e: {
                                        a: 1,
                                        k: [
                                            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 59, s: [0] },
                                            { t: 63.0000025660426, s: [100] },
                                        ],
                                        ix: 2,
                                    },
                                    o: { a: 0, k: 0, ix: 3 },
                                    m: 1,
                                    ix: 2,
                                    nm: 'Pfade trimmen 1',
                                    mn: 'ADBE Vector Filter - Trim',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 100, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Kontur 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transformieren',
                                },
                            ],
                            nm: 'Form 1',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 5,
                    ty: 4,
                    nm: 'Formebene 5',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [535, 282, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [3.824, -24.049],
                                                [-5.463, -5.216],
                                                [-10.5, 2],
                                            ],
                                            o: [
                                                [-0.898, 5.648],
                                                [10.144, 9.686],
                                                [27.966, -5.327],
                                            ],
                                            v: [
                                                [-308.102, 84.352],
                                                [-277.144, 116.314],
                                                [-239.5, 122],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Pfad 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 95, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Kontur 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transformieren',
                                },
                            ],
                            nm: 'Form 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'tm',
                            s: { a: 0, k: 0, ix: 1 },
                            e: {
                                a: 1,
                                k: [
                                    { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 63, s: [0] },
                                    { t: 67.0000027289659, s: [100] },
                                ],
                                ix: 2,
                            },
                            o: { a: 0, k: 0, ix: 3 },
                            m: 1,
                            ix: 2,
                            nm: 'Pfade trimmen 1',
                            mn: 'ADBE Vector Filter - Trim',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 6,
                    ty: 4,
                    nm: 'Formebene 4',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [300, 300, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [101.036, 123.529, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [50.749, 2.362],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-69.569, -3.238],
                                                [0, 0],
                                            ],
                                            v: [
                                                [101.449, 80.143],
                                                [10.185, -8.905],
                                                [-78.008, 43.738],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Pfad 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 89, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Kontur 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transformieren',
                                },
                            ],
                            nm: 'Form 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'tm',
                            s: { a: 0, k: 0, ix: 1 },
                            e: {
                                a: 1,
                                k: [
                                    { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 55, s: [0] },
                                    { t: 63.0000025660426, s: [100] },
                                ],
                                ix: 2,
                            },
                            o: { a: 0, k: 0, ix: 3 },
                            m: 1,
                            ix: 2,
                            nm: 'Pfade trimmen 1',
                            mn: 'ADBE Vector Filter - Trim',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 7,
                    ty: 4,
                    nm: 'Formebene 3',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [300, 300, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-35.481, 71.787],
                                                [11.5, 22.25],
                                            ],
                                            o: [
                                                [0, 0],
                                                [10.75, -21.75],
                                                [-7.072, -13.683],
                                            ],
                                            v: [
                                                [33, 221],
                                                [121.75, 142.25],
                                                [114.5, 58.75],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Pfad 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 40, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Kontur 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transformieren',
                                },
                            ],
                            nm: 'Form 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'tm',
                            s: { a: 0, k: 0, ix: 1 },
                            e: {
                                a: 1,
                                k: [
                                    { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 48, s: [0] },
                                    { t: 55.0000022401959, s: [100] },
                                ],
                                ix: 2,
                            },
                            o: { a: 0, k: 0, ix: 3 },
                            m: 1,
                            ix: 2,
                            nm: 'Pfade trimmen 1',
                            mn: 'ADBE Vector Filter - Trim',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 8,
                    ty: 4,
                    nm: 'Formebene 2',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [300, 300, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, -68],
                                                [-134, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 68],
                                                [134, 0],
                                            ],
                                            v: [
                                                [-35, -96],
                                                [-187, 88],
                                                [-19, 232],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Pfad 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 124, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Kontur 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transformieren',
                                },
                            ],
                            nm: 'Form 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'tm',
                            s: { a: 0, k: 0, ix: 1 },
                            e: {
                                a: 1,
                                k: [
                                    { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 33, s: [0] },
                                    { t: 48.0000019550801, s: [98] },
                                ],
                                ix: 2,
                            },
                            o: { a: 0, k: 0, ix: 3 },
                            m: 1,
                            ix: 2,
                            nm: 'Pfade trimmen 1',
                            mn: 'ADBE Vector Filter - Trim',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 9,
                    ty: 4,
                    nm: 'Formebene 1',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [300, 300, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [55.682, -18.175],
                                                [1.378, -28.028],
                                                [-70.581, 2.076],
                                            ],
                                            o: [
                                                [-38.671, 12.622],
                                                [-3, 61],
                                                [102, -3],
                                            ],
                                            v: [
                                                [-4.122, -257.374],
                                                [-147.5, -160],
                                                [-22.5, -86.25],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Pfad 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 115, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Kontur 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transformieren',
                                },
                            ],
                            nm: 'Form 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'tm',
                            s: { a: 0, k: 0, ix: 1 },
                            e: {
                                a: 1,
                                k: [
                                    { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 17, s: [0] },
                                    { t: 32.0000013033867, s: [100] },
                                ],
                                ix: 2,
                            },
                            o: { a: 0, k: 0, ix: 3 },
                            m: 1,
                            ix: 2,
                            nm: 'Pfade trimmen 1',
                            mn: 'ADBE Vector Filter - Trim',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 10,
                    ty: 4,
                    nm: 'Formebene 7',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [300, 300, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [-3.578, 4.025],
                                                [136, -3.5],
                                            ],
                                            o: [
                                                [4, -4.5],
                                                [-153.588, 3.953],
                                            ],
                                            v: [
                                                [62.5, -161.5],
                                                [-53, -231.5],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Pfad 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 40, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Kontur 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transformieren',
                                },
                            ],
                            nm: 'Form 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'tm',
                            s: { a: 0, k: 0, ix: 1 },
                            e: {
                                a: 1,
                                k: [
                                    { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 10, s: [0] },
                                    { t: 17.0000006924242, s: [100] },
                                ],
                                ix: 2,
                            },
                            o: { a: 0, k: 0, ix: 3 },
                            m: 1,
                            ix: 2,
                            nm: 'Pfade trimmen 1',
                            mn: 'ADBE Vector Filter - Trim',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 11,
                    ty: 4,
                    nm: 'Formebene 11',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [300, 300, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [15, 12],
                                                [-14, -27],
                                                [-13.476, 5.318],
                                                [2.333, 12.247],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-15, -12],
                                                [5.835, 11.253],
                                                [13.476, -5.318],
                                                [-4, -21],
                                            ],
                                            v: [
                                                [20, -135],
                                                [26.5, -165],
                                                [-15, -125],
                                                [24.524, -107.682],
                                                [64, -157.5],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Pfad 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 40, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Kontur 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transformieren',
                                },
                            ],
                            nm: 'Form 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'tm',
                            s: { a: 0, k: 0, ix: 1 },
                            e: {
                                a: 1,
                                k: [
                                    { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [0] },
                                    { t: 10.0000004073083, s: [100] },
                                ],
                                ix: 2,
                            },
                            o: { a: 0, k: 0, ix: 3 },
                            m: 1,
                            ix: 2,
                            nm: 'Pfade trimmen 1',
                            mn: 'ADBE Vector Filter - Trim',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
            ],
        },
    ],
    layers: [
        {
            ddd: 0,
            ind: 2,
            ty: 0,
            nm: 'animation',
            refId: 'comp_0',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [300, 300, 0], ix: 2 },
                a: { a: 0, k: [300, 300, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
            },
            ao: 0,
            hasMask: true,
            masksProperties: [
                {
                    inv: false,
                    mode: 'a',
                    pt: {
                        a: 0,
                        k: {
                            i: [
                                [-26.861, 0],
                                [-8.927, -9.756],
                                [46.375, -3.882],
                                [0, -30.836],
                                [-27.678, -2.008],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, -73.435],
                                [0, 0],
                                [-72.511, -1.179],
                                [-15.339, 52.651],
                                [49.464, 4.872],
                                [-0.027, -23.235],
                                [-4.805, 0],
                                [0, -25.844],
                                [25.856, 0],
                                [8.405, 20.303],
                                [-79.096, 7.722],
                                [-32.722, 5.915],
                                [4.912, 18.375],
                                [12.714, -2.302],
                                [5.675, 23.355],
                                [-26.472, 5.006],
                                [-13.062, -16.701],
                                [95.062, -23.569],
                                [14.132, -45.264],
                                [1.875, 184.545],
                                [-78.694, 23.876],
                                [-16.811, 43.082],
                                [-83.104, -2.182],
                                [-11.576, -38.062],
                                [0.014, -0.014],
                                [18.041, -0.387],
                                [0, 26.861],
                            ],
                            o: [
                                [14.227, 0],
                                [-1.727, -32.133],
                                [-43.362, 3.613],
                                [0, 61.645],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [-48.823, 0],
                                [0, 0],
                                [2.57, 83.245],
                                [54.819, 2.113],
                                [14.145, -48.568],
                                [-17.332, -1.713],
                                [4.376, -1.367],
                                [25.856, 0],
                                [0, 25.829],
                                [-22.766, 0],
                                [-35.239, -85.133],
                                [34.503, -3.36],
                                [25.455, -4.578],
                                [-6.53, 12.581],
                                [-26.498, 4.818],
                                [-5.674, -23.354],
                                [23.327, -4.416],
                                [54.284, 69.42],
                                [11.508, 32.174],
                                [-44.528, 148.355],
                                [-0.172, -69.795],
                                [-53.507, -21.788],
                                [24.197, -61.992],
                                [62.42, 1.646],
                                [9.703, 31.907],
                                [-9.676, 13.73],
                                [-26.861, 0],
                                [0, -26.873],
                            ],
                            v: [
                                [314.257, 114.627],
                                [350.232, 130.526],
                                [269.716, 82.038],
                                [211.699, 160.064],
                                [269.798, 250.349],
                                [283.02, 250.349],
                                [283.02, 271.013],
                                [265.193, 271.013],
                                [174.74, 382.925],
                                [174.74, 403.429],
                                [292.602, 507.794],
                                [414.259, 431.574],
                                [345.468, 334.29],
                                [267.455, 360.522],
                                [281.281, 358.434],
                                [328.203, 405.343],
                                [281.281, 452.238],
                                [233.327, 416.21],
                                [355.879, 255.287],
                                [469.492, 248.181],
                                [515.303, 198.26],
                                [483.772, 221.119],
                                [430.8, 185.318],
                                [468.555, 133.832],
                                [525.635, 157.092],
                                [430.32, 346.844],
                                [434.655, 445.601],
                                [53.044, 402.251],
                                [164.489, 252.503],
                                [107.222, 131.102],
                                [265.996, 50.788],
                                [374.161, 120.02],
                                [363.013, 185.252],
                                [314.257, 212.178],
                                [265.487, 163.409],
                            ],
                            c: true,
                        },
                        ix: 1,
                    },
                    o: { a: 0, k: 100, ix: 3 },
                    x: { a: 0, k: 0, ix: 4 },
                    nm: 'Maske 1',
                },
            ],
            tm: {
                a: 1,
                k: [
                    { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [2.402] },
                    { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 73, s: [0] },
                    { t: 900.000036657751, s: [30.03] },
                ],
                ix: 2,
            },
            w: 600,
            h: 600,
            ip: 0,
            op: 73.000002973351,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 3,
            ty: 0,
            nm: 'animation reverse',
            refId: 'comp_0',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [300, 300, 0], ix: 2 },
                a: { a: 0, k: [300, 300, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
            },
            ao: 0,
            hasMask: true,
            masksProperties: [
                {
                    inv: false,
                    mode: 'a',
                    pt: {
                        a: 0,
                        k: {
                            i: [
                                [-26.861, 0],
                                [-8.927, -9.756],
                                [46.375, -3.882],
                                [0, -30.836],
                                [-27.678, -2.008],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, -73.435],
                                [0, 0],
                                [-72.511, -1.179],
                                [-15.339, 52.651],
                                [49.464, 4.872],
                                [-0.027, -23.235],
                                [-4.805, 0],
                                [0, -25.844],
                                [25.856, 0],
                                [8.405, 20.303],
                                [-79.096, 7.722],
                                [-32.722, 5.915],
                                [4.912, 18.375],
                                [12.714, -2.302],
                                [5.675, 23.355],
                                [-26.472, 5.006],
                                [-13.062, -16.701],
                                [95.062, -23.569],
                                [14.132, -45.264],
                                [1.875, 184.545],
                                [-78.694, 23.876],
                                [-16.811, 43.082],
                                [-83.104, -2.182],
                                [-11.576, -38.062],
                                [0.014, -0.014],
                                [18.041, -0.387],
                                [0, 26.861],
                            ],
                            o: [
                                [14.227, 0],
                                [-1.727, -32.133],
                                [-43.362, 3.613],
                                [0, 61.645],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [-48.823, 0],
                                [0, 0],
                                [2.57, 83.245],
                                [54.819, 2.113],
                                [14.145, -48.568],
                                [-17.332, -1.713],
                                [4.376, -1.367],
                                [25.856, 0],
                                [0, 25.829],
                                [-22.766, 0],
                                [-35.239, -85.133],
                                [34.503, -3.36],
                                [25.455, -4.578],
                                [-6.53, 12.581],
                                [-26.498, 4.818],
                                [-5.674, -23.354],
                                [23.327, -4.416],
                                [54.284, 69.42],
                                [11.508, 32.174],
                                [-44.528, 148.355],
                                [-0.172, -69.795],
                                [-53.507, -21.788],
                                [24.197, -61.992],
                                [62.42, 1.646],
                                [9.703, 31.907],
                                [-9.676, 13.73],
                                [-26.861, 0],
                                [0, -26.873],
                            ],
                            v: [
                                [314.257, 114.627],
                                [350.232, 130.526],
                                [269.716, 82.038],
                                [211.699, 160.064],
                                [269.798, 250.349],
                                [283.02, 250.349],
                                [283.02, 271.013],
                                [265.193, 271.013],
                                [174.74, 382.925],
                                [174.74, 403.429],
                                [292.602, 507.794],
                                [414.259, 431.574],
                                [345.468, 334.29],
                                [267.455, 360.522],
                                [281.281, 358.434],
                                [328.203, 405.343],
                                [281.281, 452.238],
                                [233.327, 416.21],
                                [355.879, 255.287],
                                [469.492, 248.181],
                                [515.303, 198.26],
                                [483.772, 221.119],
                                [430.8, 185.318],
                                [468.555, 133.832],
                                [525.635, 157.092],
                                [430.32, 346.844],
                                [434.655, 445.601],
                                [53.044, 402.251],
                                [164.489, 252.503],
                                [107.222, 131.102],
                                [265.996, 50.788],
                                [374.161, 120.02],
                                [363.013, 185.252],
                                [314.257, 212.178],
                                [265.487, 163.409],
                            ],
                            c: true,
                        },
                        ix: 1,
                    },
                    o: { a: 0, k: 100, ix: 3 },
                    x: { a: 0, k: 0, ix: 4 },
                    nm: 'Maske 1',
                },
            ],
            w: 600,
            h: 600,
            ip: 73.000002973351,
            op: 146.000005946702,
            st: 73.000002973351,
            bm: 0,
        },
    ],
    markers: [],
};
const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: AnimationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};
const LoadingLogo = () => React.createElement(lottie_react_web_1.default, { options: defaultOptions, height: 100, width: 100 });
exports.default = LoadingLogo;
