"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const createStyles_1 = require("@material-ui/core/styles/createStyles");
const styles = ({ breakpoints }) => createStyles_1.default({
    root: {},
    gallery: {
        position: 'relative',
        height: '560px',
        overflow: 'hidden',
        [breakpoints.down('sm')]: {
            height: '414px',
        },
    },
});
exports.default = styles;
