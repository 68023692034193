"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const styles_1 = require("@material-ui/core/styles");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const Grid_1 = require("@material-ui/core/Grid");
const Colors_1 = require("@components/library/theme/Colors");
const HBTypography_1 = require("@components/library/HBTypography");
const HBImage_1 = require("@components/library/HBImage");
const HBLink_1 = require("@components/library/HBLink");
const withLayout_1 = require("@components/common/layout/withLayout");
const FullWidthBackground_1 = require("@components/common/utils/FullWidthBackground");
const ZoomOutImage_1 = require("@components/common/utils/ZoomOutImage");
const LabelContext_1 = require("@components/common/context/LabelContext");
const styles = ({ spacing, breakpoints }) => styles_1.createStyles({
    root: {
        paddingTop: spacing(6),
        paddingBottom: spacing(4),
    },
    brandWrapper: {
        marginBottom: spacing(5),
        [breakpoints.down('sm')]: {
            marginBottom: spacing(3.5),
        },
    },
    imageContainer: {
        display: 'block',
        height: 258,
        [breakpoints.down('sm')]: {
            height: 162,
        },
    },
    imageWrapper: {
        padding: spacing(1.75, 3.5),
        [breakpoints.down('sm')]: {
            padding: spacing(1.2, 2.75),
        },
        backgroundColor: Colors_1.default.white,
    },
});
const PageBrandList = ({ classes, page: { brandItems = [] } }) => {
    const labels = LabelContext_1.useLabelContext();
    return (React.createElement(FullWidthBackground_1.default, { className: classes.root },
        React.createElement(HBTypography_1.default, { variant: "h3", align: "center", marginBottom: 5 }, labels.OUR_BRANDS),
        React.createElement(Grid_1.default, { container: true, spacing: 2 }, brandItems === null || brandItems === void 0 ? void 0 : brandItems.map(({ to, name, logo }, idX) => (React.createElement(Grid_1.default, { key: idX.toString(), item: true, xs: 6, sm: 4, md: 3 },
            React.createElement("div", { className: classes.brandWrapper },
                React.createElement(Grid_1.default, { component: HBLink_1.default, to: to, className: classes.imageContainer },
                    React.createElement(ZoomOutImage_1.default, { dense: true, className: classes.imageWrapper },
                        React.createElement(HBImage_1.default, { src: logo === null || logo === void 0 ? void 0 : logo.path, size: 300 }))),
                React.createElement(HBTypography_1.default, { align: "center", variant: "body1", marginTop: 1.75 }, name))))))));
};
exports.default = withLayout_1.default(withStyles_1.default(styles)(PageBrandList), { paddingTop: 0, paddingBottom: 0 });
