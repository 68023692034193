"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_router_dom_1 = require("react-router-dom");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const styles_1 = require("@material-ui/core/styles");
const Grid_1 = require("@material-ui/core/Grid");
const MenuItem_1 = require("@material-ui/core/MenuItem");
const HBTypography_1 = require("@components/library/HBTypography");
const HBSelect_1 = require("@components/library/HBSelect");
const styles = ({ spacing }) => styles_1.createStyles({
    root: {
        marginBottom: spacing(2),
    },
});
const ProductDropdownVariants = ({ classes, id, variants, type, dropdownLabel }) => {
    const history = react_router_dom_1.useHistory();
    const handleChange = event => {
        let idValue = event.target.value;
        const variant = variants.find(({ _id }) => _id.toString() === idValue);
        if (!!variant) {
            history.push(variant.to);
        }
    };
    return (React.createElement(Grid_1.default, { container: true, alignItems: "center", spacing: 2, className: classes.root },
        React.createElement(Grid_1.default, { item: true, style: { flex: 1 } },
            React.createElement(HBTypography_1.default, { textTransformNone: true, variant: "subtitle2" }, dropdownLabel)),
        React.createElement(Grid_1.default, { item: true, style: { flex: 2 } },
            React.createElement(HBSelect_1.default, { value: id, onChange: handleChange, fullWidth: true, inputProps: { 'aria-label': dropdownLabel } }, variants.map(product => (React.createElement(MenuItem_1.default, { key: `size-variant-${product._id.toString()}`, value: product._id.toString() }, product[type])))))));
};
exports.default = withStyles_1.default(styles)(ProductDropdownVariants);
