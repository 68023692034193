"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.renderProductMetaTags = exports.renderMetaTags = exports.renderProductMicroData = exports.renderOrganisationMicroData = void 0;
const React = require("react");
const react_helmet_1 = require("react-helmet");
const FALLBACK_LOGO_URL = '/logo.svg';
const getAbsoluteURL = (url = '', to = '') => {
    if (url.endsWith('/'))
        url = url.slice(0, url.length - 1);
    let toSuffix = to;
    if (to.startsWith("http")) {
        const cmsIndex = to.indexOf("/cms");
        toSuffix = to.substring(cmsIndex);
    }
    return `${url}${toSuffix}`;
};
const getOptimImage = (image) => {
    var _a, _b;
    if (!image || !((_a = image) === null || _a === void 0 ? void 0 : _a.path)) {
        return image;
    }
    return `${(_b = image) === null || _b === void 0 ? void 0 : _b.path}?w=200&h=200&crop`;
};
const genLDJsonScript = (microData = {}) => (React.createElement("script", { type: "application/ld+json", dangerouslySetInnerHTML: { __html: JSON.stringify(microData) } }));
const getOrgMicroData = (seo) => {
    const { title: name, description, url, logo: { path = FALLBACK_LOGO_URL } = {}, email, telephone, addressLocality, streetAddress, postalCode, } = seo || {};
    return {
        '@context': 'https://schema.org',
        '@type': 'Organization',
        name,
        description,
        url,
        logo: getAbsoluteURL(url, path),
        email,
        telephone,
        address: {
            '@type': 'PostalAddress',
            addressLocality,
            postalCode,
            streetAddress,
        },
    };
};
exports.renderOrganisationMicroData = (seo) => {
    if (!seo)
        return undefined;
    return genLDJsonScript(getOrgMicroData(seo));
};
const getProductMicroData = (product, seo) => {
    var _a;
    const { name, description, image, images, price, brand, sku, height, depth, width, colors = [], to } = product;
    const { title, url } = seo;
    const absoluteProductUrl = getAbsoluteURL(url, to);
    const getProductImage = () => {
        if (images === null || images === void 0 ? void 0 : images.length) {
            return images === null || images === void 0 ? void 0 : images.map(img => getAbsoluteURL(url, getOptimImage(img)));
        }
        return getAbsoluteURL(url, getOptimImage(image));
    };
    const microData = {
        '@context': 'https://schema.org/',
        '@type': 'Product',
        name,
        description,
        image: getProductImage(),
        sku,
        mpn: sku,
        height,
        depth,
        width,
        price,
        color: (_a = colors[0]) === null || _a === void 0 ? void 0 : _a.name,
        itemCondition: 'http://schema.org/NewCondition',
        url: absoluteProductUrl,
        seller: { '@type': 'Organization', name: title },
        offers: {
            '@type': 'Offer',
            url: absoluteProductUrl,
            price,
            priceCurrency: 'EUR',
            sku,
            itemCondition: 'https://schema.org/NewCondition',
            availability: 'https://schema.org/InStock',
            seller: { '@type': 'Organization', name: title },
        },
    };
    if (brand === null || brand === void 0 ? void 0 : brand.name) {
        microData['brand'] = { '@type': 'Brand', name: brand === null || brand === void 0 ? void 0 : brand.name };
        microData['manufacturer'] = { '@type': 'Organization', name: brand === null || brand === void 0 ? void 0 : brand.name };
    }
    return microData;
};
exports.renderProductMicroData = (product, seo) => {
    if (!product)
        return undefined;
    return genLDJsonScript(getProductMicroData(product, seo));
};
exports.renderMetaTags = (data) => {
    const { page, settings: { seo = {} } = {} } = data;
    if (!seo || !page)
        return undefined;
    const { title: seoTitle, siteName, url, description: seoDescription, logo: { path = FALLBACK_LOGO_URL } = {} } = seo;
    const { title: pageTitle, description: pageDescription, fullSlug } = page || {};
    const imageUrl = path;
    const absoluteUrl = getAbsoluteURL(url, fullSlug);
    const title = pageTitle || seoTitle;
    const description = pageDescription || seoDescription;
    return (React.createElement(react_helmet_1.Helmet, null,
        React.createElement("title", null, title),
        React.createElement("link", { rel: "canonical", href: absoluteUrl }),
        React.createElement("meta", { name: "description", content: description }),
        React.createElement("meta", { property: "og:title", content: title }),
        React.createElement("meta", { property: "og:description", content: description }),
        React.createElement("meta", { property: "og:site", content: url }),
        React.createElement("meta", { property: "og:site_name", content: siteName }),
        React.createElement("meta", { property: "og:url", content: absoluteUrl }),
        React.createElement("meta", { property: "og:type", content: "website" }),
        React.createElement("meta", { property: "og:image", content: imageUrl }),
        React.createElement("meta", { name: "twitter:title", content: title }),
        React.createElement("meta", { name: "twitter:description", content: description }),
        React.createElement("meta", { name: "twitter:image", content: imageUrl })));
};
exports.renderProductMetaTags = (product, page, seo) => {
    var _a;
    if (!product)
        return undefined;
    const { to, price, colors = [], name: productTitle, description: productDescription, image } = product;
    const { title: seoTitle, description: seoDescription, url } = seo;
    const { title: pageTitle, description: pageDescription } = page;
    const imageUrl = getAbsoluteURL(url, getOptimImage(image));
    const absoluteUrl = getAbsoluteURL(url, to);
    const title = pageTitle || productTitle || seoTitle;
    const description = pageDescription || productDescription || seoDescription;
    return (React.createElement(react_helmet_1.Helmet, null,
        React.createElement("title", null, title),
        React.createElement("link", { rel: "canonical", href: absoluteUrl }),
        React.createElement("meta", { name: "description", content: description }),
        React.createElement("meta", { property: "og:title", content: title }),
        React.createElement("meta", { property: "og:description", content: description }),
        React.createElement("meta", { property: "og:image", content: imageUrl }),
        React.createElement("meta", { property: "og:url", content: absoluteUrl }),
        React.createElement("meta", { name: "twitter:title", content: title }),
        React.createElement("meta", { name: "twitter:description", content: description }),
        React.createElement("meta", { name: "twitter:image", content: imageUrl }),
        React.createElement("meta", { name: "twitter:card", content: "product" }),
        React.createElement("meta", { name: "twitter:data1", content: price }),
        React.createElement("meta", { name: "twitter:label1", content: "Price" }),
        React.createElement("meta", { name: "twitter:data2", content: (_a = colors[0]) === null || _a === void 0 ? void 0 : _a.name }),
        React.createElement("meta", { name: "twitter:label2", content: "Color" })));
};
