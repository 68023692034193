"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const makeStyles_1 = require("@material-ui/core/styles/makeStyles");
const Grid_1 = require("@material-ui/core/Grid");
const HBTypography_1 = require("@components/library/HBTypography");
const HBAccordion_1 = require("@components/library/HBAccordion");
const CustomerCareInfo_1 = require("@components/pages/contact/CustomerCareInfo");
const withLayout_1 = require("@components/common/layout/withLayout");
const FullWidthBackground_1 = require("@components/common/utils/FullWidthBackground");
const useStyles = makeStyles_1.default(({ palette, breakpoints, spacing }) => ({
    root: {
        padding: 0,
    },
    container: {
        maxWidth: 808,
        margin: '0 auto',
        [breakpoints.down('sm')]: {
            padding: spacing(2),
        },
    },
    wrapper: {
        position: 'relative',
        '& > *': {
            marginBottom: spacing(8),
            [breakpoints.down('sm')]: {
                marginBottom: spacing(7),
            },
        },
    },
    titleText: {
        marginTop: spacing(5),
    },
    contactInfoWrapper: {
        [breakpoints.down('xs')]: {
            flexDirection: 'column',
            '& > *:not(:last-child)': {
                marginBottom: spacing(4),
            },
        },
    },
    description: {
        maxWidth: breakpoints.values.sm,
    },
    iconWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: `0 auto ${spacing(2.25)}px auto`,
        '& svg': {
            width: 130,
            height: 130,
        },
    },
}));
const PageFaq = ({ page: { title, faqList = [], customer_care_info } }) => {
    const classes = useStyles();
    const [selected, setSelected] = React.useState({});
    const handleFacets = (e) => {
        const { id } = e.currentTarget;
        const da = Object.keys(selected)
            .filter(item => item !== id)
            .map(facet => {
            return { [facet]: false };
        });
        const data = { ...selected, ...Object.assign({}, ...da) };
        data[id] = !data[id];
        setSelected(data);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(FullWidthBackground_1.default, { className: classes.root, classes: { container: classes.container } },
            React.createElement(Grid_1.default, { container: true, direction: "column", justify: "center", className: classes.wrapper },
                React.createElement(Grid_1.default, { item: true, className: classes.titleText }, title && (React.createElement(HBTypography_1.default, { variant: "h4", align: "center" }, title))), faqList === null || faqList === void 0 ? void 0 :
                faqList.map((faq, idX) => {
                    var _a;
                    return (React.createElement(Grid_1.default, { item: true, key: idX.toString() },
                        React.createElement(HBTypography_1.default, { variant: "h6", marginBottom: 2 }, faq.title), (_a = faq === null || faq === void 0 ? void 0 : faq.faqs) === null || _a === void 0 ? void 0 :
                        _a.map(qa => (React.createElement(HBAccordion_1.default, { key: qa.question, label: qa.question, selected: selected[qa.question], onChange: handleFacets },
                            React.createElement("div", { dangerouslySetInnerHTML: { __html: qa.answer || '' } }))))));
                }))),
        React.createElement("div", null, customer_care_info && (React.createElement(Grid_1.default, { container: true, justify: "center", className: classes.wrapper },
            React.createElement(CustomerCareInfo_1.default, Object.assign({ maxWidth: 600 }, customer_care_info)))))));
};
exports.default = withLayout_1.default(PageFaq, {
    paddingTop: 0,
    paddingBottom: 0,
});
