"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const axios_1 = require("axios");
const queryString = require("querystring");
class ApiClientRequest {
    constructor() {
        this.alertSubscriptions = [];
        this.alertListeners = [];
        this.baseUrl = '';
        this.listenToAlerts = listener => {
            const sub = {
                unsubscribe: () => {
                    const i = this.alertSubscriptions.indexOf(sub);
                    if (i >= 0) {
                        this.alertSubscriptions.splice(i, 1);
                        this.alertListeners.splice(i, 1);
                    }
                },
            };
            this.alertSubscriptions.push(sub);
            this.alertListeners.push(listener);
            return sub;
        };
        this.dispatchAlert = alert => {
            this.alertListeners.forEach(listener => listener(alert));
        };
        this.get = (url, data, config = {}) => {
            return this.makeRequest(url, 'get', data, config);
        };
        this.delete = (url, config = {}) => {
            return this.makeRequest(url, 'delete', undefined, config);
        };
        this.post = (url, data, config = {}) => {
            return this.makeRequest(url, 'post', data, config);
        };
        this.put = (url, data, config = {}) => {
            return this.makeRequest(url, 'put', data, config);
        };
        this.patch = (url, data, config = {}) => {
            return this.makeRequest(url, 'patch', data, config);
        };
    }
    makeRequest(url, method, data, config = {}) {
        const source = axios_1.default.CancelToken.source();
        config = { ...config, cancelToken: source.token };
        let request;
        if (data !== undefined) {
            if (config && config.newTab) {
                window.open(`${this.baseUrl}${url}?${queryString.stringify(data)}`, '_blank');
                return;
            }
            if (method === 'post' && window.captchaByAction) {
                request = window.captchaByAction('test');
            }
            if (request) {
                request = request.then(token => {
                    if (data instanceof FormData)
                        data.append('token', token);
                    else
                        data = { ...data, token };
                    if (!config.headers)
                        config.headers = {};
                    config.headers['x-captcha-token'] = token;
                    return axios_1.default[method](this.baseUrl + url, data, config);
                });
            }
            else {
                request = axios_1.default[method](this.baseUrl + url, data, config);
            }
        }
        else {
            request = axios_1.default[method](this.baseUrl + url, config);
        }
        request = request
            .then(response => response.data)
            .then(data => {
            const { navigateTo, alert } = data;
            if (navigateTo) {
                this.onNavigateTo && this.onNavigateTo(navigateTo);
            }
            if (alert) {
                this.dispatchAlert(alert);
            }
            return data;
        })
            .catch(error => {
            if (axios_1.default.isCancel(error)) {
                return new Promise(() => { });
            }
            const { response: { data: { navigateTo = '', alert = {}, error: formErrors } = {} } = {} } = error || {};
            if (navigateTo) {
                this.onNavigateTo && this.onNavigateTo(navigateTo);
            }
            if (alert) {
                this.dispatchAlert(alert);
            }
            throw error;
        });
        return {
            request,
            cancel: () => {
                source.cancel('cancelled');
            },
        };
    }
}
exports.default = ApiClientRequest;
