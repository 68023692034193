"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
const styles = ({ breakpoints, spacing }) => styles_1.createStyles({
    root: {
        position: 'relative',
        padding: spacing(5, 0),
    },
    contactWrapper: {
        textAlign: 'center',
        [breakpoints.down('xs')]: {
            flexDirection: 'column',
            '& > *:not(:last-child)': {
                marginBottom: spacing(4),
            },
        },
    },
    iconWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: `0 auto ${spacing(2.25)}px auto`,
        '& svg': {
            width: 130,
            height: 130,
        },
    },
    maxWidth: {
        maxWidth: ({ maxWidth }) => maxWidth,
        margin: '0 auto',
    },
});
exports.default = styles;
