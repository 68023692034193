"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const withTheme_1 = require("@material-ui/core/styles/withTheme");
const Slider_1 = require("@components/common/utils/Slider/Slider");
const BrandItem_1 = require("@components/pages/home/BrandItem");
const HBButton_1 = require("@components/library/HBButton");
const BrandGalleryStyles_1 = require("./BrandGalleryStyles");
const BrandGallery = ({ theme: { breakpoints } = {}, classes, items = [] }) => {
    const lgItemCount = Slider_1.getItemCount(items, 5);
    const mdItemCount = Slider_1.getItemCount(items, 4);
    const smItemCount = Slider_1.getItemCount(items, 3);
    const xsItemCount = Slider_1.getItemCount(items, 2);
    return (React.createElement("div", null,
        React.createElement(Slider_1.default, { items: items === null || items === void 0 ? void 0 : items.map(item => (React.createElement(BrandItem_1.default, Object.assign({ className: classes.gallery, itemClassName: classes.galleryItem, key: item === null || item === void 0 ? void 0 : item.name }, item)))), slidesToScroll: lgItemCount, slidesToShow: lgItemCount, dots: false, color: "primary", responsive: [
                {
                    breakpoint: breakpoints.values.xs,
                    settings: {
                        arrows: false,
                        slidesToScroll: xsItemCount,
                        slidesToShow: xsItemCount,
                    },
                },
                {
                    breakpoint: breakpoints.values.sm,
                    settings: {
                        arrows: false,
                        slidesToScroll: smItemCount,
                        slidesToShow: smItemCount,
                    },
                },
                {
                    breakpoint: breakpoints.values.md,
                    settings: {
                        slidesToScroll: mdItemCount,
                        slidesToShow: mdItemCount,
                    },
                },
                {
                    breakpoint: breakpoints.values.lg,
                    settings: {
                        slidesToScroll: lgItemCount,
                        slidesToShow: lgItemCount,
                    },
                },
            ] }),
        React.createElement("div", { className: classes.buttonWrapper },
            React.createElement(HBButton_1.default, { color: "primary", variant: "outlined", label: "Markenwelt entdecken", href: '/brands' }))));
};
exports.default = withTheme_1.default(withStyles_1.default(BrandGalleryStyles_1.default)(BrandGallery));
