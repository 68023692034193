"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Svg_1 = require("../Svg");
const DeliveryTruck = props => (React.createElement(Svg_1.default, Object.assign({}, props, { viewBox: "0 0 24 24" }),
    React.createElement("g", { fill: "none", fillRule: "evenodd", stroke: "#fff", strokeLinejoin: "round", strokeWidth: "2" },
        React.createElement("g", { strokeLinecap: "round", transform: "translate(1 3.5)" },
            React.createElement("path", { d: "M9 10.812V4.5H2.7C1.209 4.5 0 5.843 0 7.5v6.906c0 .552.403 1 .9 1h.748" }),
            React.createElement("circle", { cx: "6", cy: "15", r: "2" }),
            React.createElement("circle", { cx: "19", cy: "15", r: "2" }),
            React.createElement("path", { d: "M9 10.812V9.5h13v1.312M0 9.5h4.167c.46 0 .833-.448.833-1v-4m4.929-4H21.07c.513 0 .929.448.929 1v8H9v-8c0-.552.416-1 .929-1zM0 12.5h1" })),
        React.createElement("path", { d: "M11.5 18h5" }))));
exports.default = DeliveryTruck;
