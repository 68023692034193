"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const Grid_1 = require("@material-ui/core/Grid");
const HBImage_1 = require("@components/library/HBImage");
const HBTypography_1 = require("@components/library/HBTypography");
const HBQuantityPicker_1 = require("@components/library/HBQuantityPicker");
const HBIcon_1 = require("@components/library/HBIcon");
const ProductPrice_1 = require("@components/pages/product/ProductPrice");
const ProductSaleTag_1 = require("@components/pages/product/ProductSaleTag");
const AppContext_1 = require("@components/common/context/AppContext");
const ApiContext_1 = require("@components/common/context/ApiContext");
const useApiRequest_1 = require("@components/common/utils/hooks/useApiRequest");
const HBLink_1 = require("@components/library/HBLink");
const LabelContext_1 = require("@components/common/context/LabelContext");
const CartItemStyles_1 = require("./CartItemStyles");
const CartItem = ({ classes, api, item }) => {
    const labels = LabelContext_1.useLabelContext();
    const { dispatch } = AppContext_1.useAppContext();
    const { to, image, name, isSale, colors, amount = 1 } = item;
    const { onSubmit, isLoading } = useApiRequest_1.default();
    const handleAddToCart = async (id, amount, isCart) => {
        await onSubmit(() => api.addToCart({ oid: id, amount, isCart }), data => {
            dispatch({
                type: AppContext_1.ActionTypes.ADD_TO_CART,
                payload: data,
            });
        });
    };
    const handleRemoveFromCart = async (id) => {
        await onSubmit(() => api.removeFromCart(id), data => {
            dispatch({
                type: AppContext_1.ActionTypes.REMOVE_FROM_CART,
                payload: data,
            });
        });
    };
    return (React.createElement(Grid_1.default, { item: true, container: true, className: classes.root },
        React.createElement(Grid_1.default, { item: true, className: classes.imageWrapper, component: HBLink_1.default, to: to },
            React.createElement(ProductSaleTag_1.default, { isSale: isSale, isSymbol: true }),
            React.createElement(HBImage_1.default, { src: image === null || image === void 0 ? void 0 : image.path, alt: name })),
        React.createElement(Grid_1.default, { item: true, className: classes.grow },
            React.createElement(Grid_1.default, { container: true, direction: "column", spacing: 2 },
                React.createElement(Grid_1.default, { item: true, container: true, justify: "space-between" },
                    React.createElement(Grid_1.default, { item: true, className: classes.title },
                        React.createElement(HBLink_1.default, { to: to },
                            React.createElement(HBTypography_1.default, { variant: "body2", textTransformNone: true, truncate: true, clamp: 1, className: classes.name }, name)),
                        !!(colors === null || colors === void 0 ? void 0 : colors.length) && (React.createElement(HBTypography_1.default, { variant: "caption" },
                            labels.COLOR,
                            ": ",
                            colors.map(item => item.name).join(' / ')))),
                    React.createElement(Grid_1.default, { item: true, className: classes.deleteIcon },
                        React.createElement(HBIcon_1.default, { icon: "remove", fontSize: "xsmall", isButton: true, ButtonProps: { onClick: () => handleRemoveFromCart(item._id), disabled: isLoading } }))),
                React.createElement(Grid_1.default, { item: true, container: true, alignItems: "center", justify: "space-between" },
                    React.createElement(Grid_1.default, { item: true },
                        React.createElement(ProductPrice_1.default, { item: item })),
                    React.createElement(Grid_1.default, { item: true, className: classes.quantityPicker },
                        React.createElement(HBQuantityPicker_1.default, { quantity: amount, onChange: pAmount => {
                                if (amount !== pAmount)
                                    return handleAddToCart(item._id, pAmount, true);
                            }, buttonProps: { isXSmall: true }, iconProps: { fontSize: '3xsmall' }, disabled: isLoading, incrementLabel: labels.INCREMENT_QUANTITY, decrementLabel: labels.DECREMENT_QUANTITY })))))));
};
exports.default = ApiContext_1.withApi(withStyles_1.default(CartItemStyles_1.default)(CartItem));
