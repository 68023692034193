"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const Grid_1 = require("@material-ui/core/Grid");
const Card_1 = require("@material-ui/core/Card");
const CardContent_1 = require("@material-ui/core/CardContent");
const CardActions_1 = require("@material-ui/core/CardActions");
const Collapse_1 = require("@material-ui/core/Collapse");
const useScrollTop_1 = require("@components/common/utils/hooks/useScrollTop");
const HBTypography_1 = require("../HBTypography");
const HBButton_1 = require("../HBButton");
const HBIcon_1 = require("../HBIcon");
const HBExpanderStyles_1 = require("./HBExpanderStyles");
const id = 'expander-anchor';
const HBExpander = ({ children, classes, collapsedHeight = 160, expandLabel = 'schliessen', collapseLabel = 'Mehr lesen', border = true, ...rest }) => {
    const [expanded, setExpanded] = React.useState(false);
    const { scrollTopFn } = useScrollTop_1.useScrollTop(id);
    const handleCollapse = (e) => {
        e.preventDefault();
        setExpanded(!expanded);
        scrollTopFn(e);
    };
    return (React.createElement(Card_1.default, { elevation: 0, square: true, className: classes.root, id: id },
        React.createElement(Collapse_1.default, { in: expanded, collapsedHeight: collapsedHeight },
            React.createElement(CardContent_1.default, { className: classes.content }, children)),
        React.createElement(CardActions_1.default, { disableSpacing: true, className: classnames_1.default(classes.moreButton, {
                [classes.collapseBgImg]: !expanded,
                [classes.moreButtoncollapse]: expanded,
                [classes.borderBottom]: border,
            }) },
            React.createElement(HBButton_1.default, { onClick: handleCollapse, disableElevation: true, fullWidth: true, variant: "text" },
                React.createElement(Grid_1.default, { container: true, item: true, alignItems: "baseline", justify: "center", spacing: 1, xs: 12 },
                    React.createElement(Grid_1.default, { item: true },
                        React.createElement(HBIcon_1.default, { icon: "keyboard_arrow_down", className: classnames_1.default(classes.icon, { [classes.expandOpen]: expanded }) })),
                    React.createElement(Grid_1.default, { item: true },
                        React.createElement(HBTypography_1.default, { variant: "h6", component: "span" }, !expanded ? collapseLabel : expandLabel)))))));
};
exports.default = withStyles_1.default(HBExpanderStyles_1.default)(HBExpander);
