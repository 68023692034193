"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const axios_1 = require("axios");
const Constants_1 = require("./Constants");
const SearchUtils_1 = require("@components/common/utils/SearchUtils");
class ApiConnect {
    constructor(apiClientRequest) {
        this.apiClientRequest = apiClientRequest;
    }
    async init(props) {
        const { captchaId } = props.settings;
        if (captchaId) {
            let script = document.createElement('script');
            script.setAttribute('src', `https://www.google.com/recaptcha/api.js?render=${captchaId}`);
            document.getElementsByTagName('head')[0].appendChild(script);
            script.onload = () => {
                window['grecaptcha'].ready(async () => {
                    window.captchaByAction = async (action) => window['grecaptcha'].execute(captchaId, { action });
                });
            };
        }
    }
    signIn(data) {
        return this.apiClientRequest.post(Constants_1.default.API.MYACCOUNT.SIGN_IN, data);
    }
    signUp(data) {
        return this.apiClientRequest.post(Constants_1.default.API.MYACCOUNT.SIGN_UP, data);
    }
    requestPassword(data) {
        return this.apiClientRequest.post(Constants_1.default.API.MYACCOUNT.REQUEST_PASSWORD, data);
    }
    resetPassword(data) {
        return this.apiClientRequest.post(Constants_1.default.API.MYACCOUNT.RESET_PASSWORD, data);
    }
    guestSignUp(data) {
        return this.apiClientRequest.post(Constants_1.default.API.MYACCOUNT.GUEST_SIGN_UP, data);
    }
    signOut(data) {
        return this.apiClientRequest.post(Constants_1.default.API.MYACCOUNT.SIGN_OUT, data);
    }
    updateMember(data) {
        return this.apiClientRequest.patch(Constants_1.default.API.MEMBER.UPDATE_MEMBER, data);
    }
    deleteMember() {
        return this.apiClientRequest.delete(Constants_1.default.API.MEMBER.DELETE_MEMBER);
    }
    addMemberAddress(data) {
        return this.apiClientRequest.post(Constants_1.default.API.MEMBER.ADD_MEMBER_ADDRESS, data);
    }
    updateMemberAddress(data, addressId) {
        const url = Constants_1.default.API.MEMBER.UPDATE_MEMBER_ADDRESS;
        const UpdateMemberAddressURL = url.replace(':oid', addressId);
        return this.apiClientRequest.patch(UpdateMemberAddressURL, data);
    }
    deleteMemberAddress(addressId) {
        const url = Constants_1.default.API.MEMBER.DELETE_MEMBER_ADDRESS;
        const UpdateMemberAddressURL = url.replace(':oid', addressId);
        return this.apiClientRequest.delete(UpdateMemberAddressURL);
    }
    addToWishlist(data) {
        return this.apiClientRequest.post(Constants_1.default.API.WISHLIST.ADD, data);
    }
    removeFromWishlist(wishlistItemId) {
        const url = Constants_1.default.API.WISHLIST.REMOVE;
        const removeFromWishlistUrl = url.replace(':oid', wishlistItemId);
        return this.apiClientRequest.delete(removeFromWishlistUrl);
    }
    addToCart(data) {
        return this.apiClientRequest.post(Constants_1.default.API.CART.ADD, data);
    }
    removeFromCart(cartItemId) {
        const url = Constants_1.default.API.CART.REMOVE;
        const removeFromWishlistUrl = url.replace(':oid', cartItemId);
        return this.apiClientRequest.delete(removeFromWishlistUrl);
    }
    deliveryOption(data) {
        return this.apiClientRequest.post(Constants_1.default.API.DELIVERY_OPTION, data);
    }
    addressOption(data) {
        return this.apiClientRequest.post(Constants_1.default.API.ADDRESS_OPTION, data);
    }
    addVoucher(data) {
        return this.apiClientRequest.post(Constants_1.default.API.VOUCHER.ADD, data);
    }
    removeVoucher() {
        return this.apiClientRequest.post(Constants_1.default.API.VOUCHER.REMOVE, {});
    }
    checkoutOrder(data) {
        return this.apiClientRequest.post(Constants_1.default.API.CHECKOUT.ORDER, data);
    }
    loadMore() {
        return this.apiClientRequest.get(Constants_1.default.API.SEARCH.LOAD_MORE, {});
    }
    getSearchResults(query = '') {
        return this.apiClientRequest.get(`${Constants_1.default.API.SEARCH.SEARCH_RESULTS}${SearchUtils_1.QUESTION_MARK}${SearchUtils_1.SEARCH_QUERY_KEY}${SearchUtils_1.EQUAL_TO}${SearchUtils_1.encodeFilters(query)}`, {});
    }
    searchTypeAhead(query = '') {
        return this.apiClientRequest.get(`${Constants_1.default.API.SEARCH.SEARCH_TYPEAHEAD}${SearchUtils_1.QUESTION_MARK}${SearchUtils_1.SEARCH_QUERY_KEY}${SearchUtils_1.EQUAL_TO}${SearchUtils_1.encodeFilters(query)}`, {});
    }
    static async getRoute(location) {
        const { pathname, search } = location;
        const { data } = await axios_1.default.get(pathname + search, undefined);
        return data;
    }
    loadMoreResults({ slug, query, page = 1 }) {
        switch (query) {
            case undefined:
                return this.apiClientRequest.get(`${slug}${SearchUtils_1.QUESTION_MARK}${SearchUtils_1.PAGE_NUMBER}${SearchUtils_1.EQUAL_TO}${page}`, {});
            case '':
            default:
                return this.apiClientRequest.get(`${Constants_1.default.API.SEARCH.SEARCH_RESULTS}${SearchUtils_1.QUESTION_MARK}${SearchUtils_1.SEARCH_QUERY_KEY}${SearchUtils_1.EQUAL_TO}${query}${SearchUtils_1.AND}${SearchUtils_1.PAGE_NUMBER}${SearchUtils_1.EQUAL_TO}${page}`, {});
        }
    }
}
exports.default = ApiConnect;
