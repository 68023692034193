"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const Typography_1 = require("@material-ui/core/Typography");
const makeStyles_1 = require("@material-ui/core/styles/makeStyles");
const useStyles = makeStyles_1.default(({ spacing }) => ({
    textTransformNone: {
        textTransform: 'none',
    },
    uppercase: {
        textTransform: 'uppercase',
    },
    capitalize: {
        textTransform: 'capitalize',
    },
    fontNormal: {
        fontWeight: 'normal',
    },
    fontMedium: {
        fontWeight: 600,
    },
    noWrap: {
        whiteSpace: 'nowrap',
    },
    extras: {
        letterSpacing: ({ letterSpacing }) => letterSpacing,
        opacity: ({ opacity }) => opacity,
        marginBottom: ({ marginBottom }) => marginBottom !== undefined ? spacing(marginBottom) : 'initial',
        marginTop: ({ marginTop }) => (marginTop !== undefined ? spacing(marginTop) : 'initial'),
        marginLeft: ({ marginLeft }) => (marginLeft !== undefined ? spacing(marginLeft) : 'initial'),
        marginRight: ({ marginRight }) => (marginRight !== undefined ? spacing(marginRight) : 'initial'),
    },
    truncate: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: ' -webkit-box',
        '-webkitBoxOrient': 'vertical',
        '-webkitLineClamp': ({ clamp }) => (clamp !== undefined ? clamp : 2),
        wordBreak: 'break-all',
        whiteSpace: 'initial',
    },
}));
const HBTypography = React.forwardRef(({ className, textTransformNone, fontNormal, fontMedium, uppercase, capitalize, letterSpacing, marginTop, marginBottom, marginLeft, marginRight, noWrap, opacity, truncate, component, clamp, ...rest }, ref) => {
    const classes = useStyles({ letterSpacing, opacity, marginBottom, marginTop, marginLeft, marginRight, clamp });
    return (React.createElement(Typography_1.default, Object.assign({}, rest, { component: component, ref: ref, className: classnames_1.default(className, classes.extras, {
            [classes.textTransformNone]: textTransformNone,
            [classes.fontNormal]: fontNormal,
            [classes.fontMedium]: fontMedium,
            [classes.uppercase]: uppercase,
            [classes.capitalize]: capitalize,
            [classes.noWrap]: noWrap,
            [classes.truncate]: truncate,
        }) })));
});
exports.default = HBTypography;
