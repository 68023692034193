"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Grid_1 = require("@material-ui/core/Grid");
const HBTypography_1 = require("@components/library/HBTypography");
const AppContext_1 = require("@components/common/context/AppContext");
const PersonalInfoEditForm_1 = require("@components/pages/profile/detail/LoginDetailInfo/PersonalInfoEditForm");
const ProfileDetailItemSummary_1 = require("@components/pages/profile/detail/ProfileDetailItemSummary");
const LabelContext_1 = require("@components/common/context/LabelContext");
const PersonalInfoSummary = ({ title, editForm }) => {
    const { state: { member } = {}, dispatch } = AppContext_1.useAppContext();
    const labels = LabelContext_1.useLabelContext();
    const { givenName, familyName, telephone } = (member === null || member === void 0 ? void 0 : member.crmContact) || {};
    return (React.createElement(ProfileDetailItemSummary_1.default, { title: title, formComponent: React.createElement(PersonalInfoEditForm_1.default, { editForm: editForm, defaults: { givenName, familyName, telephone }, dispatch: dispatch }) },
        React.createElement(Grid_1.default, { container: true, direction: "column", spacing: 2 },
            React.createElement(Grid_1.default, { item: true },
                React.createElement(HBTypography_1.default, { variant: "body2", opacity: 0.6, capitalize: true }, labels.GIVEN_NAME),
                React.createElement(HBTypography_1.default, { variant: "body1" }, givenName)),
            React.createElement(Grid_1.default, { item: true },
                React.createElement(HBTypography_1.default, { variant: "body2", opacity: 0.6, capitalize: true }, labels.FAMILY_NAME),
                React.createElement(HBTypography_1.default, { variant: "body1" }, familyName)),
            React.createElement(Grid_1.default, { item: true },
                React.createElement(HBTypography_1.default, { variant: "body2", opacity: 0.6, capitalize: true }, labels.TELEPHONE),
                React.createElement(HBTypography_1.default, { variant: "body1" }, telephone)))));
};
exports.default = PersonalInfoSummary;
