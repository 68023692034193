"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Svg_1 = require("../Svg");
const Klarna = props => (React.createElement(Svg_1.default, Object.assign({}, props, { viewBox: "0 0 64 48" }),
    React.createElement("g", { fill: "#fff" },
        React.createElement("path", { d: "M17.98 19h-2.232a5.715 5.715 0 0 1-2.303 4.606l-.882.661 3.425 4.666h2.814l-3.155-4.296A7.895 7.895 0 0 0 17.981 19zM10 19h2.283v9.933H10zm9.463 0h2.152v9.933h-2.152zM40.5 21.874c-.82 0-1.602.25-2.122.961v-.77h-2.043v6.868h2.073v-3.605c0-1.041.7-1.552 1.542-1.552.9 0 1.421.541 1.421 1.542v3.625h2.053v-4.376c0-1.602-1.271-2.693-2.924-2.693zm-12.376.19v.44c-.58-.4-1.282-.63-2.043-.63a3.624 3.624 0 0 0-3.625 3.625 3.624 3.624 0 0 0 5.668 2.994v.44h2.053v-6.869zm-1.873 5.197c-1.03 0-1.862-.791-1.862-1.762s.831-1.763 1.862-1.763c1.032 0 1.863.791 1.863 1.763s-.831 1.762-1.863 1.762zm7.11-4.306v-.89h-2.103v6.868h2.113V25.73c0-1.082 1.171-1.662 1.983-1.662h.02v-2.003c-.832 0-1.603.36-2.013.891zm16.452-.89v.44a3.624 3.624 0 0 0-5.667 2.994 3.624 3.624 0 0 0 5.667 2.994v.44h2.052v-6.869zM47.94 27.26c-1.031 0-1.862-.791-1.862-1.762s.83-1.763 1.862-1.763 1.863.791 1.863 1.763c.01.97-.831 1.762-1.863 1.762z" }),
        React.createElement("path", { d: "M53.654 22.279c0-.11-.107-.175-.276-.175h-.291v.567h.138v-.207h.153l.123.207h.153l-.138-.229c.092-.033.138-.087.138-.163zm-.276.087h-.153v-.175h.153c.092 0 .138.033.138.088 0 .054-.03.087-.138.087z" }),
        React.createElement("path", { d: "M53.247 21.884c-.27 0-.49.22-.49.49.01.27.22.491.49.491a.491.491 0 0 0 0-.981zm0 .891c-.22 0-.39-.18-.39-.4 0-.22.18-.401.39-.401.22 0 .39.18.39.4a.4.4 0 0 1-.39.401z" }),
        React.createElement("path", { d: "M54.058 26.5c-.71 0-1.291.58-1.291 1.292 0 .71.58 1.291 1.291 1.291s1.292-.58 1.292-1.291c0-.721-.58-1.292-1.292-1.292z" }))));
exports.default = Klarna;
