"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useScrollTop = void 0;
function useScrollTop(id) {
    const identifier = id || 'back-to-top-anchor';
    const scrollTopFn = event => {
        const anchor = ((event && event.target.ownerDocument) || document).querySelector(`#${identifier}`);
        if (anchor) {
            anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };
    return { scrollTopFn };
}
exports.useScrollTop = useScrollTop;
