"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Svg_1 = require("../../Svg");
const Mirror = ({ color, ...rest }) => (React.createElement(Svg_1.default, Object.assign({ width: "50", height: "60", viewBox: "0 0 50 60" }, rest),
    React.createElement("g", { transform: "translate(1 1)", stroke: "#000", strokeWidth: "2", fill: "none", fillRule: "evenodd", strokeLinecap: "round", strokeLinejoin: "round" },
        React.createElement("ellipse", { cx: "24", cy: "24", rx: "16.25", ry: "23.71" }),
        React.createElement("path", { d: "M0.25 25.21L7.75 25.21" }),
        React.createElement("path", { d: "M47.75 25.21L40.25 25.21" }),
        React.createElement("path", { d: "M2.75 52.71L45.25 52.71" }),
        React.createElement("g", { transform: "rotate(1 -618.617 986.029)" },
            React.createElement("path", { d: "M0 4.95L4.95 0" }),
            React.createElement("path", { d: "M0 12.95L11.314 1.636" })),
        React.createElement("path", { d: "M2.75 21.46L2.75 57.71" }),
        React.createElement("path", { d: "M45.25 21.46L45.25 57.71" }))));
exports.default = Mirror;
