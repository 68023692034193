"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const withLayout_1 = require("@components/common/layout/withLayout");
const createStyles_1 = require("@material-ui/core/styles/createStyles");
const Grid_1 = require("@material-ui/core/Grid");
const RequestPasswordForm_1 = require("@components/pages/myaccount/RequestPasswordForm");
const FullWidthBackground_1 = require("@components/common/utils/FullWidthBackground");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const HBTypography_1 = require("@components/library/HBTypography");
const styles = ({ palette, spacing, breakpoints }) => createStyles_1.default({
    root: {
        padding: spacing(8, 1.25),
        ['@media (max-width:768px)']: {
            padding: spacing(5, 1.25),
        },
        [breakpoints.down('xs')]: {
            padding: spacing(1.25),
        },
    },
    container: {
        [breakpoints.down('xs')]: {
            padding: spacing(0),
        },
    },
    wrapper: {
        backgroundColor: palette.common.white,
        padding: spacing(5),
        margin: '0 auto',
        maxWidth: 680,
        ['@media (max-width:768px)']: {
            padding: spacing(3),
        },
        [breakpoints.down('xs')]: {
            padding: spacing(2.5),
        },
    },
    title: {
        marginBottom: spacing(3),
    },
    subtitle: {
        marginBottom: spacing(3),
    },
});
const PageRequestPassword = ({ classes, page: { title, subtitle, form } }) => {
    return (React.createElement(FullWidthBackground_1.default, { className: classes.root },
        React.createElement(Grid_1.default, { container: true, item: true },
            React.createElement(Grid_1.default, { container: true, item: true, xs: 12, alignItems: 'center', justify: 'center', className: classes.wrapper },
                React.createElement(HBTypography_1.default, { variant: "h4", component: "h1", className: classes.title }, title),
                React.createElement(HBTypography_1.default, { variant: "body1", component: "h2", className: classes.subtitle }, subtitle),
                React.createElement(Grid_1.default, { item: true, xs: 12, md: 6 },
                    React.createElement(RequestPasswordForm_1.default, { form: form }))))));
};
exports.default = withStyles_1.default(styles)(withLayout_1.default(PageRequestPassword, { paddingTop: 0, paddingBottom: 0 }));
