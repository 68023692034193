"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Svg_1 = require("./Svg");
const G_1 = require("./Svg/G");
const Person = ({ color = 'primary', ...rest }) => (React.createElement(Svg_1.default, Object.assign({}, rest),
    React.createElement(G_1.default, { transform: "translate(-4 -5)", color: color, strokeWidth: "2", fill: "none", fillRule: "evenodd", strokeLinecap: "round", strokeLinejoin: "round" },
        React.createElement("circle", { cx: "16", cy: "12", r: "6" }),
        React.createElement("path", { d: "M25 27c-1.646-3.664-5.15-6-9-6-3.85 0-7.354 2.336-9 6" }))));
exports.default = Person;
