"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Svg_1 = require("./Svg");
const PersonFilled = ({ color, ...rest }) => (React.createElement(Svg_1.default, Object.assign({}, rest),
    React.createElement("g", { transform: "translate(-4 -5)", stroke: "#B6956C", strokeWidth: "2", fill: "none", fillRule: "evenodd", strokeLinejoin: "round" },
        React.createElement("circle", { fill: "#B6956C", strokeLinecap: "round", cx: "16", cy: "12", r: "6" }),
        React.createElement("path", { d: "M25 27c-1.646-3.664-5.15-6-9-6-3.85 0-7.354 2.336-9 6", strokeLinecap: "round" }),
        React.createElement("path", { d: "M25 27c-1.646-3.664-5.15-6-9-6-3.85 0-7.354 2.336-9 6h18z", fill: "#B6956C" }))));
exports.default = PersonFilled;
