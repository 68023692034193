"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
const typography_1 = require("@components/library/theme/typography");
const styles = ({ palette, spacing }) => styles_1.createStyles({
    img: {
        objectFit: 'cover',
        position: 'absolute',
    },
    content: {
        top: 0,
        left: 0,
        right: 0,
        width: '100%',
        height: '100%',
        position: 'absolute',
        zIndex: 1,
        color: palette.common.white,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: spacing(2),
    },
    shadow: {
        background: 'rgba(0, 0, 0, 0.5)',
    },
    title: {
        maxWidth: 560,
    },
    subtitle: {
        fontFamily: typography_1.fontFamilyPrimary,
        marginBottom: spacing(3),
    },
});
exports.default = styles;
