"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Grid_1 = require("@material-ui/core/Grid");
const HBTypography_1 = require("@components/library/HBTypography");
const HBButton_1 = require("@components/library/HBButton");
const HBIcon_1 = require("@components/library/HBIcon");
const AppContext_1 = require("@components/common/context/AppContext");
const LoginInfoEditForm_1 = require("@components/pages/profile/detail/LoginDetailInfo/LoginInfoEditForm");
const ProfileDetailItemSummary_1 = require("@components/pages/profile/detail/ProfileDetailItemSummary");
const LabelContext_1 = require("@components/common/context/LabelContext");
const useApiRequest_1 = require("@components/common/utils/hooks/useApiRequest");
const ApiContext_1 = require("@components/common/context/ApiContext");
const LoginInfoSummary = ({ api, title, editForm }) => {
    const { state: { member } = {}, dispatch } = AppContext_1.useAppContext();
    const labels = LabelContext_1.useLabelContext();
    const { email } = (member === null || member === void 0 ? void 0 : member.crmContact) || {};
    const { onSubmit } = useApiRequest_1.default();
    const handleDeleteProfile = async () => {
        if (typeof window !== 'undefined' && window.confirm(labels.DELETE_MEMBER_WARNING)) {
            await onSubmit(() => api.deleteMember(), () => {
                window.location.reload();
            });
        }
    };
    return (React.createElement(ProfileDetailItemSummary_1.default, { title: title, formComponent: React.createElement(LoginInfoEditForm_1.default, { editForm: editForm, defaults: { email }, dispatch: dispatch }) },
        React.createElement(Grid_1.default, { container: true, direction: "column", spacing: 2 },
            React.createElement(Grid_1.default, { item: true },
                React.createElement(HBTypography_1.default, { variant: "body2", opacity: 0.6, capitalize: true }, labels.EMAIL),
                React.createElement(HBTypography_1.default, { variant: "body1" }, email)),
            React.createElement(Grid_1.default, { item: true },
                React.createElement(HBTypography_1.default, { variant: "body2", opacity: 0.6, capitalize: true }, labels.PASSWORD),
                React.createElement(HBTypography_1.default, { variant: "body1" }, '• • • • • • • • • • •')),
            React.createElement(Grid_1.default, { item: true },
                React.createElement(HBButton_1.default, { variant: "text", onClick: handleDeleteProfile, hasIcon: true },
                    React.createElement(HBIcon_1.default, { icon: "remove", color: "secondary", fontSize: "xsmall" }),
                    React.createElement(HBTypography_1.default, { color: "secondary", uppercase: true }, labels.DELETE_ACCOUNT))))));
};
exports.default = ApiContext_1.withApi(LoginInfoSummary);
