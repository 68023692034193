"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PageTemplates = exports.ContentPageTemplates = exports.SystemPageTemplates = void 0;
var SystemPageTemplates;
(function (SystemPageTemplates) {
    SystemPageTemplates["PT_ERROR"] = "page-template-error";
    SystemPageTemplates["PT_NOT_FOUD"] = "page-not-found";
    SystemPageTemplates["PT_MESSAGE"] = "page-message";
    SystemPageTemplates["PT_WISHLIST"] = "page-wishlist";
    SystemPageTemplates["PT_CHECKOUT"] = "page-checkout";
    SystemPageTemplates["PT_BRAND_LIST"] = "page-brand-list";
    SystemPageTemplates["PT_BRAND_DETAIL"] = "page-brand-detail";
    SystemPageTemplates["PT_PRODUCT_DETAIL"] = "page-product-detail";
})(SystemPageTemplates = exports.SystemPageTemplates || (exports.SystemPageTemplates = {}));
var ContentPageTemplates;
(function (ContentPageTemplates) {
    ContentPageTemplates["PT_HOME"] = "page-home";
    ContentPageTemplates["PT_PRODUCT_LIST"] = "page-product-list";
    ContentPageTemplates["PT_FAQ"] = "page-faq";
    ContentPageTemplates["PT_CONTACT"] = "page-contact";
    ContentPageTemplates["PT_PROFILE_SIGNUP"] = "page-profile-signup";
    ContentPageTemplates["PT_REQUEST_PASSWORD"] = "page-request-password";
    ContentPageTemplates["PT_SET_PASSWORD"] = "page-set-password";
    ContentPageTemplates["PT_PROFILE_ACCOUNT"] = "page-profile-account";
    ContentPageTemplates["PT_SEARCH_RESULT"] = "page-search-result";
    ContentPageTemplates["PT_PLAIN_CONTENT"] = "page-plain-content";
})(ContentPageTemplates = exports.ContentPageTemplates || (exports.ContentPageTemplates = {}));
exports.PageTemplates = { ...SystemPageTemplates, ...ContentPageTemplates };
