"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const FormFieldContext_1 = require("@components/common/utils/Form/FormFieldContext");
const FormFieldContext_2 = require("@components/common/utils/Form/FormFieldContext/FormFieldContext");
const ApiContext_1 = require("@components/common/context/ApiContext");
const useApiRequest_1 = require("@components/common/utils/hooks/useApiRequest");
const FormContext_1 = require("@components/common/utils/Form/FormContext");
const AddressInfoFieldDefaults = {
    adressLine: '',
    adressLine1: '',
    streetAddress: '',
    postalCode: '',
    addressLocality: '',
};
const AddressInfoStepForm = ({ api, updateContext, form, defaultAddress, isEdit = false, }) => {
    const defaultValues = { ...AddressInfoFieldDefaults };
    const { methods } = FormFieldContext_2.useFormHook({ defaultValues });
    const { onSubmit, isLoading, data, apiError } = useApiRequest_1.default();
    React.useEffect(() => {
        if (!apiError && !!data) {
            updateContext(data);
        }
    }, [data]);
    React.useEffect(() => {
        if (!!defaultAddress) {
            methods.reset({ ...AddressInfoFieldDefaults, ...defaultAddress });
        }
    }, [defaultAddress]);
    const handleSubmit = async (payload) => await onSubmit(() => isEdit ? api.updateMemberAddress(payload, defaultAddress._id) : api.addMemberAddress(payload));
    return (React.createElement(FormFieldContext_1.default, { methods: methods, onSubmit: handleSubmit },
        React.createElement(FormContext_1.default, Object.assign({}, form, { disabled: isLoading, errors: apiError === null || apiError === void 0 ? void 0 : apiError.inputError }))));
};
exports.default = ApiContext_1.withApi(AddressInfoStepForm);
