"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Svg_1 = require("./Svg");
const G_1 = require("./Svg/G");
const Help = ({ color = 'primary', ...rest }) => (React.createElement(Svg_1.default, Object.assign({}, rest),
    React.createElement(G_1.default, { color: color, transform: "translate(2 1)", strokeWidth: "2", fill: "none", fillRule: "evenodd", strokeLinecap: "round", strokeLinejoin: "round" },
        React.createElement("path", { d: "M10 0a11.763 11.763 0 0 1 6.112 1.693c4.75 2.88 5.1 8.829 1.315 13.178A56.376 56.376 0 0 1 10 22a56.376 56.376 0 0 1-7.427-7.129C-1.211 10.521-.862 4.573 3.888 1.693A11.763 11.763 0 0 1 10 0zm0 15.5a.25.25 0 0 1 .25.25m-.5-.001A.25.25 0 0 1 10 15.5m0 .5a.25.25 0 0 1-.25-.25" }),
        React.createElement("path", { d: "M10.25 15.749A.25.25 0 0 1 10 16M7 7.5a3 3 0 1 1 4.2 2.75 2 2 0 0 0-1.2 1.833v.417" }))));
exports.default = Help;
