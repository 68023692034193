"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const App_1 = require("@components/App");
const ApiContext_1 = require("@components/common/context/ApiContext");
const AppContext_1 = require("@components/common/context/AppContext");
const LabelContext_1 = require("@components/common/context/LabelContext");
const SearchContext_1 = require("@components/common/context/SearchContext");
const SearchInputContext_1 = require("@components/common/context/SearchInputContext");
const SnackMessages_1 = require("@components/common/utils/Snackbar/SnackMessages");
const theme_1 = require("@components/library/theme");
const CssBaseline_1 = require("@material-ui/core/CssBaseline");
const styles_1 = require("@material-ui/core/styles");
const notistack_1 = require("notistack");
const React = require("react");
const ReactDOM = require("react-dom");
const react_router_dom_1 = require("react-router-dom");
const ApiClientRequest_1 = require("./api/ApiClientRequest");
const ApiConnect_1 = require("./api/ApiConnect");
const apiClientRequest = new ApiClientRequest_1.default();
const apiConnect = new ApiConnect_1.default(apiClientRequest);
function Main(props) {
    const { cart, wishlist, member, labels, page } = props;
    React.useEffect(() => {
        const jssStyles = document.querySelector('#jss-server-side');
        if (jssStyles) {
            jssStyles.parentElement.removeChild(jssStyles);
        }
    }, []);
    return (React.createElement(styles_1.ThemeProvider, { theme: theme_1.default },
        React.createElement(CssBaseline_1.default, null),
        React.createElement(react_router_dom_1.BrowserRouter, null,
            React.createElement(ApiContext_1.ApiProvider, { api: apiConnect },
                React.createElement(LabelContext_1.LabelContextProvider, { labels: labels },
                    React.createElement(AppContext_1.default, { cart: cart, member: member, wishlist: wishlist },
                        React.createElement(SearchContext_1.default, Object.assign({}, page),
                            React.createElement(SearchInputContext_1.default, null,
                                React.createElement(notistack_1.SnackbarProvider, { maxSnack: 4, dense: true, hideIconVariant: true },
                                    React.createElement(SnackMessages_1.default, null),
                                    React.createElement(react_router_dom_1.Route, { render: route => React.createElement(App_1.default, Object.assign({ route: route }, props)) }))))))))));
}
const initData = window['__INITIAL__DATA__'];
ReactDOM.hydrate(React.createElement(Main, Object.assign({}, initData)), document.querySelector('#root'));
apiConnect.init(initData);
