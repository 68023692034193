"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Grid_1 = require("@material-ui/core/Grid");
const HBTypography_1 = require("@components/library/HBTypography");
const HBIcon_1 = require("@components/library/HBIcon");
const makeStyles_1 = require("@material-ui/core/styles/makeStyles");
const Colors_1 = require("@components/library/theme/Colors");
const HBFab_1 = require("@components/library/HBFab");
const HBButton_1 = require("@components/library/HBButton");
const useApiRequest_1 = require("@components/common/utils/hooks/useApiRequest");
const ApiContext_1 = require("@components/common/context/ApiContext");
const AppContext_1 = require("@components/common/context/AppContext");
const useStyles = makeStyles_1.default(({ spacing }) => ({
    voucherButton: {
        border: 'none',
        padding: spacing(0, 7, 0, 0),
        '& svg': {
            opacity: 1,
        },
    },
    voucherCode: {
        color: Colors_1.default.successGreen,
    },
}));
const VoucherInfoStepSummary = ({ api, voucher }) => {
    const classes = useStyles();
    const { dispatch } = AppContext_1.useAppContext();
    const { onSubmit, isLoading, data, apiError } = useApiRequest_1.default();
    React.useEffect(() => {
        if (!apiError && (data === null || data === void 0 ? void 0 : data.cart)) {
            dispatch({ type: AppContext_1.ActionTypes.REMOVE_VOUCHER_INFO, payload: data });
        }
    }, [data]);
    const handleSubmit = async () => await onSubmit(() => api.removeVoucher());
    return (React.createElement(Grid_1.default, { container: true, direction: "row", justify: "space-between", alignItems: "center" },
        React.createElement(Grid_1.default, { item: true },
            React.createElement(HBFab_1.default, { variant: "extended", disabled: true, className: classes.voucherButton },
                React.createElement(HBTypography_1.default, { variant: "body1", className: classes.voucherCode }, voucher),
                React.createElement(HBIcon_1.default, { icon: "check", color: "ternary" }))),
        React.createElement(Grid_1.default, { item: true },
            React.createElement(HBButton_1.default, { variant: "text", onClick: handleSubmit, hasIcon: true, disabled: isLoading },
                React.createElement(HBIcon_1.default, { icon: "close", color: "secondary", fontSize: "3xsmall" }),
                React.createElement(HBTypography_1.default, { color: "secondary" }, "Entfernen")))));
};
exports.default = ApiContext_1.withApi(VoucherInfoStepSummary);
