"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const createStyles_1 = require("@material-ui/core/styles/createStyles");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const HBImage_1 = require("@components/library/HBImage");
const styles = () => createStyles_1.default({
    root: {
        width: '100%',
        height: '100%',
        backgroundRepeat: 'no-repeat',
        '& img': {
            cursor: 'pointer',
        },
        '&:hover img': {
            opacity: 0,
        },
    },
    image: {
        maxHeight: '100%',
        height: 'auto',
    },
});
const HoverZoomImage = ({ classes, src, alt, ...rest }) => {
    const [style, setStyle] = React.useState({
        backgroundImage: 'none',
        backgroundPosition: '0% 0%',
    });
    const handleMouseMove = React.useCallback(e => {
        const { left, top, width, height } = e.target.getBoundingClientRect();
        const x = ((e.pageX - left) / width) * 100;
        const y = ((e.pageY - top) / height) * 100;
        setStyle({ backgroundImage: `url(${src})`, backgroundPosition: `${x}% ${y}%` });
    }, [setStyle, src]);
    const handleMouseLeave = React.useCallback(() => {
        setStyle({ backgroundImage: 'none', backgroundPosition: '0% 0%' });
    }, []);
    return (React.createElement("div", { onMouseMove: handleMouseMove, onMouseLeave: handleMouseLeave, className: classes.root, style: style },
        React.createElement(HBImage_1.default, Object.assign({ src: src, alt: alt }, rest, { className: classes.image }))));
};
exports.default = withStyles_1.default(styles)(HoverZoomImage);
