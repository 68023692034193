"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const Card_1 = require("@material-ui/core/Card");
const CardContent_1 = require("@material-ui/core/CardContent");
const CardActions_1 = require("@material-ui/core/CardActions");
const HBImage_1 = require("@components/library/HBImage");
const HBLink_1 = require("@components/library/HBLink");
const HBTypography_1 = require("@components/library/HBTypography");
const ProductSaleTag_1 = require("@components/pages/product/ProductSaleTag");
const ProductPrice_1 = require("@components/pages/product/ProductPrice");
const ProductWishlist_1 = require("@components/pages/product/detail/ProductWishlist");
const AppContext_1 = require("@components/common/context/AppContext");
const ApiContext_1 = require("@components/common/context/ApiContext");
const useApiRequest_1 = require("@components/common/utils/hooks/useApiRequest");
const ZoomOutImage_1 = require("@components/common/utils/ZoomOutImage");
const ProductCardStyles_1 = require("./ProductCardStyles");
const LabelContext_1 = require("@components/common/context/LabelContext");
const ProductCardButton_1 = require("@components/pages/product/ProductCard/ProductCardButton");
const ProductCard = withStyles_1.default(ProductCardStyles_1.default)(({ api, cartButton, classes, imgSize, ...props }) => {
    const { _id, image, name, to, isSale, availability, alternateName } = props;
    const outOfStock = availability === 'OutOfStock';
    const { state: { cart: { items: cartItems } = {} }, dispatch, } = AppContext_1.useAppContext();
    const { isLoading, onSubmit } = useApiRequest_1.default();
    const labels = LabelContext_1.useLabelContext();
    const handleAddToCart = async (id) => {
        await onSubmit(() => api.addToCart({ oid: id, amount: 1, isCart: false }), data => {
            dispatch({
                type: AppContext_1.ActionTypes.ADD_TO_CART,
                payload: data,
            });
        });
    };
    return (React.createElement(Card_1.default, { elevation: 0, className: classes.root },
        React.createElement("div", { ref: props === null || props === void 0 ? void 0 : props.lastElementRef, className: classnames_1.default({ [classes.outOfStock]: outOfStock }) },
            React.createElement(HBLink_1.default, { to: to, className: classes.content, title: name },
                React.createElement(React.Fragment, null,
                    React.createElement("div", { className: classes.prodImageWrapper },
                        React.createElement(ZoomOutImage_1.default, { dense: true },
                            React.createElement(HBImage_1.default, { src: image === null || image === void 0 ? void 0 : image.path, alt: name, size: imgSize }))),
                    React.createElement(CardContent_1.default, { className: classes.nameWrapper },
                        React.createElement(HBTypography_1.default, { variant: "body1", align: "center", fontMedium: true, gutterBottom: true, truncate: true, className: classes.name }, name),
                        React.createElement(HBTypography_1.default, { variant: "body1", align: "center", truncate: true, gutterBottom: true, clamp: 1, className: classes.alternateName }, alternateName),
                        React.createElement(ProductPrice_1.default, { item: props, classes: {
                                root: classes.priceWrapper,
                                price: classes.price,
                                salePrice: classes.salePrice,
                            } })))),
            React.createElement(ProductSaleTag_1.default, { isSale: isSale }),
            React.createElement("div", { className: classes.wishlistIconWrapper },
                React.createElement(ProductWishlist_1.default, { item: props, iconColor: "primary", classes: { icon: classes.wishlistIcon, filledIcon: classes.wishlistFilledIcon } }))),
        cartButton && (React.createElement(CardActions_1.default, { className: classes.ctaWrapper },
            React.createElement(React.Fragment, null, outOfStock ? (React.createElement(ProductCardButton_1.default, { "aria-label": labels.OUT_OF_STOCK, label: labels.OUT_OF_STOCK, icon: "error_outline", className: classes.outOfStockButton, disabled: true })) : (cartItems === null || cartItems === void 0 ? void 0 : cartItems.find(item => item._id === _id.toString())) ? (React.createElement(ProductCardButton_1.default, { "aria-label": labels.ALREADY_IN_CART, label: labels.ALREADY_IN_CART, icon: "check_mark", className: classes.inCartButton, disabled: true, isTernary: true, disableHover: true, variant: "text" })) : (React.createElement(ProductCardButton_1.default, { "aria-label": labels.ADD_TO_CART, label: labels.ADD_TO_CART, icon: "cart", disabled: isLoading, variant: "contained", color: "primary", onClick: () => handleAddToCart(_id) })))))));
});
exports.default = ApiContext_1.withApi(ProductCard);
