"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Svg_1 = require("./Svg");
const G_1 = require("./Svg/G");
const FaqCircle = ({ color = 'primary', ...rest }) => (React.createElement(Svg_1.default, Object.assign({}, rest, { viewBox: "0 0 134 134" }),
    React.createElement(G_1.default, { fill: "none", fillRule: "evenodd", color: color, strokeWidth: "3", transform: "translate(2 2)" },
        React.createElement("circle", { cx: "65", cy: "65", r: "65" }),
        React.createElement("path", { d: "M65.2 82.4a.85.85 0 100 1.7.85.85 0 000-1.7zM55 55.217a10.2 10.2 0 1114.28 9.35 6.8 6.8 0 00-4.08 6.232V72.2", strokeLinecap: "round", strokeLinejoin: "round" }))));
exports.default = FaqCircle;
