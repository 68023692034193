"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Grid_1 = require("@material-ui/core/Grid");
const HBFilter_1 = require("@components/library/HBFilter");
const HBAccordion_1 = require("@components/library/HBAccordion/HBAccordion");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const createStyles_1 = require("@material-ui/core/styles/createStyles");
const SearchPageContext_1 = require("@components/common/context/SearchPageContext");
const SearchContext_1 = require("@components/common/context/SearchContext");
const styles = ({ palette }) => createStyles_1.default({
    selectedIcon: {
        transform: 'rotate(45deg)',
    },
    selected: {
        opacity: 0.4,
        color: palette.primary.main,
        backgroundColor: '#d8d8d8',
    },
});
const FilterOption = ({ classes, title, selected, onChange, items, isSelect }) => {
    const { handleSortOption } = SearchPageContext_1.useSearchPageContext();
    const { initialSearchResults: { sortBy }, } = SearchContext_1.useSearchContext();
    const { selectedFilterIds, handleSelectedFilters } = SearchContext_1.useSearchContext();
    return (React.createElement(HBAccordion_1.default, { label: title, selected: selected, onChange: onChange, denseHeader: true, icon: "plus", classes: { selectedIcon: classes.selectedIcon } },
        React.createElement(Grid_1.default, { container: true, spacing: 1, wrap: "nowrap" }, items === null || items === void 0 ? void 0 : items.map((filter, idX) => {
            const selected = isSelect
                ? sortBy === filter.id
                : !!(selectedFilterIds === null || selectedFilterIds === void 0 ? void 0 : selectedFilterIds.some(sFilter => sFilter === (filter === null || filter === void 0 ? void 0 : filter.id)));
            return (React.createElement(Grid_1.default, { item: true, key: idX.toString() },
                React.createElement(HBFilter_1.default, { value: filter.id, label: filter.title, onClick: isSelect ? () => handleSortOption(filter.id) : () => handleSelectedFilters(filter), selected: selected, endIcon: selected ? 'check_mark' : 'plus', classes: { selected: classes.selected }, textWrap: true })));
        }))));
};
exports.default = withStyles_1.default(styles)(FilterOption);
