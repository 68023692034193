"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@material-ui/core");
exports.default = {
    white: '#FFFFFF',
    darkGrey: '#121212',
    mainGrey: '#1A1A1A',
    lightGrey: '#474747',
    darkGold: '#7f684b',
    mainGold: '#B6956C',
    lightGold: '#c4aa89',
    orange: '#ED9025',
    red: '#BC4414',
    green: '#34C585',
    borderGrey: core_1.fade('#1A1A1A', 0.5),
    borderLighGrey: core_1.fade('#474747', 0.5),
    backgroundGrey: '#F5F5F5',
    successGreen: '#34c585',
};
