"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Svg_1 = require("../../Svg");
const Lighting = ({ color, ...rest }) => (React.createElement(Svg_1.default, Object.assign({ width: "52", height: "54", viewBox: "0 0 52 54" }, rest),
    React.createElement("g", { transform: "translate(1 1)", stroke: "#000", strokeWidth: "2", fill: "none", fillRule: "evenodd", strokeLinecap: "round", strokeLinejoin: "round" },
        React.createElement("rect", { x: "12", width: "27", height: "38", rx: "1" }),
        React.createElement("path", { d: "M25 38L25 45" }),
        React.createElement("path", { d: "M8 52c0-3.866 7.933-7 17-7 10.2 0 17 3.134 17 7H8z" }),
        React.createElement("path", { d: "M0 18L7 18" }),
        React.createElement("path", { d: "M2 4L7 9" }),
        React.createElement("path", { d: "M2 32L7 27" }),
        React.createElement("path", { d: "M50 18L43 18" }),
        React.createElement("path", { d: "M48 4L43 9" }),
        React.createElement("path", { d: "M48 32L43 27" }))));
exports.default = Lighting;
