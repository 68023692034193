"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Svg_1 = require("../../Svg");
const Fashion = ({ color, ...rest }) => (React.createElement(Svg_1.default, Object.assign({ width: "50", height: "60", viewBox: "0 0 50 60" }, rest),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M30.264 51c2.601 0 4.806-2.185 4.806-5s-2.205-5-4.806-5c-2.6 0-4.805 2.185-4.805 5s2.204 5 4.805 5Zm0 2c3.759 0 6.806-3.134 6.806-7s-3.047-7-6.806-7c-3.758 0-6.805 3.134-6.805 7s3.047 7 6.805 7ZM48.736 51c2.601 0 4.806-2.185 4.806-5s-2.205-5-4.806-5-4.805 2.185-4.805 5 2.204 5 4.805 5Zm0 2c3.759 0 6.806-3.134 6.806-7s-3.047-7-6.806-7c-3.758 0-6.805 3.134-6.805 7s3.047 7 6.805 7Z", fill: "#000" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M42.278 44.953c-2.377-1.048-5.018-.433-5.999 0l-.806-1.83c1.288-.568 4.545-1.352 7.612 0l-.807 1.83ZM21 46a1 1 0 0 1 1-1h1.458a1 1 0 1 1 0 2H22a1 1 0 0 1-1-1ZM54.542 46a1 1 0 0 1 1-1H57a1 1 0 1 1 0 2h-1.458a1 1 0 0 1-1-1ZM42.57 4a1 1 0 0 1 .977.788l4.379 20.233c.106.491.232.986.364 1.502l.017.065c.135.533.276 1.089.393 1.65.235 1.12.391 2.323.231 3.566-.184 1.432-.779 3.092-2.223 4.63-.695.74-1.89 1.369-3.229 1.812-1.37.454-3.015.754-4.713.754H38a1 1 0 0 1 0-2h.766c1.472 0 2.905-.262 4.085-.653 1.21-.4 2.037-.897 2.399-1.282 1.12-1.192 1.56-2.445 1.697-3.516.12-.923.01-1.875-.204-2.902a37.458 37.458 0 0 0-.375-1.565l-.019-.076a43.974 43.974 0 0 1-.378-1.562L41.763 6H23.55V4H42.57ZM6.156 6h17.392V5l.001-1H5.311a1 1 0 0 0-.986.834L.385 28.187l-.042.133a7.161 7.161 0 0 0-.148.573A8.673 8.673 0 0 0 0 30.843c.022 1.598.5 3.685 2.292 5.592.694.74 1.89 1.368 3.228 1.811 1.37.454 3.015.754 4.713.754h12.712a1 1 0 0 0 0-2H10.234c-1.472 0-2.905-.262-4.085-.653-1.21-.4-2.037-.897-2.399-1.282-1.379-1.468-1.732-3.038-1.75-4.25a6.674 6.674 0 0 1 .149-1.497 5.166 5.166 0 0 1 .138-.509l.006-.017v.001M6.155 6 2.344 28.604a1 1 0 0 1-.05.187", fill: "#000" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M25 17h-2v2h2v-2Zm-2-2a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2v-2a2 2 0 0 0-2-2h-2Z", fill: "#000" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M27.994 17c7.862-.1 13.013-6.332 13.013-12h2c0 6.781-6.052 13.89-14.995 14H26v-2h1.994ZM20.013 17C12.151 16.9 7 10.668 7 5H5c0 6.781 6.052 13.89 14.994 14H22v-2h-1.987Z", fill: "#000" })));
exports.default = Fashion;
