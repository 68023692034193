"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSearchInputContext = void 0;
const React = require("react");
const react_router_dom_1 = require("react-router-dom");
const ApiContext_1 = require("@components/common/context/ApiContext");
const useApiRequest_1 = require("@components/common/utils/hooks/useApiRequest");
const useDebounce_1 = require("@components/common/utils/hooks/useDebounce");
const SearchUtils_1 = require("@components/common/utils/SearchUtils");
const SearchContext_1 = require("./SearchContext");
const SearchInputContext = React.createContext({});
const SearchInputContextProvider = ({ api, children }) => {
    const [searchTerm, setSearchTerm] = React.useState('');
    const [searchTerms, setSearchTerms] = React.useState([]);
    const [focused, setFocused] = React.useState(false);
    const [isSearching, setIsSearching] = React.useState(false);
    const [typeAheadResults, setTypeAheadResults] = React.useState(null);
    const { onSubmit } = useApiRequest_1.default();
    const history = react_router_dom_1.useHistory();
    const { selectedFilterIds, handleSelectedFilters, handleResetAllFilters } = SearchContext_1.useSearchContext();
    const debouncedSearchTerm = useDebounce_1.default(searchTerm, 300);
    const isFirstRun = React.useRef(true);
    React.useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            const { t } = SearchUtils_1.getFilters(history);
            return setSearchTerms(t);
        }
        if (!focused)
            return;
        if (focused) {
            fetchSearchTypeAheadResults(SearchUtils_1.getTypeAheadPayload(selectedFilterIds, [...searchTerms, debouncedSearchTerm]));
        }
    }, [focused, debouncedSearchTerm]);
    React.useEffect(() => {
        const { t: termsInUrl } = SearchUtils_1.getFilters(history);
        const delta = searchTerms
            .map(term => {
            if (!termsInUrl.includes(term))
                return term;
        })
            .filter(Boolean);
        for (const term of delta) {
            SearchUtils_1.addSearchTerm(term, history);
        }
    }, [searchTerms]);
    const handleDeleteSearchTerm = term => e => {
        SearchUtils_1.removeSearchTerm(term, history);
        setSearchTerms(searchTerms.filter(t => t !== term));
        e.stopPropagation();
    };
    const handleClearSearchTerms = () => {
        setSearchTerms(() => []);
        setSearchTerm(() => '');
        handleResetAllFilters();
    };
    const handleSearchTerm = (searchTerm = '') => setSearchTerm(searchTerm);
    const handleDeleteSearchFilter = filter => e => {
        handleSelectedFilters(filter);
        e.stopPropagation();
    };
    const handleOpenSearch = () => {
        if (!focused) {
            setFocused(true);
        }
    };
    const handleCloseSearch = () => {
        if (focused) {
            handleSearchTerm();
            setFocused(false);
        }
    };
    const fetchSearchTypeAheadResults = (data = {}) => {
        if (!focused)
            return;
        setIsSearching(true);
        onSubmit(() => api.searchTypeAhead(data), setTypeAheadResults)
            .then(() => { })
            .catch(console.error)
            .finally(() => setIsSearching(false));
    };
    return (React.createElement(SearchInputContext.Provider, { value: {
            searchTerm,
            setSearchTerms,
            handleSearchTerm,
            focused,
            setFocused,
            handleOpenSearch,
            handleCloseSearch,
            isSearching,
            typeAheadResults,
            searchSelectedTerms: searchTerms,
            handleDeleteSearchTerm,
            handleDeleteSearchFilter,
            handleClearSearchTerms,
        } }, children));
};
exports.default = ApiContext_1.withApi(SearchInputContextProvider);
exports.useSearchInputContext = () => React.useContext(SearchInputContext);
