"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const makeStyles_1 = require("@material-ui/core/styles/makeStyles");
const Grid_1 = require("@material-ui/core/Grid");
const TextField_1 = require("@material-ui/core/TextField");
const HBFab_1 = require("../HBFab");
const HBIcon_1 = require("../HBIcon");
const useStyles = makeStyles_1.default(() => ({
    textField: {
        maxWidth: '49px',
    },
    input: {
        textAlign: 'center',
    },
}));
const HBQuantityPicker = ({ quantity, buttonProps, iconProps, onChange, max = 10, disabled, incrementLabel = 'increment quantity', decrementLabel = 'decrement quantity', }) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(quantity);
    const handleChangeQuantity = (event) => {
        if (!(event.target instanceof HTMLInputElement)) {
            throw new Error('wrong target');
        }
        const onlyNums = event.target.value.replace(/[^0-9]/g, '');
        if (onlyNums && parseInt(onlyNums) > 0 && parseInt(onlyNums) <= max) {
            setValue(parseInt(onlyNums, 10));
            if (onChange)
                onChange(value);
        }
    };
    React.useEffect(() => {
        if (onChange)
            onChange(value);
    }, [value]);
    const handleIncrement = () => {
        setValue(value + 1);
    };
    const handleDecrement = () => {
        if (value !== 1) {
            setValue(value - 1);
        }
    };
    return (React.createElement(Grid_1.default, { container: true, alignItems: "center", spacing: 1, wrap: "nowrap" },
        React.createElement(Grid_1.default, { item: true },
            React.createElement(HBFab_1.default, Object.assign({ "aria-label": incrementLabel, disabled: disabled || value === 1, onClick: handleDecrement }, buttonProps),
                React.createElement(HBIcon_1.default, Object.assign({ icon: "minus", fontSize: "2xsmall" }, iconProps)))),
        React.createElement(Grid_1.default, { item: true },
            React.createElement(TextField_1.default, { value: value, fullWidth: true, className: classes.textField, InputProps: { classes: { input: classes.input } }, onChange: handleChangeQuantity })),
        React.createElement(Grid_1.default, { item: true },
            React.createElement(HBFab_1.default, Object.assign({ "aria-label": decrementLabel, onClick: handleIncrement, disabled: disabled || max === value }, buttonProps),
                React.createElement(HBIcon_1.default, Object.assign({ icon: "plus", fontSize: "2xsmall" }, iconProps))))));
};
exports.default = HBQuantityPicker;
