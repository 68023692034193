"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const Button_1 = require("@material-ui/core/Button");
const makeStyles_1 = require("@material-ui/core/styles/makeStyles");
const core_1 = require("@material-ui/core");
const HBIcon_1 = require("@components/library/HBIcon");
const useStyles = makeStyles_1.default(({ palette, spacing, transitions }) => ({
    root: {
        padding: spacing(1.375, 4),
        height: spacing(6),
        borderRadius: spacing(3),
        whiteSpace: 'nowrap',
        '& .MuiButton-startIcon': {
            marginRight: spacing(2),
        },
    },
    outlined: {
        padding: spacing(1.375, 4),
    },
    outlinedPrimary: {
        border: `2px solid ${palette.primary.main}`,
        '&:hover': {
            color: palette.common.white,
            border: `2px solid ${palette.primary.main}`,
            backgroundColor: palette.primary.main,
        },
        '&.Mui-disabled': {
            border: `2px solid ${core_1.fade(palette.primary.main, 0.3)}`,
        },
    },
    containedPrimary: {
        '&:hover': {
            opacity: '0.8',
        },
        '&.Mui-disabled': {
            color: `${palette.common.white} !important`,
            backgroundColor: `${core_1.fade(palette.primary.main, 0.3)}`,
        },
    },
    textPrimary: {
        '&:hover': {
            background: 'none',
            opacity: '0.8',
        },
    },
    ternary: {
        backgroundColor: '#ffdf72',
        color: palette.primary.main,
        '&:hover': {
            backgroundColor: '#ffdf72',
            opacity: 0.8,
        },
        '&.Mui-disabled': {
            color: `${palette.primary.main} !important`,
            opacity: 0.3,
            backgroundColor: '#ffdf72',
        },
    },
    disabled: {
        pointerEvents: 'disabled',
    },
    disableHover: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    hasIcon: {
        padding: 0,
        height: 'auto',
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '& svg': {
            marginRight: spacing(1),
        },
    },
    underline: {
        padding: 0,
        position: 'relative',
        '&:before': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            width: '100%',
            left: 0,
            right: 0,
            height: 2,
            transition: transitions.create(['all'], { duration: 400, easing: 'ease-out' }),
            background: props => (props.color ? palette[props.color].main : palette.primary.main),
        },
    },
    arrow: {
        display: 'inline-flex',
        alignItems: 'center',
        position: 'relative',
        '& .arrow': {
            position: 'relative',
            right: 25,
            opacity: 0,
            transition: transitions.create(['all'], { duration: 400, easing: 'ease-out' }),
            willChange: 'right, opacity',
        },
        '&:hover': {
            '& button::before': {
                width: '120%',
            },
            '& .arrow': {
                opacity: 1,
                right: 0,
                marginLeft: spacing(1),
            },
        },
    },
}));
const HBButton = React.forwardRef((props, ref) => {
    const { className, label, isTernary, disableHover, hasIcon, showUnderline, hoverArrow, fullWidth, children, ...rest } = props;
    const classes = useStyles(props);
    const renderButton = () => (React.createElement(Button_1.default, Object.assign({ ref: ref, classes: {
            root: classes.root,
            outlined: classes.outlined,
            outlinedPrimary: classes.outlinedPrimary,
            containedPrimary: classes.containedPrimary,
            disabled: classes.disabled,
            textPrimary: classes.textPrimary,
        }, className: classnames_1.default(className, {
            [classes.ternary]: isTernary,
            [classes.disableHover]: disableHover,
            [classes.hasIcon]: hasIcon,
            [classes.underline]: props.variant === 'text' && showUnderline,
        }), fullWidth: fullWidth, disableElevation: true }, rest),
        label,
        children));
    return (React.createElement(React.Fragment, null, props.variant === 'text' && hoverArrow ? (React.createElement("div", { className: classes.arrow },
        renderButton(),
        React.createElement(HBIcon_1.default, { icon: "arrow_right", className: "arrow", color: props.color, fontSize: props.size === 'small' ? '4xsmall' : '3xsmall', strokeWidth: 4 }))) : (renderButton())));
});
exports.default = HBButton;
