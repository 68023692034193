"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const PageWrapperCheckout_1 = require("@components/common/layout/PageWrapperCheckout");
const CheckoutSteps_1 = require("@components/pages/checkout/CheckoutSteps");
const PageCheckout = ({ header, footer, page: { steps } }) => {
    return (React.createElement(PageWrapperCheckout_1.default, { header: header, footer: footer },
        React.createElement(CheckoutSteps_1.default, Object.assign({}, steps))));
};
exports.default = PageCheckout;
