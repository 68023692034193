"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const react_router_dom_1 = require("react-router-dom");
const Grid_1 = require("@material-ui/core/Grid");
const styles_1 = require("@material-ui/core/styles");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const Hidden_1 = require("@material-ui/core/Hidden");
const HBTypography_1 = require("@components/library/HBTypography");
const HBLink_1 = require("@components/library/HBLink");
const HBIcon_1 = require("@components/library/HBIcon");
const HBAccordion_1 = require("@components/library/HBAccordion/HBAccordion");
const styles = ({ palette, spacing, breakpoints, transitions }) => styles_1.createStyles({
    root: {
        transition: transitions.create(['all']),
        padding: spacing(0, 1.25, 0, 1.25),
        [breakpoints.up('md')]: {
            '& > div:not(:last-child)': {
                marginBottom: spacing(1),
            },
        },
        ['@media (min-width:1161px)']: {
            maxWidth: 400,
        },
        ['@media (min-width:960px) and (max-width:1160px)']: {
            maxWidth: 320,
        },
        ['@media (min-width:960px) and (max-width:999px)']: {
            maxWidth: 300,
        },
    },
    icon: {
        display: 'flex',
        flexShrink: 0,
        width: '40px',
        height: '40px',
        [breakpoints.down('sm')]: {
            width: spacing(5),
            height: spacing(5),
        },
    },
    title: {
        marginRight: spacing(1),
        transition: transitions.create(['all'], { easing: 'ease-out' }),
        [breakpoints.down('md')]: {
            wordBreak: 'break-all',
        },
        ['@media (min-width:960px) and (max-width:999px)']: {
            fontSize: '1rem',
        },
        display: 'inline-block',
        position: 'relative',
        '&:before': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            width: 0,
            right: 0,
            height: 2,
            transition: 'width .4s cubic-bezier(0.51, 0.18, 0, 0.88) .1s',
            background: palette.secondary.main,
        },
    },
    activeMenu: {
        '&:before': {
            width: '100%',
            left: 0,
        },
    },
    menuItemWrapper: {
        width: '100%',
        margin: 0,
        position: 'relative',
        transition: transitions.create(['all'], { easing: 'ease-out' }),
        '&:hover': {
            cursor: 'pointer',
        },
    },
    subMenuWrapper: {
        listStyle: 'none',
        padding: 0,
        margin: 0,
        [breakpoints.down('sm')]: {
            marginLeft: spacing(6),
            '& li': {
                padding: spacing(1),
            },
        },
    },
    desktopSubMenuWrapper: {
        height: '30rem',
        [breakpoints.up('md')]: {
            paddingTop: spacing(1.75),
        },
    },
    desktopIcon: {
        position: 'absolute',
        right: 50,
        opacity: 0,
        top: '50%',
        transform: 'translateY(-50%) rotate(-90deg)',
        transition: transitions.create(['all'], { duration: 400, easing: 'ease-out' }),
        willChange: 'right, opacity',
    },
    desktopIconHover: {
        opacity: 1,
        right: 30,
    },
    accordionHeader: {},
});
const getActiveMenu = (location, categoryItems = []) => {
    return (categoryItems === null || categoryItems === void 0 ? void 0 : categoryItems.find(category => (category === null || category === void 0 ? void 0 : category.to) === (location === null || location === void 0 ? void 0 : location.pathname))) || categoryItems[0];
};
const CategoryItems = ({ classes, items }) => {
    var _a;
    const location = react_router_dom_1.useLocation();
    const [activeMenu, setActiveMenu] = React.useState(getActiveMenu(location, items));
    const [expanded, setExpanded] = React.useState('');
    const [delayHandler, setDelayHandler] = React.useState(null);
    const handleMouseEnter = menu => (event) => {
        setDelayHandler(setTimeout(() => {
            setActiveMenu(menu);
            setExpanded((menu === null || menu === void 0 ? void 0 : menu.name) === expanded ? '' : (menu === null || menu === void 0 ? void 0 : menu.name) || '');
        }, 300));
    };
    const handleMouseLeave = () => {
        clearTimeout(delayHandler);
    };
    const handleActiveMenu = menu => (event) => {
        setActiveMenu(menu);
        setExpanded((menu === null || menu === void 0 ? void 0 : menu.name) === expanded ? '' : (menu === null || menu === void 0 ? void 0 : menu.name) || '');
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Grid_1.default, { container: true, item: true, direction: "column", wrap: "wrap", className: classes.root }, items.map(({ to, icon, name, subMenu }, idX) => (React.createElement(Grid_1.default, { item: true, key: name + idX.toString() },
            React.createElement(Hidden_1.default, { mdUp: true },
                React.createElement(HBAccordion_1.default, { hideBorder: true, key: name, label: React.createElement(Grid_1.default, { container: true, spacing: 2, alignItems: "center", wrap: "nowrap", component: HBLink_1.default, to: to },
                        React.createElement(Grid_1.default, { item: true },
                            React.createElement(HBIcon_1.default, { icon: icon, className: classes.icon })),
                        React.createElement(Grid_1.default, { item: true },
                            React.createElement(HBTypography_1.default, { variant: "h6", gutterBottom: true, className: classnames_1.default(classes.title, {
                                    [classes.activeMenu]: name === expanded,
                                }) }, name))), selected: name === expanded, onChange: () => { }, useButtonToSelect: handleActiveMenu({ to, icon, name, subMenu }) },
                    React.createElement("ul", { className: classes.subMenuWrapper }, subMenu === null || subMenu === void 0 ? void 0 : subMenu.map(({ name, to }, listIdX) => (React.createElement("li", { key: name + listIdX.toString() },
                        React.createElement(HBLink_1.default, { to: to },
                            React.createElement(HBTypography_1.default, { variant: "body1" }, name)))))))),
            React.createElement(Hidden_1.default, { smDown: true },
                React.createElement(Grid_1.default, { container: true, spacing: 2, alignItems: "center", role: "button", className: classes.menuItemWrapper, wrap: "nowrap", onMouseEnter: handleMouseEnter({ icon, name, subMenu, to }), onMouseLeave: handleMouseLeave, component: HBLink_1.default, to: to },
                    React.createElement(Grid_1.default, { item: true },
                        React.createElement(HBIcon_1.default, { icon: icon, className: classes.icon })),
                    React.createElement(Grid_1.default, { item: true },
                        React.createElement(HBTypography_1.default, { variant: "h6", noWrap: true, className: classnames_1.default(classes.title, {
                                [classes.activeMenu]: name === activeMenu.name,
                            }) }, name)),
                    React.createElement(Grid_1.default, { item: true },
                        React.createElement(HBIcon_1.default, { icon: "keyboard_arrow_down", fontSize: "2xsmall", color: "secondary", className: classnames_1.default(classes.desktopIcon, {
                                [classes.desktopIconHover]: name === activeMenu.name,
                            }) })))))))),
        React.createElement(Grid_1.default, { container: true, item: true },
            React.createElement(Hidden_1.default, { smDown: true },
                React.createElement(Grid_1.default, { spacing: 1, item: true, container: true, direction: "column", className: classes.desktopSubMenuWrapper }, (_a = activeMenu.subMenu) === null || _a === void 0 ? void 0 : _a.map(({ name, to }, idX) => (React.createElement(Grid_1.default, { item: true, key: name + idX.toString() },
                    React.createElement(HBLink_1.default, { to: to, showHoverline: true },
                        React.createElement(HBTypography_1.default, { variant: "body1" }, name))))))))));
};
exports.default = withStyles_1.default(styles)(CategoryItems);
