"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_hook_form_1 = require("react-hook-form");
const FormControl_1 = require("@material-ui/core/FormControl");
const FormControlLabel_1 = require("@material-ui/core/FormControlLabel");
const FormHelperText_1 = require("@material-ui/core/FormHelperText");
const HBCheckbox_1 = require("@components/library/HBCheckbox");
const RichText_1 = require("@components/common/utils/RichText");
const FormCheckboxField = (props) => {
    var _a;
    const { name = '', label, error, required, isHtmlLabel, disabled, validationRules, ...other } = props;
    const { control, errors } = react_hook_form_1.useFormContext();
    const errorMessage = (_a = errors[name]) === null || _a === void 0 ? void 0 : _a.message;
    return (React.createElement(FormControl_1.default, { fullWidth: true, error: Boolean(errorMessage), margin: "normal", required: required, disabled: disabled },
        React.createElement(react_hook_form_1.Controller, { render: props => (React.createElement(FormControlLabel_1.default, { control: React.createElement(HBCheckbox_1.default, Object.assign({}, other)), label: React.createElement(RichText_1.default, { content: label }), onChange: e => props.onChange(e.target.checked), checked: props.value })), name: name, control: control, rules: validationRules }),
        errorMessage && React.createElement(FormHelperText_1.default, null, errorMessage)));
};
exports.default = FormCheckboxField;
