"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Hidden_1 = require("@material-ui/core/Hidden");
const HBIcon_1 = require("@components/library/HBIcon");
const HBTypography_1 = require("@components/library/HBTypography");
const HBButton_1 = require("@components/library/HBButton");
const ProductCardButton = ({ icon, label, isTernary, ...rest }) => {
    const getLabel = () => (React.createElement(React.Fragment, null,
        React.createElement(Hidden_1.default, { smUp: true, implementation: "css" },
            React.createElement(HBIcon_1.default, { icon: icon, color: isTernary ? 'ternary' : 'default' })),
        React.createElement(Hidden_1.default, { xsDown: true, implementation: "css" },
            React.createElement(HBTypography_1.default, { variant: "button" }, label))));
    return React.createElement(HBButton_1.default, Object.assign({}, rest, { label: getLabel(), fullWidth: true }));
};
exports.default = ProductCardButton;
