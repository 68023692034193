"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Svg_1 = require("../../Svg");
const Beauty = ({ color, ...rest }) => (React.createElement(Svg_1.default, Object.assign({ width: "50", height: "60", viewBox: "0 0 50 60" }, rest),
    React.createElement("g", { transform: "translate(1 1)", stroke: "#000", strokeWidth: "2", fill: "none", fillRule: "evenodd", strokeLinecap: "round", strokeLinejoin: "round" },
        React.createElement("path", { d: "M47.215 43.292a4.54 4.54 0 0 0-6.873-.537 4.563 4.563 0 0 0-3.88-6.11 4.557 4.557 0 0 0-3.895 6.047 4.5 4.5 0 0 0-6.787.6 4.683 4.683 0 0 0 4.262 6.655 4.48 4.48 0 0 0-.942 6.655 4.65 4.65 0 0 0 7.4-2.32 4.65 4.65 0 0 0 7.398 2.32 4.48 4.48 0 0 0-.943-6.654 4.685 4.685 0 0 0 4.26-6.656z" }),
        React.createElement("circle", { cx: "36.498", cy: "47.75", r: "3.72" }),
        React.createElement("path", { d: "M24 57.75H5.25a5 5 0 0 1-5-5v-32.5a5 5 0 0 1 5-5h20a5 5 0 0 1 5 5V34m-10-18.75l-2.188-5.465A1.25 1.25 0 0 0 16.902 9h-3.307c-.512 0-.972.31-1.163.785L10.25 15.25" }),
        React.createElement("path", { d: "M15.248 0.25L15.248 9" }),
        React.createElement("path", { d: "M24 .25H7.678a2.5 2.5 0 0 0-1.928.91L1.343 6.5" }))));
exports.default = Beauty;
