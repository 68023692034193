"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
const palette_1 = require("./palette");
const typography_1 = require("./typography");
const overrides_1 = require("./overrides");
const theme = styles_1.createMuiTheme({
    props: {
        MuiButtonBase: {
            disableRipple: true,
        },
    },
});
theme.palette = palette_1.default;
theme.typography = typography_1.default;
theme.overrides = overrides_1.default(theme);
exports.default = styles_1.responsiveFontSizes(theme);
