"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
const styles = ({ palette, spacing, breakpoints, transitions, typography }) => styles_1.createStyles({
    locationWrapper: {
        paddingTop: spacing(1.2),
        paddingBottom: spacing(0.5),
        '& svg': {
            marginRight: spacing(0.6),
        },
    },
    addressText: {
        '&:after': {
            content: '"|"',
            margin: spacing(0, 0.6),
        },
    },
    overline: {
        ...typography.subtitle2,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        opacity: 1,
        [breakpoints.down('xs')]: {
            fontSize: spacing(1.25),
        },
    },
    header: {
        transition: transitions.create(['all'], { easing: 'ease-in-out' }),
        backgroundColor: palette.common.white,
        justifyContent: 'center',
        minHeight: '90px',
        ['@media (max-width:768px)']: {
            minHeight: '120px',
        },
    },
    wrapper: {
        flexWrap: 'nowrap',
        ['@media (max-width:768px)']: {
            flexWrap: 'wrap',
        },
    },
    searchWrapper: {
        flexGrow: 1,
        ['@media (max-width:768px)']: {
            order: 3,
            marginTop: spacing(3),
        },
    },
    mainMenu: {
        justifyContent: 'space-evenly',
        ['@media (max-width:768px)']: {
            justifyContent: 'flex-end',
        },
    },
    button: {
        margin: spacing(1),
        ['@media (max-width:1024px)']: {
            margin: spacing(1.1),
        },
    },
    search: {
        flexGrow: 1,
        transition: transitions.create(['all']),
        marginRight: spacing(4),
        paddingLeft: '20%',
        ['@media (max-width:1024px)']: {
            marginRight: spacing(1),
            paddingLeft: '10%',
        },
        ['@media (max-width:768px)']: {
            paddingLeft: 0,
        },
    },
});
exports.default = styles;
