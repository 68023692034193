"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const withLayout_1 = require("@components/common/layout/withLayout");
const StageGallery_1 = require("@components/pages/home/StageGallery");
const BrandGallery_1 = require("@components/pages/home/BrandGallery");
const TeaserGallery_1 = require("@components/pages/home/TeaserGallery");
const HBContainer_1 = require("@components/library/HBContainer");
const makeStyles_1 = require("@material-ui/core/styles/makeStyles");
const createStyles_1 = require("@material-ui/core/styles/createStyles");
const useStyles = makeStyles_1.default(({ breakpoints, spacing }) => createStyles_1.default({
    root: {},
    tileContainer: {
        [breakpoints.down('sm')]: {
            marginTop: spacing(3),
        },
    },
}));
const PageHome = (props) => {
    const classes = useStyles();
    const { page: { tiles = [], brandItems = [], slides = [] } = {} } = props || {};
    const firstTiles = (tiles === null || tiles === void 0 ? void 0 : tiles.length) > 2 ? [tiles[0], tiles[1]] : tiles;
    const secondTiles = tiles === null || tiles === void 0 ? void 0 : tiles.filter(item => !(firstTiles === null || firstTiles === void 0 ? void 0 : firstTiles.some(tile => tile === item)));
    return (React.createElement(React.Fragment, null,
        !!slides.length && React.createElement(StageGallery_1.default, { items: slides }),
        !!(firstTiles === null || firstTiles === void 0 ? void 0 : firstTiles.length) && (React.createElement(HBContainer_1.default, { mt: 7, mb: 3, className: classes.tileContainer },
            React.createElement(TeaserGallery_1.default, { items: firstTiles }))),
        !!(brandItems === null || brandItems === void 0 ? void 0 : brandItems.length) && (React.createElement(HBContainer_1.default, { my: 3 },
            React.createElement(BrandGallery_1.default, { items: brandItems }))),
        !!(secondTiles === null || secondTiles === void 0 ? void 0 : secondTiles.length) && (React.createElement(HBContainer_1.default, { my: 7, className: classes.tileContainer },
            React.createElement(TeaserGallery_1.default, { items: secondTiles })))));
};
exports.default = withLayout_1.default(PageHome, { paddingTop: 0, paddingBottom: 0 });
