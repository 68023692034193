"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Svg_1 = require("../../Svg");
const Misc = ({ color, ...rest }) => (React.createElement(Svg_1.default, Object.assign({ width: "60", height: "60", viewBox: "0 0 60 60" }, rest),
    React.createElement("g", { transform: "translate(1 1)", stroke: "#000", strokeWidth: "2", fill: "none", fillRule: "evenodd", strokeLinecap: "round", strokeLinejoin: "round" },
        React.createElement("path", { d: "M0.25 17.75L57.75 17.75" }),
        React.createElement("path", { d: "M.25 57.75v-55a2.5 2.5 0 0 1 2.5-2.5h52.5a2.5 2.5 0 0 1 2.5 2.5v55" }),
        React.createElement("path", { d: "M0.25 27.75L15.25 27.75" }),
        React.createElement("path", { d: "M0.25 37.75L15.25 37.75" }),
        React.createElement("path", { d: "M0.25 50.25L15.25 50.25" }),
        React.createElement("path", { d: "M49 42.345c0 1.604-1.3 2.905-2.905 2.905h-16.69a2.905 2.905 0 0 1-.918-5.663l9.263-2.67 9.25 2.67a2.905 2.905 0 0 1 2 2.758zM34.417 28.583a3.333 3.333 0 1 1 4.75 3.012 2.5 2.5 0 0 0-1.427 2.25v3.063M27.75 15.25a2.5 2.5 0 0 1-2.5 2.5h-15a2.5 2.5 0 1 1 0-5h15a2.5 2.5 0 0 1 2.5 2.5zm0-5a2.5 2.5 0 0 1-2.5 2.5h-15a2.5 2.5 0 1 1 0-5h15a2.5 2.5 0 0 1 2.5 2.5z" }),
        React.createElement("path", { d: "M15.25 27.75L29 27.75" }),
        React.createElement("path", { d: "M46.5 27.75L57.75 27.75" }),
        React.createElement("path", { d: "M15.25 57.75L15.25 17.75" }))));
exports.default = Misc;
