"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Svg_1 = require("../../Svg");
const BathAccessories = ({ color, ...rest }) => (React.createElement(Svg_1.default, Object.assign({ width: "50", height: "58", viewBox: "0 0 50 58" }, rest),
    React.createElement("g", { transform: "translate(1.037 1)", stroke: "#1A1A1A", strokeWidth: "2", fill: "none", fillRule: "evenodd", strokeLinecap: "round", strokeLinejoin: "round" },
        React.createElement("path", { d: "M30.963 48.402c1.375-.83 4.249-1.402 7.571-1.402 4.655 0 8.43 1.123 8.43 2.503 0 1.377-3.775 2.497-8.43 2.497-3.29 0-6.142-.562-7.53-1.377M46.751 11.88c1.069 6.287-2 12.108-6.854 13.004-4.854.897-9.655-3.47-10.722-9.756-1.067-6.291 2.003-12.114 6.857-13.01 4.856-.895 9.652 3.474 10.719 9.762zM29.963 56h-12a1 1 0 0 1-1-1V36a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v19a1 1 0 0 1-1 1z" }),
        React.createElement("path", { d: "M21.9634829 35L25.9634829 35 25.9634829 31 21.9634829 31z" }),
        React.createElement("path", { d: "M21.963 31h4a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1zm-9 24H1a1 1 0 0 1-1-1V16a1 1 0 0 1 1-1h11.963a1 1 0 0 1 1 1v38a1 1 0 0 1-1 1z" }),
        React.createElement("path", { d: "M29.963 28L26.963 28" }),
        React.createElement("path", { d: "M6.963 13.5L6.963 0" }),
        React.createElement("path", { d: "M38.963 47L38.963 25" }))));
exports.default = BathAccessories;
