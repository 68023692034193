"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const Grid_1 = require("@material-ui/core/Grid");
const CartItem_1 = require("@components/pages/cart/CartItem");
const HBTypography_1 = require("@components/library/HBTypography");
const LabelContext_1 = require("@components/common/context/LabelContext");
const HBContainer_1 = require("@components/library/HBContainer");
const Zero_1 = require("@components/library/icons/Zero");
const CartContentInnerStyles_1 = require("./CartContentInnerStyles");
const CartContentInner = ({ labels, classes, title, items }) => (React.createElement(Grid_1.default, { container: true, direction: "column", className: classes.root, wrap: "nowrap" },
    React.createElement(Grid_1.default, { item: true, className: classes.titleWrapper },
        React.createElement(HBTypography_1.default, { component: "h5", className: classes.title }, title)),
    React.createElement(Grid_1.default, { item: true, className: classes.cartContent }, !!(items === null || items === void 0 ? void 0 : items.length) ? (items.map(item => React.createElement(CartItem_1.default, { key: item._id, item: item }))) : (React.createElement(Grid_1.default, { item: true, className: classes.cartEmpty },
        React.createElement(HBContainer_1.default, { maxWidth: "xs" },
            React.createElement(Grid_1.default, { direction: "column", item: true, container: true, justify: "center", alignItems: "center" },
                React.createElement(Zero_1.default, null),
                React.createElement(HBTypography_1.default, { variant: "body1", textTransformNone: true, marginTop: 2 }, labels.CART_EMPTY_MESSAGE))))))));
exports.default = withStyles_1.default(CartContentInnerStyles_1.default)(LabelContext_1.withLabel(CartContentInner));
