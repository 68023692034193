"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Svg_1 = require("../Svg");
const Instagram = props => (React.createElement(Svg_1.default, Object.assign({}, props, { viewBox: "0 0 44 44" }),
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { d: "M0 0h44v44H0z" }),
        React.createElement("circle", { cx: "22", cy: "22", r: "21", stroke: "#b6956c", strokeWidth: "2" }),
        React.createElement("g", { fill: "#b6956c", fillRule: "nonzero", transform: "translate(12 12)" },
            React.createElement("path", { d: "M10 5c-2.734 0-5 2.227-5 5s2.227 5 5 5 5-2.266 5-5-2.266-5-5-5zm0 8c-1.646 0-3-1.354-3-3s1.354-3 3-3 3 1.354 3 3-1.354 3-3 3z" }),
            React.createElement("circle", { cx: "15", cy: "5", r: "1" }),
            React.createElement("path", { d: "M18.387 1.653C17.34.565 15.847 0 14.153 0H5.847C2.339 0 0 2.339 0 5.847v8.266c0 1.734.565 3.226 1.694 4.314C2.782 19.476 4.234 20 5.887 20h8.226c1.734 0 3.185-.565 4.234-1.573C19.435 17.38 20 15.887 20 14.153V5.847c0-1.694-.565-3.145-1.613-4.194zM18 14.08c0 1.228-.433 2.218-1.143 2.891-.71.674-1.694 1.03-2.877 1.03H5.94c-1.181 0-2.167-.356-2.876-1.03C2.354 16.257 2 15.267 2 14.04V5.92c0-1.187.355-2.177 1.064-2.89C3.734 2.356 4.759 2 5.941 2h8.118c1.182 0 2.168.356 2.877 1.07.67.712 1.064 1.702 1.064 2.85z" })))));
exports.default = Instagram;
