"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Step_1 = require("@components/pages/checkout/Step/Step");
const VoucherInfoStepForm_1 = require("@components/pages/checkout/Step/VoucherInfoStep/VoucherInfoStepForm");
const makeStyles_1 = require("@material-ui/core/styles/makeStyles");
const VoucherInfoStepSummary_1 = require("@components/pages/checkout/Step/VoucherInfoStep/VoucherInfoStepSummary");
const AppContext_1 = require("@components/common/context/AppContext");
const useStyles = makeStyles_1.default(({ spacing }) => ({
    root: {
        paddingTop: spacing(2),
        paddingBottom: spacing(0),
    },
}));
const VoucherInfoStep = ({ disabled, completed, title, form }) => {
    const classes = useStyles();
    const { state: { cart: { voucher } = {} } = {} } = AppContext_1.useAppContext();
    return (React.createElement(Step_1.default, { title: title, active: !disabled, completed: completed, noBackground: true, classes: { root: classes.root }, summary: (voucher === null || voucher === void 0 ? void 0 : voucher.id) && React.createElement(VoucherInfoStepSummary_1.default, { voucher: voucher.id }) }, !(voucher === null || voucher === void 0 ? void 0 : voucher.id) && React.createElement(VoucherInfoStepForm_1.default, { form: form })));
};
exports.default = VoucherInfoStep;
