"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Svg_1 = require("./Svg");
const G_1 = require("./Svg/G");
const EMailCircle = ({ color = 'primary', ...rest }) => (React.createElement(Svg_1.default, Object.assign({}, rest, { viewBox: "0 0 134 134" }),
    React.createElement(G_1.default, { fill: "none", fillRule: "evenodd", color: color, strokeWidth: "3", transform: "translate(2 2)" },
        React.createElement("circle", { cx: "65", cy: "65", r: "65" }),
        React.createElement("g", { strokeLinecap: "round", strokeLinejoin: "round" },
            React.createElement("rect", { height: "35.2", rx: "4.4", width: "50.6", x: "39.1", y: "47.099" }),
            React.createElement("path", { d: "M88.404 48.38L64.4 67.998l-24.004-19.62" })))));
exports.default = EMailCircle;
