"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Svg_1 = require("./Svg");
const Path_1 = require("./Svg/Path");
const Parking = ({ color = 'primary', ...rest }) => (React.createElement(Svg_1.default, Object.assign({}, rest, { viewBox: "0 0 44 50" }),
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement(Path_1.default, { d: "M22.092 1.167A23.623 23.623 0 0134.779 4.84c9.86 6.25 10.587 19.16 2.73 28.598a123.164 123.164 0 01-14.75 14.923 1.038 1.038 0 01-1.334 0 123.095 123.095 0 01-14.75-14.92C-1.18 24-.455 11.091 9.405 4.84a23.623 23.623 0 0112.687-3.674z", color: color, isStroke: true, strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2" }),
        React.createElement("path", { d: "M20.823 31.5v-6.936h1.854c2.529 0 4.478-.555 5.85-1.667 1.37-1.111 2.056-2.716 2.056-4.815 0-2.018-.643-3.537-1.93-4.555C27.364 12.51 25.484 12 23.011 12H16.5v19.5zm1.422-10.324h-1.422v-5.788h1.966c1.18 0 2.047.231 2.6.693.553.463.83 1.179.83 2.148 0 .96-.33 1.691-.99 2.194-.66.502-1.655.753-2.984.753z", fill: "#202020", fillRule: "nonzero" }))));
exports.default = Parking;
