"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const createStyles_1 = require("@material-ui/core/styles/createStyles");
const useApiRequest_1 = require("@components/common/utils/hooks/useApiRequest");
const ApiContext_1 = require("@components/common/context/ApiContext");
const HBButton_1 = require("@components/library/HBButton");
const HBIcon_1 = require("@components/library/HBIcon");
const HBTypography_1 = require("@components/library/HBTypography");
const LabelContext_1 = require("@components/common/context/LabelContext");
const styles = () => createStyles_1.default({
    signOutWrapper: {
        zIndex: 1,
        position: 'absolute',
        right: 25,
        top: 30,
        bottom: 'auto',
        left: 'auto',
        ['@media (max-width: 700px)']: {
            left: 0,
            bottom: 0,
            right: 'auto',
            top: 'auto',
        },
    },
});
const ProfileSignOut = ({ classes, api }) => {
    const { isLoading, onSubmit } = useApiRequest_1.default();
    const labels = LabelContext_1.useLabelContext();
    const handleLogout = async () => {
        await onSubmit(() => api.signOut({}), () => {
            window.location.reload();
        });
    };
    return (React.createElement("div", { className: classes.signOutWrapper },
        React.createElement(HBButton_1.default, { variant: "text", onClick: handleLogout, hasIcon: true, disabled: isLoading },
            React.createElement(HBIcon_1.default, { icon: "signOut", color: "secondary", fontSize: "xsmall" }),
            React.createElement(HBTypography_1.default, { color: "secondary", uppercase: true }, labels.SIGN_OUT))));
};
exports.default = ApiContext_1.withApi(withStyles_1.default(styles)(ProfileSignOut));
