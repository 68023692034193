"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const styles_1 = require("@material-ui/core/styles");
const G = ({ color, children, isFill, ...rest }) => {
    const { palette: { primary, secondary, common }, } = styles_1.useTheme();
    const pathColor = color === 'primary' ? primary.main : color === 'secondary' ? secondary.main : common.white;
    const props = rest;
    if (isFill) {
        props.fill = pathColor;
    }
    else {
        props.stroke = pathColor;
    }
    return React.createElement("g", Object.assign({}, props), children);
};
exports.default = G;
