"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const HBButton_1 = require("@components/library/HBButton");
const HBIcon_1 = require("@components/library/HBIcon");
const Grid_1 = require("@material-ui/core/Grid");
const createStyles_1 = require("@material-ui/core/styles/createStyles");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const styles = ({ palette, breakpoints }) => createStyles_1.default({
    button: {
        '& .MuiButton-iconSizeMedium > *:first-child': {
            fontSize: 16,
        },
        '&:hover': {
            backgroundColor: 'transparent',
        },
        [breakpoints.down('sm')]: {
            fontSize: 14,
            '& .MuiButton-iconSizeMedium > *:first-child': {
                fontSize: 14,
            },
        },
    },
    border: {
        borderTop: `1px solid ${palette.secondary.main}`,
        borderBottom: `1px solid ${palette.secondary.main}`,
    },
});
const SearchResetButton = ({ classes, border, onClick }) => {
    return (React.createElement(Grid_1.default, { item: true, container: true, justify: "center", className: classnames_1.default({ [classes.border]: border }) },
        React.createElement(HBButton_1.default, { color: "secondary", variant: "text", className: classes.button, startIcon: React.createElement(HBIcon_1.default, { icon: "back_arrow", fontSize: "2xsmall" }), onClick: onClick }, "zur\u00FCcksetzen")));
};
exports.default = withStyles_1.default(styles)(SearchResetButton);
