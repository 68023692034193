"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const HBTypography_1 = require("@components/library/HBTypography");
const HBIcon_1 = require("@components/library/HBIcon");
const HBFab_1 = require("@components/library/HBFab");
const AppContext_1 = require("@components/common/context/AppContext");
const useApiRequest_1 = require("@components/common/utils/hooks/useApiRequest");
const ApiContext_1 = require("@components/common/context/ApiContext");
const styles_1 = require("@material-ui/core/styles");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const LabelContext_1 = require("@components/common/context/LabelContext");
const ProductDetailContext_1 = require("@components/common/context/ProductDetailContext");
const styles = ({ palette }) => styles_1.createStyles({
    root: {
        '&.Mui-disabled': {
            opacity: 0.4,
            color: palette.common.white,
            backgroundColor: palette.primary.main,
            '&:hover': {
                color: palette.common.white,
                backgroundColor: palette.primary.main,
            },
        },
    },
});
const ProductAddtoCartButton = ({ api, classes, onlyIcon, outOfStock }) => {
    const { product, quantity } = ProductDetailContext_1.useProductDetailContext();
    const { dispatch } = AppContext_1.useAppContext();
    const { isLoading, onSubmit } = useApiRequest_1.default();
    const labels = LabelContext_1.useLabelContext();
    const handleAddToCart = async (id, amount) => {
        await onSubmit(() => api.addToCart({ oid: id, amount, isCart: false }), data => {
            dispatch({
                type: AppContext_1.ActionTypes.ADD_TO_CART,
                payload: data,
            });
        });
    };
    return (React.createElement(HBFab_1.default, { variant: onlyIcon ? 'round' : 'extended', color: outOfStock ? 'default' : 'primary', "aria-label": labels.ADD_TO_CART, onClick: () => handleAddToCart(product._id, quantity), disabled: outOfStock ? true : isLoading, className: classes.root },
        !onlyIcon && (React.createElement(HBTypography_1.default, { variant: "h6", fontMedium: true }, outOfStock ? labels.OUT_OF_STOCK : labels.ADD_TO_CART)),
        React.createElement(HBIcon_1.default, { icon: "cart", color: "default" })));
};
exports.default = ApiContext_1.withApi(withStyles_1.default(styles)(ProductAddtoCartButton));
