"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSearchContext = void 0;
const React = require("react");
const SearchUtils_1 = require("../utils/SearchUtils");
const react_router_dom_1 = require("react-router-dom");
const SearchContext = React.createContext({});
const getState = ({ allFilters = [], products = [], count = 0, filters = [], sortOptions = { title: 'test', items: [] }, selectedFilters = [], brandIds = [], sortBy = 'relevanz', terms = [], }) => ({
    products,
    count,
    brandIds,
    filters,
    sortOptions,
    selectedFilters,
    sortBy,
    terms,
    allFilters,
});
const SearchContextProvider = (props) => {
    const [initialSearchResults, setInitialSearchResults] = React.useState(() => getState(props));
    const [searchFilters, setSearchFilters] = React.useState(() => (props === null || props === void 0 ? void 0 : props.selectedFilters) || []);
    const [selectedFilterIds, setSelectedFilterIds] = React.useState(() => { var _a; return ((_a = props === null || props === void 0 ? void 0 : props.selectedFilters) === null || _a === void 0 ? void 0 : _a.map((f) => f.id)) || []; });
    const history = react_router_dom_1.useHistory();
    React.useEffect(() => {
        const newProps = getState(props);
        return setInitialSearchResults(() => newProps);
    }, [props]);
    React.useEffect(() => {
        const selectedFiltersObjs = selectedFilterIds.map((selectedFilterId) => { var _a; return (_a = initialSearchResults.allFilters) === null || _a === void 0 ? void 0 : _a.find(({ id }) => id === selectedFilterId); });
        setSearchFilters(selectedFiltersObjs);
    }, [selectedFilterIds]);
    const handleSelectedFilters = (filter) => {
        if ((filter === null || filter === void 0 ? void 0 : filter.isBrand) === true) {
            const { q } = SearchUtils_1.updateSearchBrand(filter.id, initialSearchResults.brandIds, history);
            setSelectedFilterIds(q);
        }
        else if (selectedFilterIds === null || selectedFilterIds === void 0 ? void 0 : selectedFilterIds.some(id => id === filter.id)) {
            setSelectedFilterIds([...selectedFilterIds.filter((id) => id !== filter.id)]);
            SearchUtils_1.removeSearchFilter(filter.id, history);
        }
        else {
            setSelectedFilterIds([...selectedFilterIds, filter.id]);
            SearchUtils_1.addSearchFilter(filter.id, history);
        }
    };
    const handleResetAllFilters = () => {
        SearchUtils_1.resetEntireSearch(history);
        setSelectedFilterIds([]);
    };
    return (React.createElement(SearchContext.Provider, { value: {
            initialSearchResults,
            selectedFilters: searchFilters,
            selectedFilterIds,
            setSelectedFilterIds,
            handleSelectedFilters,
            handleResetAllFilters,
        } }, props.children));
};
exports.default = SearchContextProvider;
exports.useSearchContext = () => React.useContext(SearchContext);
