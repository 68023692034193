"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const createStyles_1 = require("@material-ui/core/styles/createStyles");
const makeStyles_1 = require("@material-ui/core/styles/makeStyles");
const Box_1 = require("@material-ui/core/Box");
const useStyles = makeStyles_1.default(({ spacing, breakpoints }) => createStyles_1.default({
    root: {
        maxWidth: ({ maxWidth }) => !maxWidth
            ? breakpoints.values.lg
            : maxWidth === 'xs'
                ? Math.max(breakpoints.values.xs, 444)
                : breakpoints.values[maxWidth],
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingLeft: spacing(3),
        paddingRight: spacing(3),
        [breakpoints.down('sm')]: {
            paddingLeft: spacing(2),
            paddingRight: spacing(2),
        },
    },
}));
const HBContainer = ({ children, className, maxWidth, ...rest }) => {
    const styles = useStyles({ maxWidth });
    return (React.createElement(Box_1.default, Object.assign({}, rest, { className: classnames_1.default(className, styles.root) }), children));
};
exports.default = HBContainer;
