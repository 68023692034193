"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const createStyles_1 = require("@material-ui/core/styles/createStyles");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const styles_1 = require("@material-ui/core/styles");
const styles = ({ palette, spacing }) => createStyles_1.default({
    root: {
        display: 'block',
        height: '1.2em',
        background: styles_1.fade(palette.primary.main, 0.06),
    },
    width: {
        width: ({ width }) => `${width}%`,
        margin: '0 auto',
    },
    spacing: {
        marginTop: ({ mt }) => (!!mt ? spacing(mt) : 'auto'),
    },
});
const Skeleton = ({ className, classes, width, mt }) => (React.createElement("span", { className: classnames_1.default(className, classes.root, { [classes.width]: !!width, [classes.spacing]: !!mt }) }));
exports.default = withStyles_1.default(styles)(Skeleton);
