"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const createStyles_1 = require("@material-ui/core/styles/createStyles");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const SearchPageContext_1 = require("../../../common/context/SearchPageContext");
const FullWidthBackground_1 = require("@components/common/utils/FullWidthBackground/FullWidthBackground");
const core_1 = require("@material-ui/core");
const react_1 = require("react");
const ProductCard_1 = require("@components/pages/product/ProductCard");
const LoadingProductCard_1 = require("../LoadingProductCard");
const styles = ({ spacing, breakpoints }) => createStyles_1.default({
    root: {
        padding: spacing(0),
        position: 'relative',
        minHeight: '100vh',
    },
    grid: {
        outline: 'none',
        marginTop: spacing(1),
        marginBottom: spacing(1),
        display: 'flex',
        flexWrap: 'wrap',
    },
    gridItem: {
        padding: spacing(1.5),
        width: '25%',
        display: 'flex',
        flex: 'none',
        alignContent: 'stretch',
        boxSizing: 'border-box',
        '@media (max-width: 1024px)': {
            width: '33%',
        },
        '@media (max-width: 768px)': {
            width: '50%',
        },
        '@media (max-width: 480px)': {
            width: '50%',
        },
    },
    gridListContainer: {
        paddingTop: spacing(6),
        [breakpoints.down('sm')]: {
            paddingTop: spacing(4),
        },
    },
    footer: {
        padding: '1rem',
        textAlign: 'center',
    },
});
const SearchResults = ({ classes }) => {
    const { loadedResults: { products }, isLoading, endReached, setCurrentPageNumber, } = SearchPageContext_1.useSearchPageContext();
    const observer = react_1.useRef(null);
    const lastElementRef = react_1.useCallback(node => {
        if (isLoading)
            return;
        if (observer.current)
            observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && !endReached) {
                setCurrentPageNumber(prevPageNumber => prevPageNumber + 1);
            }
        });
        if (node)
            observer.current.observe(node);
    }, [isLoading, endReached]);
    return (React.createElement(FullWidthBackground_1.default, null,
        React.createElement(core_1.Grid, { container: true, direction: "column" },
            React.createElement(core_1.Grid, { item: true, className: classes.gridListContainer },
                React.createElement("div", { className: classes.grid }, products === null || products === void 0 ? void 0 :
                    products.map((product, index) => {
                        if (products.length === index + 1) {
                            return (React.createElement("div", { className: classes.gridItem },
                                React.createElement(ProductCard_1.default, Object.assign({ key: `${product.name}-${index}`, lastElementRef: lastElementRef }, product, { imgSize: 258 }))));
                        }
                        return (React.createElement("div", { className: classes.gridItem },
                            React.createElement(ProductCard_1.default, Object.assign({ key: `${product.name}-${index}` }, product, { imgSize: 258 }))));
                    }),
                    !!(products === null || products === void 0 ? void 0 : products.length) &&
                        isLoading &&
                        !endReached &&
                        [...Array(10).keys()].map(index => (React.createElement("div", { key: index, className: classes.gridItem },
                            React.createElement(LoadingProductCard_1.default, null)))))))));
};
exports.default = withStyles_1.default(styles)(SearchResults);
