"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
const styles = ({ spacing, breakpoints }) => styles_1.createStyles({
    gallery: {
        position: 'relative',
        display: 'flex',
        height: '160px',
        overflow: 'hidden',
    },
    galleryItem: {
        padding: spacing(1.75, 3),
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: spacing(3),
    },
});
exports.default = styles;
