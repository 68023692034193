"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Grid_1 = require("@material-ui/core/Grid");
const HBTypography_1 = require("@components/library/HBTypography");
const HBIcon_1 = require("@components/library/HBIcon");
const Box_1 = require("@material-ui/core/Box");
const LabelContext_1 = require("@components/common/context/LabelContext");
const PersonalInfoStepSummary = ({ labels, member }) => {
    var _a;
    return (React.createElement(Grid_1.default, { container: true, direction: "column" },
        React.createElement(Box_1.default, { display: "inline-flex" },
            React.createElement(HBTypography_1.default, { variant: "body2", opacity: 0.4, textTransformNone: true }, labels.EMAIL),
            React.createElement(HBIcon_1.default, { icon: "check", color: "ternary" })),
        React.createElement(HBTypography_1.default, { variant: "body1" }, (_a = member === null || member === void 0 ? void 0 : member.crmContact) === null || _a === void 0 ? void 0 : _a.email)));
};
exports.default = LabelContext_1.withLabel(PersonalInfoStepSummary);
