"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Svg_1 = require("../Svg");
const AmericanExpress = props => (React.createElement(Svg_1.default, Object.assign({}, props, { viewBox: "0 0 64 48" }),
    React.createElement("g", { fill: "none" },
        React.createElement("path", { d: "M18.62 9h26.76c.895 0 1.62.725 1.62 1.62v26.76A1.62 1.62 0 0 1 45.38 39H18.62A1.62 1.62 0 0 1 17 37.38V10.62c0-.895.725-1.62 1.62-1.62z", fill: "#fff" }),
        React.createElement("path", { d: "M22.95 29.532V24.79h4.763l.537.702.556-.702H47v4.415s-.476.322-1.026.327H35.899l-.606-.748v.748h-1.987v-1.276s-.271.178-.858.178h-.676v1.098h-3.009l-.537-.718-.545.718z", fill: "#000" }),
        React.createElement("path", { d: "M23.53 28.939v-3.556h2.845v.795H24.41v.609h1.918v.748H24.41v.632h1.964v.772zm13.987 0v-3.556h2.845v.795h-1.964v.609h1.909v.748h-1.909v.632h1.964v.772z", fill: "#fff" }),
        React.createElement("path", { d: "M26.407 28.939l1.366-1.756-1.398-1.8h1.083l.833 1.113.836-1.113h1.04l-1.38 1.778 1.37 1.778h-1.084l-.809-1.095-.789 1.095zm3.998-3.556v3.556h.868v-1.123h.89c.753 0 1.324-.42 1.324-1.237 0-.677-.448-1.196-1.214-1.196zm.868.804h.937c.243 0 .417.157.417.41 0 .237-.173.409-.42.409h-.934zm2.688-.804v3.556h.864v-1.263h.364l1.035 1.263h1.056l-1.137-1.31c.467-.04.948-.453.948-1.092 0-.749-.57-1.154-1.208-1.154zm.864.795h.987c.237 0 .41.191.41.375 0 .236-.224.374-.397.374h-1zm5.884 2.761v-.772h1.731c.256 0 .367-.142.367-.298 0-.15-.11-.3-.367-.3h-.782c-.68 0-1.059-.427-1.059-1.066 0-.57.347-1.12 1.357-1.12h1.685l-.364.8H41.82c-.279 0-.364.15-.364.294 0 .147.106.31.318.31h.82c.758 0 1.087.442 1.087 1.02 0 .622-.367 1.132-1.128 1.132zm3.319 0v-.772h1.731c.256 0 .367-.142.367-.298 0-.15-.11-.3-.367-.3h-.782c-.68 0-1.059-.427-1.059-1.066 0-.57.347-1.12 1.357-1.12h1.685l-.364.8h-1.457c-.279 0-.364.15-.364.294 0 .147.106.31.318.31h.82c.758 0 1.087.442 1.087 1.02 0 .622-.367 1.132-1.128 1.132z", fill: "#fff" }),
        React.createElement("path", { d: "M17 21.503l1.114-2.64h1.926l.632 1.479v-1.479h2.395l.376 1.069.365-1.069h10.75v.538s.564-.538 1.493-.538l3.488.013.62 1.459v-1.472h2.005l.551.839v-.839h2.141v4.742h-2.14l-.53-.841v.84h-2.943l-.297-.747h-.791l-.291.748h-1.997c-.799 0-1.31-.526-1.31-.526v.526h-3.01l-.598-.748v.748H19.755l-.296-.748h-.789l-.294.748H17z", fill: "#000" }),
        React.createElement("g", { fill: "#fff" },
            React.createElement("path", { d: "M18.548 19.456L17 23.012h1.008l.285-.712h1.66l.284.712h1.03l-1.546-3.556zm.572.828l.506 1.244h-1.013zm2.147 2.728v-3.556l1.359.005.79 2.293.771-2.298h1.348v3.556h-.854v-2.62l-.905 2.62h-.748l-.907-2.62v2.62zm4.979 0v-3.556h2.845v.796h-1.964v.608h1.918v.748h-1.918v.632h1.964v.772zm3.319-3.556v3.556h.864V21.75h.363l1.036 1.263h1.056l-1.137-1.31c.467-.04.948-.453.948-1.092 0-.749-.57-1.154-1.208-1.154zm.864.796h.987c.237 0 .41.19.41.374 0 .236-.224.374-.397.374h-1zm3.64 2.76h-.948v-3.556h.948zm2.106 0h-.186c-.9 0-1.446-.747-1.446-1.763 0-1.042.54-1.793 1.676-1.793h.932v.842h-.966c-.461 0-.787.38-.787.96 0 .688.373.977.91.977h.222zm1.813-3.556l-1.548 3.556h1.008l.285-.712h1.66l.284.712h1.03l-1.546-3.556zm.572.828l.506 1.244h-1.013zm2.384 2.728v-3.556h1.084l1.383 2.234v-2.234h.852v3.556h-1.048l-1.418-2.293v2.293z" })))));
exports.default = AmericanExpress;
