"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const HBContainer_1 = require("@components/library/HBContainer");
const Badge_1 = require("@material-ui/core/Badge");
const Grid_1 = require("@material-ui/core/Grid");
const AppBar_1 = require("@material-ui/core/AppBar");
const Hidden_1 = require("@material-ui/core/Hidden");
const Logo_1 = require("@components/common/layout/Logo");
const HBLink_1 = require("@components/library/HBLink");
const HBIcon_1 = require("@components/library/HBIcon");
const CategoryMenuFlyout_1 = require("./CategoryMenuFlyout");
const MenuItem_1 = require("./MenuItem");
const HeaderStyles_1 = require("./HeaderStyles");
const AppContext_1 = require("@components/common/context/AppContext");
const ElevationScroll_1 = require("@components/common/ElevationScroll");
const Search_1 = require("@components/common/layout/Header/Search");
const Header = ({ classes, logo, menu, addressText: { openingTimes, address, phone } = {} }) => {
    var _a, _b;
    const { state: { member, cart: { quantity } = {}, wishlist } = {} } = AppContext_1.useAppContext();
    const mainMenu = menu === null || menu === void 0 ? void 0 : menu.filter(item => item.type !== 'category');
    const categoryMenuItem = menu === null || menu === void 0 ? void 0 : menu.find(item => item.type === 'category');
    const getIconByType = (type, icon) => {
        switch (type) {
            case 'wishlist':
                return (React.createElement(Badge_1.default, { badgeContent: wishlist === null || wishlist === void 0 ? void 0 : wishlist.length, color: "error" },
                    React.createElement(HBIcon_1.default, { icon: icon })));
            case 'cart':
                return (React.createElement(Badge_1.default, { badgeContent: quantity, color: "error" },
                    React.createElement(HBIcon_1.default, { icon: icon })));
            case 'account':
                return !(member === null || member === void 0 ? void 0 : member.validated) ? React.createElement(HBIcon_1.default, { icon: icon }) : React.createElement(HBIcon_1.default, { icon: "member" });
            default:
                return React.createElement(HBIcon_1.default, { icon: icon });
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(HBContainer_1.default, null,
            React.createElement(Grid_1.default, { item: true, container: true, alignItems: "center", className: classes.locationWrapper },
                openingTimes && (React.createElement(Grid_1.default, { item: true },
                    React.createElement(Grid_1.default, { container: true, alignItems: "center" },
                        React.createElement(HBIcon_1.default, { icon: openingTimes === null || openingTimes === void 0 ? void 0 : openingTimes.icon, color: "primary", fontSize: "2xsmall", strokeWidth: 3 }),
                        React.createElement(HBLink_1.default, { className: classnames_1.default(classes.overline, classes.addressText), to: openingTimes === null || openingTimes === void 0 ? void 0 : openingTimes.to }, openingTimes === null || openingTimes === void 0 ? void 0 : openingTimes.label)))),
                React.createElement(Hidden_1.default, { implementation: "css", smDown: true }, address && (React.createElement(HBLink_1.default, { className: classnames_1.default(classes.overline, classes.addressText), to: address === null || address === void 0 ? void 0 : address.to }, address === null || address === void 0 ? void 0 : address.label))),
                phone && (React.createElement(HBLink_1.default, { className: classes.overline, to: phone === null || phone === void 0 ? void 0 : phone.to }, phone.label)))),
        React.createElement(ElevationScroll_1.default, null,
            React.createElement(AppBar_1.default, { position: "sticky", color: "default", className: classes.header, elevation: 0 },
                React.createElement(HBContainer_1.default, null,
                    React.createElement(Grid_1.default, { container: true, className: classes.wrapper },
                        React.createElement(Grid_1.default, { item: true, md: true, xs: 4 },
                            React.createElement(Logo_1.default, { logo: logo })),
                        React.createElement(Grid_1.default, { item: true, md: 6, xs: 12, className: classes.searchWrapper },
                            React.createElement(Grid_1.default, { container: true, direction: "row", alignItems: "flex-start", wrap: "nowrap" },
                                React.createElement(Grid_1.default, { item: true, className: classes.search },
                                    React.createElement(Search_1.default, null)),
                                categoryMenuItem && (React.createElement(MenuItem_1.default, { type: categoryMenuItem.type, label: categoryMenuItem.name, className: classes.button, MenuIcon: React.createElement(HBIcon_1.default, { icon: categoryMenuItem.icon }) }, categoryMenuItem.flyout && (React.createElement(CategoryMenuFlyout_1.default, { categoryItems: (_a = categoryMenuItem.flyout) === null || _a === void 0 ? void 0 : _a.categoryItems, brandItems: (_b = categoryMenuItem.flyout) === null || _b === void 0 ? void 0 : _b.brandItems })))))),
                        React.createElement(Grid_1.default, { item: true, md: true, xs: 8 },
                            React.createElement(Grid_1.default, { container: true, direction: "row", wrap: "nowrap", className: classes.mainMenu }, mainMenu === null || mainMenu === void 0 ? void 0 : mainMenu.map(({ name, to, type, icon }, idX) => (React.createElement(MenuItem_1.default, { key: idX.toString(), label: name, className: classes.button, MenuIcon: getIconByType(type, icon), to: to, type: type }))))))))),
        React.createElement("div", { id: "back-to-top-anchor" })));
};
exports.default = withStyles_1.default(HeaderStyles_1.default)(Header);
