"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const makeStyles_1 = require("@material-ui/core/styles/makeStyles");
const colorManipulator_1 = require("@material-ui/core/styles/colorManipulator");
const Fab_1 = require("@material-ui/core/Fab");
const useStyles = makeStyles_1.default(({ palette, spacing }) => ({
    root: {
        minHeight: '32px',
        width: '100%',
        display: 'inline-flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        boxShadow: 'none',
        '&:hover': {
            opacity: '0.8',
            boxShadow: 'none',
        },
        '&.Mui-disabled': {
            backgroundColor: 'transparent',
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
    },
    fabButtonExtended: {
        padding: '11px 65px 11px 24px',
        justifyContent: 'initial',
        '& svg': {
            position: 'absolute',
            right: '25px',
        },
    },
    fabButtonOutlined: {
        border: `1px solid ${colorManipulator_1.fade(palette.primary.main, 0.3)}`,
        backgroundColor: palette.primary.contrastText,
        '&:hover': {
            backgroundColor: `#E4E4E4 !important`,
        },
        '& svg': {
            opacity: ({ disabled }) => (disabled ? 0.3 : 1),
        },
    },
    fabButton: {
        width: ({ isXSmall }) => spacing(isXSmall ? 4 : 6),
        height: ({ isXSmall }) => spacing(isXSmall ? 4 : 6),
    },
}));
const HBFab = React.forwardRef(({ className, color = 'default', variant = 'round', disabled, isXSmall, ...rest }, ref) => {
    const classes = useStyles({ isXSmall, disabled });
    return (React.createElement(Fab_1.default, Object.assign({ className: classnames_1.default(className, classes.root, {
            [classes.fabButton]: variant !== 'extended',
            [classes.fabButtonExtended]: variant === 'extended',
            [classes.fabButtonOutlined]: color === 'default',
        }) }, rest, { variant: variant, color: color, disabled: disabled, ref: ref })));
});
exports.default = HBFab;
