"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Svg_1 = require("./Svg");
const Path_1 = require("./Svg/Path");
const G_1 = require("./Svg/G");
const KeyboardArrowDown = ({ color = 'primary', strokeWidth = 3, ...rest }) => (React.createElement(Svg_1.default, Object.assign({}, rest),
    React.createElement(G_1.default, { strokeWidth: strokeWidth, fill: "none", strokeLinecap: "round", strokeLinejoin: "round", color: color },
        React.createElement(Path_1.default, { d: "M1 6l10.642 11.842a.482.482 0 0 0 .716 0L23 6", isStroke: true, color: color }))));
exports.default = KeyboardArrowDown;
