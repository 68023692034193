"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Svg_1 = require("../../Svg");
const HomeAccessories = ({ color, ...rest }) => (React.createElement(Svg_1.default, Object.assign({ width: "59", height: "48", viewBox: "0 0 59 48" }, rest),
    React.createElement("g", { transform: "translate(1 1)", stroke: "#000", strokeWidth: "2", fill: "none", fillRule: "evenodd", strokeLinecap: "round", strokeLinejoin: "round" },
        React.createElement("g", { transform: "translate(0 3.56)" },
            React.createElement("g", { transform: "translate(17 11.44)" },
                React.createElement("path", { d: "M0 0L2.301 2.301" }),
                React.createElement("path", { d: "M7.118 0L4.817 2.301" }),
                React.createElement("path", { d: "M7.118 7.118L4.817 4.817" }),
                React.createElement("path", { d: "M0 7.118L2.301 4.817" }),
                React.createElement("circle", { cx: "3.559", cy: "3.559", r: "1.78" })),
            React.createElement("path", { d: "M9 38.441L9 41.441" }),
            React.createElement("path", { d: "M32 38.441L32 41.441" }),
            React.createElement("path", { d: "M37 35.058a3.379 3.379 0 0 1-1.024 2.412 3.432 3.432 0 0 1-2.45.97H7.474a3.434 3.434 0 0 1-2.45-.97A3.38 3.38 0 0 1 4 35.057V32.37a3.45 3.45 0 0 1 2.432-3.287A53.552 53.552 0 0 1 20.5 26.441a53.552 53.552 0 0 1 14.068 2.643A3.45 3.45 0 0 1 37 32.371v2.687z" }),
            React.createElement("path", { d: "M10.78 27.65c.843-2.969-.807-6.08-3.698-6.97-2.89-.89-5.941.775-6.834 3.728-.894 2.954.705 6.093 3.58 7.033m26.393-3.79c-.844-2.97.806-6.08 3.697-6.97 2.89-.89 5.941.774 6.834 3.727.894 2.954-.705 6.093-3.58 7.033" }),
            React.createElement("path", { d: "M35.595 20.44c1.353-6.105-.744-12.485-5.392-16.41C25.555.106 19.178-.667 13.802 2.042 8.426 4.75 5.004 10.462 5 16.73c.005 1.248.147 2.492.423 3.706" })),
        React.createElement("g", { transform: "translate(41.71)" },
            React.createElement("path", { d: "M2.291 41L12.291 41" }),
            React.createElement("path", { d: "M7.291 0.854L7.291 40.854" }),
            React.createElement("path", { d: "M14.236 5.98c0 3.773-3.187 6.833-7.118 6.833m0-5.125C3.187 7.688 0 4.628 0 .854M14.236 16.23c0 3.774-3.187 6.834-7.118 6.834m0-5.125C3.187 17.938 0 14.878 0 11.104" })))));
exports.default = HomeAccessories;
