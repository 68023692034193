"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Svg_1 = require("./Svg");
const G_1 = require("./Svg/G");
const Material = ({ color = 'primary', strokeWidth = 3, ...rest }) => (React.createElement(Svg_1.default, Object.assign({}, rest),
    React.createElement(G_1.default, { transform: "matrix(0 1 1 0 0 1)", color: color, strokeWidth: strokeWidth, fill: "none", fillRule: "evenodd", strokeLinecap: "round", strokeLinejoin: "round" },
        React.createElement("path", { d: "M0 12L22 12" }),
        React.createElement("path", { d: "M11 23L11 1" }))));
exports.default = Material;
