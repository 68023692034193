"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const createStyles_1 = require("@material-ui/core/styles/createStyles");
const SnackbarContent_1 = require("@material-ui/core/SnackbarContent");
const HBIcon_1 = require("@components/library/HBIcon");
const HBTypography_1 = require("@components/library/HBTypography");
const HBContainer_1 = require("@components/library/HBContainer");
const styles = ({ palette, spacing }) => createStyles_1.default({
    root: {
        position: 'relative',
    },
    content: {
        borderRadius: '24px',
    },
    success: {
        backgroundColor: '#e8e8e8',
        color: palette.primary.main,
    },
    error: {
        backgroundColor: '#BC4414',
        color: '#ffffff',
    },
    warning: {
        backgroundColor: '#e9ad52',
        color: palette.primary.main,
    },
    info: {
        backgroundColor: '#e8e8e8',
        color: palette.primary.main,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: spacing(4),
    },
    action: {
        marginLeft: 0,
        marginRight: 0,
        position: 'absolute',
        right: 30,
    },
});
const CustomSnackbarContent = ({ classes, className, message, onClose, variant, ...other }) => (React.createElement(HBContainer_1.default, { className: classes.root },
    React.createElement(SnackbarContent_1.default, Object.assign({ elevation: 0, className: classnames_1.default(classes.content, classes[variant], className), classes: { action: classes.action }, "aria-describedby": "client-snackbar", message: React.createElement(HBTypography_1.default, { variant: "subtitle2", id: "client-snackbar", className: classes.message, textTransformNone: true }, message), action: [
            React.createElement(HBIcon_1.default, { icon: "close", fontSize: "2xsmall", isButton: true, ButtonProps: {
                    key: 'close',
                    'aria-label': 'Close',
                    onClick: onClose,
                } }),
        ] }, other))));
exports.default = withStyles_1.default(styles)(CustomSnackbarContent);
