"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Grid_1 = require("@material-ui/core/Grid");
const HBTypography_1 = require("@components/library/HBTypography");
const HBIcon_1 = require("@components/library/HBIcon");
const Box_1 = require("@material-ui/core/Box");
const ShippingInfoStepSummary = ({ label, helperText, helperText1, description }) => (React.createElement(Grid_1.default, { item: true, container: true, direction: "column" },
    React.createElement(Grid_1.default, { item: true },
        React.createElement(Box_1.default, { display: "inline-flex" },
            React.createElement(HBTypography_1.default, { variant: "body1" }, label),
            React.createElement(HBIcon_1.default, { icon: "check", color: "ternary" }))),
    React.createElement(Grid_1.default, { item: true, container: true, wrap: "nowrap", justify: "space-between", spacing: 3 },
        React.createElement(Grid_1.default, { item: true },
            React.createElement(HBTypography_1.default, { variant: "body1" }, helperText)),
        React.createElement(Grid_1.default, { item: true },
            React.createElement(HBTypography_1.default, { variant: "subtitle1", noWrap: true }, helperText1))),
    { description } && (React.createElement(Grid_1.default, { item: true },
        React.createElement(Box_1.default, { display: "inline-flex" },
            React.createElement(HBTypography_1.default, { variant: "body1" }, description &&
                description.split('\\n').map((line, i) => (React.createElement(React.Fragment, { key: i },
                    line,
                    React.createElement("br", null))))))))));
exports.default = ShippingInfoStepSummary;
