"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const FormFieldContext_1 = require("@components/common/utils/Form/FormFieldContext");
const FormFieldContext_2 = require("@components/common/utils/Form/FormFieldContext/FormFieldContext");
const ApiContext_1 = require("@components/common/context/ApiContext");
const useApiRequest_1 = require("@components/common/utils/hooks/useApiRequest");
const FormContext_1 = require("@components/common/utils/Form/FormContext");
const AppContext_1 = require("@components/common/context/AppContext");
const PaymentInfoFieldDefaults = { acceptTerms: false };
const PaymentInfoStepForm = ({ api, form }) => {
    const { state: { steps } = {}, dispatch } = AppContext_1.useAppContext();
    const defaultValues = { ...PaymentInfoFieldDefaults };
    const { methods } = FormFieldContext_2.useFormHook({ defaultValues });
    const { onSubmit, isLoading, data, apiError } = useApiRequest_1.default();
    const allStepsCompleted = !Object.values(steps)
        .slice(0, 3)
        .find(step => !(step === null || step === void 0 ? void 0 : step.completed) || (step === null || step === void 0 ? void 0 : step.isEditing));
    React.useEffect(() => {
        if (!apiError && !!(data === null || data === void 0 ? void 0 : data.transactionId)) {
            dispatch({ type: AppContext_1.ActionTypes.ADD_PAYMENT_INFO, payload: data });
        }
    }, [data]);
    const handleSubmit = async (payload) => await onSubmit(() => api.checkoutOrder(payload));
    return (React.createElement(FormFieldContext_1.default, { methods: methods, onSubmit: handleSubmit },
        React.createElement(FormContext_1.default, Object.assign({}, form, { hideAsterisk: true, errors: apiError === null || apiError === void 0 ? void 0 : apiError.inputError, disabled: !allStepsCompleted || isLoading, isTernaryButton: true }))));
};
exports.default = ApiContext_1.withApi(PaymentInfoStepForm);
