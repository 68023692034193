"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const createStyles_1 = require("@material-ui/core/styles/createStyles");
const styles = ({ breakpoints, palette, spacing, transitions }) => createStyles_1.default({
    root: {
        height: '100vh',
        width: 480,
        display: 'flex',
        flexDirection: 'column',
        [breakpoints.down('xs')]: {
            width: '100vw',
        },
    },
    fullWidth: {
        width: '100%',
    },
    cartFooter: {
        padding: spacing(3),
        backgroundColor: palette.common.black,
        color: palette.common.white,
        position: 'sticky',
        bottom: 0,
        flexGrow: 0,
    },
    cartFooterVisible: {
        opacity: 1,
        visibility: 'visible',
        transform: 'translateY(0px)',
    },
    button: {
        marginTop: spacing(2),
    },
    divider: {
        backgroundColor: palette.common.white,
        margin: spacing(2, 0),
    },
});
exports.default = styles;
