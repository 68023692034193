"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
const core_1 = require("@material-ui/core");
const styles = ({ palette, spacing, breakpoints, transitions }) => styles_1.createStyles({
    root: {
        paddingTop: spacing(2),
        paddingBottom: spacing(2.5),
        [breakpoints.up('sm')]: {
            paddingBottom: spacing(2),
        },
        borderBottom: `1px solid ${core_1.fade(palette.primary.main, 0.3)}`,
    },
    imageWrapper: {
        display: 'block',
        position: 'relative',
        marginRight: spacing(1),
        width: 80,
        height: 80,
        [breakpoints.up('sm')]: {
            width: 112,
            height: 112,
        },
    },
    grow: {
        flexGrow: 1,
    },
    title: {
        width: 200,
        height: 52,
    },
    name: {
        lineHeight: 1.5,
    },
    deleteIcon: {
        marginRight: spacing(-0.875),
        transition: transitions.create(['opacity']),
        opacity: 0.3,
        '&:hover': {
            opacity: 1,
        },
    },
    quantityPicker: {
        '& button': {
            backgroundColor: core_1.fade(palette.primary.main, 0.01),
        },
    },
});
exports.default = styles;
