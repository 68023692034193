"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const HBLink_1 = require("@components/library/HBLink");
const HBTypography_1 = require("@components/library/HBTypography");
const HBImage_1 = require("@components/library/HBImage");
const TeaserItemStyles_1 = require("./TeaserItemStyles");
const ZoomOutImage_1 = require("@components/common/utils/ZoomOutImage");
const TeaserItem = ({ classes, title, subTitle, media, to }) => (React.createElement(HBLink_1.default, { to: to, className: classes.root },
    React.createElement(ZoomOutImage_1.default, null,
        React.createElement("div", { className: classes.wrapper },
            React.createElement(HBImage_1.default, { src: media === null || media === void 0 ? void 0 : media.path, alt: title, className: classes.img }),
            (title || subTitle) && (React.createElement("div", { className: classes.content },
                React.createElement("div", { className: classes.contentInner },
                    title && (React.createElement(HBTypography_1.default, { component: "p", className: classes.title }, title)),
                    subTitle && (React.createElement(HBTypography_1.default, { variant: "overline", textTransformNone: true, fontNormal: true, className: classes.subTitle }, subTitle)))))))));
exports.default = withStyles_1.default(TeaserItemStyles_1.default)(TeaserItem);
