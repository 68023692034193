"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const withStyles_1 = require("@material-ui/core/styles/withStyles");
const Grid_1 = require("@material-ui/core/Grid");
const HBContainer_1 = require("@components/library/HBContainer");
const Divider_1 = require("@material-ui/core/Divider");
const Box_1 = require("@material-ui/core/Box");
const HBTypography_1 = require("@components/library/HBTypography");
const HBLink_1 = require("@components/library/HBLink");
const HBIcon_1 = require("@components/library/HBIcon");
const FooterLegalBar_1 = require("@components/common/layout/Footer/FooterLegalBar");
const FooterStyles_1 = require("./FooterStyles");
const FooterLinks = ({ classes, links }) => (React.createElement(Grid_1.default, { container: true, spacing: 4 }, links.map((item, idX) => {
    var _a, _b, _c;
    return item.comp === 'customerService' ? (React.createElement(Grid_1.default, { item: true, key: idX.toString(), xs: 12, sm: 6, md: 3 },
        !!item.questions && (React.createElement(React.Fragment, null,
            React.createElement(HBTypography_1.default, { variant: "h6", marginBottom: 2 }, item.questions.name),
            React.createElement("ul", { className: classes.ul }, (_a = item.questions.info) === null || _a === void 0 ? void 0 : _a.map((infoItem, listIdX) => (React.createElement("li", { key: listIdX.toString() },
                React.createElement(Grid_1.default, { container: true },
                    React.createElement(Grid_1.default, { item: true, xs: 4 },
                        React.createElement(HBTypography_1.default, { variant: "body1" }, infoItem.key)),
                    React.createElement(Grid_1.default, { item: true, xs: true },
                        React.createElement(HBLink_1.default, { to: infoItem.to },
                            React.createElement(HBTypography_1.default, { variant: "body1" }, infoItem.value)))))))))),
        !!item.social && (React.createElement(Box_1.default, { mt: 5 },
            React.createElement(HBTypography_1.default, { variant: "h6", marginBottom: 2, noWrap: true }, item.social.name),
            React.createElement(Box_1.default, { component: "ul", className: classes.ul, display: "flex", flexWrap: "wrap" }, (_b = item.social.info) === null || _b === void 0 ? void 0 : _b.map((socialItem, listIdX) => (React.createElement("li", { key: listIdX.toString(), className: classes.socialIcons },
                React.createElement(HBLink_1.default, { to: socialItem.to, title: socialItem.alt },
                    React.createElement(HBIcon_1.default, { icon: socialItem.icon, fontSize: "large" })))))))))) : (React.createElement(Grid_1.default, { item: true, key: idX.toString(), xs: 12, sm: 6, md: 3 },
        React.createElement(HBTypography_1.default, { variant: "h6", marginBottom: 2, noWrap: true }, item.name),
        React.createElement("ul", { className: classes.ul }, (_c = item.list) === null || _c === void 0 ? void 0 : _c.map((listItem, listIdX) => (React.createElement("li", { key: listIdX.toString() },
            React.createElement(HBLink_1.default, { to: listItem.to, hoverArrow: true, showHoverline: true, className: classes.footerLinksMenuLink },
                React.createElement(HBTypography_1.default, { variant: "body1", noWrap: true, className: classes.footerLinksMenulinkText }, listItem.name))))))));
})));
const FooterServiceBar = ({ links, classes }) => (React.createElement("div", { className: classes.serviceBar },
    React.createElement(HBContainer_1.default, null,
        React.createElement(Grid_1.default, { container: true, spacing: 2 }, links === null || links === void 0 ? void 0 : links.map(({ name, items }, idX) => (React.createElement(Grid_1.default, { item: true, key: idX.toString(), xs: 12, sm: 4 },
            React.createElement(Grid_1.default, { container: true, spacing: 2, direction: "column", alignItems: "center" },
                React.createElement(Grid_1.default, { item: true },
                    React.createElement(HBTypography_1.default, { variant: "h6", align: "center" }, name)),
                React.createElement(Grid_1.default, { item: true, container: true, direction: "row", justify: "center", spacing: 1 }, items === null || items === void 0 ? void 0 : items.map((item, idX) => typeof item.icon === 'string' ? (React.createElement(Grid_1.default, { item: true, key: idX.toString() },
                    React.createElement(HBIcon_1.default, { icon: item.icon, className: classes.serviceIcon }))) : (React.createElement(Grid_1.default, { item: true, key: idX.toString(), className: classes.serviceIconWithTextWrapper },
                    React.createElement(Grid_1.default, { item: true, xs: 6, container: true, direction: "row", wrap: "nowrap", justify: "center", className: classes.serviceIconWithTextWrapperInner },
                        React.createElement(HBIcon_1.default, { key: idX.toString(), icon: item.icon.name, className: classes.serviceIconName }),
                        React.createElement(HBTypography_1.default, { align: "left", component: "p", variant: "caption", textTransformNone: true, className: classes.serviceIconText }, item.icon.text))))))))))))));
const Footer = ({ classes, footerLinks, serviceBar, legalBar }) => (React.createElement("footer", null,
    !!(serviceBar === null || serviceBar === void 0 ? void 0 : serviceBar.length) && React.createElement(FooterServiceBar, { links: serviceBar, classes: classes }),
    React.createElement("div", { className: classes.footerLinksWrapper },
        React.createElement(HBContainer_1.default, null,
            !!(footerLinks === null || footerLinks === void 0 ? void 0 : footerLinks.length) && React.createElement(FooterLinks, { classes: classes, links: footerLinks }),
            React.createElement(Divider_1.default, { className: classes.divider }),
            !!legalBar && React.createElement(FooterLegalBar_1.default, Object.assign({}, legalBar, { classNameLinks: classes.legalBarLinks }))))));
exports.default = withStyles_1.default(FooterStyles_1.default)(Footer);
