"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Svg_1 = require("./Svg");
const Path_1 = require("./Svg/Path");
const PhoneCircle = ({ color = 'primary', ...rest }) => (React.createElement(Svg_1.default, Object.assign({}, rest, { viewBox: "0 0 134 134" }),
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement(Path_1.default, { d: "M67 .5C30.273.5.5 30.273.5 67c0 36.727 29.773 66.5 66.5 66.5 36.727 0 66.5-29.773 66.5-66.5C133.5 30.273 103.727.5 67 .5zm0 3c35.07 0 63.5 28.43 63.5 63.5s-28.43 63.5-63.5 63.5S3.5 102.07 3.5 67 31.93 3.5 67 3.5z", color: color, fillRule: "nonzero" }),
        React.createElement(Path_1.default, { isStroke: true, d: "M70.35 88.537c-5.863-5.878-3.784-10.461-.6-13.645l2.054-2.054a5.238 5.238 0 017.408 0l8.89 8.893a3.059 3.059 0 01-1.009 4.998 30.539 30.539 0 01-39.822-39.822 3.059 3.059 0 014.998-1.009l8.898 8.886a5.238 5.238 0 010 7.407l-2.055 2.055c-3.176 3.176-7.766 5.262-13.644-.601", color: color, strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "3" }))));
exports.default = PhoneCircle;
